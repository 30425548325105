import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExpenseComponent } from './expense.component';


export const ExpenseRoutes: Routes = [{
  path: '',
  component: ExpenseComponent,
 }];
 
@NgModule({
  imports: [RouterModule.forChild(ExpenseRoutes)],
  exports: [RouterModule]
})


export class ExpenseRoutingModule { }
