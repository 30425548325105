<div class="uk-grid">
  <mat-progress-bar mode="indeterminate" class="inner-loader" [style.display]="spinner_div"></mat-progress-bar>
  <div class="uk-width-medium-1-10">
    <div class="header-container">
      <h3 class="heading_a uk-margin-bottom" style="font-weight: bolder;">Expense</h3>
    </div>
  </div>
</div>

<div class="uk-grid">
  <div class="uk-width-medium-2-10">
    <mat-form-field appearance="outline">
      <mat-label>Staff</mat-label>
      <mat-select [(ngModel)]="staff_id" (selectionChange)="getExpenseRequestList()">
        <mat-option (selectionChange)="getExpenseRequestList()">All</mat-option>
        <mat-option *ngFor="let data of staff_data" [value]="data.customer_id">
          {{ data.first_name }} {{ data.last_name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="uk-width-medium-2-10">
    <mat-form-field appearance="outline">
      <mat-label> Status</mat-label>
      <mat-select [(ngModel)]="status" (selectionChange)="getExpenseRequestList()">
        <mat-option value="">All</mat-option>
        <mat-option value="Submitted">Pending</mat-option>
        <mat-option value="Approved">Approved</mat-option>
        <mat-option value="Rejected">Rejected</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="uk-width-medium-2-10">
    <mat-form-field appearance="outline">
      <mat-label>From Date</mat-label>
      <input matInput [matDatepicker]="frompicker" (dateChange)="getExpenseRequestList();getPendingExpenseDetails();"
        [min]="org_est_date" [max]="to_date" readonly placeholder="Choose a date" [(ngModel)]="from_date">
      <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
      <mat-datepicker #frompicker></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="uk-width-medium-2-10">
    <mat-form-field appearance="outline">
      <mat-label>To Date</mat-label>
      <input matInput [matDatepicker]="topicker" (dateChange)="getExpenseRequestList();getPendingExpenseDetails();"
        [min]="from_date" [max]="maxToDate" readonly placeholder="Choose a date" [(ngModel)]="to_date">
      <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
      <mat-datepicker #topicker></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="uk-width-medium-2-10">
    <ul style="float:right;">
      <li> <a class="show-link" (click)="export_to_csv();" style="font-size: 15px;" [style.color]="main_text_color">
          <i class="material-icons" [style.color]="main_text_color">file_download</i> Export</a></li>
    </ul>
  </div>
</div>

<div class="uk-grid">
  <div class="uk-grid uk-child-width-1-6" style="margin-top: 0px;">
    <!-- Green Cards -->
    <div *ngIf="status==='Submitted' || status===''">
      <div class="md-card green-card md-card-responsive">
        <div class="md-card-content">
          <h3 style="margin-bottom: 4px;">Pending Expense</h3>
          <p style="margin: 0; font-size: 20px;" *ngIf="request_list!=='Request list not found'">{{pending_expenses}}</p>
          <p style="margin: 0; font-size: 20px;" *ngIf="request_list=='Request list not found'">0</p>
        </div>
      </div>
    </div>
    <div *ngIf="status==='Approved' || status===''">
      <div class="md-card green-card md-card-responsive">
        <div class="md-card-content">
          <h3 style="margin-bottom: 4px;">Approved Expense</h3>
          <p style="margin: 0; font-size: 20px;" *ngIf="request_list!=='Request list not found'">{{approved_expense}}</p>
          <p style="margin: 0; font-size: 20px;" *ngIf="request_list=='Request list not found'">0</p>
        </div>
      </div>
    </div>
    <div *ngIf="status==='Rejected' || status===''">
      <div class="md-card green-card md-card-responsive">
        <div class="md-card-content">
          <h3 style="margin-bottom: 4px;">Rejected Expense</h3>
          <p style="margin: 0; font-size: 20px;" *ngIf="request_list!=='Request list not found'">{{rejected_expense}}</p>
          <p style="margin: 0; font-size: 20px;" *ngIf="request_list=='Request list not found'">0</p>
        </div>
      </div>
    </div>
  
    <!-- Red Cards -->
    <div *ngIf="status==='Submitted' || status===''">
      <div class="md-card red-card md-card-responsive">
        <div class="md-card-content">
          <h3 style="margin-bottom: 4px;">Pending Amount</h3>
          <p *ngIf="request_list!=='Request list not found' && pending_amount !=''" style="margin: 0; font-size: 20px;">
            {{ currency }}&nbsp;{{pending_amount}}
          </p>
          <p *ngIf="request_list=='Request list not found' || pending_amount==''" style="margin: 0; font-size: 20px;">
            {{ currency }}&nbsp;0
          </p>
        </div>
      </div>
    </div>
    <div *ngIf="status==='Approved' || status===''">
      <div class="md-card red-card md-card-responsive">
        <div class="md-card-content">
          <h3 style="margin-bottom: 4px;">Approved Amount</h3>
          <p *ngIf="request_list!=='Request list not found' && approved_amount !=''" style="margin: 0; font-size: 20px;">
            {{ currency }}&nbsp;{{approved_amount}}
          </p>
          <p *ngIf="request_list=='Request list not found' || approved_amount==''" style="margin: 0; font-size: 20px;">
            {{ currency }}&nbsp;0
          </p>
        </div>
      </div>
    </div>
    <div *ngIf="status==='Rejected' || status===''">
      <div class="md-card red-card md-card-responsive">
        <div class="md-card-content">
          <h3 style="margin-bottom: 4px;">Rejected Amount</h3>
          <p *ngIf="request_list!=='Request list not found' && rejected_amount !=''" style="margin: 0; font-size: 20px;">
            {{ currency }}&nbsp;{{rejected_amount}}
          </p>
          <p *ngIf="request_list=='Request list not found' || rejected_amount==''" style="margin: 0; font-size: 20px;">
            {{ currency }}&nbsp;0
          </p>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="md-card no-shadow" *ngIf="request_list=='Request list not found'">
  <div class="md-card-content">No Data Available</div>
</div>

<br>
<div class="uk-grid" *ngIf="request_list!=='Request list not found'" style="margin-top:0px;">

  <div class="uk-width-medium-1-4 uk-width-small-1-4" *ngFor="let data of request_list" style="margin-bottom: 20px;">

    <div class="md-card md-card-overlay no-shadow" style="border-radius: 9px;">

      <div class="md-card-content">
        <div class="uk-grid">

          <div class="uk-width-medium-1-1">

            <h3>{{data.requested_by | titlecase}}</h3>

            <p style="margin:2px 0px;">
              <span><b>Requested Date:</b> {{data.expense_date}}</span>
            </p>
            <p style="margin:5px 0px;"><span><b>Amount :</b> {{currency}} {{data.amount}}</span></p>
            <p style="margin:5px 0px;"><span><b>Merchant Name:</b> {{data.merchant_name}}</span></p>
          </div>
        </div>
      </div>
      <div class="md-card-overlay-content">
        <div class="uk-clearfix md-card-overlay-header">
          <mat-chip style="background-color: #ff9933" selected="true"
            *ngIf="data.status=='Submitted'">Pending</mat-chip>
          <mat-chip style="background-color: #02CC66" selected="true"
            *ngIf="data.status=='Approved'">Approved</mat-chip>
          <mat-chip style="background-color:#d9534f" selected="true" *ngIf="data.status=='Rejected'">Rejected</mat-chip>
          <i class="md-icon md-icon material-icons md-card-overlay-toggler"
            (click)="getExpenseRequestDetails(data.request_id)">&#xE5D4;</i>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #expenseDetails>
  <h4 mat-dialog-title>Expense Details
    <button mat-icon-button [mat-dialog-close]=" " style="float:right;"><mat-icon aria-hidden="false"
        (click)="closePopup()">close</mat-icon></button>
  </h4>
  <mat-dialog-content>
    <ng-container *ngFor="let data of request_details">
      <div style="margin-top: 3px; border:0px;margin-bottom: 11px;">
        <div class="card-body" style="padding:10px;">

          <div class="uk-grid">
            <div class="uk-width-medium-7-10">
              <p style="margin:0px 0px 2px 0px;"><b>Requested By :</b> {{data.requested_by | titlecase}} </p><br>
              <p style="margin:0px 0px 2px 0px;"><b>Expense Date :</b> {{data.expense_date}} </p><br>
              <p style="margin:0px 0px 2px 0px;"><b>Merchant Name :</b> {{data.merchant_name}} </p><br>
              <p style="margin:0px 0px 2px 0px;"><b>Amount :</b> {{currency}} {{data.amount}} </p><br>
              <ng-container *ngIf="isImage(data.image_path)">
                <img [src]="data.image_path" style="cursor: zoom-in;margin-bottom: 15px;"
                  (click)="enlargeImage(data.image_path);">
              </ng-container>
              <ng-container *ngIf="isPDF(data.image_path)">
                <iframe [src]="data.sanitizedUrl" style="width:100%; height:200px;margin-bottom: 10px;"></iframe>
                <a (click)="viewAttachment(data.image_path);">View Attachment</a>
              </ng-container>
              <br><br>
              <p style="margin:0px 0px 2px 0px;"><b>Description :</b>{{data.description}}</p>
            </div>

            <div class="uk-width-medium-3-10" style="padding-left:15px; text-align:right;">
              <mat-chip style="background-color: #ff9933" selected="true"
                *ngIf="data.status=='Submitted'">Pending</mat-chip>
              <mat-chip style="background-color: #02CC66" selected="true"
                *ngIf="data.status=='Approved'">Approved</mat-chip>
              <mat-chip style="background-color: #d9534f" selected="true"
                *ngIf="data.status=='Rejected'">Rejected</mat-chip>
            </div>
          </div>
        </div>
      </div>
      <br>
      <ng-container *ngIf="data.status=='Submitted'">
        <ng-container>
          <button mat-raised-button (click)="selectedStatus('Approved')" style="float: right;"
            style="float:right; margin-left: 20px;background-color: #02CC66;" [mat-dialog-close]=" ">Approve</button>
          <button mat-raised-button (click)="selectedStatus('Rejected')" color="warn" style="float:right;width: 28%"
            [mat-dialog-close]=" ">Reject</button>
        </ng-container>
      </ng-container>
    </ng-container>
  </mat-dialog-content>
</ng-template>

<ng-template #receipt>
  <h4 mat-dialog-title>
    Receipt/Proof
    <button mat-icon-button style="float:right;" [mat-dialog-close]=" "><mat-icon>close</mat-icon></button>
  </h4>
  <mat-dialog-content>
    <img [src]="imagePath" class="fullscreen-image">
  </mat-dialog-content>
  <mat-dialog-actions>
    <div>
      <button mat-raised-button [style.background-color]="button_background_color" [style.color]="button_text_color"
        [mat-dialog-close]=" ">Cancel</button>
    </div>
  </mat-dialog-actions>
</ng-template>

<ng-template #rejectionReason>
  <h5 mat-dialog-title>
    Enter your Reason here
    <button mat-icon-button style="float:right;" [mat-dialog-close]=" "><mat-icon>close</mat-icon></button>
  </h5>
  <mat-form-field>
    <input matInput placeholder="Reason" [(ngModel)]="rejection_reason" required>
  </mat-form-field>
  <div>
    <button mat-raised-button [style.background-color]="button_background_color" [style.color]="button_text_color"
      [disabled]="!rejection_reason" (click)="updateExpenseRequestStatus();" [mat-dialog-close]=" ">Submit</button>
  </div>
</ng-template>