import { Injectable } from "@angular/core";
import { AppComponent } from "../../app.component";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map, catchError, tap } from "rxjs/operators";
import { Subject, Observable } from "rxjs";

@Injectable()
export class BulkStudentUploadService {
  httpOptions;
  temp_date: any;
  date_format: any;
  add_player_form1_submit:any;
  private _fileUploaded: Subject<any> = new Subject();
  fileUploaded: Observable<any> = this._fileUploaded.asObservable();

  add_player_final_submit:any;

  constructor(private http_client: HttpClient) {
    this.date_format = sessionStorage.getItem("date_format");
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
    };
  }

  dateFormater(date)
  {  
    var day:any;
    var month:any;
    day=date.getDate();
    month=(date.getMonth() + 1);
    if(day<10)
    {
      day="0"+day;
    }
    if(month<10)
    {
      month="0"+month;
    }
    if (this.date_format=="Y-m-d") 
    {      
      return this.temp_date = date.getFullYear()+"-"+month+"-"+day;
    }
    if (this.date_format=="d-m-Y") 
    {      
      return this.temp_date = day+ "-"+month+"-"+date.getFullYear();
    }
    if (this.date_format=="Y/m/d") 
    {      
      return this.temp_date = date.getFullYear()+"/"+ month+"/"+day;
    }
  
    if (this.date_format=="d/m/Y") 
    {      
      return this.temp_date = day+"/"+month+"/"+date.getFullYear();
    }
  
    if (this.date_format=="m/d/Y") 
    {      
      return this.temp_date =day+"/"+month+"/"+date.getFullYear();
    }   
       
      
  }

  getUploadFileDetail(organization_id) {
    const params = new HttpParams().set("organization_id", organization_id);
    const url =
      AppComponent.API_URL +
      "web_bulkupload_old/GetOrganizationPlayerBulkUploadFilesDetails";

    return this.http_client.post(url, params, this.httpOptions).pipe(
      tap(response => {
        if (response) {
          this._fileUploaded.next(response);
        } else {
          return "";
        }
      })
    );
  }

  revalidatePlayerData(login_customer_id,file_metadata_id,upload_parent_details_flag, player_data) {
    let params = new HttpParams();
    params = params.append("login_customer_id", login_customer_id);
    params = params.append("file_metadata_id", file_metadata_id);
    params = params.append("upload_parent_details_flag", upload_parent_details_flag);
    params = params.append("player_data", player_data);
    const url = AppComponent.API_URL + "web_bulkupload_old/RevalidatePlayerData";

    return this.http_client.post(url, params, this.httpOptions).pipe(
      tap(response => {
        if (response) {
          console.log(response);
        }
      })
    );
  }

  continue_to_revalidate_data(file_metadata_id) {
    const params = new HttpParams().set("file_metadata_id", file_metadata_id);
    const url =
      AppComponent.API_URL + "web_bulkupload_old/GetBulkPlayerInvalidDataDetails";

    return this.http_client.post(url, params, this.httpOptions).pipe(
      tap(response => {
        console.log(response);
      })
    );
  }

  get_valid_player_details(organization_id, file_metadata_id) {
    let params = new HttpParams();

    params = params.append("organization_id", organization_id);
    params = params.append("file_metadata_id", file_metadata_id);

    const url = AppComponent.API_URL + "web_bulkupload_old/GetValidPlayerDetails";

    return this.http_client.post(url, params, this.httpOptions).pipe(
      tap(response => {
        console.log(response);
      })
    );
  }

  get_player_fee_and_kit_details(academy_id,academy_center_id){
    let params = new HttpParams();

    params = params.append('academy_id',academy_id);
    params = params.append('academy_center_id',academy_center_id);

    const url = AppComponent.API_URL + 'mobile/SendAddPlayerForm2Data';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                let add_player_form2_details =data['Send Add Player form 2 Data State'];
                if(add_player_form2_details.length!=0)
                {         
                  return add_player_form2_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }

  AddPlayerDetails(academy_center_id,first_name,last_name,email_address,gender,date_of_birth,blood_group,
    mobile_number,dominant_side,school,medical_condition,organization_id,player_image,thumbnail_image,
    emailExistFlag,customer_id,player_id,academy_group_id,file_metadata_id,temp_uploaded_player_id,uin)
  { 
    let params = new HttpParams();   
    params =params.append('academy_center_id[0]', academy_center_id);
    params =params.append('first_name', first_name);
    params =params.append('last_name', last_name);
    params =params.append('gender', gender);
    params =params.append('date_of_birth', date_of_birth);
    params =params.append('organization_id', organization_id);       
    params =params.append('academy_group_id', academy_group_id);
    params =params.append('file_metadata_id', file_metadata_id);       
    params =params.append('temp_uploaded_player_id', temp_uploaded_player_id);

    if(emailExistFlag=="Y")
    {
      params =params.append('emailExistFlag', emailExistFlag);
      params =params.append('customer_id', customer_id);  
      if(player_id!=undefined)
      {    
      params =params.append('player_id', player_id);
      }
      else{
        params =params.append('player_id', '');
      }
      
    }
    if(email_address!='' && email_address!=null && email_address!=undefined)
    {
      params =params.append('email_address', email_address); 
    }
    if(mobile_number!='' && mobile_number!=null && mobile_number!=undefined)
    {
      params =params.append('mobile_number', mobile_number); 
    }
    if(school!='' && school!=null)
    {
      params =params.append('school', school);
    }
    if(medical_condition!='' && medical_condition!=null)
    {
      params =params.append('medical_condition', medical_condition);
    }
    if(dominant_side!='' && dominant_side!=null)
    {
      params =params.append('dominant_side', dominant_side);
    }
    if(blood_group!='' && blood_group!=null)
    {
      params =params.append('blood_group', blood_group);
    }

    if(player_image!=undefined)
    {
      params =params.append('player_image', player_image);
      params =params.append('thumbnail_image', thumbnail_image);
    }
     if(player_image!=undefined)
    {
      params =params.append('player_image', player_image);
      params =params.append('thumbnail_image', thumbnail_image);
    }

    if(uin!='' && uin!=null)
    {
      params =params.append('uin', uin);
    }

    const addPlayer_form1_details_url = AppComponent.API_URL + 'player/AddPlayerDetails';
    return this.http_client.post(addPlayer_form1_details_url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.add_player_form1_submit =data;
                if(this.add_player_form1_submit.length!=0)
                {         
                  return this.add_player_form1_submit;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }

  AddPlayer_final_Submit(academy_center_id,player_id,organization_id,Customer_id,
    player_customer_id,register_student_form2,parents_contact_form,parent_info_flag,academy_group_id,file_metadata_id,temp_uploaded_player_id)
    { 
      
      let params = new HttpParams();   
      params =params.append('academy_center_id', academy_center_id);
      params =params.append('player_id', player_id);
      params =params.append('organization_id', organization_id);
      
      params =params.append('player_customer_id', player_customer_id);  
      params =params.append('academy_group_id', academy_group_id); 
      params =params.append('file_metadata_id', file_metadata_id);  
      params =params.append('temp_uploaded_player_id', temp_uploaded_player_id);  
    
      if(Customer_id!=undefined)
      {
        params =params.append('customer_id', Customer_id);
      }
    
      if(register_student_form2.Kit_Details_select!=undefined)
      {
        params =params.append('kits_size', register_student_form2.Kit_Details_select); 
      }
      var flag=false;
    
      if(parents_contact_form.father_first_name!=null)
      {
        params =params.append('father[first_name]', parents_contact_form.father_first_name);
        flag=true;
      }
      if(parents_contact_form.father_last_name!=null)
      {
        params =params.append('father[last_name]', parents_contact_form.father_last_name);
        flag=true;
      }
      if(parents_contact_form.father_email!=null)
      {
       params =params.append('father[email_address]', parents_contact_form.father_email);
       flag=true;
      }
      if(parents_contact_form.father_mobile!=null)
      {
        params =params.append('father[mobile_number]', parents_contact_form.father_mobile);
        flag=true;
      }
      if(parents_contact_form.father_occupation!=null)
      {
        params =params.append('father[occupation]', parents_contact_form.father_occupation);
        flag=true;
      }
      if(parents_contact_form.mother_first_name!=null)
      {
        params =params.append('mother[first_name]', parents_contact_form.mother_first_name);
        flag=true;
      }
      if(parents_contact_form.mother_last_name!=null)
      {
        params =params.append('mother[last_name]', parents_contact_form.mother_last_name);
        flag=true;
      }
      if(parents_contact_form.mother_email!=null)
      {
        params =params.append('mother[email_address]', parents_contact_form.mother_email);
        flag=true;
      }
      if(parents_contact_form.mother_mobile!=null)
      {
        params =params.append('mother[mobile_number]', parents_contact_form.mother_mobile);
        flag=true;
      }
      if(parents_contact_form.mother_occupation!=null)
      {
        params =params.append('mother[occupation]', parents_contact_form.mother_occupation);
        flag=true;
      } 
    
      if(flag==true)
      {
        params =params.append('parent_info_flag', "Y");
      }
      else
      {
        params =params.append('parent_info_flag', "N");
      }
     let count:number=0;
     register_student_form2['fees_type'].forEach((element,i) => {
       
       if(element.fee_type=='Onetime')
       {     
         
         params =params.append('oneTime_payment_type['+count+']',element.fee_type);
         params =params.append('oneTime_plan_id['+count+']', element.oneTime_plan_id);
         params =params.append('oneTime_payment_due_date['+count+']', this.dateFormater(element.oneTime_payment_due_date));
         params =params.append('oneTime_frequency['+count+']', element.oneTime_frequency);
         params =params.append('onetime_invoice_amount['+count+']', element.onetime_invoice_amount); 
         params =params.append('oneTime_training_start_date['+count+']',element.training_start_date); 
        count ++;
       }
       if(element.fee_type=='Recurring')
       {
        params =params.append('plan_id', element.recurring_plan_id);
        params =params.append('payment_type', element.fee_type);
        params =params.append('frequency', element.recurring_frequency);
        params =params.append('invoice_amount', element.recurring_invoice_amount);
        params =params.append('training_start_date', this.dateFormater(element.recurring_training_start_date));
        params =params.append('payment_due_date', this.dateFormater(element.recurring_payment_due_date));     
       }
       if(element.fee_type=='Free')
       {
         
         var day=new Date();
        params =params.append('plan_id', element.free_plan_id);
        params =params.append('payment_type', element.fee_type);
        params =params.append('frequency', 'NA');    
        params =params.append('training_start_date',this.dateFormater(day));
        params =params.append('payment_due_date', this.dateFormater(day));    
       }
       if(element.fee_type=='Installments')
       {
        params =params.append('plan_id', element.installments_plan_id);
        params =params.append('payment_type', element.fee_type);
        params =params.append('frequency', 'NA');
        params =params.append('invoice_amount', element.installments_fee_amount);
        params =params.append('training_start_date',this.dateFormater(element.installments_training_start_date));
        params =params.append('payment_due_date', this.dateFormater(element.installments_payment_due_date)); 
       }
     });
     
              
      const addPlayer_finalSubmit_url = AppComponent.API_URL + 'mobile/AddPlayerForm2Data';
      return this.http_client.post(addPlayer_finalSubmit_url,params,this.httpOptions)
      .pipe(
                map((data:any) =>{
                    this.add_player_final_submit =data['Add Player form 2 Data State'];
                    if(this.add_player_final_submit.length!=0)
                    {         
                      return this.add_player_final_submit;
                    }
                    else
                      {
                        return '';
                      }
                }),
            )
    }

    BulkPlayersUpload(player_data,organization_id,login_customer_id,login_role_id,academy_center_id,file_name,date_format,upload_parent_details_flag)
    {  
      //var body = player_data;
      let params = new HttpParams();
      // alert(filter_value['Group']);
      params =params.append('player_data', player_data);
      params =params.append('login_customer_id', login_customer_id);
      params =params.append('organization_id', organization_id);
      params =params.append('login_role_id', login_role_id);
      params =params.append('academy_center_id', academy_center_id);
      params =params.append('file_name', file_name);
      params =params.append('date_format', date_format);
      params =params.append('upload_parent_details_flag', upload_parent_details_flag);
      const local_addbulkplayers_url = AppComponent.API_URL + 'web_bulkupload_old/UploadBulkPlayerData';
      return this.http_client.post(local_addbulkplayers_url, params,this.httpOptions)
      .pipe(
              map((data:any) =>{
                  if(data!=null)
                  {         
                    return data;
                  }
                  else
                    {
                      return '';
                    }
              }),
          )
    }
}
