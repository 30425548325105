import { parents_contact_form } from './../student-list/bulk-file-uplaod/file-data';
import { Injectable } from '@angular/core';
import { AppComponent } from '../app.component';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable()
export class MessagingService {
  recipient_details: any;
  staff_data: any;
  student_list: any;
  template_messages: any;
  mode_of_notify: any;
  send_email_notification: any;
  player_list: any;
  batch_data: any;
  center_data: any;
  httpOptions;
  notification_list: any;
  date_format: string;
  temp_date: string;
  unread_message_count: any;
  updateCustomerNotification: any;
  constructor(private http_client: HttpClient) {


    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };
  }
  getCustomerNotificationList(customer_id, organization_id) {
    const params = new HttpParams().set('organization_id', organization_id).set('customer_id', customer_id);

    const local_getNotification_url = AppComponent.API_URL + '/messaging/GetCustomerNotificationList';
    return this.http_client.post(local_getNotification_url, params, this.httpOptions)

      .pipe(
        map((data: any) => {
          this.notification_list = data['customer_notification_List'];
          if (this.notification_list.length != 0) {
            return this.notification_list;
          }
          else {
            return '';
          }
        }),
      )
  }

  getRecepientTypeList(role_type) {
    const params = new HttpParams().set('role_type', role_type);

    const local_getreciepent_url = AppComponent.API_URL + 'messaging/GetRecepientTypeList';
    return this.http_client.post(local_getreciepent_url, params, this.httpOptions)

      .pipe(
        map((data: any) => {
          this.recipient_details = data['recipient_details'];
          if (this.recipient_details.length != 0) {
            return this.recipient_details;
          }
          else {
            return '';
          }
        }),
      )
  }

  getStaffDetails(organization_id) {
    const params = new HttpParams().set('organization_id', organization_id);

    // const local_staff_url=AppComponent.API_URL+'user/GetCenterUserList';
    const local_staff_url = AppComponent.API_URL + 'user/GetAllUserList';
    return this.http_client.post(local_staff_url, params, this.httpOptions)

      .pipe(
        map((data: any) => {
          this.staff_data = data['User List'];
          if (this.staff_data.length != 0) {
            return this.staff_data;
          }
          else {
            return '';
          }
        }),
      )
  }

  getPlayerList(flag, organization_id) {
    const params = new HttpParams().set('flag', flag).set('organization_id', organization_id);

    const local_playerlist_url = AppComponent.API_URL + 'player/GetPlayerList';
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)

      .pipe(
        map((data: any) => {
          this.student_list = data['center_player_data'];
          if (this.student_list.length != 0) {
            return this.student_list;
          }
          else {
            return '';
          }
        }),
      )
  }
  getModeOfNotifyList(organization_id) {
    const params = new HttpParams().set('organization_id', organization_id);

    const mode_of_notify_url = AppComponent.API_URL + 'messaging/GetModeOfNotifyList';
    return this.http_client.post(mode_of_notify_url, params, this.httpOptions)

      .pipe(
        map((data: any) => {
          this.mode_of_notify = data['notification_details'];
          if (this.mode_of_notify.length != 0) {
            return this.mode_of_notify;
          }
          else {
            return '';
          }
        }),
      )
  }

  getMessageTemplateList(organization_id) {
    let params = new HttpParams();
    // for(var i=0;i<recipient_id.length;i++)  
    // {
    //   params =params.append('recipient_id['+i+']',recipient_id[i]);
    // }     
    params = params.append('organization_id', organization_id);
    // const local_playerlist_url = AppComponent.API_URL + 'messaging/GetMessageTemplateList';  
    const local_playerlist_url = AppComponent.API_URL + 'messaging/GetSMSTemplateList';
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)

      .pipe(
        map((data: any) => {
          this.template_messages = data['sms_template_details'];
          if (this.template_messages.length != 0) {
            return this.template_messages;
          }
          else {
            return '';
          }
        }),
      )
  }

  SendNotification(notification_mode, organization_id, subject, body, player_id, customer_id,
    academy_group_id, recipient, academy_center_id, template_id) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('subject', subject);
    params = params.append('body', body);
    params = params.append('device_os', '');
    if (recipient != "Student/Staff") {
      params = params.append('recipient', recipient);
    }
    for (var i = 0; i < notification_mode.length; i++) {
      params = params.append('notification_mode[' + i + ']', notification_mode[i]);
    }
    for (var i = 0; i < player_id.length; i++) {
      params = params.append('player_id[' + i + ']', player_id[i]);
    }
    for (var i = 0; i < customer_id.length; i++) {
      params = params.append('customer_id[' + i + ']', customer_id[i]);
    }
    for (var i = 0; i < academy_group_id.length; i++) {
      params = params.append('academy_group_id[' + i + ']', academy_group_id[i]);
    }
    for (var i = 0; i < academy_center_id.length; i++) {
      params = params.append('academy_center_id[' + i + ']', academy_center_id[i]);
    }
    if (template_id != undefined) {
      params = params.append('template_id', template_id);
    }


    const send_notification_url = AppComponent.API_URL + 'mobile/SendNotification';
    return this.http_client.post(send_notification_url, params, this.httpOptions)

      .pipe(
        map((data: any) => {

          this.send_email_notification = data['sent_notification'];
          if (this.send_email_notification == true) {
            return this.send_email_notification;
          }
          else {
            return '';
          }
        }),
      )

  }

  getPlayerListWithFilter(flag, organization_id, filters_born_before_age,
    filters_born_after_age, age_flag, gender_filter, to_date, from_date, paymentDue_filter) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('flag', flag);

    if (gender_filter != '') {
      params = params.append('filters[gender]', gender_filter);
    }
    if (to_date != '' && to_date != undefined) {
      params = params.append('filters[training_date][to_date]', this.dateFormater(to_date));
    }
    if (from_date != '' && from_date != undefined) {
      params = params.append('filters[training_date][from_date]', this.dateFormater(from_date));
    }
    if (paymentDue_filter != "") {
      params = params.append('filters[payment_due][flag]', paymentDue_filter);
    }

    if (filters_born_after_age != null && filters_born_before_age != null) {
      params = params.append('filters[age][after]', filters_born_after_age);
      params = params.append('filters[age][before]', filters_born_before_age);
    }
    else if (age_flag == "Y" && filters_born_after_age != null) {
      params = params.append('filters[age][after]', filters_born_after_age);
    }
    else if (age_flag == "N" && filters_born_before_age != null) {
      params = params.append('filters[age][before]', filters_born_before_age);
    }



    const local_playerlist_url = AppComponent.API_URL + 'player/GetPlayerList';
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)

      .pipe(
        map((data: any) => {
          this.player_list = data['center_player_data'];
          if (this.player_list.length != 0) {
            return this.player_list;
          }
          else {
            return '';
          }
        }),
      )
  }

  GetBatchsByAcademy(organization_id) {
    const params = new HttpParams().set('organization_id', organization_id);

    const local_center_url = AppComponent.API_URL + 'batch/GetBatchsByAcademy';
    return this.http_client.post(local_center_url, params, this.httpOptions)

      .pipe(
        map((data: any) => {
          this.batch_data = data['batch'];
          if (this.batch_data.length != 0) {
            return this.batch_data;
          }
          else {
            return '';
          }
        }),
      )
  }
  getCenterDetails(customer_id, organization_id, is_admin) {
    const params = new HttpParams().set('customer_id', customer_id)
      .set('organization_id', organization_id)
      .set('is_admin', is_admin);

    const local_center_url = AppComponent.API_URL + 'center/GetCenterListWithGroups ';
    return this.http_client.post(local_center_url, params, this.httpOptions)

      .pipe(
        map((data: any) => {
          this.center_data = data['Academy Center Ids'];
          if (this.center_data.length != 0) {
            return this.center_data;
          }
          else {
            return '';
          }
        }),
      )
  }

  SendMessage(notification_mode, organization_id, subject, body, player_id) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('subject', subject);
    params = params.append('body', body);
    params = params.append('device_os', '');

    for (var i = 0; i < notification_mode.length; i++) {
      params = params.append('notification_mode[' + i + ']', notification_mode[i]);
    }
    for (var i = 0; i < player_id.length; i++) {
      params = params.append('player_id[' + i + ']', player_id[i]);
    }

    const send_notification_url = AppComponent.API_URL + 'mobile/SendNotification';
    return this.http_client.post(send_notification_url, params, this.httpOptions)

      .pipe(
        map((data: any) => {

          this.send_email_notification = data['sent_notification'];
          if (this.send_email_notification == true) {
            return this.send_email_notification;
          }
          else {
            return '';
          }
        }),
      )
  }

  getCustomerUnreadMsgCount(customer_id,organization_id){
      let params=new HttpParams();
      params=params.append('customer_id',customer_id);
      params=params.append('organization_id',organization_id);

      const url=AppComponent.API_URL+'messaging/GetCustomerUnreadMsgCount';
      return this.http_client.post(url,params,this.httpOptions)
      .pipe(
        map((data:any)=>{
          this.unread_message_count=data['unread_message_count'];
         if(this.unread_message_count!=''){
          return this.unread_message_count;
         }
        })
      )

  }

  updateCustomerNotificationFlag(organization_id,notification_id,customer_id){
    let params=new HttpParams();
   
    params=params.append('organization_id',organization_id);
    params=params.append('notification_id',notification_id);
    params=params.append('customer_id',customer_id);

    const url=AppComponent.API_URL+'messaging/UpdateCustomerNotificationFlag';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(
      map((data:any)=>{
        this.updateCustomerNotification=data['customer_notification_updated'];
       if(this.updateCustomerNotification!=''){
        return this.updateCustomerNotification;
       }
      })
    )

}

  dateFormater(date) {
    this.date_format = sessionStorage.getItem('date_format');
    var day1 = new Date(date);
    var month: any;
    var day;
    day = day1.getDate();
    month = (day1.getMonth() + 1);
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    if (this.date_format == "Y-m-d") {
      return this.temp_date = day1.getFullYear() + "-" + month + "-" + day;
    }
    if (this.date_format == "d-m-Y") {
      return this.temp_date = day + "-" + month + "-" + day1.getFullYear();
    }
    if (this.date_format == "Y/m/d") {
      return this.temp_date = day1.getFullYear() + "/" + month + "/" + day;
    }

    if (this.date_format == "d/m/Y") {
      return this.temp_date = day + "/" + month + "/" + day1.getFullYear();
    }

    if (this.date_format == "m/d/Y") {
      return this.temp_date = day + "/" + month + "/" + day1.getFullYear();
    }


  }
}
