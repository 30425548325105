import { Injectable } from '@angular/core';
import {AppComponent} from '../../app.component';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable()
export class AssessmentService {
  assesment_data:any='';
  player_assesment_details:any;
  playerScore_FK_assesment_details:any;
  import_player_score_assesment_details:any;
  test_bank_list:any;
  checkDeleteTestBank_details:any;
  DeleteTestBank_details:any;
  add_test_respo:any; 
  update_test_details:any;
  testParameter_details:any;
  tests_list:any;
  addExistingTests_details:any;
  score_type_details:any;
  searchAreaName_details:any;
  add_new_performance_parameter_details:any;
  performance_parameter_score_details:any;
  score_details:any;
  update_performance_parameter_details:any;
  Specific_score_details:any;
  updated_score_details:any;
  IsAllowDeletePerformance_details:any;
  player_list:any;
  parameterList:any;
  tag_list:any;
  student_tag_list:any;
  add_tag_list:any;
  delete_tag_details:any;
  player_list_data:any;
  common_performance_list:any;
  player_performance_statistics:any;
  assessmentPerformanceScoreReportCard:any;
  center_data:any;
  batch_data:any;
  attendance_report_details:any;
  session_attendance_report_details:any;
  report_acl:any;

  httpOptions;  
  isallow_delete_score: any;
  deleted_score_details: any;

  constructor(private http_client: HttpClient) 
  {   
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
    };
  }
  getAssesmentDetails(organization_id) 
  {
    const  params = new  HttpParams().set('organization_id', organization_id);
    const local_assesment_url=AppComponent.API_URL+'performance/GetPerformanceAssessment';
    return this.http_client.post(local_assesment_url,params,this.httpOptions)
     .pipe(
            map((data:any) =>{
                this.assesment_data =data['performance_assessment_details'];
                if(this.assesment_data.length!=0)
                {         
                  return this.assesment_data;
                }
                else
                  {
                    return '';
                  }
            }),
        ) 
  }

  getPlayerAssesmentDetails(performance_set_assessment_id,organization_id)
  {
    const  params = new  HttpParams().set('performance_set_assessment_id', performance_set_assessment_id)
                                    .set('organization_id', organization_id);
    
    const local_player_assesment_url=AppComponent.API_URL+'player/GetPlayerAssessment';
    return this.http_client.post(local_player_assesment_url,params,this.httpOptions)
     .pipe(
            map((data:any) =>{
                this.player_assesment_details =data['performance_assessment_details'];
                if(this.player_assesment_details.length!=0)
                {         
                  return this.player_assesment_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }

  getPlayerAssesmentDetailsWithoutScore(performance_set_assessment_id,organization_id)
  {
    const  params = new  HttpParams().set('performance_set_assessment_id', performance_set_assessment_id)
                                    .set('organization_id', organization_id);
    
    const local_player_assesment_url=AppComponent.API_URL+'player/GetPlayerAssessmentWithoutScore';
    return this.http_client.post(local_player_assesment_url,params,this.httpOptions)
     .pipe(
            map((data:any) =>{
                this.player_assesment_details =data['performance_assessment_details'];
                if(this.player_assesment_details.length!=0)
                {         
                  return this.player_assesment_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }


 getPlayerScoringForeignKeyDetails(bulkuploadPlayerScoreData,organization_id,login_customer_id,login_role_id)
 {
   
  let params = new HttpParams();
    params = params.append('player_data', bulkuploadPlayerScoreData);
    params = params.append('login_customer_id', login_customer_id);
    params = params.append('organization_id', organization_id);
    params = params.append('login_role_id', login_role_id);

    const foreignKey_player_assesment_url=AppComponent.API_URL+'webadmin/GetPlayerScoringForeignKeyDetails';
    return this.http_client.post(foreignKey_player_assesment_url,params,this.httpOptions)
     .pipe(
            map((data:any) =>{
                this.playerScore_FK_assesment_details =data['get_player_scoring_foreign_key_details'];
                if(this.playerScore_FK_assesment_details.length!=0)
                {         
                  return this.playerScore_FK_assesment_details;
                }
                else
                  {
                    return '';
                  }
            }),
        ) 
 }  

 ImportBulkPlayerPerformanceScore(player_Score_final_data,organization_id,login_customer_id,login_role_id)
 {
  
    let params = new HttpParams();
    params = params.append('player_data', player_Score_final_data);
    params = params.append('login_customer_id', login_customer_id);
    params = params.append('organization_id', organization_id);
    params = params.append('login_role_id', login_role_id);

    const import_bulk_player_assesment_url=AppComponent.API_URL+'webadmin/ImportBulkPlayerPerformanceScore';
    return this.http_client.post(import_bulk_player_assesment_url,params,this.httpOptions)
     .pipe(
            map((data:any) =>{
                this.import_player_score_assesment_details =data['terms_of_use_detimport_bulk_player_performance_score_detailsails'];
                if(this.import_player_score_assesment_details.length!=0)
                {         
                  return this.import_player_score_assesment_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )
 }

 getTestBankList(organization_id)
  {
    const  params = new  HttpParams().set('organization_id', organization_id);       
    
    const local_performancePlans_url=AppComponent.API_URL+'performance/GetPerformanceSetList';
    return this.http_client.post(local_performancePlans_url,params,this.httpOptions)
     .pipe(
            map((data:any) =>{
                this.test_bank_list =data['performance_set_List_details'];
                if(this.test_bank_list.length!=0)
                {         
                  return this.test_bank_list;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }
  checkDeleteTestBank(performance_set_id)
{  
  const  params = new  HttpParams().set('performance_set_id', performance_set_id) 
    
    const local_performancePlans_url=AppComponent.API_URL+'performance/IsAllowDeletePerformanceSet';
    return this.http_client.post(local_performancePlans_url,params,this.httpOptions)
     .pipe(
            map((data:any) =>{
                this.checkDeleteTestBank_details =data['performance_set_details'];
                if(this.checkDeleteTestBank_details.length!=0)
                {         
                  return this.checkDeleteTestBank_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }
  deleteTestBank(performance_set_id)
  { 
    const  params = new  HttpParams().set('performance_set_id', performance_set_id)  
    
    const local_performancePlans_url=AppComponent.API_URL+'performance/DeletePerformanceSet';
    return this.http_client.post(local_performancePlans_url,params,this.httpOptions)
     .pipe(
            map((data:any) =>{
                this.DeleteTestBank_details =data;
                if(this.DeleteTestBank_details.length!=0)
                {         
                  return this.DeleteTestBank_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )
}
addTest(test_name,test_description,organization_id)
{

  let params = new HttpParams();

  params = params.append('performance_set_name',test_name);
  params = params.append('performance_set_description',test_description);
  params = params.append('organization_id',organization_id);    
  const local_performanceset_url=AppComponent.API_URL+'performance/AddPerformanceSet';

  return this.http_client.post(local_performanceset_url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.add_test_respo =data['performance_set_details'];
                if(this.add_test_respo.length!=0)
                {         
                  return this.add_test_respo;
                }
                else
                  {
                    return '';
                  }
            }),
        )
}

updateTest(test_id,test_name,test_description,org_id)
{
  const  params = new  HttpParams().set('performance_set_id', test_id)
                                   .set('performance_set_name', test_name)
                                   .set('performance_set_description', test_description)
                                   .set('organization_id', org_id);  

  const local_updatePerformanceSet_url=AppComponent.API_URL+'performance/UpdatePerformanceSet';
  return this.http_client.post(local_updatePerformanceSet_url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.update_test_details =data['Updated_performance_set_details'];
                if(this.update_test_details.length!=0)
                {         
                  return this.update_test_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )
}

getTestParameter(organization_id,performance_set_id)
{
     
  let params = new HttpParams();
  
  params = params.append('organization_id',organization_id);
  params = params.append('performance_set_id',performance_set_id);
  const local_viewPerformanceSet_url=AppComponent.API_URL+'mobile/ViewPerformanceSet';
  return this.http_client.post(local_viewPerformanceSet_url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.testParameter_details =data['performance_set_view'];
                if(this.testParameter_details.length!=0)
                {         
                  return this.testParameter_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )
}

GetExistingTestList(organization_id)
{
  const  params = new  HttpParams().set('organization_id', organization_id);  
  
  const local_get_performance_parameter_list_url=AppComponent.API_URL+'performance/GetPerformanceParameterList';
  return this.http_client.post(local_get_performance_parameter_list_url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.tests_list =data['performance_parameter_details'];
                if(this.tests_list.length!=0)
                {         
                  return this.tests_list;
                }
                else
                  {
                    return '';
                  }
            }),
        )
}
AddExistingTests(performance_parameter_id,performance_set_id,score_id)
{
  let params = new HttpParams();
  params = params.append('performance_parameter_id', performance_parameter_id);
  params = params.append('performance_set_id', performance_set_id);
  var multiple_score_id=score_id.split(',');  
  
  for(var i=0;i<multiple_score_id.length;i++)
  {
    params = params.append('score_id['+i+']', multiple_score_id[i]);
  }  
  const local_add_performance_parameter_details_url=AppComponent.API_URL+'performance/AddPerformanceSetParameter';
  return this.http_client.post(local_add_performance_parameter_details_url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.addExistingTests_details =data['performance_parameter_set_details'];
                if(this.addExistingTests_details.length!=0)
                {         
                  return this.addExistingTests_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )
} 

GetScoreType()
{  
  const local_performance_parameter_isDelete_url=AppComponent.API_URL+'general/GetScoreType';
  return this.http_client.post(local_performance_parameter_isDelete_url,[],this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.score_type_details =data['score_type_details'];
                if(this.score_type_details.length!=0)
                {         
                  return this.score_type_details;
                }
                else
                  {
                    return '';
                  }
            }),
        ) 

}
SearchAreaName(searchString)
{
  let params = new HttpParams();
  params = params.append('searchString', searchString );
  const local_performance_parameter_isDelete_url=AppComponent.API_URL+'performance/SearchAreaName';
  return this.http_client.post(local_performance_parameter_isDelete_url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.searchAreaName_details =data['Area Name'];
                if(this.searchAreaName_details.length!=0)
                {         
                  return this.searchAreaName_details;
                }
                else
                  {
                    return '';
                  }
            }),
        ) 
}
addPerformanceParameter(performance_parameter_name,performance_parameter_description,
  performance_parameter_comment,performance_area_id,performance_area_name,score_type_id,score_unit,score_label,
  performance_set_id,min,max,value,max_length,max_range_selected,ranking_criteria,dropdown_option,show_countdown_timer
  ,score_form,calculated_parameter_data)
{  
  var performance_parameter_status="1";
  let params = new HttpParams();
  params = params.append('performance_set_id', performance_set_id );
  params = params.append('performance_parameter_name', performance_parameter_name );
  params = params.append('performance_parameter_status', performance_parameter_status );
  params = params.append('performance_parameter_description', performance_parameter_description );
  params = params.append('performance_parameter_comment', performance_parameter_comment );
  if(performance_area_id!=null)
  {
    params = params.append('performance_area_id', performance_area_id );    
  }
  params = params.append('performance_area_name', performance_area_name );   
  params = params.append('score_label', score_label ); 
  params = params.append('score_type_id', score_type_id );
  params = params.append('score_unit', score_unit );
  if(score_type_id=="1")
  {    
    params = params.append('min', min );
    params = params.append('max', max );
    params = params.append('ranking_criteria', ranking_criteria );
    params = params.append('show_countdown_timer', show_countdown_timer );
  }
  if(score_type_id=="2")
  {
    params = params.append('min', "1" );
    params = params.append('max', max_range_selected );   
    params = params.append('ranking_criteria', ranking_criteria );
    params = params.append('show_countdown_timer', show_countdown_timer );
    for(var i=0;i<value.length;i++) 
    {
      params = params.append('value['+i+']', value[i]);
    }   
  }
  if(score_type_id=="3")
  {
    params = params.append('max_length', max_length );
    params = params.append('show_countdown_timer', show_countdown_timer );
  
  }    
  if(score_type_id=="5")
  {
    
    dropdown_option.forEach((element,i) => {
      params = params.append('value['+i+']', element );
    });
  } 
  if(score_type_id=="6")
  {
    params = params.append('ranking_criteria', ranking_criteria ); 
  } 
 
  if(score_form.calculation_mode=='Calculation'){
    params = params.append('parameter_cal_type', 'CALCULATED'); 
    params = params.append('calculated_parameter_id', score_form.calculated_parameter_id); 
    calculated_parameter_data['dependent_parameters'].forEach((element,i) => {      
      params = params.append('dependent_parameter['+i+'][required_parameter]', element.parameter_name);
      params = params.append('dependent_parameter['+i+'][dependent_performance_parameter_id]',  score_form['dependant_parameter_field'+i]['performance_parameter_id']); 
    });
    params = params.append('score_unit', score_form.calculated_score_unit );
  }else{
    params = params.append('parameter_cal_type', 'REGULAR'); 
    params = params.append('score_unit', score_unit );
  }
  
  const local_add_new_performance_parameter_url=AppComponent.API_URL+'mobile/AddPerformance';
  return this.http_client.post(local_add_new_performance_parameter_url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.add_new_performance_parameter_details =data['performance_details'];
                if(this.add_new_performance_parameter_details.length!=0)
                {         
                  return this.add_new_performance_parameter_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )  
}

AddPerformanceParameterScore(performance_parameter_id,score_type_id,score_unit,score_label,
  performance_set_id,min,max,value,max_length,max_range_selected,ranking_criteria,dropdown_option,
  show_countdown_timer,score_form,calculated_parameter_data) 
{
  let params = new HttpParams();
  params = params.append('performance_parameter_id', performance_parameter_id ); 
  params = params.append('performance_set_id', performance_set_id );  
  
  params = params.append('score_label', score_label );
  params = params.append('score_type_id', score_type_id );
 
  if(score_type_id=="1")
  {
    
    params = params.append('min', min );
    params = params.append('max', max );
    params = params.append('ranking_criteria', ranking_criteria );
    params = params.append('show_countdown_timer', show_countdown_timer );
  }
  if(score_type_id=="2")
  {    
    params = params.append('min', "1" );
    params = params.append('max', max_range_selected );
    params = params.append('ranking_criteria', ranking_criteria );
    params = params.append('show_countdown_timer', show_countdown_timer );
    for(var i=0;i<value.length;i++) 
    {
      params = params.append('value['+i+']', value[i]);
    }   
  }
  if(score_type_id=="3")
  {   
    params = params.append('max_length', max_length );
    params = params.append('show_countdown_timer', show_countdown_timer );
  } 
  if(score_type_id=="5")
  {
    
    dropdown_option.forEach((element,i) => {
      params = params.append('value['+i+']', element );
    });
  } 
  if(score_type_id=="6")
  {
    params = params.append('ranking_criteria', ranking_criteria );
  } 
 

  if(score_form.calculation_mode=='Calculation'){
    params = params.append('parameter_cal_type', 'CALCULATED'); 
    params = params.append('calculated_parameter_id', score_form.calculated_parameter_id); 
    calculated_parameter_data['dependent_parameters'].forEach((element,i) => {      
      params = params.append('dependent_parameter['+i+'][required_parameter]', element.parameter_name);
      params = params.append('dependent_parameter['+i+'][dependent_performance_parameter_id]',  score_form['dependant_parameter_field'+i]['performance_parameter_id']); 
    });
    params = params.append('score_unit', score_form.calculated_score_unit );
  }else{
    params = params.append('parameter_cal_type', 'REGULAR'); 
    params = params.append('score_unit', score_unit );
  }
  
  
  const local_add_score_performance_parameter_url=AppComponent.API_URL+'performance/AddPerformanceParameterScore';
  return this.http_client.post(local_add_score_performance_parameter_url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.performance_parameter_score_details =data['performance_parameter_score_details'];
                if(this.performance_parameter_score_details.length!=0)
                {         
                  return this.performance_parameter_score_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )  

}
getParameterScoreList(performance_parameter_id,performance_set_id)
{
  let params = new HttpParams();
  params = params.append('performance_parameter_id', performance_parameter_id );
  params = params.append('performance_set_id', performance_set_id );
  const local_get_score_performance_parameter_url=AppComponent.API_URL+'performance/GetParameterScoreList';
  return this.http_client.post(local_get_score_performance_parameter_url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.score_details =data['score_list'];
                if(this.score_details.length!=0)
                {         
                  return this.score_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )  
}

updatePerformanceParameter(performance_parameter_name,performance_area_id, performance_parameter_id, performance_parameter_description, performance_parameter_comment,performance_area_name,performance_parameter_status)
{
  
  var performance_parameter_status=performance_parameter_status;
  let params = new HttpParams();
  params = params.append('performance_parameter_id', performance_parameter_id );
  params = params.append('performance_parameter_name', performance_parameter_name );
  params = params.append('performance_parameter_status', performance_parameter_status );
  params = params.append('performance_parameter_description', performance_parameter_description );
  params = params.append('performance_parameter_comment', performance_parameter_comment );
  if(performance_area_id!=null)
  {
    params = params.append('performance_area_id', performance_area_id );    
  }
  else
  {
  params = params.append('performance_area_name', performance_area_name); 
  }
  const local_update_performance_parameter_url=AppComponent.API_URL+'performance/UpdatePerformanceParameter';
  return this.http_client.post(local_update_performance_parameter_url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.update_performance_parameter_details =data['performance_parameter_details'];
                if(this.update_performance_parameter_details.length!=0)
                {         
                  return this.update_performance_parameter_details;
                }
                else
                  {
                    return '';
                  }
            }),
        ) 
}

getParameterScoreSetDetails(performance_parameter_id, performance_set_id,score_id)
{
  let params = new HttpParams();
  params = params.append('performance_parameter_id', performance_parameter_id );
  params = params.append('performance_set_id', performance_set_id );
  params = params.append('score_id', score_id );
  const local_performance_scoreSet_details_url=AppComponent.API_URL+'performance/GetParameterScoreSetDetails';
  return this.http_client.post(local_performance_scoreSet_details_url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.Specific_score_details =data['score_details'];
                if(this.Specific_score_details.length!=0)
                {         
                  return this.Specific_score_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )

}
updateParameterScore(score_type_id,score_label,score_unit,min ,max,value,max_length,score_id,
  max_range,score_criteria,dropdown_option,show_countdown_timer,score_form,calculated_parameter_data
  ,performance_parameter_id,performance_set_id)
{
  let params = new HttpParams();
  params = params.append('score_label', score_label );  
  params = params.append('score_id', score_id ); 
  params = params.append('score_type_id', score_type_id );
  params = params.append('performance_parameter_id', performance_parameter_id); 
  params = params.append('performance_set_id', performance_set_id); 

  if(score_type_id=="1")
  {
    
    params = params.append('min', min );
    params = params.append('max', max );
    params = params.append('score_criteria', score_criteria );
    params = params.append('show_countdown_timer', show_countdown_timer );
  }
  if(score_type_id=="2")
  {
    
    params = params.append('score_criteria', score_criteria );
    params = params.append('show_countdown_timer', show_countdown_timer );
    for(var i=0;i<value.length;i++) 
    {
      params = params.append('min', "1" );
      params = params.append('max', max_range );
      params = params.append('value['+i+']', value[i]);
    }   
  }
  if(score_type_id=="3")
  {
    params = params.append('max_length', max_length );
    params = params.append('show_countdown_timer', show_countdown_timer );
  }  

  if(score_type_id=="5")
  {
    
    dropdown_option.forEach((element,i) => {
      params = params.append('value['+i+']', element );
    });
  } 
  if(score_type_id=="6")
  {
    params = params.append('score_criteria', score_criteria );
  } 

  if(score_form.calculation_mode=='Calculation'){
    
    params = params.append('parameter_cal_type', 'CALCULATED'); 
    params = params.append('calculated_parameter_id', score_form.calculated_parameter_id); 
    
    calculated_parameter_data['dependent_parameters'].forEach((element,i) => {      
      params = params.append('dependent_parameter['+i+'][required_parameter]', element.parameter_name);
      params = params.append('dependent_parameter['+i+'][dependent_performance_parameter_id]',  score_form['dependant_parameter_field'+i]); 
    });
    params = params.append('score_unit', score_form.calculated_score_unit );
  }else{
    params = params.append('parameter_cal_type', 'REGULAR'); 
    params = params.append('score_unit', score_unit );
  }
 
  const local_update_score_performance_parameter_url=AppComponent.API_URL+'performance/UpdateParameterScore';
  return this.http_client.post(local_update_score_performance_parameter_url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.updated_score_details =data['parameter_score'];
                if(this.updated_score_details.length!=0)
                {         
                  return this.updated_score_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )
}
IsAllowDeletePerformance(performance_set_id)
{
  let params = new HttpParams();
  params = params.append('performance_set_id', performance_set_id);
  const local_performance_parameter_isDelete_url=AppComponent.API_URL+'performance/IsAllowDeletePerformance';
  return this.http_client.post(local_performance_parameter_isDelete_url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.IsAllowDeletePerformance_details =data['performance_parameter_details'];
                if(this.IsAllowDeletePerformance_details.length!=0)
                {         
                  return this.IsAllowDeletePerformance_details;
                }
                else
                  {
                    return '';
                  }
            }),
        ) 
}

DeletePerformanceParameter(performance_set_id,performance_parameter_id)
{
  let params = new HttpParams();
  params = params.append('performance_set_id', performance_set_id);
  params = params.append('performance_parameter_id', performance_parameter_id);
  const url=AppComponent.API_URL+'performance/DeletePerformanceParameterAllDetails';
  return this.http_client.post(url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                if(data['deleted_parameter_details'].length!=0)
                {         
                  return data['deleted_parameter_details'];
                }
                else
                  {
                    return '';
                  }
            }),
        )
}
getPlayerList(flag,organization_id,performance_set_assessment_id) 
{
  const  params = new  HttpParams().set('flag', flag)
                                    .set('organization_id', organization_id)
                                    .set('filters[performance_ranking][performance_set_assessment_id]', performance_set_assessment_id);  

  const local_getplayer_url=AppComponent.API_URL+'player/GetPlayerList';
  return this.http_client.post(local_getplayer_url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.player_list =data['center_player_data'];
                if(this.player_list.length!=0)
                {         
                  return this.player_list;
                }
                else
                  {
                    return '';
                  }
            }),
        )     
}
getPlayerListWithFilter(role_flag, organization_id, filter_value, filter_type,age_flag,performance_set_assessment_id) 
{  
  let params = new HttpParams();    
    params = params.append('organization_id', organization_id);
    params = params.append('flag', role_flag);
    params = params.append('filters[performance_ranking][performance_set_assessment_id]', performance_set_assessment_id);
 

    for (var i = 0; i < filter_type.length; i++) 
    {
      if (filter_type[i] == "Gender") 
      {
        params = params.append('filters[gender]', filter_value[i]['Gender']);
      }
      else if (filter_type[i] == "Age") 
      {
        if(filter_value[i]['Age'][0]!=null && filter_value[i]['Age'][1]!=null)
        {               
        params = params.append('filters[age][after]', filter_value[i]['Age'][0]);
        params = params.append('filters[age][before]', filter_value[i]['Age'][1]);
        }
        else if(age_flag=="Y")
        {
          params = params.append('filters[age][after]', filter_value[i]['Age'][0]);
        }
        else if(age_flag=="N")
        {
          params = params.append('filters[age][before]', filter_value[i]['Age'][0]);
        }
      } 
      else if(filter_type[i] == "Score") 
      {        
        for (var j = 0; j < filter_value[i]['Score'].length; j++) 
        {
          params = params.append('filters[performance_ranking][score_id]'+'['+j+']', filter_value[i]['Score'][j]);          
        }
      }
      else if(filter_type[i] == "performanceParameterId")
      {
        for (var j = 0; j < filter_value[i]['performanceParameterId'].length; j++) 
        {   
          params = params.append('filters[performance_ranking][performance_parameter_id]'+'['+j+']', filter_value[i]['performanceParameterId'][j]); 
        }
      }
      else if(filter_type[i] == "Toppers") 
      {        
          params = params.append('filters[toppers]', filter_value[i]['Toppers']); 
        
      }
    }
  const local_getplayer_url=AppComponent.API_URL+'player/GetPlayerList';
  return this.http_client.post(local_getplayer_url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.player_list =data['center_player_data'];
                if(this.player_list.length!=0)
                {         
                  return this.player_list;
                }
                else
                  {
                    return '';
                  }
            }),
        )      
}

getPerformanceParameterList(performance_set_id,performance_set_assessment_id,organization_id) 
{
  let params = new HttpParams();    
  params = params.append('performance_set_id', performance_set_id);
  params = params.append('performance_set_assessment_id', performance_set_assessment_id);
  params = params.append('organization_id', organization_id);
  params = params.append('flag', "ranking");
  
  const local_assesment_url=AppComponent.API_URL+'performance/GetPerformanceParameterList';
  return this.http_client.post(local_assesment_url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.parameterList =data['performance_parameter_details'];
                if(this.parameterList.length!=0)
                {         
                  return this.parameterList;
                }
                else
                  {
                    return '';
                  }
            }),
        )      
}
getTagList(academy_id,tag_name)
{
  let params = new HttpParams();    
  params = params.append('academy_id', academy_id);
  if(tag_name!='')
  {
    params = params.append('tag_name', tag_name);
  }  
  const local_getTagList_url=AppComponent.API_URL+'tag/GetTagListByTagName';
  return this.http_client.post(local_getTagList_url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.tag_list =data['tag'];
                if(this.tag_list.length!=0)
                {         
                  return this.tag_list;
                }
                else
                  {
                    return '';
                  }
            }),
        )
}
getTagsDetailsByPlayer(player_ids,academy_id)
{
  let params = new HttpParams();    
  params = params.append('academy_id', academy_id);
  player_ids.forEach((item, index) => {
    params = params.append('player_ids['+index+']',item);
  });   
  const local_getTagdetails_url=AppComponent.API_URL+'tag/GetTagsDetailsByPlayer';
  return this.http_client.post(local_getTagdetails_url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.student_tag_list =data['player'];
                if(this.student_tag_list.length!=0)
                {         
                  return this.student_tag_list;
                }
                else
                  {
                    return '';
                  }
            }),
        )
}
addTagsDetails(customer_id,tag_id,tag_name,academy_id,player_id,batch_ids,performance_set_assessment_id)
{
  let params = new HttpParams();    
  params = params.append('customer_id', customer_id);
  params = params.append('academy_id', academy_id);
  params = params.append('performance_set_assessment_id', performance_set_assessment_id);
  for(var i=0;i<tag_id.length;i++)
  {
    params = params.append('tag['+i+'][tag_id]',tag_id[i]);
    params = params.append('tag['+i+'][tag_name]',tag_name[i]);
  }  
  
  player_id.forEach((item, index) => {
    params = params.append('player['+index+'][player_id]',item);  
  }); 
  batch_ids.forEach((item, index) => {
    params = params.append('player['+index+'][academy_group_id]',item);
  }); 
  const local_addTagList_url=AppComponent.API_URL+'tag/AddPlayersTagsDetails';
  return this.http_client.post(local_addTagList_url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.add_tag_list =data['tag'];
                if(this.add_tag_list.length!=0)
                {         
                  return this.add_tag_list;
                }
                else
                  {
                    return '';
                  }
            }),
        ) 
}
deletePlayerAllTag(player_id,academy_id)
{
  let params = new HttpParams();
  params = params.append('academy_id',academy_id);
  player_id.forEach((item, index) => {
    params = params.append('player_id['+index+']',item);
  }); 
  const local_deleteTagList_url=AppComponent.API_URL+'tag/DeletePlayerAllTag';
  return this.http_client.post(local_deleteTagList_url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.delete_tag_details =data['delete_player_tag'];
                if(this.delete_tag_details.length!=0)
                {         
                  return this.delete_tag_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )
}
getPlayerListForProgressReport(flag, organization_id){
  const  params = new  HttpParams().set('organization_id', organization_id).set('flag', flag);  
 
  const local_playerlist_url = AppComponent.API_URL + 'player/GetPlayerList';
  return this.http_client.post(local_playerlist_url, params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.player_list_data =data['center_player_data'];
                if(this.player_list_data.length!=0)
                {         
                  return this.player_list_data;
                }
                else
                  {
                    return '';
                  }
            }),
        )
}
getCommonPerformanceSetList(player_id){
  const  params = new  HttpParams().set('player_id', player_id);  
  var body = `player_id=${player_id}`;
  const common_performanceSet_list_url = AppComponent.API_URL + 'player/GetPlayerPerformanceSet';
  return this.http_client.post(common_performanceSet_list_url, params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.common_performance_list =data['player_performance_set_details'];
                if(this.common_performance_list.length!=0)
                {         
                  return this.common_performance_list;
                }
                else
                  {
                    return '';
                  }
            }),
        )
}
GetAssessmentListWithCommonSet(performance_set_id, player_id){

  const  params = new  HttpParams().set('performance_set_id', performance_set_id).set('player_id', player_id);  
  
  const assessmentlist_with_commonset_url = AppComponent.API_URL + 'webadmin/GetAssessmentListWithCommonSet';
  return this.http_client.post(assessmentlist_with_commonset_url, params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                if(data['status']==200)
                {         
                  return data;
                }
                else
                  {
                    return '';
                  }
            }),
        ) 
}
getPlayerPerformanceStatistics(organization_id, player_id, performance_set_assessment_id){
  let params = new HttpParams();
  params = params.append('organization_id', organization_id);
  params = params.append('player_id', player_id);
  for (var i = 0; i < performance_set_assessment_id.length; i++)
   {
    params = params.append('performance_set_assessment_id[' + i + ']', performance_set_assessment_id[i]);
  }

  const getPlayerPerformanceStatistics_url = AppComponent.API_URL + 'dashboard/GetPlayerPerformanceStatistics';
  return this.http_client.post(getPlayerPerformanceStatistics_url, params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.player_performance_statistics =data['player_performance_statistics'];
                if(this.player_performance_statistics.length!=0)
                {         
                  return this.player_performance_statistics;
                }
                else
                  {
                    return '';
                  }
            }),
        ) 
}

getAssesmentPerformanceSetScoreReportCard(academy_id,performance_set_assessment_id,filter_value,filter_type,player_id)
  {
    let params = new HttpParams();
    params = params.append('academy_id', academy_id);
    params = params.append('performance_set_assessment_id', performance_set_assessment_id);
    if(player_id!=undefined || player_id!=null)
    {
      params = params.append('filter[player_id]', player_id);
    }
    
    if (filter_value.length != 0) 
    {
      for (var i = 0; i < filter_type.length; i++) 
      {       
        if (filter_type[i] == "Center") 
        {
          for (var j = 0; j < filter_value[i]['Center'].length; j++) 
          {
            params = params.append('filter[academy_center_id]' + '[' + j + ']', filter_value[i]['Center'][j]);
          }
        }
        else if (filter_type[i] == "Batch") 
        {
          for (var j = 0; j < filter_value[i]['Batch'].length; j++) 
          {
            params = params.append('filter[academy_group_id]' + '[' + j + ']', filter_value[i]['Batch'][j]);
          }
        } 
      }
    }
  
    const getPlayerPerformanceStatistics_url = AppComponent.API_URL + 'performance/GetAssesmentPerformanceSetScore';
    return this.http_client.post(getPlayerPerformanceStatistics_url, params,this.httpOptions)
     .pipe(
            map((data:any) =>{
                this.assessmentPerformanceScoreReportCard =data['assesment'];
                if(this.assessmentPerformanceScoreReportCard.length!=0)
                {         
                  return this.assessmentPerformanceScoreReportCard;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }

  getCenterDetails(customer_id, organization_id, is_admin){
    const  params = new  HttpParams().set('customer_id', customer_id)
                                      .set('organization_id', organization_id)
                                      .set('is_admin', is_admin);  
    
    const local_center_url = AppComponent.API_URL + 'center/GetCenterListWithGroups ';
    return this.http_client.post(local_center_url, params,this.httpOptions)
     .pipe(
            map((data:any) =>{
                this.center_data =data['Academy Center Ids'];
                if(this.center_data.length!=0)
                {         
                  return this.center_data;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }

  GetBatchsByAcademy(organization_id){
    const  params = new  HttpParams().set('organization_id', organization_id);  
    
    const local_center_url = AppComponent.API_URL + 'batch/GetBatchsByAcademy';
    return this.http_client.post(local_center_url, params,this.httpOptions)
     .pipe(
            map((data:any) =>{
                this.batch_data =data['batch'];
                if(this.batch_data.length!=0)
                {         
                  return this.batch_data;
                }
                else
                  {
                    return '';
                  }
            }),
        ) 
  }

  GetAttendanceReport(organization_id, customer_id, academy_center_id, filter_value, filter_type, age_flag, is_staff){
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    if (customer_id != null) {
      params = params.append('customer_id', customer_id);
    }
     if (filter_value.length != 0) 
    {
      for (var i = 0; i < filter_type.length; i++) 
      {
         if (filter_type[i] == "Date")
         {
          if (filter_value[i]['Date'][0] != null && filter_value[i]['Date'][1] != null) {
            params = params.append('from', filter_value[i]['Date'][0]);
            params = params.append('to', filter_value[i]['Date'][1]);
          }
          else if (age_flag == "Y") {
            params = params.append('from', filter_value[i]['Date'][0]);
          }
          else if (age_flag == "N") {
            params = params.append('to', filter_value[i]['Date'][0]);
          }
        }
        else if (filter_type[i] == "Center") 
        {
          for (var j = 0; j < filter_value[i]['Center'].length; j++) 
          {
            params = params.append('academy_center_id' + '[' + j + ']', filter_value[i]['Center'][j]);
          }
        } 
               
      }
    }   
    if (is_staff != null) {
      params = params.append('is_staff', is_staff);
    }

    const deregister_bulk_player_url = AppComponent.API_URL + 'dashboard/GetAttendanceReport';
    return this.http_client.post(deregister_bulk_player_url, params,this.httpOptions)
     .pipe(
            map((data:any) =>{
                this.attendance_report_details =data['attendance_report_details'];
                if(this.attendance_report_details.length!=0)
                {         
                  return this.attendance_report_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }

  GetSessionsAttendedReport(organization_id, filter_value, filter_type, age_flag,date_format){
    
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('date_format', date_format);
    if (filter_value.length != 0) 
    {
      for (var i = 0; i < filter_type.length; i++) 
      {
        if (filter_type[i] == "Current") 
        {
          params = params.append('filters[current]', filter_value[i]['Current']);
        }
        else if (filter_type[i] == "Date")
         {
          if (filter_value[i]['Date'][0] != null && filter_value[i]['Date'][1] != null) {
            params = params.append('filters[from_date]', filter_value[i]['Date'][0]);
            params = params.append('filters[to_date]', filter_value[i]['Date'][1]);
          }
          else if (age_flag == "Y") {
            params = params.append('filters[from_date]', filter_value[i]['Date'][0]);
          }
          else if (age_flag == "N") {
            params = params.append('filters[to_date]', filter_value[i]['Date'][0]);
          }
        }
        else if (filter_type[i] == "Center") 
        {
          for (var j = 0; j < filter_value[i]['Center'].length; j++) 
          {
            params = params.append('filters[center]' + '[' + j + ']', filter_value[i]['Center'][j]);
          }
        }
        else if (filter_type[i] == "Batch") 
        {
          for (var j = 0; j < filter_value[i]['Batch'].length; j++) 
          {
            params = params.append('filters[batch]' + '[' + j + ']', filter_value[i]['Batch'][j]);
          }
        } 
      }
    }
    const student_session_report_url = AppComponent.API_URL + 'dashboard/GetSessionsAttendedReport';
    return this.http_client.post(student_session_report_url, params,this.httpOptions)
     .pipe(
            map((data:any) =>{
                this.session_attendance_report_details =data['session_attended_report_details'];
                if(this.session_attendance_report_details.length!=0)
                {         
                  return this.session_attendance_report_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }

  GetAccessControleOfReport(Organization_Id)
  {
    const  params = new  HttpParams().set('organization_id', Organization_Id);    
    const local_authetication_url=AppComponent.API_URL+'/entitlement/GetAccessControleOfReport';
    return this.http_client.post(local_authetication_url,params,this.httpOptions)
     .pipe(
            map((data:any) =>{
                this.report_acl =data['report_acl'];
                if(this.report_acl.length!=0)
                {         
                  return this.report_acl;
                }
                else
                  {
                    return '';
                  }
            }),
        ) 
  }

  getReportList(player_id,organization_id,assessment_id,activity_id)
  {
    const  params = new  HttpParams().set('organization_id', organization_id)
                                      .set('filter[player_id]', player_id) 
                                      .set('performance_set_assessment_id',assessment_id)
                                      .set('activity_id',activity_id);      
    // const url=AppComponent.API_URL+'report/GetReportPublishList';
    const url=AppComponent.API_URL+'report/GetActivityAssessmentReportList';
    return this.http_client.post(url,params,this.httpOptions)
     .pipe(
            map((data:any) =>{
                if(data['status']==200)
                {          
                  return data;
                }
                else
                {
                  return '';
                }
            }),
        ) 
  }

   getPlayerReport(academy_id,performance_set_criteria_setting_id,performance_set_assessment_id,player_id) 
  {
    
    let params = new HttpParams(); 
    params =params.append('academy_id', academy_id);
    performance_set_criteria_setting_id.forEach((element,i) => {
      params =params.append('performance_set_criteria_setting_id['+i+']', element);
    });   
    params =params.append('performance_set_assessment_id', performance_set_assessment_id);
    
    if(player_id!=undefined || player_id!=null)
    {
      params =params.append('filter[player_id]', player_id);
    }    
    
    // const url=AppComponent.API_URL+'report/GetPlayerReport';
    const url=AppComponent.API_URL+'report/GetPlayerAssessmentReport';
    return this.http_client.post(url,params,this.httpOptions)
     .pipe(
            map((data:any) =>{         
                if(data['status']==200)
                {
                  return data;
                }
                else
                {
                  return '';
                }
            }),
        ) 
  }

  isAllowDeleteScoreDetails(score_id, performance_set_id, performance_parameter_id) 
{
  let params = new HttpParams();
  params = params.append('score_id', score_id );
  params = params.append('performance_parameter_id', performance_parameter_id );
  params = params.append('performance_set_id', performance_set_id );
  const local_isallow_score_performance_parameter_delete_url=AppComponent.API_URL+'performance/IsAllowDeleteScoreDetails';
  return this.http_client.post(local_isallow_score_performance_parameter_delete_url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.isallow_delete_score =data['score_details'];
                if(this.isallow_delete_score.length!=0)
                {         
                  return this.isallow_delete_score;
                }
                else
                  {
                    return '';
                  }
            }),
        )
}

DeleteScoreDetails(score_id) 
{
  let params = new HttpParams();
  params = params.append('score_id', score_id ); 
  const local_delete_score_performance_parameter_url=AppComponent.API_URL+'performance/DeleteScoreDetails';
  return this.http_client.post(local_delete_score_performance_parameter_url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                this.deleted_score_details =data['deleted_score_details'];
                if(this.deleted_score_details.length!=0)
                {         
                  return this.deleted_score_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )

}

getCalculatedParameters() 
{
  let params = new HttpParams();
  const local_getCalculatedParameterMaster_url=AppComponent.API_URL+'performance/GetCalculatedParameterMaster';
  return this.http_client.post(local_getCalculatedParameterMaster_url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                if(data['status']==200)
                {         
                  return data;
                }
                else
                  {
                    return '';
                  }
            }),
        )

}


}
// class GhQueryEncoder extends QueryEncoder {
//   encodeKey(k: string): string {
//       k = super.encodeKey(k);
//       return k.replace(/\+/gi, '%2B');
//   }
//   encodeValue(v: string): string {
//       v = super.encodeKey(v);
//       return v.replace(/\+/gi, '%2B');
//   }
// }
