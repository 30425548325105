<div class="uk-grid" data-uk-grid-margin style="margin-top:0px;" >  
    <div class="uk-width-medium-1-1" *ngIf="player_performance_statistics">
        <mat-card class="example-card progress-dashboard select-option" [style.background]="header_background" [style.color]="header_text_color">          
            <mat-card-content>
                <div class="uk-grid" >
                    <div class="uk-width-medium-1-3" *ngIf="player_performance_statistics" style="text-align: center;">  
                        <img [src]="'data:image/png;base64,'+player_performance_statistics['base64_logo_path']" style="width:80px; margin: 0px auto;" />                    
                    </div>
                    <div class="uk-width-medium-2-3" *ngIf="player_performance_statistics">  
                        <div class="uk-width-medium-1-1" *ngIf="player_performance_statistics">  
                            <h2 style="margin-bottom: 5px !important;" [style.color]="header_text_color"><strong>{{player_performance_statistics['organization_name']}}</strong>    </h2>   
                        </div> 
                        <div class="uk-grid uk-grid-collapse">                            
                            <div class="uk-width-medium-1-3" *ngIf="player_performance_statistics" style="margin-top: 10px;">
                                {{role_name}} Name :&nbsp; <strong style="font-size: 16px;">{{player_performance_statistics['Full Name']}}</strong>
                            </div>
                            <div class="uk-width-medium-1-3" *ngIf="player_performance_statistics" style="margin-top: 10px;">  
                              Age :&nbsp; <strong>{{player_performance_statistics['Age']}}</strong>                                        
                            </div>
                            <div class="uk-width-medium-1-3" *ngIf="player_performance_statistics" style="margin-top: 10px;">                            
                            </div>                           
                            <div class="uk-width-medium-1-3" *ngIf="player_performance_statistics">
                                Gender :&nbsp; <strong>{{player_performance_statistics['Gender']}}</strong>
                            </div>
                            <div class="uk-width-medium-1-3" *ngIf="player_performance_statistics">  
                              {{preference_center_name}} / Discipline :&nbsp; <strong>{{player_performance_statistics['academy_center_name']}}</strong>                   
                            </div> 
                        </div>                        
                    </div>                                          
                  </div>                         
            </mat-card-content>
        </mat-card>
    </div>

  <ng-container *ngFor="let item of graph_data ">
          <ng-container *ngFor="let data of graph_div_name;let i = index" >
          <div class="uk-width-medium-1-2"  *ngIf='data==item.performance_parameter_name' [style.display]="Progress_report_div"> 
                  <div style="margin-top:15px; margin-bottom:15px;" >
                          <div class="md-card">
                                  <div class="md-card-toolbar" [style.background]="button_background_color">
                                          <h3 class="md-card-toolbar-heading-text" [style.color]="button_text_color">
                                                  {{item.performance_parameter_name}}
                                          </h3>
                                      </div>  
                              <div class="md-card-content">
                                Objective : <div [innerHTML]="item.performance_parameter_description"  style="display: inline-block;margin:0px 0px; font-size:13px; "
                                [style.color]="text_color"></div>
                                 <canvas [attr.id]="'canvas'+i" style="width:100%; height: 180px" >{{ chart }}</canvas>
                                 <h6 style="text-align: center;" *ngIf="item.parameter_average_score!=''">Average Score: {{item.parameter_average_score| number : '1.2-2'}}</h6>
                              </div>  
                        </div>                            
                    </div>
          </div>
</ng-container>  

  <ng-container *ngFor="let data of performance_parameter_name;let i=index">
      

              <div class="uk-width-medium-1-2" *ngIf='data==item.performance_parameter_name' [style.display]="Progress_report_div"> 
          
                 <div style="margin-top:15px; margin-bottom:15px;">
                      <div class="md-card"  >  
                          <div class="md-card-toolbar" [style.background]="button_background_color">
                              <h3 class="md-card-toolbar-heading-text" [style.color]="button_text_color">
                                  {{item.performance_parameter_name}} 
                              </h3>
                          </div>                      
                          <div class="md-card-content" style="height:180px;overflow: auto;"> 
                                  
                                  <table class="table table-bordered" cellpadding="0" cellspacing="0" style="width:100%; margin-bottom:5px;">
                                      <thead> 
                                          <tr>
                                              <th style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Assessment name</th>
                                              <th style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Score</th>
                                          </tr>
                                      </thead> 
                                      <tbody>
                                          <tr >
                                              <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;"><p *ngFor="let label of final_table_lables;let j=index">{{final_table_lables[i][j]}}</p></td>
                                              <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;"><p *ngFor="let score of final_table_scores;let k=index">{{final_table_scores[i][k]}}</p> </td>
                                          </tr>
                                      </tbody>
                                  
                                  </table>
                              </div>
                          </div> 
                          </div>
                          
                  
           </div>

  </ng-container></ng-container>          
</div>