import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule,Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { SharedModule } from './shared/shared.module';
import {LocationStrategy, PathLocationStrategy,HashLocationStrategy} from '@angular/common';
import {LoginService} from './authentication/login_services/login.service';
import {ForgotPasswordService} from './authentication/forgotPassword_services/forgotPassword.service';

import {OrganizationDetailsService} from './layouts/admin/organization-details.service';

import {StudentListService} from './student-list/student-list-service/student-list.service';
import {AcademyProfileService} from './academy-profile/academy-profile.service';
import {CenterService} from'./center/Center_services/center.service';
import {RoleService} from './role/RoleServices/role.service';
import {StaffService} from './staff/StaffServices/staff.service';
import{FeePlansService }from './fee-plans/fee-plans.service';
import {AssessmentService} from './assessment/assessment_services/assessment.service';
import {InOutReportService} from './in-out-report/in-out-report.service';

import {ResetpasswordService} from './authentication/resetpassword_services/resetpassword.service';
import { ForgotComponent } from './authentication/forgot/forgot.component';
import {ResetpasswordComponent} from './authentication/resetpassword/resetpassword.component';
//import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { OrganizationSelectorComponent } from './authentication/login/login_form/organization_selector/organization-selector.component';
import {AuthGuard} from './auth.guard';
import { RegistrationComponent } from './registration/registration.component';
import{FilterPipe } from './assessment/assessment.uniquepipe';
import 'hammerjs';
import {ChartService} from './chart_dashboard/chart_services/chart.service';
import { NewsService } from './news/news_services/news.component.service';
import {GoogleAnalyticsEventsService} from './google-analytics-events-service';
import { StudentRankingService } from './assessment/student-ranking/student-ranking.service';
import { TokenInterceptor } from './app.interceptor';
import { MessagingService } from './messaging/messaging.service';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import {  RecaptchaModule,RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RegistrationService } from './registration/registration.service';
import{DeregisterStudentServices} from './deregister-student/deregister-student.service';
import { IndividualReportComponent } from './individual-report/individual-report.component';
import{ IndividualReportServices } from './individual-report/individual-report.service';
import{ FeesReportServices } from './fees-reports/fees-reports.service';
// import { QuillModule } from 'ngx-quill';
import { TrendReportsComponent } from './individual-report/trend-reports/trend-reports.component';
import{ TrendReportServices } from './individual-report/trend-reports/trend-reports.service';
import { BulkStudentUploadService } from './student-list/bulk-file-uplaod/bulk-file-upload.service';
import { TaxSettingServices } from './academy-settings/tax-setting/tax-setting.service';
import { CommonApiService } from './common-api/common-api.service';

import { SalesOrderReportService } from './sales-order-report/sales-order-report.service';
import { TermsConditionServices } from './academy-settings/terms-conditions/terms-condition.service';

import {MatRippleModule} from '@angular/material/core';
import { LinkyModule } from 'ngx-linky';
import { TermOfUseComponent } from './term-of-use/term-of-use.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ExpenseModule } from './expense/expense.module';
import { DataDeletionReqComponent } from './authentication/data_deletion/data-deletion-req/data-deletion-req.component';
import { DataDeletionReqService } from './authentication/data_deletion/data-deletion-req/data-deletion-req.service';
import { DeleteReqListingComponent } from './authentication/delete-req-listing/delete-req-listing/delete-req-listing.component';
import { DeleteRequestListService } from './authentication/delete-req-listing/delete-request-list.service';

@NgModule({
  declarations: [
    FilterPipe,
    AppComponent,
    AdminLayoutComponent,    
    ForgotComponent,
    ResetpasswordComponent, 
    OrganizationSelectorComponent,
    RegistrationComponent,
    IndividualReportComponent,
    TrendReportsComponent,
    TermOfUseComponent,
    DataDeletionReqComponent,
    DeleteReqListingComponent,
      
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule, 
    SharedModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,       
    RecaptchaFormsModule,RecaptchaModule,ExpenseModule,
    // QuillModule.forRoot(),
    MatRippleModule,
    LinkyModule,
    NgxMatSelectSearchModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => (new TranslateHttpLoader(http, './assets/i18n/', '.json')),
        deps: [HttpClient]
      }
    })
  ],
  exports: [ ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    LoginService,ForgotPasswordService,OrganizationDetailsService,
      TaxSettingServices,TermsConditionServices,
    StudentListService,ResetpasswordService,CenterService,BulkStudentUploadService,
      AuthGuard,RoleService,StaffService,FeePlansService,AcademyProfileService,MessagingService,
      AssessmentService,InOutReportService,ChartService,
      NewsService,GoogleAnalyticsEventsService,StudentRankingService,IndividualReportServices,
    RegistrationService,DeregisterStudentServices,FeesReportServices,TrendReportServices,
    CommonApiService,
    SalesOrderReportService,DataDeletionReqService,DeleteRequestListService,
    
     
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LeoHoYUAAAAAJumRuuNvUhvRIdhkeK99rB5-7t3',
      } as RecaptchaSettings,
    },    
    
  ], 
  
  bootstrap: [AppComponent]
})
export class AppModule {}