declare const $: any;
import {Component, OnInit, ViewChild, ViewEncapsulation,TemplateRef, ElementRef, AfterViewInit, Input, Output, EventEmitter, HostListener} from '@angular/core';
//import 'rxjs/add/operator/filter';
import {state, style, transition, animate, trigger, AUTO_STYLE} from '@angular/animations';
import { Router, NavigationEnd } from '@angular/router';
import { MenuItems } from '../../shared/menu-items/menu-items';
import {OrganizationDetailsService} from './organization-details.service';
import { FormBuilder, FormGroup,  FormControl } from '@angular/forms';
import {LoginService} from '../../authentication/login_services/login.service';
import {MatDialog, MatTableDataSource} from '@angular/material';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {MatSnackBar,MatSnackBarConfig,MatSnackBarHorizontalPosition,MatSnackBarVerticalPosition,} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import { CommonApiService } from '../../common-api/common-api.service';

export interface Options {
  heading?: string;
  removeFooter?: boolean;
  mapHeader?: boolean; 
  
}

@Component({
  selector: 'app-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('mobileMenuTop', [
        state('no-block, void',
            style({
                overflow: 'hidden',
                height: '0px',
            })
        ),
        state('yes-block',
            style({
                height: AUTO_STYLE,
            })
        ),
        transition('no-block <=> yes-block', [
            animate('400ms ease-in-out')
        ])
    ])
  ],
  
})

export class AdminLayoutComponent implements OnInit,AfterViewInit  {
  deviceType = 'desktop';
  verticalNavType = 'expanded';
  verticalEffect = 'shrink';
  innerHeight: string;
  isCollapsedMobile = 'no-block';
  isCollapsedSideBar = 'no-block';
  toggleOn = true;
  windowWidth: number;
  customer_id:string='';
  public htmlButton: string;
  organization_data:any;  
  center_details:any;
  organization_details:any;
  organization_details1:any;
  organization_id:string;
  pref_detail:any;
  fonttype:string;
  color:string;
  username:string='';
  userMail:string='';
  organization_list:any=[];
  header_background:string='';
  header_text_color:string='';
  public organization_selector_form: FormGroup;
  options: FormGroup;
  Profile_img:any;
  term_conditon_data:any;
  accessControleOfReport:any=[];
  list_of_notification:any;
  list_of_notification_flag:boolean;
  all_notification= new BehaviorSubject<any>('');
  notification_acl_details:any;
  birthday_notification:boolean;
  outstanding_payments_notification:boolean;
  payment_received_notification:boolean;
  invite_to_download_app_notification:boolean;
  cheque_status_change_notification:boolean;

  success_msg:any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  setAutoHide: boolean = true;
  autoHide: number = 3000;
  actionButtonLabel: string = 'Close';
  action: boolean = true;
  iWantToDoAction= new BehaviorSubject<any>('');

  role_name:any;
  right_arrow:boolean=true;
  down_arrow:boolean=false;
  loader:string="none";
  childAcademy: string;
  parent_academy_flag: string;
  role: string;
  assessment_flag: boolean;
  messaging_flag: boolean;
  post_flag: boolean;
  attendance_flag: boolean;
  payments_flag: boolean;

  Content_creator_flag:boolean;
  main_menu:boolean=false;
  main_page_background_color: string;
  menuName: any;
  main_text_color: string;
  button_background_color: string;
  button_text_color: string;
  side_nav_menu_color: string;
  side_panel_background: string;
  organization_preference_details: any;
  side_nav_header_font_color: string;
  cheque_tracking_flag: boolean;

  dataSource = new MatTableDataSource<Element>(this.organization_list);
  organization_search_list: Element[];
  fees_flag: boolean;
  center_name: any;
  lession_plan_flag: boolean;
  ltad_coaching_drill_flag: boolean;
  menu_icon_flag: boolean;
  unread_msg_count: any;
  updateCustomerNotification: string;
  notification_id: any;
  notification_flag: any;
  message_Status: boolean;
  show_comp_with_result_flag: any;
  
  constructor(public menuItems: MenuItems,private OrganizationService:OrganizationDetailsService,private router: Router,
    private fb: FormBuilder,private login_service: LoginService,public dialog: MatDialog,public snackBar:MatSnackBar,
    private translate:TranslateService, private commonApiService:CommonApiService) {
    const browserLang = sessionStorage.getItem("locale")!=null?sessionStorage.getItem("locale"):'en';
    translate.use(browserLang.match(/en|hi/) ? browserLang : 'en');
    this.alldata();    
 }

 alldata()
 {
  const scrollHeight = window.innerHeight-100;
  this.innerHeight = scrollHeight + 'px';
 
  this.windowWidth = window.innerWidth;
  this.setMenuAttributs(this.windowWidth);

  this.options = this.fb.group({
    'fixed': true,
    'top': 64,
    'bottom': 0,
  });
  
  this.customer_id=localStorage.getItem('customer_id');
  this.username=localStorage.getItem('UserName');
  this.userMail=localStorage.getItem('userMail');
  this.childAcademy=localStorage.getItem('childAcademy');  
  this.role=localStorage.getItem('Flag');
  if(this.childAcademy!='null' && this.childAcademy!=null){
    sessionStorage.setItem('OrganizationID',this.childAcademy);
    sessionStorage.setItem('parent_academy_flag','N');
    localStorage.setItem('childAcademy',null);
  }
  
  if(localStorage.getItem('organization_list')!=null)
  {    
    this.organization_list=JSON.parse(localStorage.getItem('Organization_list_1'));
    this.dataSource.data=this.organization_list;
    this.organization_search_list=this.organization_list;
  }
  this.organization_id=sessionStorage.getItem('OrganizationID');
  this.parent_academy_flag=sessionStorage.getItem('parent_academy_flag'); 
                                 
    this.fees_flag=false;
  
        this.OrganizationService.getOrganizationDetails(sessionStorage.getItem('OrganizationID')).subscribe(
          organization_response=>
          {
            
            if(organization_response!='')
            {              
              this.organization_details=organization_response;      
              sessionStorage.setItem('org_name',this.organization_details['organization_name']);   
              sessionStorage.setItem('parents_info_flag',this.organization_details['capture_parents_info_flag']); 
              sessionStorage.setItem('LTAD_flag',this.organization_details['LTAD_flag']); 
              sessionStorage.setItem('org_est_date',this.organization_details['eshtablished_date']);
              sessionStorage.setItem('org_logo',this.organization_details['thumbnail_logo_path']); 
              sessionStorage.setItem('rating_flag',this.organization_details['player_overall_rating_flag']);               
              sessionStorage.setItem('allow_partial_payment_of_fees',this.organization_details['allow_partial_payment_of_fees']); 
              sessionStorage.setItem('auto_uin_generator_flag',this.organization_details['auto_uin_generator_flag']);   
              
              organization_response['organization_module_status'].forEach(element => {                   
              if(element.code=='assmnt')
              {             
                this.assessment_flag=(element.status=='A'?true:false);
              }
              if(element.code=='mesg')
              {                
                this.messaging_flag=(element.status=='A'?true:false);
              }
              if(element.code=='news')
              {                
                this.post_flag=(element.status=='A'?true:false);
              }
              if(element.code=='attd')
              {                
                this.attendance_flag=(element.status=='A'?true:false);
                sessionStorage.setItem('attendance_flag',(element.status=='A'?'true':'false')); 
              }
              if(element.code=='pymt')
              {                
                this.payments_flag=(element.status=='A'?true:false);
                sessionStorage.setItem('payments_flag',(element.status=='A'?'true':'false')); 
              }
              if(element.code=='ch_track')
              {                
                this.cheque_tracking_flag=(element.status=='A'?true:false);
                sessionStorage.setItem('cheque_track_flag',(element.status=='A'?'true':'false')); 
              }
              if(element.code=='cnt')
              {                
                // this.cheque_tracking_flag=true; 
                sessionStorage.setItem('parent_contact_info_flag',(element.status=='A'?'true':'false')); 
              }
              if(element.code=='kit')
              {
                sessionStorage.setItem('kit_flag',(element.status=='A'?'true':'false')); 
              }
              if(element.code=='ltad_ranking')
              {
                sessionStorage.setItem('ranking_tracker_flag',(element.status=='A'?'true':'false')); 
              }              
              if(element.code=='fees')
              {                                
                this.fees_flag=(element.status=='A'?true:false);
              }
              if(element.code=='lesson_planning'){
                this.lession_plan_flag=(element.status=='A'?true:false);
              }
              if(element.code=='ltad_coaching_drill'){
                this.ltad_coaching_drill_flag=(element.status=='A'?true:false);
              }
              if(element.code=='event')
              {
                sessionStorage.setItem('event_flag',element.status); 
              }
              if(element.code=='show_comp_with_result_only'){
                this.show_comp_with_result_flag=(element.status=='A'?'Y':'N');
                sessionStorage.setItem('show_comp_with_result_flag',(element.status=='A'?'Y':'N'));
              }
             
            }); 
            
            }
            else
            {
              error => console.log("Organization API is not working something went wrong!");
            }
          });

      this.OrganizationService.getOrganizationPreferences(sessionStorage.getItem('OrganizationID')).subscribe(
        organization_preference_response=>
        {
          
          if(organization_preference_response!='')
          {
            
            this.pref_detail=organization_preference_response;
            this.organization_preference_details=organization_preference_response;
            sessionStorage.setItem('org_img',this.pref_detail[0]['logo_path']);
            sessionStorage.setItem('date_format',this.pref_detail[0]['date_format']);
            sessionStorage.setItem('currency',this.pref_detail[0]['currency']);
            sessionStorage.setItem('contact_no_length',this.pref_detail[0]['contact_no_length']);
            sessionStorage.setItem('role_name',this.pref_detail[0]['role_name']);
            sessionStorage.setItem('center_name',this.pref_detail[0]['center_name']);
            sessionStorage.setItem('has_privilege_users',this.pref_detail[0]['has_privilege_users']);
            sessionStorage.setItem('debit_to_member_flag',this.pref_detail[0]['debit_to_member_flag']);
            this.fonttype=this.pref_detail[0].font_family;
             if(this.fonttype=="OpenSans")
             {
               this.fonttype="Open Sans";
             }

            this.role_name = this.pref_detail[0]['role_name'];
            this.center_name = this.pref_detail[0]['center_name'];
            this.header_background="rgb("+this.pref_detail[0].page_header_background['r']+","+this.pref_detail[0].page_header_background['g']+","+this.pref_detail[0].page_header_background['b']+")";
            this.header_text_color="rgb("+this.pref_detail[0].page_header_text_color['r']+","+this.pref_detail[0].page_header_text_color['g']+","+this.pref_detail[0].page_header_text_color['b']+")";      
           
            this.button_background_color="rgb(" + this.pref_detail[0]['button_background_color']['r']+ "," + this.pref_detail[0]['button_background_color']['g']+ "," + this.pref_detail[0]['button_background_color']['b'] + ")";
            this.button_text_color="rgb(" + this.pref_detail[0]['button_text_color']['r']+ "," + this.pref_detail[0]['button_text_color']['g']+ "," + this.pref_detail[0]['button_text_color']['b'] + ")";
            this.main_page_background_color="rgb("+organization_preference_response[0]['main_page_background_color']['r']+ "," + organization_preference_response[0]['main_page_background_color']['g']+ "," + organization_preference_response[0]['main_page_background_color']['b'] + ")";
            this.main_text_color="rgb("+organization_preference_response[0]['main_text_color']['r']+ "," + organization_preference_response[0]['main_text_color']['g']+ "," + organization_preference_response[0]['main_text_color']['b'] + ")";

            this.side_nav_menu_color="rgb("+this.pref_detail[0].side_nav_menu_color['r']+","+this.pref_detail[0].side_nav_menu_color['g']+","+this.pref_detail[0].side_nav_menu_color['b']+")";
            this.side_panel_background="rgb("+this.pref_detail[0].side_panel_background['r']+","+this.pref_detail[0].side_panel_background['g']+","+this.pref_detail[0].side_panel_background['b']+")";
            this.side_nav_header_font_color="rgb("+this.pref_detail[0].side_nav_header_font_color['r']+","+this.pref_detail[0].side_nav_header_font_color['g']+","+this.pref_detail[0].side_nav_header_font_color['b']+")";
           
            sessionStorage.setItem('header_background', this.header_background);            
            sessionStorage.setItem('header_text_color', this.header_text_color);             
            sessionStorage.setItem('button_background_color', this.button_background_color);
            sessionStorage.setItem('button_text_color',this.button_text_color);
            sessionStorage.setItem('main_page_background_color',this.main_page_background_color);
            sessionStorage.setItem('main_text_color',this.main_text_color);
            sessionStorage.setItem('side_nav_menu_color', this.side_nav_menu_color); 
            sessionStorage.setItem('side_panel_background', this.side_panel_background);  
            sessionStorage.setItem('side_nav_header_font_color', this.side_nav_header_font_color);  
            sessionStorage.setItem('header_background_rbga', "rgba(" + this.pref_detail[0]['page_header_background']['r']+ "," + this.pref_detail[0]['page_header_background']['g']+ "," + this.pref_detail[0]['page_header_background']['b'] + ",0.95)");

            document.documentElement.style.setProperty('--color', this.header_text_color);
            document.documentElement.style.setProperty('--scrollcolor', this.button_background_color);
            document.getElementById("page_background").style.backgroundColor = this.main_page_background_color;
            document.getElementById("page_content_inner").style.backgroundColor = this.main_page_background_color;
            
            sessionStorage.setItem('allow_share_payment_link',  this.pref_detail[0]['allow_share_payment_link']); 
            
          }
        });

      this.getNotificationList();

      this.OrganizationService.getAccessControleOfNotification(sessionStorage.getItem('OrganizationID')).subscribe(
        response=>
        {      
          if(response!=[])
          {              
            this.notification_acl_details=response; 
            if(this.notification_acl_details['birthday_notification']==1)
            {
              this.birthday_notification=true;
            }
            else
            {
              this.birthday_notification=false;
            }
            if(this.notification_acl_details['outstanding_payments_notification']==1)
            {
              this.outstanding_payments_notification=true;
            }
            else
            {
              this.outstanding_payments_notification=false;
            }
            if(this.notification_acl_details['payment_received_notification']==1)
            {
              this.payment_received_notification=true;
            }
            else
            {
              this.payment_received_notification=false;
            }
            if(this.notification_acl_details['invite_to_download_app_notification']==1)
            {
              this.invite_to_download_app_notification=true;
            }
            else
            {
              this.invite_to_download_app_notification=false;
            }            
                          
          }      
          else
          {            
            this.notification_acl_details=[];                     
          }
        },

        error => console.log()
      ); 
      this.Content_creator_flag=localStorage.getItem('Content_creator_flag')=='Y'?true:false;  
      this.menuName=this.Content_creator_flag==true?'coachingDrills':'dashboard';
      // console.log(this.router.url)
 }

  ngOnInit() {
    this.organization_selector_form = this.fb.group ( {
      Organization_list: new FormControl()
    } );    
    this.Profile_img=localStorage.getItem('Profile_img');     
 
  }
  getNotificationList()
  {
    this.OrganizationService.getCustomerNotificationList(localStorage.getItem('customer_id'),sessionStorage.getItem('OrganizationID')).subscribe(
      response=>
      {  
        if(response!='')
        {     
          this.list_of_notification=response; 
        
          this.all_notification.next(this.list_of_notification);
          this.list_of_notification_flag=true;      
          this.getCustomerUnreadMsgCount();
        }      
        else
        {            
          this.list_of_notification=[];      
          this.list_of_notification_flag=false;                  
        }
      },

      error => console.log("getCustomerNotificationList service is not working!")
    );
  }
  shouldRun = true;
  onClickedOutside(e: Event) {
    this.windowWidth = window.innerWidth;
      if (this.windowWidth < 768 ) 
      {                  
        $('#page_content').before().on('click', function(){
          $("body").removeClass("sidebar_main_active");
      });          
      }
      
  }
  onWindowResize(event) {
    this.windowWidth = event.target.innerWidth;
    this.menu_icon_flag=$('body').hasClass('sidebar_main_active');
  }

  leftMenuToggle(){
    $("body").toggleClass("sidebar_main_active");
    this.menu_icon_flag=$('body').hasClass('sidebar_main_active');
  }
 
  onResize(event) 
  {    
    this.innerHeight = event.target.innerHeight + 'px';
    /* menu responsive */
    this.windowWidth = event.target.innerWidth;
    let reSizeFlag = true;
    if (this.deviceType === 'tablet' && this.windowWidth >= 768 && this.windowWidth <= 1024) {
      reSizeFlag = false;
    } else if (this.deviceType === 'mobile' && this.windowWidth < 768) {
      reSizeFlag = false;
    }

    if (reSizeFlag) {
      this.setMenuAttributs(this.windowWidth);
    }
  }

  setMenuAttributs(windowWidth) {
      if (windowWidth >= 768 && windowWidth <= 1024) {
        this.deviceType = 'tablet';
        this.verticalNavType = 'collapsed';
        this.verticalEffect = 'push';
      } else if (windowWidth < 768) {
        this.deviceType = 'mobile';
        this.verticalNavType = 'offcanvas';
        this.verticalEffect = 'overlay';
      } else {
        this.deviceType = 'desktop';
        this.verticalNavType = 'expanded';
        this.verticalEffect = 'shrink';
      }
  }

  toggleOpened() {
    if (this.windowWidth < 768) {
        this.toggleOn = this.verticalNavType === 'offcanvas' ? true : this.toggleOn;
        this.verticalNavType = this.verticalNavType === 'expanded' ? 'offcanvas' : 'expanded';
    } else {
        this.verticalNavType = this.verticalNavType === 'expanded' ? 'collapsed' : 'expanded';
    }
  }

  toggleOpenedSidebar() {
    this.isCollapsedSideBar = this.isCollapsedSideBar === 'yes-block' ? 'no-block' : 'yes-block';
  }

  onMobileMenu() {
    this.isCollapsedMobile = this.isCollapsedMobile === 'yes-block' ? 'no-block' : 'yes-block';
  }
 
  sidebar_toggle()
  {     
    this.windowWidth = window.innerWidth;    
    if (this.windowWidth < 768 ) {   
    $("body").toggleClass("sidebar_main_active");   

    }
    else if(this.windowWidth >= 768 && this.windowWidth <= 1024)
    {
      $("body").toggleClass("sidebar_main_active"); 
    }
   
    else{
      // $("sidebar_main1").show();
      
       $("body").toggleClass("sidebar_main_open");
    }
   
  }
  highlightmenu(menuName)
  {
    
    $(".current_section").click(function(){

      $(".current_section").removeClass("active");
      $(this).addClass("active");  
    });
    this.menuName=menuName;
 

    // $(".submenu").click(function(){
    //   $(".submenu").removeClass("active");
    //   $(this).addClass("active");  
    // });
  }
 
  selectedMenu()
  {
   this.loader="block";
  }

  openTermsCondition()
  {
    this.dialog.open(this.term_conditon,
      {
        //height:'500px',
        width:'550px',
        disableClose:true,
      });
      this.login_service.getUserTermCondition().subscribe(
        response => 
        {
          if(response!='')
          {
          this.term_conditon_data=response;
          }
          else
          {
            console.log('error');
          }
        });
  }
  subMenu()
  {  
    $('ul>li ul').toggleClass("showhideSubMenu");  
    this.down_arrow=!this.down_arrow;
    this.right_arrow=!this.right_arrow;
  }

  setNotificationAccessCntrl(notification_cntrl_name)
  {
    let birthday_notification,outstanding_payments_notification,payment_received_notification,invite_to_download_app_notification,news_notification,cheque_status_change_notification;

    switch (notification_cntrl_name) {
      case "birthday_notification":
        this.success_msg=(this.birthday_notification==true?"Birthday Notification Activated Successfully":
        "Birthday Notification Deactivated Successfully");

        break;
        case "outstanding_payments_notification":         
            this.success_msg=this.outstanding_payments_notification==true?"Outstanding Payments 5 days after Due Date Notification Activated Successfully":
            "Outstanding Payments 5 days after Due Date Notification Activated Successfully"; 
         
        break;
        case "payment_received_notification":
          if(this.payment_received_notification==true)
          {
            this.success_msg="Payment Received Thank You Note Notification Activated Successfully"; 
            
          }
          else
          {
            this.success_msg="Payment Received Thank You Note Notification Activated Successfully"; 
            
          }
        break;
        case "invite_to_download_app_notification":
          if(this.birthday_notification==true)
          {
            this.success_msg="Invite To Download To Registered Email On New Registrations Notification Activated Successfully";   
            
          }
          else
          {
            this.success_msg="Invite To Download To Registered Email On New Registrations Notification Activated Successfully";   
            
          }
        break;  
        case "cheque_status_change_notification":
        if(this.cheque_status_change_notification==true)
          {
            this.success_msg="Cheque Status Change Notification Activated Successfully"; 
          }
          else
          {
            this.success_msg="Cheque Status Change Notification Deactivated Successfully";
          }
        break; 
      default:
        break;
    }
    birthday_notification=(this.birthday_notification==true?'1':'0');
    outstanding_payments_notification=(this.outstanding_payments_notification==true?'1':'0');
    payment_received_notification=(this.payment_received_notification==true?'1':'0');
    invite_to_download_app_notification=(this.invite_to_download_app_notification==true?'1':'0');
    cheque_status_change_notification=(this.cheque_status_change_notification==true?'1':'0');
    
  //   this.OrganizationService.setAccessControleOfNotification(sessionStorage.getItem('OrganizationID'),birthday_notification,outstanding_payments_notification,payment_received_notification,
  //   invite_to_download_app_notification,news_notification,cheque_status_change_notification,this.customer_id).subscribe(
  //     response => 
  //     {
  //       if(response!='')
  //       {
  //         console.log(response);
  //         this.snackBarDisplay();
  //       //  this.term_conditon_data=response;
  //       }
  //       else
  //       {
  //         console.log('error');
  //       }
  //     },
  //     error => console.log("getUserTermCondition IS NOT WORKING!")
  // );  
  }

  selectedOption(value)
  {  
    switch (value) {

      case '1':   
        this.router.navigate(['/student']);   
        this.iWantToDoAction.next("addStudent");   
      break;
      case '2':
        this.router.navigate(['/student']);
      break;
      case '3':
        this.router.navigate(['/student']);
      break;
      case '4':
        this.router.navigate(['/student']);
      break;
      case '5':
        this.router.navigate(['/center']);
      break;
      case '6':
        this.router.navigate(['/staff']);
        this.iWantToDoAction.next("addStaff"); 
      break;
      case '7':
        this.router.navigate(['/FeePlans']);
        this.iWantToDoAction.next("addFeePlan"); 
      break;
      case '8':
        this.router.navigate(['/center']);
        
        this.iWantToDoAction.next("addCenter");   
      break;
      default:
        break;
    }
  }
  snackBarDisplay()
  {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    this.snackBar.open(this.success_msg,this.action ? this.actionButtonLabel : undefined,config);
  } 
  ngAfterViewInit() {
  
  }

  @ViewChild('term_conditon') term_conditon: TemplateRef<any>;
  @ViewChild('app-dashboard') a:TemplateRef<any>;

  selectorganization(org_data)
  {
    this.organization_id=org_data;  
    this.OrganizationService.getChildOrganizations(this.customer_id,this.organization_id).subscribe(
    response => {
      if (response['status']==200 && response['data']['organization_details']!=false) {  
        if(response['data']['organization_details']['child_academies'].length!=0)
        { 
          this.router.navigate(['/organization_selector',this.organization_id]);
        }
        else{        
          this.login_academy(this.organization_id);                    
        }
      }
      else{
        this.login_academy(this.organization_id);
      }
    }); 
  } 

 login_academy(academy_id)
  { 
  sessionStorage.setItem('parent_academy_flag','N');
  var org_data=[];
  this.organization_list.forEach(element => {
    if(element.organization_id==academy_id)
    {
      org_data.push(element);
    }
  });
  var admin=0;
  var headoffice=0;
  var admin_role=0;
  var content_creator=0;
  org_data[0]['role_types'].forEach(element => {
    if(element=='H')
    {
      headoffice=1; 
    }
    if(element=='A')
    {
      admin=1;
    }
  });
  org_data[0]['role_names'].forEach(element => {
        
    if(element=='Admin')
    {
      admin_role=1;
    }
    if(element=='Content Creator')
    {
      content_creator=1;
    }
  });
  if(admin==1)
  {
    localStorage.setItem('Flag', 'A');
  }
  if(headoffice==1 && admin==0)
  {
    localStorage.setItem('Flag', 'H');
  }
 
  sessionStorage.setItem('OrganizationID',academy_id);
  this.alldata(); 
  if(admin_role==1 || headoffice==1)
    {
      localStorage.setItem('Content_creator_flag', 'N');
      this.router.navigate(['dashboard'],{ queryParams: { page: 1 }  });
      this.menuName='dashboard';
    }
    if(content_creator==1 && admin_role==0)
    {
      localStorage.setItem('Content_creator_flag', 'Y');
      this.router.navigate(['/coaching-drill']);
      this.menuName='coachingDrills';
    }
  }

  leftMenuResize(event){
    this.innerHeight = (event.target.innerHeight-100) + 'px';
    $("body").addClass("sidebar_main_active");
  }
  applyFilter(filterValue: any) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    this.organization_search_list=this.dataSource['filteredData'];
    if(this.dataSource.filteredData.length==0)
     {
      //  this.NoRecord_flag=true;
     }
     else{
      //  this.NoRecord_flag=false;
     }
  }
  changeLocale(locale:string){
    this.commonApiService.changeLocale(locale);
  } 
  
  getCustomerUnreadMsgCount() {
    this.OrganizationService.getCustomerUnreadMsgCount(this.customer_id, this.organization_id).subscribe(
      response => {
        this.unread_msg_count = response;
     
      }
    )
  }

}