import { Injectable } from '@angular/core';
import {AppComponent} from '../../app.component';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { TaxSetting,Paymentflags } from './tax-setting';

@Injectable()
export class TaxSettingServices {
  httpOptions;
  constructor(private http_client: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
    };
  }
  addTax(taxsetting: TaxSetting,tax_plan_id) {
   
    let params=new  HttpParams();
      params = params.append('organization_id', taxsetting.organization_id);
      params = params.append('business_legal_name',taxsetting.business_legal_name);
      params = params.append('tax_address',taxsetting.tax_address);
      params = params.append('tax_registration_number',taxsetting.tax_registration_number);
      params = params.append('PAN',taxsetting.PAN);
      if(taxsetting.status==true)
      {
        params = params.append('status','on');
      }
      else{
        params = params.append('status','off');
      }
      
      if(tax_plan_id !=undefined){
        params = params.append('tax_plan_id',tax_plan_id);
      }
      taxsetting['tax_plan_name'].forEach((element,i) => {
        params = params.append('tax_plan_name['+i+']',element);
      });
      taxsetting['tax_percentage'].forEach((element,i) => {
        params = params.append('tax_percentage['+i+']',element);
      });
    const url = AppComponent.API_URL + "payment/AddTaxDetails";
    return this.http_client.post(url, params, this.httpOptions).pipe(
      tap(data => {       
      })
    );
  }
  getTaxDetail(organization_id){

    const  params = new  HttpParams().set('organization_id', organization_id);
    
    const local_playerlist_url = AppComponent.API_URL + 'payment/GetAcademyTaxDetails';  
    return this.http_client.post(local_playerlist_url, params,this.httpOptions)
    .pipe(
            map((data:any) =>{
             return data
            }),
        )
  }

  getPaymentFlagDetails(organization_id) {
   
    let params=new  HttpParams();
      params = params.append('organization_id', organization_id);

    const url = AppComponent.API_URL + "entitlement/GetPaymentFlagDetails";
    return this.http_client.post(url, params, this.httpOptions).pipe(
      tap(data => {  
        return data     
      })
    );
  }

  setPaymentFlagDetails(organization_id,paymentflags:Paymentflags) {
   
    let params=new  HttpParams();
      params = params.append('organization_id', organization_id);
      params = params.append('online_payment_flag', (paymentflags.online_payment_flag==true?'Y':'N'));
      params = params.append('allow_partial_payment_of_fees', (paymentflags.allow_partial_payment_of_fees==true?'Y':'N'));
      params = params.append('tax_settings_flag', (paymentflags.tax_settings_flag==true?'Y':'N'));

    const url = AppComponent.API_URL + "entitlement/SetPaymentFlagDetails";
    return this.http_client.post(url, params, this.httpOptions).pipe(
      tap(data => {  
        return data     
      })
    );
  }
}
