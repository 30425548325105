export enum  ApiUrlConstant {
     AuthenticateUser = 'authentication/AuthenticateUser',
     UserAuthenticate = 'authentication/UserAuthenticate', 
     Authorization ='authorization/Authorization',
     GetTermsOfUse = 'termsofuse/GetTermsOfUse',
     GetOrganizationsList = 'organization/GetOrganizationsList',
     GetChildOrganizations = 'parent_child_academy/GetChildOrganizations',
     GetAccessToken = 'authentication/GetAccessToken',

     GetCountries = 'general/GetCountries',
     GetStates = 'general/GetStates',
     GetCities = 'general/GetCities',

     AddOrganization='organization/AddOrganization',
     GetSportType = 'general/GetSportType',

     ForgotPassword = 'authentication/ForgotPassword',
     SetPassword = 'authentication/SetPassword',

     // Chart page
     GetAcademyStudentHeadCount = 'dashboard/GetAcademyStudentHeadCount',
     GetChildAcademyStudentHeadCountAverage = 'dashboard/GetChildAcademyStudentHeadCountAverage',
     GetAcademyAllCenterStudentHeadCount = 'dashboard/GetAcademyAllCenterStudentHeadCount',
     GetAcademyCenterUtilization = 'dashboard/GetAcademyCenterUtilization',
     GetAcademyAllCenterUtilization = 'dashboard/GetAcademyAllCenterUtilization',
     GetAcademyAllCenterPaymentDetails = 'dashboard/GetAcademyAllCenterPaymentDetails',
     GetAcademyAttedance = 'dashboard/GetAcademyAttedance',
     GetAcademyAllCenterAttendance = 'dashboard/GetAcademyAllCenterAttendance',
     GetAcademyAllCenterStudentHeadCountDonut='dashboard/GetAcademyAllCenterStudentHeadCountDonut',
     GetAcademyAllCenterStudentHeadCountTable = 'dashboard/GetAcademyAllCenterStudentHeadCountTable',
     GetAcademyAgeCount = 'dashboard/GetAcademyAgeCount',
     GetChildAcademyAgeCount = 'dashboard/GetChildAcademyAgeCount',
     GetAcademyAllCenterStudentAgeCount = 'dashboard/GetAcademyAllCenterStudentAgeCount',
     GetAcadmeyDashboardEntitlement = 'entitlement/GetAcadmeyDashboardEntitlement',
     GetChildAcademyDetailsForDashboard = 'dashboard/GetChildAcademyDetailsForDashboard',
     GetChildAcademyUtilizationDetails = 'dashboard/GetChildAcademyUtilizationDetails',
     GetChildAcademyStudentHeadCount = 'dashboard/GetChildAcademyStudentHeadCount',
     GetCascadedAssessmentList = 'performance/GetCascadedAssessmentList',
     GetAssessmentConsolidatedRankingDetails = 'dashboard/GetAssessmentConsolidatedRankingDetails',
     AddOrganizationFormData = 'mobile/AddOrganizationFormData',
     UpdatePlayerPerformanceConsoliatedRanking = 'player/UpdatePlayerPerformanceConsoliatedRanking',
     GetPlayerConsolidatedRankingAssesmentReport = 'report/GetPlayerConsolidatedRankingAssesmentReport',
     GetChildAcademyUtilizationFacetNevigation = 'dashboard/GetChildAcademyUtilizationFacetNevigation',
     GetCenterUtiizationGraphDetails = 'dashboard/GetCenterUtiizationGraphDetails',
     TodaysLiveAttendance = 'attendance/TodaysLiveAttendance',

     // student list

     // Center list

     GetCenterListWithGroups = 'center/GetCenterListWithGroups',
     AddCenter = 'center/AddCenter',
     IsAllowDeleteCenter = 'center/IsAllowDeleteCenter',
     DeleteCenter = 'center/DeleteCenter',
     GetCenterDetails = 'center/GetCenterDetails',
     UpdateCenter = 'center/UpdateCenter',
     CheckCenterName = 'center/CheckCenterName',
     GetCenterUserList = 'user/GetCenterUserList',
     GetAddBatchFormDetails = 'batch/GetAddBatchFormDetails',
     SetDefaultBatch = 'batch/SetDefaultBatch',
     GetBatchDetails = 'batch/GetBatchDetails',
     UpdateBatchDetails = 'batch/UpdateBatchDetails',
     GetFeeSetup = 'feesetup/GetFeeSetup',
     SetFeeSetup = 'feesetup/SetFeeSetup',

     // roles
     GetRole = 'role/GetRole',
     SetRole = 'role/SetRole',
     IsAllowDeleteRole = 'role/IsAllowDeleteRole',
     DeleteRole = 'role/DeleteRole',
     CheckRoleDetails = 'role/CheckRoleDetails',
     AccessControlQuestionsByRoleType = 'entitlement/AccessControlQuestionsByRoleType',
     GetOrganizationModulesQuestionsList = 'entitlement_granularization/GetOrganizationModulesQuestionsList',
     AddAccessControlDetails = 'entitlement_granularization/AddAccessControlDetails',
     AddRoleEntitlementDetails = 'mobile/AddRoleEntitlementDetails',
     GetRoleEntitlementDetails = 'entitlement_granularization/GetRoleEntitlementDetails',
     UpdateAccessControlDetails = 'entitlement_granularization/UpdateAccessControlDetails',

     
}