import { Component, OnInit,ViewChild,TemplateRef } from '@angular/core';
import { IndividualReportServices } from './individual-report.service';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import {MatPaginator, MatTableDataSource,MatSort,MatDialog,MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
declare const $: any;
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-individual-report',
  templateUrl: './individual-report.component.html',
  styleUrls: ['./individual-report.component.css']
})
export class IndividualReportComponent implements OnInit {
   
  organization_id:any;
  publish_report_list:any;
  specific_student_report_card_data:any;
  performance_set_assessment_id:any;
  spinner_div:any;
  no_report_card_data:boolean=true;  
  report_card_data:any;
  player_id:any;
  default_scores:any;
  legends:any;
  input_assessment_name:any;
  start_date:any; 
  end_date:any; 
  report_type:string;
  report_name:any;

  msg:any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  setAutoHide: boolean = true;
  autoHide: number = 3000;
  actionButtonLabel: string = 'Close';
  action: boolean = true;
 
  sub:any;
  // auth_key:any;
  // session_id:any;
  // device_id:any;
  org_id:any;
  academy_center_id:any;
  org_logo:any;
  report_publish_id:any;  
  academy_logo:any;
  reportname:any;
  organization_name:any;
  academy_center_name:any;
  performance_set_details:any;

  doc_src: any;
  image_doc_flag: any = false;
  pdf_doc_flag: any = false;

  report_card_color:any;
  card_color:any;
  text_color:any;
  report_header:any;
  bottom_border:any;
  box_shadow:any;
  ranking: any;
  player_count: any;
  percentile: any;
  authorization: any;
  performance_set_criteria_setting_id: any;
  activity_report: any;
  default_report_score: any=[];
  preference_center_name: any;
  constructor(private IR_services:IndividualReportServices,public snackBar:MatSnackBar,
    private route:ActivatedRoute,public dialog: MatDialog,private _sanitizer: DomSanitizer) {
    this.activity_report='';
      this.sub = this.route.queryParams.subscribe(params => {        
          // this.auth_key = params['auth_key'];
          // this.device_id = params['device_id'];
          // this.session_id = params['session_id'];
          this.player_id = params['player_id'];
          this.organization_id = params['org_id'];
          this.academy_center_id = params['academy_center_id'];
          this.performance_set_assessment_id=params['performance_set_assessment_id'];
          this.authorization=params['Authorization'];
          this.performance_set_criteria_setting_id=params['performance_set_criteria_setting_id'];
          this.activity_report=params['activity_report'];
          this.report_publish_id=params['report_publish_id'];
      });
      
      
      // localStorage.setItem("auth_key", this.auth_key);
      // localStorage.setItem("session_id", this.session_id);
      // localStorage.setItem("device_id", this.device_id);
      localStorage.setItem('access_token', this.authorization);
      
   }

  ngOnInit() { 
    //this.getReportPublishList();
     if(this.organization_id=='2115')
    {
      this.report_card_color='#080824fc'; // dark blue
      this.card_color='#15154fcc';  // blue
      this.text_color='#dddddd';  //white
      this.report_header='#ffd700'; //gold
      this.bottom_border='#080824fc';
      this.box_shadow='0px 0px 0px ';
    }
    else
    {
      this.report_card_color='#ffffff'; // white
      this.card_color='#efefef';
      this.text_color='#555';
      this.bottom_border='#eee';
      this.box_shadow='1px 1px 6px #c7c7c7';

    }
    this.getReportCard();
  }

  getReportPublishList()
 {   
   this.spinner_div="block";    
    this.IR_services.getReportPublishList(this.organization_id).subscribe(
      response => {        
        if (response != '') 
        {   
          this.spinner_div="none";
          this.publish_report_list=response;   
        }  
        else 
        {
          this.publish_report_list = null;    
          this.spinner_div="none";        
        }
      }); 
 } 
  

 getReportCard() 
 {     
   if(this.activity_report=='1'){
    this.v628Report()
   }else{
    this.v627Report()
   }
 }
 v627Report(){
    this.specific_student_report_card_data=[]; 
    this.IR_services.getPlayer627Report(this.organization_id,this.report_publish_id,
      this.player_id,this.academy_center_id).subscribe(
        response => {
    
      if (response != '')  
      {       
          this.org_logo=response['logo_path'];
          if(response['legend']!=undefined)
          {
            this.legends=response['legend'];             
          }            
          this.input_assessment_name=response['input_assessment_name'];
          this.academy_logo= response['logo_path'];  
          this.start_date=response['start_date'];
          this.end_date=response['end_date'];
          this.report_type=response['report_type'];  
          this.report_name=response['report_name'];    
          this.reportname=response['input_assessment_name'];   
          this.specific_student_report_card_data=response['player'];  
          this.ranking=this.specific_student_report_card_data[0]['ranking'];
          this.player_count=this.specific_student_report_card_data[0]['player_count'];
          this.percentile=this.specific_student_report_card_data[0]['percentile'];
          this.organization_name=response['organization_name'];
          this.academy_center_name=this.specific_student_report_card_data[0]['academy_center_name'];   
          this.performance_set_details=this.specific_student_report_card_data[0]['performance_set_details'];

            this.default_scores=[];            
            if(this.report_type=='default')
            {
              this.specific_student_report_card_data[0]['performance_parameters'].forEach((element,j) => {
              element['scores'].forEach(element1 => 
              {
                if( element1.score_type_name=='Numeric')
              {
                if(element1.better_score!='-' && element1.score!='-' && element1.better_score!='' && element1.score!='')
                {
                this.default_scores.push({'better_score':(parseFloat(element1.better_score)),   
                'min_score':(parseFloat(element1.min)),
                'max_score':(parseFloat(element1.max)),        
                'performance_parameter_name':element.performance_parameter_name,
                'score':(parseFloat(element1.score)),            
                'desc':element.performance_parameter_description,
                'comments':element1.comments,
                'score_unit':element1.score_unit,
                'score_label':element1.score_label,
                'performance_set_id':element.performance_set_id,
                'score_type_name':element1.score_type_name,
                'performance_area_name':element.performance_area_name,
                'batch_avg_score':element1.batch_avg_score});
                }
              }
              if( element1.score_type_name=='Rating')
              {
                this.default_scores.push({'better_score':(parseFloat(element1.better_score)),           
                'performance_parameter_name':element.performance_parameter_name,
                'score':(parseFloat(element1.score)),            
                'desc':element.performance_parameter_description,
                'comments':element1.comments,
                'score_unit':element1.score_unit,
                'score_label':element1.score_label,
                'rating_details':element1.rating_details,
                'performance_set_id':element.performance_set_id,
                'score_type_name':element1.score_type_name,
                'performance_area_name':element.performance_area_name,
                'batch_avg_score':element1.batch_avg_score,
                'better_score_grade':element1.better_score_grade,
                'batch_avg_score_grade':element1.batch_avg_score_grade});
              } 
              
              if(element1.score_type_name=='Dropdown' || element1.score_type_name=='Text' || element1.score_type_name=='Document/Image')
              {
                this.default_scores.push({         
                'performance_parameter_name':element.performance_parameter_name,
                'score':element1.score,           
                'desc':element.performance_parameter_description,
                'comments':element1.comments,
                'score_unit':element1.score_unit,
                'score_label':element1.score_label,
                'performance_set_id':element.performance_set_id,
                'score_type_name':element1.score_type_name,
                'performance_area_name':element.performance_area_name});
              }  
              
              if(element1.score_type_name=='Stopwatch')
              {
                this.default_scores.push({         
                'performance_parameter_name':element.performance_parameter_name,
                'score':parseFloat(element1.score),            
                'desc':element.performance_parameter_description,
                'comments':element1.comments,
                'score_unit':element1.score_unit,
                'score_label':element1.score_label,
                'performance_set_id':element.performance_set_id,
                'score_type_name':element1.score_type_name,
                'performance_area_name':element.performance_area_name,
                'score_mm_ss_ms':element1.score_mm_ss_ms, 
                'better_score':parseFloat(element1.better_score),
                'better_score_mm_ss_ms':element1.better_score_mm_ss_ms,
                'batch_avg_score':element1.batch_avg_score,
                'batch_avg_score_mm_ss_ms':element1.batch_avg_score_mm_ss_ms});
              }
              });             
              });
              }
              if(this.report_type=='by_criteria')
              {
              this.specific_student_report_card_data[0]['performance_parameters'].forEach((element,j) => {            
              
              element['scores'].forEach(element1 => {
                
              if(element1.score_type_name=='Numeric')
                {
                  this.default_scores.push({
                      
                      'performance_parameter_name':element.performance_parameter_name,                    
                      'parameter_score_compare_criteria_key':element1.parameter_score_compare_criteria_key,
                      'min_score':(parseFloat(element1.min)),
                      'max_score':(parseFloat(element1.max)),
                      'min_score1':(parseFloat(element1.min_score)),
                      'max_score1':(parseFloat(element1.max_score)),
                      'score':(parseFloat(element1.score)),
                      'batch_highest_score':(parseFloat(element1.batch_highest_score)),
                      'batch_lowest_score':(parseFloat(element1.batch_lowest_score)),
                      'avg_score':(parseFloat(element1.avg_score)),
                      'benchmark_score':(parseFloat(element1.benchmark_score)),
                      'less_than_min_score_color':element1.less_than_min_score_color,
                      'less_than_min_score_label':element1.less_than_min_score_label,
                      'in_between_min_and_max_score_color':element1.in_between_min_and_max_score_color,
                      'in_between_min_and_max_score_label':element1.in_between_min_and_max_score_label,
                      'greater_than_max_score_color':element1.greater_than_max_score_color,
                      'greater_than_max_score_label':element1.greater_than_max_score_label,
                      'color':element1.color,
                      'desc':element.performance_parameter_description,
                      'comments':element1.comments,
                      'is_allow_show_comment':element1.is_allow_show_comment,
                      'is_allow_show_desc':element1.is_allow_show_desc,
                      'score_unit':element1.score_unit,
                      'label':element1.label,
                      'lowest_in_batch_score_label':element1.lowest_in_batch_score_label,
                      'highest_in_batch_score_label':element1.highest_in_batch_score_label,
                      'closer_to_average_in_batch_score_label':element1.closer_to_average_in_batch_score_label,
                      'score_label':element1.score_label,
                      'score_type_name':element1.score_type_name,
                      'performance_area_name':element.performance_area_name,
                      'batch_avg_score':element1.batch_avg_score,
                      'is_display_parameter':element.is_display_parameter});

                } 
                if(element1.score_type_name=='Rating')
                {
                  this.default_scores.push({
                      
                      'performance_parameter_name':element.performance_parameter_name,                    
                      'parameter_score_compare_criteria_key':element1.parameter_score_compare_criteria_key,
                      'min_score':(parseFloat(element1.min)),
                      'max_score':(parseFloat(element1.max)),
                      'score':(parseFloat(element1.score)),
                      'benchmark_score':(parseFloat(element1.benchmark_score)),
                      'color':element1.color,
                      'desc':element.performance_parameter_description,
                      'comments':element1.comments,
                      'is_allow_show_comment':element1.is_allow_show_comment,
                      'is_allow_show_desc':element1.is_allow_show_desc,
                      'score_unit':element1.score_unit,
                      'score_label':element1.score_label,
                      'rating_details':element1.rating_details,
                      'score_type_name':element1.score_type_name,
                      'performance_area_name':element.performance_area_name,
                      'batch_avg_score':element1.batch_avg_score,
                      'better_score_grade':element1.better_score_grade,
                      'batch_avg_score_grade':element1.batch_avg_score_grade,
                      'is_display_parameter':element.is_display_parameter});
                }             
                if(element1.score_type_name=='Dropdown' || element1.score_type_name=='Text' || element1.score_type_name=='Document/Image')
              {
                this.default_scores.push({
                      
                      'performance_parameter_name':element.performance_parameter_name,                    
                      'parameter_score_compare_criteria_key':element1.parameter_score_compare_criteria_key,
                      'min_score':element1.min_score,
                      'max_score':element1.max_score,
                      'score': element1.score,
                      'batch_highest_score':element1.batch_highest_score,
                      'batch_lowest_score':element1.batch_lowest_score,
                      'avg_score':element1.avg_score,
                      'benchmark_score':element1.benchmark_score,
                      'less_than_min_score_color':element1.less_than_min_score_color,
                      'less_than_min_score_label':element1.less_than_min_score_label,
                      'in_between_min_and_max_score_color':element1.in_between_min_and_max_score_color,
                      'in_between_min_and_max_score_label':element1.in_between_min_and_max_score_label,
                      'greater_than_max_score_color':element1.greater_than_max_score_color,
                      'greater_than_max_score_label':element1.greater_than_max_score_label,
                      'color':element1.color,
                      'desc':element.performance_parameter_description,
                      'comments':element1.comments,
                      'is_allow_show_comment':element1.is_allow_show_comment,
                      'is_allow_show_desc':element1.is_allow_show_desc,
                      'score_unit':element1.score_unit,
                      'score_label':element1.score_label,
                      'score_type_name':element1.score_type_name,
                      'performance_area_name':element.performance_area_name,
                      'is_display_parameter':element.is_display_parameter});
                }
                if(element1.score_type_name=='Stopwatch')
                {
                    this.default_scores.push({                  
                    
                      'performance_parameter_name':element.performance_parameter_name,                    
                      'parameter_score_compare_criteria_key':element1.parameter_score_compare_criteria_key,
                      'score':(parseFloat(element1.score)),
                      'score_label':element1.score_label,
                      'score_unit':element1.score_unit,
                      'score_mm_ss_ms':element1.score_mm_ss_ms,
                      'desc':element.performance_parameter_description,
                      'comments':element1.comments,
                      'is_allow_show_comment':element1.is_allow_show_comment,
                      'is_allow_show_desc':element1.is_allow_show_desc,
                      'color':element1.color,
                      'label':element1.label,

                      'benchmark_score':(parseFloat(element1.benchmark_score)),
                      'benchmark_score_mm_ss_ms':element1.benchmark_score_mm_ss_ms,


                      'min_score':(parseFloat(element1.min)),
                      'max_score':(parseFloat(element1.max)),


                      'min_score1':(parseFloat(element1.min_score)),
                      'max_score1':(parseFloat(element1.max_score)),
                      'min_score_mm_ss_ms':element1.min_score_mm_ss_ms,
                      'max_score_mm_ss_ms':element1.max_score_mm_ss_ms,
                      'less_than_min_score_color':element1.less_than_min_score_color,
                      'less_than_min_score_label':element1.less_than_min_score_label,
                      'in_between_min_and_max_score_color':element1.in_between_min_and_max_score_color,
                      'in_between_min_and_max_score_label':element1.in_between_min_and_max_score_label,
                      'greater_than_max_score_color':element1.greater_than_max_score_color,
                      'greater_than_max_score_label':element1.greater_than_max_score_label,
                      'optimum':element1.optimum,

                      
                      'batch_highest_score':(parseFloat(element1.batch_highest_score)),
                      'highest_in_batch_score_label':element1.highest_in_batch_score_label,
                      'batch_highest_score_mm_ss_ms':element1.batch_highest_score_mm_ss_ms,

                      'batch_lowest_score':(parseFloat(element1.batch_lowest_score)),
                      'lowest_in_batch_score_label':element1.lowest_in_batch_score_label,
                      'batch_lowest_score_mm_ss_ms':element1.batch_lowest_score_mm_ss_ms,

                      'avg_score':(parseFloat(element1.avg_score)),
                      'avg_score_mm_ss_ms':element1.avg_score_mm_ss_ms,                    
                      'closer_to_average_in_batch_score_label':element1.closer_to_average_in_batch_score_label,
                      
                      'score_type_name':element1.score_type_name,
                      'performance_area_name':element.performance_area_name,
                      'batch_avg_score':element1.batch_avg_score,
                      'batch_avg_score_mm_ss_ms':element1.batch_avg_score_mm_ss_ms,
                      'is_display_parameter':element.is_display_parameter});                

                }
              });
              
              });
              
              } 

        
      
        
          
      }
      else {
        this.report_card_data = [];  
        this.no_report_card_data=true;
        this.msg="Report card details not found";      
        this.snackBarDisplay();   
      }
    },    
  );
 }
 v628Report(){
  this.specific_student_report_card_data=[]; 
  this.IR_services.getPlayerReport(this.organization_id,this.performance_set_criteria_setting_id,this.performance_set_assessment_id,
    this.player_id,this.academy_center_id).subscribe(
      response1 => {
   
    if (response1 != '')  
    {
      if (response1['data']['report_card']!=false){
        var response= response1['data']['report_card'];  
        this.org_logo=response['logo_path'];
        if(response['legend']!=undefined)
        {
          this.legends=response['legend'];             
        }            
        this.input_assessment_name=response['input_assessment_name'];
        this.academy_logo= response['logo_path'];  
        this.start_date=response['start_date'];
        this.end_date=response['end_date'];
        this.report_type=response['report_type'];  
        this.report_name=response['report_name'];    
        this.reportname=response['input_assessment_name'];   
        this.specific_student_report_card_data=response['player'];  
        this.ranking=this.specific_student_report_card_data[0]['ranking'];
        this.player_count=this.specific_student_report_card_data[0]['player_count'];
        this.percentile=this.specific_student_report_card_data[0]['percentile'];
        this.organization_name=response['organization_name'];
        this.preference_center_name=response['preference_center_name'];
        this.academy_center_name=this.specific_student_report_card_data[0]['academy_center_name'];   
        this.performance_set_details=this.specific_student_report_card_data[0]['performance_set_details'];

          this.default_report_score=[];            
          if(this.report_type=='default')
          {
            this.specific_student_report_card_data[0]['performance_parameters'].forEach((element,j) => {
              this.default_scores=[]; 
            element['scores'].forEach(element1 => 
            {
               if( element1.score_type_name=='Numeric')
            {
              if(element1.better_score!='-' && element1.score!='-' && element1.better_score!='' && element1.score!='')
              {
              this.default_scores.push({'better_score':(parseFloat(element1.better_score)),   
              'min_score':(parseFloat(element1.min)),
              'max_score':(parseFloat(element1.max)),        
              'performance_parameter_name':element.performance_parameter_name,
              'score':(parseFloat(element1.score)),            
              'desc':element.performance_parameter_description,
              'comments':element1.comments,
              'score_unit':element1.score_unit,
              'score_label':element1.score_label,
              'performance_set_id':element.performance_set_id,
              'score_type_name':element1.score_type_name,
              'performance_area_name':element.performance_area_name,
              'batch_avg_score':element1.batch_avg_score});
              }
            }
            if( element1.score_type_name=='Rating')
            {
              this.default_scores.push({'better_score':(parseFloat(element1.better_score)),           
              'performance_parameter_name':element.performance_parameter_name,
              'score':(parseFloat(element1.score)),            
              'desc':element.performance_parameter_description,
              'comments':element1.comments,
              'score_unit':element1.score_unit,
              'score_label':element1.score_label,
              'rating_details':element1.rating_details,
              'performance_set_id':element.performance_set_id,
              'score_type_name':element1.score_type_name,
              'performance_area_name':element.performance_area_name,
              'batch_avg_score':element1.batch_avg_score,
              'better_score_grade':element1.better_score_grade,
              'batch_avg_score_grade':element1.batch_avg_score_grade});
            } 
            
            if(element1.score_type_name=='Dropdown' || element1.score_type_name=='Text' || element1.score_type_name=='Document/Image')
            {
              this.default_scores.push({         
              'performance_parameter_name':element.performance_parameter_name,
              'score': this.videoURLConverter(element1),          
              'desc':element.performance_parameter_description,
              'comments':element1.comments,
              'score_unit':element1.score_unit,
              'score_label':element1.score_label,
              'performance_set_id':element.performance_set_id,
              'score_type_name':element1.score_type_name,
              'performance_area_name':element.performance_area_name,
            'file_type':element1.file_type});
            }  
            
            if(element1.score_type_name=='Stopwatch')
            {
              this.default_scores.push({         
              'performance_parameter_name':element.performance_parameter_name,
              'score':parseFloat(element1.score),            
              'desc':element.performance_parameter_description,
              'comments':element1.comments,
              'score_unit':element1.score_unit,
              'score_label':element1.score_label,
              'performance_set_id':element.performance_set_id,
              'score_type_name':element1.score_type_name,
              'performance_area_name':element.performance_area_name,
              'score_mm_ss_ms':element1.score_mm_ss_ms, 
              'better_score':parseFloat(element1.better_score),
              'better_score_mm_ss_ms':element1.better_score_mm_ss_ms,
              'batch_avg_score':element1.batch_avg_score,
              'batch_avg_score_mm_ss_ms':element1.batch_avg_score_mm_ss_ms});
            }
             });     
             this.default_report_score.push(this.default_scores);             
            });
            }
            if(this.report_type=='by_criteria')
            {
            this.specific_student_report_card_data[0]['performance_parameters'].forEach((element,j) => {            
              this.default_scores=[];
             element['scores'].forEach(element1 => {
               
             if(element1.score_type_name=='Numeric')
              {
                 this.default_scores.push({
                    
                    'performance_parameter_name':element.performance_parameter_name,                    
                    'parameter_score_compare_criteria_key':element1.parameter_score_compare_criteria_key,
                    'min_score':(parseFloat(element1.min)),
                    'max_score':(parseFloat(element1.max)),
                    'min_score1':(parseFloat(element1.min_score)),
                    'max_score1':(parseFloat(element1.max_score)),
                    'score':(parseFloat(element1.score)),
                    'batch_highest_score':(parseFloat(element1.batch_highest_score)),
                    'batch_lowest_score':(parseFloat(element1.batch_lowest_score)),
                    'avg_score':(parseFloat(element1.avg_score)),
                    'benchmark_score':(parseFloat(element1.benchmark_score)),
                    'less_than_min_score_color':element1.less_than_min_score_color,
                    'less_than_min_score_label':element1.less_than_min_score_label,
                    'in_between_min_and_max_score_color':element1.in_between_min_and_max_score_color,
                    'in_between_min_and_max_score_label':element1.in_between_min_and_max_score_label,
                    'greater_than_max_score_color':element1.greater_than_max_score_color,
                    'greater_than_max_score_label':element1.greater_than_max_score_label,
                    'color':element1.color,
                    'desc':element.performance_parameter_description,
                    'comments':element1.comments,
                    'is_allow_show_comment':element1.is_allow_show_comment,
                    'is_allow_show_desc':element1.is_allow_show_desc,
                    'score_unit':element1.score_unit,
                    'label':element1.label,
                    'lowest_in_batch_score_label':element1.lowest_in_batch_score_label,
                    'highest_in_batch_score_label':element1.highest_in_batch_score_label,
                    'closer_to_average_in_batch_score_label':element1.closer_to_average_in_batch_score_label,
                    'score_label':element1.score_label,
                    'score_type_name':element1.score_type_name,
                    'performance_area_name':element.performance_area_name,
                    'batch_avg_score':element1.batch_avg_score,
                    'is_display_parameter':element.is_display_parameter});

              } 
              if(element1.score_type_name=='Rating')
              {
                 this.default_scores.push({
                    
                    'performance_parameter_name':element.performance_parameter_name,                    
                    'parameter_score_compare_criteria_key':element1.parameter_score_compare_criteria_key,
                    'min_score':(parseFloat(element1.min)),
                    'max_score':(parseFloat(element1.max)),
                    'score':(parseFloat(element1.score)),
                    'benchmark_score':(parseFloat(element1.benchmark_score)),
                    'color':element1.color,
                    'desc':element.performance_parameter_description,
                    'comments':element1.comments,
                    'is_allow_show_comment':element1.is_allow_show_comment,
                    'is_allow_show_desc':element1.is_allow_show_desc,
                    'score_unit':element1.score_unit,
                    'score_label':element1.score_label,
                    'rating_details':element1.rating_details,
                    'score_type_name':element1.score_type_name,
                    'performance_area_name':element.performance_area_name,
                    'batch_avg_score':element1.batch_avg_score,
                    'better_score_grade':element1.better_score_grade,
                    'batch_avg_score_grade':element1.batch_avg_score_grade,
                    'is_display_parameter':element.is_display_parameter});
              }             
               if(element1.score_type_name=='Dropdown' || element1.score_type_name=='Text' || element1.score_type_name=='Document/Image')
            {
              this.default_scores.push({
                    
                    'performance_parameter_name':element.performance_parameter_name,                    
                    'parameter_score_compare_criteria_key':element1.parameter_score_compare_criteria_key,
                    'min_score':element1.min_score,
                    'max_score':element1.max_score,
                    'score': this.videoURLConverter(element1), 
                    'batch_highest_score':element1.batch_highest_score,
                    'batch_lowest_score':element1.batch_lowest_score,
                    'avg_score':element1.avg_score,
                    'benchmark_score':element1.benchmark_score,
                    'less_than_min_score_color':element1.less_than_min_score_color,
                    'less_than_min_score_label':element1.less_than_min_score_label,
                    'in_between_min_and_max_score_color':element1.in_between_min_and_max_score_color,
                    'in_between_min_and_max_score_label':element1.in_between_min_and_max_score_label,
                    'greater_than_max_score_color':element1.greater_than_max_score_color,
                    'greater_than_max_score_label':element1.greater_than_max_score_label,
                    'color':element1.color,
                    'desc':element.performance_parameter_description,
                    'comments':element1.comments,
                    'is_allow_show_comment':element1.is_allow_show_comment,
                    'is_allow_show_desc':element1.is_allow_show_desc,
                    'score_unit':element1.score_unit,
                    'score_label':element1.score_label,
                    'score_type_name':element1.score_type_name,
                    'performance_area_name':element.performance_area_name,
                    'file_type':element1.file_type,
                    'is_display_parameter':element.is_display_parameter});
              }
              if(element1.score_type_name=='Stopwatch')
              {
                  this.default_scores.push({                  
                   
                    'performance_parameter_name':element.performance_parameter_name,                    
                    'parameter_score_compare_criteria_key':element1.parameter_score_compare_criteria_key,
                    'score':(parseFloat(element1.score)),
                    'score_label':element1.score_label,
                    'score_unit':element1.score_unit,
                    'score_mm_ss_ms':element1.score_mm_ss_ms,
                    'desc':element.performance_parameter_description,
                    'comments':element1.comments,
                    'is_allow_show_comment':element1.is_allow_show_comment,
                    'is_allow_show_desc':element1.is_allow_show_desc,
                    'color':element1.color,
                    'label':element1.label,

                    'benchmark_score':(parseFloat(element1.benchmark_score)),
                    'benchmark_score_mm_ss_ms':element1.benchmark_score_mm_ss_ms,


                    'min_score':(parseFloat(element1.min)),
                    'max_score':(parseFloat(element1.max)),


                    'min_score1':(parseFloat(element1.min_score)),
                    'max_score1':(parseFloat(element1.max_score)),
                    'min_score_mm_ss_ms':element1.min_score_mm_ss_ms,
                    'max_score_mm_ss_ms':element1.max_score_mm_ss_ms,
                    'less_than_min_score_color':element1.less_than_min_score_color,
                    'less_than_min_score_label':element1.less_than_min_score_label,
                    'in_between_min_and_max_score_color':element1.in_between_min_and_max_score_color,
                    'in_between_min_and_max_score_label':element1.in_between_min_and_max_score_label,
                    'greater_than_max_score_color':element1.greater_than_max_score_color,
                    'greater_than_max_score_label':element1.greater_than_max_score_label,
                    'optimum':element1.optimum,

                    
                    'batch_highest_score':(parseFloat(element1.batch_highest_score)),
                    'highest_in_batch_score_label':element1.highest_in_batch_score_label,
                    'batch_highest_score_mm_ss_ms':element1.batch_highest_score_mm_ss_ms,

                    'batch_lowest_score':(parseFloat(element1.batch_lowest_score)),
                    'lowest_in_batch_score_label':element1.lowest_in_batch_score_label,
                    'batch_lowest_score_mm_ss_ms':element1.batch_lowest_score_mm_ss_ms,

                    'avg_score':(parseFloat(element1.avg_score)),
                    'avg_score_mm_ss_ms':element1.avg_score_mm_ss_ms,                    
                    'closer_to_average_in_batch_score_label':element1.closer_to_average_in_batch_score_label,
                    
                    'score_type_name':element1.score_type_name,
                    'performance_area_name':element.performance_area_name,
                    'batch_avg_score':element1.batch_avg_score,
                    'batch_avg_score_mm_ss_ms':element1.batch_avg_score_mm_ss_ms,
                    'is_display_parameter':element.is_display_parameter});                

              }
             });
             this.default_report_score.push(this.default_scores); 
            });
            // console.log(this.default_report_score)
            } 

      }else{
        this.report_card_data = [];  
        this.no_report_card_data=true;
        this.msg="Report card details not found";      
        this.snackBarDisplay();  
      }
     
       
        
    }
    else {
      this.report_card_data = [];  
      this.no_report_card_data=true;
      this.msg="Report card details not found";      
      this.snackBarDisplay();   
    }
  },    
);
 }
 showDescription(i)
 {
   $("#score_div"+i).toggle();
    $("#desc_div"+i).toggle();
    
 }
 openDoc(docSrc) {
   
    this.doc_src = docSrc;
    var last2 = this.doc_src.slice(-3);
    if (last2 == "pdf" || last2 == "doc") {
      this.pdf_doc_flag = true;
      this.image_doc_flag = false;
    }
    else {
      this.image_doc_flag = true;
      this.pdf_doc_flag = false; 
    }
    window.open(this.doc_src);
    // this.dialog.open(this.document, {
    //   width: '420px',

    // });
  }

  snackBarDisplay()
  {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    this.snackBar.open(this.msg,this.action ? this.actionButtonLabel : undefined,config);
  } 

    @ViewChild('document') document: TemplateRef<any>;

    videoURLConverter(score){
  
      if(score.file_type=='VIDEO'){
        let pattern = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        var match =score.score.match(pattern); 
        if(match!=null)
        {              
        
            return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'+match[7]);
          
        }
        else{
     
            return this._sanitizer.bypassSecurityTrustResourceUrl( this.parseVimeo(score.score));       
         
        }
      }
      else{
        return score.score;
      } 
        
      }
      parseVimeo(url) {
        
        const re = /\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i;
        const matches = re.exec(url);
        return matches && "https://player.vimeo.com/video/"+matches[1];
      }
    

}