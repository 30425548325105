import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItems } from './menu-items/menu-items';
import { PaginationModule } from 'ngx-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//import {AgmCoreModule} from '@agm/core';
import { LogoutComponent } from '../authentication/logout/logout.component';
import { ImageCropperModule } from 'ng2-img-cropper';
import { ScrollbarModule } from 'ngx-scrollbar';
import { ColorPickerModule } from 'ngx-color-picker';

import { CdkTableModule } from '@angular/cdk/table';
import {
  MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatFormFieldModule, MatSliderModule, MatCardModule, MatDividerModule, MatExpansionModule, MatGridListModule, MatChipsModule, MatProgressBarModule, MatDialogModule, MatSnackBarModule, MatTooltipModule, MatSortModule, MatTableModule,
  MatMenuModule, MatSidenavModule, MatToolbarModule, MatNativeDateModule, MatInputModule, MatRadioModule, MatListModule, MatStepperModule, MatButtonToggleModule, MatTabsModule, MatSelectModule, MatIconModule, MatProgressSpinnerModule, MatPaginatorModule, MatAutocompleteModule, MatSlideToggleModule
} from '@angular/material';


import { MatTreeModule } from '@angular/material/tree';
import { AddCnterFeePlanComponent } from '../add-cnter-fee-plan/add-cnter-fee-plan.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PaginationModule.forRoot(),

    //   AgmCoreModule.forRoot({apiKey: 'AIzaSyCE0nvTeHBsiQIrbpMVTe489_O5mwyqofk'}),
    //ClickOutsideModule,
    ScrollbarModule,
    MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatFormFieldModule, MatSliderModule, MatCardModule, MatDividerModule, MatExpansionModule, MatGridListModule, MatChipsModule, MatProgressBarModule, MatDialogModule, MatSnackBarModule, MatTooltipModule, MatSortModule, MatTableModule,
    MatMenuModule, MatSidenavModule, MatToolbarModule, MatNativeDateModule, MatInputModule, MatRadioModule, MatListModule, MatStepperModule, MatButtonToggleModule, MatTabsModule, MatSelectModule, MatIconModule, MatProgressSpinnerModule, MatPaginatorModule, MatAutocompleteModule, MatSlideToggleModule,
    ImageCropperModule,
    ColorPickerModule,
    CdkTableModule,
    MatTreeModule




  ],
  declarations: [
    LogoutComponent,
    AddCnterFeePlanComponent
  ],
  exports: [
    PaginationModule,
    FormsModule,
    ReactiveFormsModule,

    // AgmCoreModule,
    // ClickOutsideModule,
    ImageCropperModule,
    ScrollbarModule,
    MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatFormFieldModule, MatSliderModule, MatCardModule, MatDividerModule, MatExpansionModule, MatGridListModule, MatChipsModule, MatProgressBarModule, MatDialogModule, MatSnackBarModule, MatTooltipModule, MatSortModule, MatTableModule,
    MatMenuModule, MatSidenavModule, MatToolbarModule, MatNativeDateModule, MatInputModule, MatRadioModule, MatListModule, MatStepperModule, MatButtonToggleModule, MatTabsModule, MatSelectModule, MatIconModule, MatProgressSpinnerModule, MatPaginatorModule, MatAutocompleteModule, MatSlideToggleModule,
    ColorPickerModule,
    AddCnterFeePlanComponent

  ],
  providers: [
    MenuItems
  ]
})
export class SharedModule { }
