<!-- main header -->
<header id="header_main" [style.background-color]="header_background" [style.color]="header_text_color">
    <div class="header_main_content">
        <nav class="uk-navbar" style="margin-top: 15px;">


            <div [routerLink]="['/academyDetails']" class="hideTitle">
                <p style="float:left;text-align:center;" *ngIf="organization_details"
                    [routerLink]="['/academyDetails']">
                    <span
                        style="float:left; width:100%;cursor: pointer;white-space: nowrap;font-size: 30px;margin-top: 5px;"
                        [style.color]="header_text_color">
                        {{organization_details.organization_name}}
                    </span>
                </p>
            </div>
            <ul class="uk-navbar-nav user_actions" *ngIf="windowWidth < 600 && !menu_icon_flag">

                <li style="line-height: 51px;">
                    <mat-icon (click)="leftMenuToggle()" [style.color]="header_text_color"
                        style="font-weight: 600;">menu</mat-icon>
                </li>
            </ul>

            <div class="uk-navbar-flip">



                <ul class="uk-navbar-nav user_actions">

                    <li style="line-height: 51px;" *ngIf="windowWidth < 600 && menu_icon_flag">
                        <mat-icon (click)="leftMenuToggle()" [style.color]="header_text_color"
                            style="font-weight: 600;">keyboard_backspace </mat-icon>
                    </li>
                    <li data-uk-dropdown="{mode:'click',pos:'bottom-right'}" *ngIf="organization_list.length>1">
                        <a href="#" class="user_action_icon"><i class="material-icons md-24"
                                [style.color]="header_text_color">list</i>
                            <span class="uk-badge"
                                *ngIf="organization_list.length>1">{{organization_list.length}}</span></a>
                        <div class="uk-dropdown uk-dropdown-xlarge">
                            <div class="md-card-content">
                                <ul class="uk-tab uk-tab-grid"
                                    data-uk-tab="{connect:'#header_alerts',animation:'slide-horizontal'}">
                                </ul>
                                <ul id="header_alerts" class="uk-switcher uk-margin" *ngIf="organization_list!=''">
                                    <li>
                                        <ul class="md-list md-list-addon">
                                            <li style="margin-left: 15px !important ;">
                                                <div class="example-header_a search-field">
                                                    <mat-form-field>
                                                        <mat-icon matPreffix style="float: left; font-size:23px;">search
                                                        </mat-icon>
                                                        <input matInput (keyup)="applyFilter($event.target.value)"
                                                            placeholder="Search an Academy Name"
                                                            style="float:left; width:auto; padding:0px 10px;"
                                                            [(ngModel)]="search">
                                                        <button mat-button *ngIf="search" matSuffix mat-icon-button
                                                            style="float: left; font-size:23px;margin-bottom: 8px;"
                                                            (click)="search='';applyFilter('')"
                                                            onclick="event.stopPropagation()">
                                                            <mat-icon>close</mat-icon>
                                                        </button>
                                                    </mat-form-field>
                                                </div>
                                            </li>
                                            <ng-container *ngIf="search==''">
                                                <li *ngFor="let list of organization_list">
                                                    <div class="md-list-addon-element">
                                                        <img class="img-circle" src="{{list.logo_path}}"
                                                            alt="Organization Logo"
                                                            style="width:20px; float:left; margin:9px; margin-left: 15px;"
                                                            onerror="this.src='assets/images/acadware_logo_vme.png'">
                                                    </div>
                                                    <div class="md-list-content">
                                                        <span class="md-list-heading uk-dropdown-close"
                                                            (click)="selectorganization(list.organization_id)">{{list.organization_name}}</span>
                                                    </div>
                                                </li>
                                            </ng-container>
                                            <ng-container *ngIf="search!=''">
                                                <li *ngFor="let list of organization_search_list">
                                                    <div class="md-list-addon-element">
                                                        <img class="img-circle" src="{{list.logo_path}}"
                                                            alt="Organization Logo"
                                                            style="width:20px; float:left; margin:9px; margin-left: 15px;"
                                                            onerror="this.src='assets/images/acadware_logo_vme.png'">
                                                    </div>
                                                    <div class="md-list-content">
                                                        <span class="md-list-heading uk-dropdown-close"
                                                            (click)="selectorganization(list.organization_id)">{{list.organization_name}}</span>
                                                    </div>
                                                </li>
                                            </ng-container>

                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li style="line-height:46px;">
                        <span *ngIf="organization_details" style="padding-right:0px;"
                            [style.color]="header_text_color"><b>{{organization_details.organization_name}}
                                &nbsp;</b></span>
                    </li>

                    <li style="line-height: 48px;" *ngIf="!Content_creator_flag">
                        <button mat-icon-button [style.background-color]="header_text_color" style="width: 35px !important;
                            height: 35px !important;" *ngIf="organization_details" [routerLink]="['/academySettings']">
                            <mat-icon [style.color]="header_background" placeholder="Academy Settings"
                                style="font-size: 23px;">settings</mat-icon>
                        </button>
                    </li>
                    <!--notification here-->
                    <li data-uk-dropdown="{mode:'click',pos:'bottom-right'}"
                        style="line-height: 48px;    margin-left: 8px;">

                        <a href="#" class="user_action_icon">
                            <i class="material-icons md-24" [style.color]="header_text_color">notifications</i>
                            <!-- <i class="material-icons md-24" style="color:#333;">notifications</i> -->
                            <ng-container *ngIf="list_of_notification">
                                <span class="uk-badge"
                                    *ngIf="list_of_notification.length>=1">{{unread_msg_count}}</span>
                            </ng-container>
                        </a>
                        <div class="uk-dropdown uk-dropdown-xlarge">
                            <div class="md-card-content">
                                <ul class="uk-tab uk-tab-grid"
                                    data-uk-tab="{connect:'#notification_alerts',animation:'slide-horizontal'}">
                                </ul>
                                <ul id="notification_alerts" class="uk-switcher uk-margin">
                                    <li>
                                        <ul class="md-list md-list-addon" *ngIf="list_of_notification_flag">
                                            <ng-container *ngFor="let data of list_of_notification;let i=index">
                                                <li *ngIf="i<4">
                                                    <div class="md-list-addon-element">
                                                        <span class="md-user-letters md-bg-cyan"
                                                            *ngIf="data.notification_subject!=null">{{data.notification_subject[0]}}
                                                        </span>
                                                    </div>
                                                    <div class="md-list-content">
                                                        <span
                                                            class="md-list-heading"><a>{{data.notification_subject}}</a></span>
                                                        <span class="uk-text-small uk-text-muted"
                                                            [innerHTML]="data.notification_body">{{data.notification_body}}</span>
                                                    </div>
                                                </li>
                                            </ng-container>
                                            <div class="uk-text-center uk-margin-top uk-margin-small-bottom">
                                                <a class="md-btn md-btn-flat md-btn-flat-primary js-uk-prevent" 
                                                    [routerLink]="['/messaging']">Show All</a>
                                            </div>
                                        </ul>

                                        <ul *ngIf="!list_of_notification_flag">
                                            <li>
                                                <ul style="padding-left: 75px"><b>Notification not available</b></ul>
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        <ul class="md-list md-list-addon">
                                            <li>
                                                <div class="md-list-addon-element">
                                                    <i class="material-icons" style="color:dodgerblue">cake </i>
                                                    <!-- <i class="md-list-addon-icon material-icons uk-text-warning">&#xE8B2;</i> -->
                                                </div>
                                                <div class="md-list-content">
                                                    <span class="md-list-heading">Birthday notification
                                                        <mat-slide-toggle style="float:right;" color="primary"
                                                            (change)="setNotificationAccessCntrl('birthday_notification')"
                                                            [(ngModel)]="birthday_notification"></mat-slide-toggle>
                                                    </span>
                                                    <span class="uk-text-small uk-text-muted uk-text-truncate">Birthday
                                                        wishes message</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="md-list-addon-element">
                                                    <i class="material-icons" style="color:firebrick">payment</i>
                                                    <!-- <i class="md-list-addon-icon material-icons uk-text-warning">&#xE8B2;</i> -->
                                                </div>
                                                <div class="md-list-content">
                                                    <span class="md-list-heading">Payment notification <mat-slide-toggle
                                                            style="float:right;" color="primary"
                                                            (change)="setNotificationAccessCntrl('outstanding_payments_notification')"
                                                            [(ngModel)]="outstanding_payments_notification">
                                                        </mat-slide-toggle></span>
                                                    <span
                                                        class="uk-text-small uk-text-muted uk-text-truncate">Outstanding
                                                        payments 5 days after due date</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="md-list-addon-element">
                                                    <i class="material-icons" style="color:green">thumb_up_alt </i>
                                                    <!-- <i class="md-list-addon-icon material-icons uk-text-warning">&#xE8B2;</i> -->
                                                </div>
                                                <div class="md-list-content">
                                                    <span class="md-list-heading">Thank You Note <mat-slide-toggle
                                                            style="float:right;" color="primary"
                                                            (change)="setNotificationAccessCntrl('payment_received_notification')"
                                                            [(ngModel)]="payment_received_notification">
                                                        </mat-slide-toggle></span>
                                                    <span class="uk-text-small uk-text-muted uk-text-truncate">Payment
                                                        received thank you note</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="md-list-addon-element">
                                                    <i class="material-icons" style="color:gray">system_update </i>
                                                    <!-- <i class="md-list-addon-icon material-icons uk-text-warning">&#xE8B2;</i> -->
                                                </div>
                                                <div class="md-list-content">
                                                    <span class="md-list-heading">Download Invites <mat-slide-toggle
                                                            style="float:right;" color="primary"
                                                            (change)="setNotificationAccessCntrl('invite_to_download_app_notification')"
                                                            [(ngModel)]="invite_to_download_app_notification">
                                                        </mat-slide-toggle></span>
                                                    <span class="uk-text-small uk-text-muted uk-text-truncate">Invite to
                                                        download to registered email on new registrations</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li data-uk-dropdown="{mode:'click',pos:'bottom-right'}">
                        <a href="#" class="user_action_image"><img class="md-user-image" src="{{Profile_img}}"
                                title="{{username}}" alt="" onerror="this.src='assets/images/pi.png'" /></a>
                        <div class="uk-dropdown uk-dropdown-width-2 profile-dropdown">

                            <div class="uk-grid uk-dropdown-grid">
                                <div class="uk-width-1-3">
                                    <img class="" src="{{Profile_img}}" title="{{username}}" alt=""
                                        onerror="this.src='assets/images/pi.png'"
                                        style="width:85px; height:85px; display:block; margin:auto;" />
                                </div>
                                <div class="uk-width-2-3">
                                    <ul class="uk-nav uk-nav-dropdown js-uk-prevent">
                                        <li>
                                            <a
                                                style="color:#333; font-size:17px;font-weight:bold; padding-bottom:1px;">{{username}}</a>
                                            <a
                                                style="color:#888; font-size:13px;font-weight:normal;font-style: italic;word-break: break-all;">{{userMail}}</a>
                                        </li>
                                        <li>&nbsp;
                                            <!--<a style="font-size:15px;">My Profile</a>-->
                                        </li>
                                    </ul>
                                </div>
                                <div class="uk-width-3-3 dropdown-footer">
                                    <!-- <p class="text-right" style="text-align:left;">{{'LanguageOffered' | translate}}:
                                        <a (click)="changeLocale('en')" [style.color]="header_background">English </a>|
                                        <a (click)="changeLocale('hi')" [style.color]="header_background">हिन्दी</a>
                                    </p>  -->
                                    <a class="md-btn md-btn-primary md-btn-mini md-btn-wave-light waves-effect waves-button waves-light"
                                        [style.background-color]="header_background" [style.color]="header_text_color"
                                        (click)="openTermsCondition()" style="margin: 4px;">{{'termOfUse' |
                                        translate}}</a>

                                    <a class="md-btn md-btn-wave waves-effect waves-button"
                                        [style.background-color]="header_background" [style.color]="header_text_color"
                                        style="float:right; margin: 0px 4px;" [routerLink]="['/logout']">{{'Logout' |
                                        translate}}</a>
                                </div>

                            </div>


                        </div>
                    </li>

                </ul>
            </div>
            <!--right side header ends-->

        </nav>
    </div>

</header>
<!-- main header end -->

<ng-container *ngIf="main_menu">
    <!-- main sidebar -->
    <aside id="sidebar_main" [style.background-color]="header_background" [style.color]="header_text_color"
        style="box-shadow: 3px 52px 8px #888;">



        <!-- <div class="sidebar_main_header">
        <div class="sidebar_logo">
            <div class="sSidebar_hide sidebar_logo_large" style="text-align:center;">
                <p class="acad-logo">
                    
                    <a [routerLink]="['/dashboard']">
                        <span class="acad-img">
                            <img class="img-fluid" src="assets/images/acadware_logo_vme.png" alt="Acadware" style="width:133px;" />
                        </span>
                    </a>
                
                </p>
            </div>
        </div>

    </div> -->
        <ng-scrollbar style="height:100%;" autoHide="true">

            <div class="menu_section">
                <ul>
                    <li class="current_section">
                        <!-- <div class="setting-icon" *ngIf="organization_details" [routerLink]="['/academySettings']">
                        <button mat-icon-button [style.background-color]="header_background"><i class="material-icons" [style.color]="header_text_color">settings</i></button>
                    </div> -->
                        <div [routerLink]="['/academyDetails']">
                            <p style="float:left; width:100%; text-align:center; margin-top:12px;font-size: 14px;"
                                (click)="highlightmenu()">
                                <span style="float:left; width:100%">
                                    <img class="img-40 User-Profile-Image"
                                        style="cursor: pointer; margin:0px 0px 5px 0px;padding: 5px;"
                                        *ngIf="organization_preference_details"
                                        src="{{organization_preference_details[0].logo_path}}"
                                        alt="Organization Thumbnail"></span>
                                <span class="org-name-text" *ngIf="organization_details"
                                    style="cursor: pointer;">{{organization_details.organization_name}}</span>
                            </p>
                        </div>
                    </li>
                </ul>
                <ng-container *ngIf="!Content_creator_flag">
                    <ul *ngIf="organization_details" style="clear:both;">

                        <li class="current_section active" [routerLink]="['/dashboard']"
                            [style.color]="header_text_color">
                            <p (click)="highlightmenu()">
                                <span class="menu_icon"><img src="assets/images/dashboard.png"
                                        style="width: 25px;opacity: 0.5;" /></span>
                                <span class="menu_title" mat-line>{{'Dashboard' | translate}}</span>
                            </p>

                        </li>

                        <li class="current_section" [routerLink]="['/student']" [style.color]="header_text_color"
                            (click)="selectedMenu()">
                            <p (click)="highlightmenu()">
                                <span class="menu_icon"><img src="assets/images/Player-Shirt-Filled-100.png"
                                        style="width: 25px;opacity: 0.5;" /></span>
                                <span class="menu_title" mat-line>{{role_name}}</span>
                                <span style="float:right;">
                                    <mat-chip-list>
                                        <mat-chip [style.background-color]="button_background_color"
                                            [style.color]="button_text_color" selected="true">
                                            {{organization_details.players_count}}</mat-chip>
                                    </mat-chip-list>
                                </span>
                            </p>

                        </li>
                        <li class="current_section" [routerLink]="['/center']" *ngIf="parent_academy_flag=='N'">
                            <p (click)="highlightmenu()">
                                <span class="menu_icon"><img src="assets/images/center96.png"
                                        style="width: 25px;opacity: 0.5;" /></span>
                                <span class="menu_title" mat-line>{{center_name|uppercase}}</span>
                                <span style="float:right;">
                                    <mat-chip-list>
                                        <mat-chip color="primary" selected="true">{{organization_details.centers_count}}
                                        </mat-chip>
                                    </mat-chip-list>
                                </span>
                            </p>


                        </li>
                        <li class="current_section" [routerLink]="['/role']" *ngIf="parent_academy_flag=='N'">
                            <p (click)="highlightmenu()">
                                <span class="menu_icon"><img src="assets/images/roles96.png"
                                        style="width: 25px;opacity: 0.5;" /></span>
                                <span class="menu_title" mat-line>Roles</span>
                                <span style="float:right;">
                                    <mat-chip-list>
                                        <mat-chip color="primary" selected="true">{{organization_details.roles_count}}
                                        </mat-chip>
                                    </mat-chip-list>
                                </span>
                            </p>

                        </li>
                        <li class="current_section" [routerLink]="['/staff']">
                            <p (click)="highlightmenu()">
                                <span class="menu_icon"><img src="assets/images/staff.png"
                                        style="width: 25px;opacity: 0.5;" /></span>
                                <span class="menu_title" mat-line>Staff</span>
                                <span style="float:right;">
                                    <mat-chip-list>
                                        <mat-chip color="primary" selected="true">{{organization_details.staff_count}}
                                        </mat-chip>
                                    </mat-chip-list>
                                </span>
                            </p>

                        </li>
                        <li class="current_section" [routerLink]="['/FeePlans']" *ngIf="parent_academy_flag=='N'">
                            <p (click)="highlightmenu()">
                                <span class="menu_icon"><img src="assets/images/rupee.png"
                                        style="width: 25px;opacity: 0.5;" /></span>
                                <span class="menu_title" mat-line>Fee Plans</span>
                                <span style="float:right;">
                                    <mat-chip-list>
                                        <mat-chip color="primary" selected="true">
                                            {{organization_details.fee_plan_count}}
                                        </mat-chip>
                                    </mat-chip-list>
                                </span>
                            </p>
                        </li>
                        <li class="current_section" [routerLink]="['/lesson-plans']" *ngIf="lession_plan_flag">
                            <p (click)="highlightmenu()">
                                <span class="menu_icon"><img src="assets/images/ic_assesment_x96.png"
                                        style="width: 25px;opacity: 0.5;" /></span>
                                <span class="menu_title" mat-line>Lesson Plans</span>
                                <span style="float:right;">
                                    <mat-chip-list>
                                        <!-- <mat-chip color="primary" selected="true">{{organization_details.fee_plan_count}}
                                    </mat-chip> -->
                                    </mat-chip-list>
                                </span>
                            </p>
                        </li>
                        <!-- <li class="current_section" [routerLink]="['/Test_Bank']">
                            <p (click)="highlightmenu()">
                                <span class="menu_icon"><i class="material-icons">trending_up</i></span>
                                <span class="menu_title" mat-line >Test Banks </span>
                                <span style="float:right;">
                                        <mat-chip-list>
                                                <mat-chip color="primary" selected="true">{{organization_details.performance_set_count}}</mat-chip>
                                        </mat-chip-list>
                                </span>        
                            </p>
                    </li> -->
                        <li class="current_section" [routerLink]="['/payment-tracker']"
                            *ngIf="parent_academy_flag=='N' && payments_flag">
                            <p (click)="highlightmenu()">
                                <span class="menu_icon"><img src="assets/images/ic_cheque.png"
                                        style="width: 25px;opacity: 0.5;" /></span>
                                <span class="menu_title" mat-line>Payment Tracker</span>
                                <!-- <span style="float:right;">
                                <mat-chip-list>
                                    <mat-chip selected="true">{{organization_details.performance_assessment_count}}</mat-chip>
                                </mat-chip-list>
                            </span> -->
                            </p>
                        </li>

                        <li class="current_section" [routerLink]="['/attendance-tracker']"
                            *ngIf="parent_academy_flag=='N' && attendance_flag">
                            <p (click)="highlightmenu()">
                                <span class="menu_icon"><img src="assets/images/attandance-report.png"
                                        style="width: 25px;opacity: 0.5;" /></span>
                                <span class="menu_title" mat-line>Attendance Tracker</span>
                                <!-- <span style="float:right;">
                                <mat-chip-list>
                                    <mat-chip selected="true">{{organization_details.performance_assessment_count}}</mat-chip>
                                </mat-chip-list>
                            </span> -->
                            </p>
                        </li>
                        <li class="current_section" [routerLink]="['/Assessment']" (click)="selectedMenu()"
                            *ngIf="assessment_flag">
                            <p (click)="highlightmenu()">
                                <span class="menu_icon"><img src="assets/images/ic_assesment_x96.png"
                                        style="width: 25px;opacity: 0.5;" /></span>
                                <span class="menu_title" mat-line>Assessment</span>
                                <span style="float:right;">
                                    <mat-chip-list>
                                        <mat-chip selected="true">{{organization_details.performance_assessment_count}}
                                        </mat-chip>
                                    </mat-chip-list>
                                </span>
                            </p>

                        </li>
                        <li class="current_section" [routerLink]="['/posts']">
                            <p (click)="highlightmenu()" *ngIf="post_flag">
                                <span class="menu_icon"><img src="assets/images/Post.png"
                                        style="width: 25px;opacity: 0.5;" /></span>
                                <span class="menu_title" mat-line>Posts</span>
                                <span style="float:right;">
                                    <mat-chip-list>
                                        <!-- <mat-chip color="primary" selected="true">{{organization_details.performance_assessment_count}}</mat-chip> -->
                                    </mat-chip-list>
                                </span>
                            </p>

                        </li>
                        <li class="current_section" [routerLink]="['/report']">
                            <p (click)="highlightmenu()">
                                <span class="menu_icon"><img src="assets/images/reports.png"
                                        style="width: 25px;opacity: 0.5;" /></span>
                                <span class="menu_title" mat-line>Reports</span>
                                <span style="float:right;">
                                    <mat-chip-list>
                                        <!-- <mat-chip color="primary" selected="true">{{organization_details.performance_assessment_count}}</mat-chip> -->
                                    </mat-chip-list>
                                </span>
                            </p>

                        </li>

                        <li class="current_section" [routerLink]="['/coaching-drill']">
                            <p (click)="highlightmenu()">
                                <span class="menu_icon"><img src="assets/images/reports.png"
                                        style="width: 25px;opacity: 0.5;" /></span>
                                <span class="menu_title" mat-line>Coaching Drills</span>
                                <span style="float:right;">
                                    <mat-chip-list>
                                        <!-- <mat-chip color="primary" selected="true">{{organization_details.performance_assessment_count}}</mat-chip> -->
                                    </mat-chip-list>
                                </span>
                            </p>

                        </li>

                        <!-- <li class="current_section">
                        <p (click)="subMenu();highlightmenu()">
                            <span class="menu_icon"><img src="assets/images/reports.png" style="width: 25px;opacity: 0.5;"/></span>
                            <span class="menu_title" mat-line>Reports</span>
                            <span style="float:right;">
                            <i class="material-icons right" *ngIf="right_arrow">keyboard_arrow_right</i>
                                <i class="material-icons down"  *ngIf="down_arrow">keyboard_arrow_down</i>
                            </span>
                        </p>
                        <ul class="sub-menu-cls">
                            <li [routerLink]="['/Reports']" [queryParams]="{ report_type: 'Progress Report' }" (click)="highlightmenu();" class="current_section"><a [style.color]="header_text_color"><i class="fa fa-line-chart"></i> Progress Report</a></li> 
                            <li [routerLink]="['/Reports']" [queryParams]="{ report_type: 'Attendance Report' }" (click)="highlightmenu();"
                                class="submenu"><a [style.color]="header_text_color"><img src="assets/images/attandance-report.png" style="width: 25px;opacity: 0.5;" />
                                    Attendance Report</a></li>
                            
                            <li [routerLink]="['/Reports']" [queryParams]="{ report_type: 'Session Report' }" (click)="highlightmenu();"
                                class="submenu"><a [style.color]="header_text_color"><img src="assets/images/session-report.png" style="width: 25px;opacity: 0.5;" />
                                    Session Attended Report</a></li>

                            <li routerLink="/Assessment/reports" (click)="highlightmenu();" [queryParams]="{ type: '1' }" 
                            class="submenu"><a [style.color]="header_text_color"><img src="assets/images/assessmentReport.png" style="width: 25px;opacity: 0.5;" />
                                Assessment Report</a></li>        
                            <li [routerLink]="['/DeregisterList']"  (click)="highlightmenu();"
                                class="submenu"><a [style.color]="header_text_color"><img src="assets/images/deregister.png" style="width: 25px;opacity: 0.5;" />
                                    Deregistered {{role_name}}</a></li>
                            <li [routerLink]="['/Reports']" [queryParams]="{ report_type: 'Report Card' }" (click)="highlightmenu();" class="current_section" *ngIf="accessControleOfReport=='1'"><a [style.color]="header_text_color"><i class="fa fa-address-book-o"></i> Report Card</a></li>
                            <li [routerLink]="['/FeesReports']"  (click)="highlightmenu();"
                            class="submenu"><a [style.color]="header_text_color"><img src="assets/images/fee-report.png" style="width: 25px;opacity: 0.5;" />
                                Fees Reports</a></li>

                            <li [routerLink]="['/LTADReports']"  (click)="highlightmenu();"
                            class="submenu"><a [style.color]="header_text_color"><img src="assets/images/news.png" style="width: 25px;opacity: 0.5;" />
                                LTAD Reports</a></li>
                        </ul>
                    </li> -->


                        <!-- <li class="current_section" [routerLink]="['/StudentRanking']" > 
                        <p (click)="highlightmenu();">
                            <span class="menu_icon"><i class="material-icons">star</i></span>
                            <span class="menu_title" mat-line >Ranking</span>
                            <span style="float:right;">                                
                            </span>        
                        </p>                
                </li> -->
                        <li class="current_section" [routerLink]="['/messaging']"
                            *ngIf="parent_academy_flag=='N' && messaging_flag">
                            <p (click)="highlightmenu();">
                                <span class="menu_icon"><img src="assets/images/ic_action_message.png"
                                        style="width: 25px;opacity: 0.5;" /></span>
                                <span class="menu_title" mat-line>Messaging</span>
                                <span style="float:right;">
                                </span>
                            </p>
                        </li>

                        <li class="current_section" [routerLink]="['/cheque-tracker']"
                            *ngIf="organization_details.cheque_tracking_flag=='Y' && parent_academy_flag=='N' && payments_flag">
                            <p (click)="highlightmenu();">
                                <span class="menu_icon"><img src="assets/images/ic_cheque.png"
                                        style="width: 25px;opacity: 0.5;" /></span>
                                <span class="menu_title" mat-line>Cheque Tracking</span>
                                <span style="float:right;">
                                </span>
                            </p>
                        </li>

                        <li class="current_section" [routerLink]="['/request']" *ngIf="parent_academy_flag=='N'">
                            <p (click)="highlightmenu();">
                                <span class="menu_icon"><img src="assets/images/request.png"
                                        style="width: 25px;opacity: 0.5;" /></span>
                                <span class="menu_title" mat-line>Request</span>
                                <span style="float:right;">
                                </span>
                            </p>
                        </li>
                        <li class="current_section" [routerLink]="['/expense']" *ngIf="parent_academy_flag=='N'">
                            <p (click)="highlightmenu();">
                                <span class="menu_icon"><img src="assets/images/request.png"
                                        style="width: 25px;opacity: 0.5;" /></span>
                                <span class="menu_title" mat-line>Expense</span>
                                <span style="float:right;">
                                </span>
                            </p>
                        </li>

                        <!-- 
                    <li class="current_section" [routerLink]="['/tournaments']" >
                        <p (click)="highlightmenu();">
                            <span class="menu_icon"><i class="material-icons">sync</i></span>
                            <span class="menu_title" mat-line>Tournaments</span>
                            <span style="float:right;">
                            </span>
                        </p>
                    </li> -->
                    </ul>
                </ng-container>
                <ng-container *ngIf="Content_creator_flag">
                    <ul *ngIf="organization_details" style="clear:both;">
                        <li class="current_section" [routerLink]="['/Assessment']" (click)="selectedMenu()"
                            *ngIf="assessment_flag">
                            <p (click)="highlightmenu()">
                                <span class="menu_icon"><img src="assets/images/ic_assesment_x96.png"
                                        style="width: 25px;opacity: 0.5;" /></span>
                                <span class="menu_title" mat-line>Assessment</span>
                                <span style="float:right;">
                                    <mat-chip-list>
                                        <mat-chip selected="true">{{organization_details.performance_assessment_count}}
                                        </mat-chip>
                                    </mat-chip-list>
                                </span>
                            </p>

                        </li>
                        <li class="current_section active" [routerLink]="['/coaching-drill']">
                            <p (click)="highlightmenu()">
                                <span class="menu_icon"><img src="assets/images/reports.png"
                                        style="width: 25px;opacity: 0.5;" /></span>
                                <span class="menu_title" mat-line>Coaching Drills</span>
                                <span style="float:right;">
                                    <mat-chip-list>
                                        <!-- <mat-chip color="primary" selected="true">{{organization_details.performance_assessment_count}}</mat-chip> -->
                                    </mat-chip-list>
                                </span>
                            </p>

                        </li>

                        <li class="current_section" [routerLink]="['/lesson-plans']">
                            <p (click)="highlightmenu()">
                                <span class="menu_icon"><img src="assets/images/ic_assesment_x96.png"
                                        style="width: 25px;opacity: 0.5;" /></span>
                                <span class="menu_title" mat-line>Lesson Plans</span>
                                <span style="float:right;">
                                    <mat-chip-list>
                                        <!-- <mat-chip color="primary" selected="true">{{organization_details.fee_plan_count}}
                                    </mat-chip> -->
                                    </mat-chip-list>
                                </span>
                            </p>
                        </li>
                    </ul>
                </ng-container>

            </div>
        </ng-scrollbar>

    </aside>
    <!-- main sidebar end -->
</ng-container>

<!-- main sidebar -->
<aside id="sidebar_main" [style.background-color]="side_panel_background" [style.color]="side_nav_menu_color"
    style="width: 95px;" *ngIf="!main_menu">
    <!--box-shadow: 3px 52px 8px #888;-->

    <div [routerLink]="['/academyDetails']">

        <!-- <span class="org-name-text" *ngIf="organization_details"
                    style="cursor: pointer;white-space: nowrap;width: auto;font-size: 25px;" [style.color]="header_text_color">{{organization_details.organization_name}}</span> -->
        <p style="float:left; width:100%; text-align:center;font-size: 14px;" *ngIf="organization_details"
            [routerLink]="['/academyDetails']">
            <span style="float:left; width:100%;cursor: pointer;white-space: nowrap;font-size: 25px;margin-top: 5px;">
                <img class="" style="cursor: pointer; margin:-5px 0px 0px 0px;vertical-align: top;height: 80px;padding: 5px;
                    width: 95px;" *ngIf="organization_preference_details"
                    src="{{organization_preference_details[0].logo_path}}" alt="Organization Thumbnail">
                <!-- {{organization_details.organization_name}} -->
            </span>
            <!-- <span class="org-name-text" *ngIf="organization_details"
                    style="cursor: pointer;">{{organization_details.organization_name}}</span> -->
        </p>
    </div>

    <div class="menu_section" style="width: 95px;overflow: auto;" [style.height]="innerHeight"
        (window:resize)="leftMenuResize($event)">
        <ng-container *ngIf="!Content_creator_flag">
            <ul *ngIf="organization_details">

                <li class="current_section active" [routerLink]="['/dashboard']"
                    [style.color]="menuName=='dashboard'?side_nav_menu_color:side_nav_header_font_color">
                    <p (click)="highlightmenu('dashboard')">
                        <span class="menu_icon1"><img src="assets/images/sos/Dashboard.svg"
                                style="width: 50px;" />{{'Dashboard' | translate |uppercase}}</span>
                    </p>
                </li>

                <li class="current_section" [routerLink]="['/student']"
                    [style.color]="menuName=='player'?side_nav_menu_color:side_nav_header_font_color"
                    (click)="selectedMenu()">
                    <p (click)="highlightmenu('player')">
                        <span class="menu_icon1"><img src="assets/images/sos/Players.svg"
                                style="width: 50px;" />{{role_name|uppercase}}</span>
                    </p>

                </li>
                <li class="current_section" [routerLink]="['/center']" *ngIf="parent_academy_flag=='N'"
                    [style.color]="menuName=='centers'?side_nav_menu_color:side_nav_header_font_color">
                    <p (click)="highlightmenu('centers')">
                        <span class="menu_icon1"><img src="assets/images/sos/centers.svg" style="width: 50px;" />
                            {{center_name|uppercase}}
                        </span>
                    </p>
                </li>
                <li class="current_section" [routerLink]="['/role']" *ngIf="parent_academy_flag=='N'"
                    [style.color]="menuName=='roles'?side_nav_menu_color:side_nav_header_font_color">
                    <p (click)="highlightmenu('roles')">
                        <span class="menu_icon1"><img src="assets/images/sos/Roles.svg" style="width: 50px;" />{{'Roles'
                            | translate | uppercase}}</span>

                    </p>

                </li>
                <li class="current_section" [routerLink]="['/staff']"
                    [style.color]="menuName=='staff'?side_nav_menu_color:side_nav_header_font_color">
                    <p (click)="highlightmenu('staff')">
                        <span class="menu_icon1"><img src="assets/images/sos/Staff.svg" style="width: 50px;" />{{'Staff'
                            | translate | uppercase}}</span>
                    </p>

                </li>
                <li class="current_section" [routerLink]="['/FeePlans']" *ngIf="parent_academy_flag=='N' && fees_flag"
                    [style.color]="menuName=='feePlans'?side_nav_menu_color:side_nav_header_font_color">
                    <p (click)="highlightmenu('feePlans')">
                        <span class="menu_icon1"><img src="assets/images/sos/Fee Plans.svg" style="width: 50px;" />
                            {{'FeePlans' | translate | uppercase}}</span>
                    </p>
                </li>
                <li class="current_section" [routerLink]="['/cheque-tracker']"
                    *ngIf="parent_academy_flag=='N' && cheque_tracking_flag"
                    [style.color]="menuName=='chequeTracking'?side_nav_menu_color:side_nav_header_font_color">
                    <p (click)="highlightmenu('chequeTracking');">
                        <span class="menu_icon1"><img src="assets/images/sos/Cheque.svg" style="width: 50px;" />
                            {{'ChequeTracking' | translate | uppercase}}</span>

                    </p>
                </li>
                <li class="current_section" [routerLink]="['/report']"
                    [style.color]="menuName=='reports'?side_nav_menu_color:side_nav_header_font_color">
                    <p (click)="highlightmenu('reports')">
                        <span class="menu_icon1"><img src="assets/images/sos/reports.svg" style="width: 50px;" />
                            {{'Reports' | translate | uppercase}}</span>
                    </p>
                </li>
                <!-- *ngIf="parent_academy_flag=='N'" -->
                <li class="current_section" [routerLink]="['/request']"
                    [style.color]="menuName=='request'?side_nav_menu_color:side_nav_header_font_color">
                    <p (click)="highlightmenu('request');">
                        <span class="menu_icon1"><img src="assets/images/sos/paymenttracker.svg" style="width: 50px;" />
                            {{'Request' | translate | uppercase}}</span>
                    </p>
                </li>
                <!-- *ngIf="parent_academy_flag=='N'" -->
                <li class="current_section" [routerLink]="['/expense']"
                [style.color]="menuName=='expense'?side_nav_menu_color:side_nav_header_font_color">
                <p (click)="highlightmenu('expense');">
                    <span class="menu_icon1"><img src="assets/images/sos/expense.svg" style="width: 40px;margin-bottom: 5px;" />
                        {{'Expense' | translate | uppercase}}</span>
                </p>
                </li>

                <li class="current_section" [routerLink]="['/messaging']"
                    *ngIf="parent_academy_flag=='N' && messaging_flag"
                    [style.color]="menuName=='messaging'?side_nav_menu_color:side_nav_header_font_color">
                    <p (click)="highlightmenu('messaging');">
                        <span class="menu_icon1"><img src="assets/images/sos/Messaging.svg"
                                style="width: 50px;" />{{'Messaging' | translate | uppercase}}</span>

                    </p>
                </li>
                <li class="current_section" [routerLink]="['/posts']"
                    [style.color]="menuName=='posts'?side_nav_menu_color:side_nav_header_font_color">
                    <p (click)="highlightmenu('posts')" *ngIf="post_flag">
                        <span class="menu_icon1"><img src="assets/images/sos/posts.svg" style="width: 50px;" />{{'Posts'
                            | translate | uppercase}}</span>
                    </p>
                </li>
                <li class="current_section" [routerLink]="['/Assessment']" (click)="selectedMenu()"
                    *ngIf="assessment_flag"
                    [style.color]="menuName=='assessment'?side_nav_menu_color:side_nav_header_font_color">
                    <p (click)="highlightmenu('assessment')">
                        <span class="menu_icon1">
                            <img src="assets/images/sos/Assessments.svg" style="width: 50px;" />
                            {{'Assessment' | translate | uppercase}}</span>
                    </p>
                </li>
                <li class="current_section" [routerLink]="['/coaching-drill']" *ngIf="ltad_coaching_drill_flag"
                    [style.color]="menuName=='coachingDrills'?side_nav_menu_color:side_nav_header_font_color">
                    <p (click)="highlightmenu('coachingDrills')">
                        <span class="menu_icon1"><img src="assets/images/sos/coaching_drills.svg"
                                style="width: 50px;" />{{'CoachingDrills' | translate | uppercase}}</span>

                    </p>

                </li>
                <li class="current_section" [routerLink]="['/lesson-plans']"
                    [style.color]="menuName=='lessonPlans'?side_nav_menu_color:side_nav_header_font_color"
                    *ngIf="lession_plan_flag">
                    <p (click)="highlightmenu('lessonPlans')">
                        <span class="menu_icon1"><img src="assets/images/sos/LessonPlans.svg"
                                style="width: 50px;" />{{'LessonPlans' | translate | uppercase}}</span>
                    </p>
                </li>
                <!-- <li class="current_section" [routerLink]="['/Test_Bank']">
                                <p (click)="highlightmenu()">
                                    <span class="menu_icon1"><i class="material-icons">trending_up</i></span>
                                    <span class="menu_title" mat-line >Test Banks </span>
                                    <span style="float:right;">
                                            <mat-chip-list>
                                                    <mat-chip color="primary" selected="true">{{organization_details.performance_set_count}}</mat-chip>
                                            </mat-chip-list>
                                    </span>        
                                </p>
                        </li> -->
                <!-- <li class="current_section" [routerLink]="['/payment-tracker']" *ngIf="parent_academy_flag=='N' && payments_flag" [style.color]="menuName=='paymentTracker'?side_nav_menu_color:side_nav_header_font_color">
                            <p (click)="highlightmenu('paymentTracker')">
                                <span class="menu_icon1"><img src="assets/images/sos/paymenttracker.svg"
                                        style="width: 50px;" />PAYMENT TRACKER</span>                           
                            </p>
                        </li>
        
                        <li class="current_section" [routerLink]="['/attendance-tracker']" *ngIf="parent_academy_flag=='N' && attendance_flag" [style.color]="menuName=='attendanceTracker'?side_nav_menu_color:side_nav_header_font_color">
                            <p (click)="highlightmenu('attendanceTracker')">
                                <span class="menu_icon1"><img src="assets/images/sos/attendance.svg"
                                        style="width: 50px;" />ATTENDANCE TRACKER</span>
                            </p>
                        </li> -->








                <!-- <li class="current_section" [routerLink]="['/StudentRanking']" > 
                            <p (click)="highlightmenu();">
                                <span class="menu_icon1"><i class="material-icons">star</i></span>
                                <span class="menu_title" mat-line >Ranking</span>
                                <span style="float:right;">                                
                                </span>        
                            </p>                
                    </li> -->





                <!-- 
                        <li class="current_section" [routerLink]="['/tournaments']" >
                            <p (click)="highlightmenu();">
                                <span class="menu_icon1"><i class="material-icons">sync</i></span>
                                <span class="menu_title" mat-line>Tournaments</span>
                                <span style="float:right;">
                                </span>
                            </p>
                        </li> -->
            </ul>
        </ng-container>
        <ng-container *ngIf="Content_creator_flag">
            <ul *ngIf="organization_details" style="clear:both;">
                <li class="current_section" [routerLink]="['/Assessment']" (click)="selectedMenu()"
                    *ngIf="assessment_flag"
                    [style.color]="menuName=='assessment'?side_nav_menu_color:side_nav_header_font_color">
                    <p (click)="highlightmenu('assessment')">
                        <span class="menu_icon1"><img src="assets/images/sos/Assessments.svg"
                                style="width: 50px;" />{{'Assessment' | translate | uppercase}}</span>
                    </p>

                </li>
                <li class="current_section active" [routerLink]="['/coaching-drill']"
                    [style.color]="menuName=='coachingDrills'?side_nav_menu_color:side_nav_header_font_color">
                    <p (click)="highlightmenu('coachingDrills')">
                        <span class="menu_icon1"><img src="assets/images/sos/coaching_drills.svg"
                                style="width: 50px;" />{{'CoachingDrills' | translate | uppercase}}</span>
                    </p>

                </li>

                <li class="current_section" [routerLink]="['/lesson-plans']"
                    [style.color]="menuName=='lessonPlans'?side_nav_menu_color:side_nav_header_font_color">
                    <p (click)="highlightmenu('lessonPlans')">
                        <span class="menu_icon1"><img src="assets/images/sos/LessonPlans.svg"
                                style="width: 50px;" />{{'LessonPlans' | translate | uppercase}}</span>
                    </p>
                </li>
            </ul>
        </ng-container>
    </div>
    <!-- </ng-scrollbar> -->

</aside>
<!-- main sidebar end -->

<div id="page_content" (click)="onClickedOutside($event);" (window:resize)="onWindowResize($event)">
    <div id="page_content_inner">
        <router-outlet>
            <mat-progress-bar mode="indeterminate" class="inner-loader" [style.display]="loader"></mat-progress-bar>
        </router-outlet>
    </div>
</div>

<ng-template #term_conditon>
    <h4 mat-dialog-title>{{'termOfUse' | translate}}
        <button mat-icon-button [mat-dialog-close]=" " style="float:right;">
            <mat-icon aria-hidden="false">close</mat-icon>
        </button>
    </h4>
    <mat-dialog-content>
        <p *ngIf="term_conditon_data" [innerHTML]="term_conditon_data.content"></p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button color="primary" [mat-dialog-close]=" ">{{'Cancel' | translate}}</button>
    </mat-dialog-actions>
</ng-template>