import { Injectable } from '@angular/core';
import {AppComponent} from '../../app.component';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable()
export class TrendReportServices {   
   
  publish_report_list:any;  
  assesment_data:any;  
  report_card_data:any;
  center_data:any;
  batch_data:any;


  httpOptions;   

  constructor(private http_client: HttpClient) 
  {   
    
  }
   
      getPlayerPerformanceStatistics(organization_id, player_id, performance_set_assessment_id) {
        this.httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/x-www-form-urlencoded',     
            // 'auth_key': '' + localStorage.getItem("auth_key"),
            // 'device_id': '' + localStorage.getItem("device_id"),
            // 'session_id': '' + localStorage.getItem("session_id"),
            'Authorization':''+ localStorage.getItem("access_token")
            })
        };
      let params = new HttpParams();  
      params = params.append('organization_id', organization_id);
      params = params.append('player_id', player_id);
      for (var i = 0; i < performance_set_assessment_id.length; i++)
      {
        params = params.append('performance_set_assessment_id[' + i + ']', performance_set_assessment_id[i]);
        }

      const getPlayerPerformanceStatistics_url = AppComponent.API_URL + 'dashboard/GetPlayerPerformanceStatistics';
      return this.http_client.post(getPlayerPerformanceStatistics_url, params,this.httpOptions)
      .pipe(
                map((data:any) =>{
                    if(data['player_performance_statistics'].length!=0)
                    {         
                      return data;
                    }
                    else
                      {
                        return '';
                      }
                }),
            )    
      }
}