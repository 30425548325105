import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CropperSettings } from 'ng2-img-cropper';
import { MatDialog } from '@angular/material';
import { RegistrationService } from './registration.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { UUID } from 'angular2-uuid';
import { PasswordValidation } from './password-validator';
import {LoginService} from '../authentication/login_services/login.service';
import {MatSnackBar,MatSnackBarConfig,MatSnackBarHorizontalPosition,MatSnackBarVerticalPosition,} from '@angular/material';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
})
export class RegistrationComponent implements OnInit {
  selectedIndex: any = 0;
  email_id: any;
  timeLeft: number = 90;
  interval;
  firstFormGroup: FormGroup;
  data: any;
  cropperSettings: CropperSettings;
  isLinear = false;
  countriesList: any = [];
  country_id: any;
  statesList: any;
  state_id: any;
  citiesList: any;
  city_id: any;
  academy_name: any;
  auth_key: any;   
  session_id:any;
  device_id:any;
  addressFormGroup: FormGroup;
  formInvalidFlag:boolean=false;
  form1InvalidFlag:boolean=false;
  form2InvalidFlag:boolean=false;
  ownerDetailsForm: FormGroup;
  passwordForm: FormGroup;
  org_image: any;
  address_line1: any;
  address_line2: any;  
  first_name: any;
  last_name: any;
  mobile_number: any;
  email_address: any;
  org_thumbnail_image: any;
  organization_id: any;
  guest_org_id:any;
  sub:any;
  form3InvalidFlag:boolean=false;
  approvedDiv:any='none';
  pendingDiv:any='none';
  customer_id:any;
  email_verification_status:any;
  password:any;
  login_flag:string;
  checkcredential_data:any;
  full_name:string=''; 
  user_profile_img:any;
  errorMessage:string='';

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  setAutoHide: boolean = true;
  autoHide: number = 3000;
  actionButtonLabel: string = 'Close';
  action: boolean = true;
  success_msg:any;
  submit_btn_show_hide="block";
  hide:any;
  emailExistDetails:any;
  emailExistFlag:boolean=false;
  SubmitBtnDiv:any="inline-block";
  constructor(private _formBuilder: FormBuilder, public dialog: MatDialog, private registrationService: RegistrationService,private route:ActivatedRoute, private login_service:LoginService,private router: Router,public snackBar:MatSnackBar) {
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.width = 100;
    this.cropperSettings.height = 100;
    this.cropperSettings.croppedWidth = 100;
    this.cropperSettings.croppedHeight = 100;
    this.cropperSettings.canvasWidth = 400;
    this.cropperSettings.canvasHeight = 300;
    this.cropperSettings.rounded = false;
    this.data = { image: "assets/images/museum.png" };

        this.sub = this.route.queryParams.subscribe(params => {
          this.guest_org_id = params['guest_org_id'];
          
      });
      
      if( this.guest_org_id!=undefined &&  this.guest_org_id!=" " &&  this.guest_org_id!=null)
      {
        this.emailVerificationStatusChk();
      }
  }
  ngOnInit() {    
    this.firstFormGroup = this._formBuilder.group({
      academy_name: ['', Validators.required],
    });  
    this.addressFormGroup = this._formBuilder.group({
      address_line1: ['', Validators.required],
      address_line2: ['', Validators.required],
      country_id: ['', Validators.required],
      state_id: ['', Validators.required],
      city_id: ['', Validators.required],
    }); 

    this.ownerDetailsForm= this._formBuilder.group({      
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      mobile_number:  [null, Validators.compose([Validators.required, Validators.pattern(/^(?:\d{10})$/)])],
      email_address: [null, Validators.compose([Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)])],
    }); 

    this.passwordForm = this._formBuilder.group({
        password: ['', [Validators.compose([Validators.required, Validators.pattern(/^(?=.*?[A-Z,a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)])]],
        confirmPassword: ['',[Validators.required]]
      }, {
      validator: PasswordValidation.MatchPassword
    });
    
  }
  
  emailVerificationStatusChk()
  {   
     
     this.registrationService.emailVerificationForSelfRegistration(this.guest_org_id).subscribe(
      response => {
        if (response != '') {          
          this.auth_key = response[0]['auth_key'];
          this.email_verification_status=response[0]['email_verification_status'];            
           if(this.email_verification_status=="Pending")
           {
              this.stepper.selectedIndex =3;
              this.pendingDiv='inline-block';
              this.approvedDiv='none';
              const uuid = UUID.UUID();
              this.session_id= uuid;
              this.device_id= uuid;
              this.SubmitBtnDiv="inline-block";
              
           }     
          else if(this.email_verification_status=="Verified")  
          {            
            this.approvedDiv='inline-block';
            this.pendingDiv='none';           
            this.stepper.selectedIndex =3;
            this.SubmitBtnDiv="none";
            
          } 
        }
        else {
          console.log('something is wrong with emailVerificationForSelfRegistration Service');
          
        }
      },
      error => console.log("emailVerificationForSelfRegistration Service IS NOT WORKING!")
    );
  }
  getAcademyName(formOne,stepper: MatStepper) {
    
    if(this.firstFormGroup.valid)
    {
      this.formInvalidFlag=false;	
      this.academy_name = formOne.academy_name;   
      this.getAuthKey(); 
      stepper.next();
    }else{
      this.formInvalidFlag=true;	
    }     
    
  }
  getAuthKey()
  {
    this.registrationService.generateGuestAuthKey(this.academy_name).subscribe(
      response => {
        if (response != '') {
          this.auth_key = response;
          this.setSession();
        }
        else {
          console.log('something is wrong with auth_key Service');
          this.auth_key = [];
        }
      },
      error => console.log("auth_key Service IS NOT WORKING!")
    );
  }
  setSession()
  {
    const uuid = UUID.UUID();
    this.session_id= uuid;
    this.device_id= uuid;  
    this.registrationService.generateSession(this.auth_key,this.session_id,this.device_id).subscribe(
      response => {
        if (response != '') {
      
         this.getCountryList();         
        }
        else {
          console.log('something is wrong with setSession Service');
         // this.auth_key = [];
        }
      },
      error => console.log("setSession Service IS NOT WORKING!")
    );
  }
  getCountryList()
  {
    this.registrationService.CountriesList(this.auth_key,this.session_id, this.device_id).subscribe(
      response => {
        if (response != '') {
          this.countriesList = response;
        }
        else {
          console.log('something is wrong with registrationService Service');
          this.countriesList = [];
        }
      },
      error => console.log("registrationService Service IS NOT WORKING!")
    );
  }
  selectedcountry(contry_id) {
    this.country_id = contry_id;
    this.citiesList = [];
    this.registrationService.GetStates(this.country_id,this.auth_key,this.session_id,this.device_id).subscribe(
      response => {
        if (response != '') {
          this.statesList = response;
        }
        else {
          console.log('something is wrong with GetStates Service');
          this.statesList = [];
        }
      },
      error => console.log("GetStates Service IS NOT WORKING!")
    );
  }
  selectedstate(state_id) {
    this.state_id = state_id;
    this.registrationService.GetCities(this.country_id, this.state_id,this.auth_key,this.session_id,this.device_id).subscribe(
      response => {
        if (response != '') {
          this.citiesList = response;
        }
        else {
          console.log('something is wrong with GetCities Service');
          this.citiesList = [];
        }
      },
      error => console.log("GetCities Service IS NOT WORKING!")
    );
  }
  selectedcity(city) {
    this.city_id = city;
  }

  submitAcademyDetails(addressFormGroup,stepper: MatStepper)
  {
     if(this.addressFormGroup.valid)
    {
      this.form1InvalidFlag=false;
      this.address_line1=addressFormGroup.address_line1;
      this.address_line2=addressFormGroup.address_line2;
      this.country_id=addressFormGroup.country_id;
      this.state_id=addressFormGroup.state_id;	  
      this.city_id=addressFormGroup.city_id;	        
      stepper.next();
    }else{
      this.form1InvalidFlag=true;	
    } 
  }
  submitOwnerDetails(ownerDetailsForm,stepper: MatStepper)
  {
    
     if(this.ownerDetailsForm.valid)
    {
      this.form2InvalidFlag=false; 
      this.first_name=ownerDetailsForm.first_name;
      this.last_name=ownerDetailsForm.last_name;   
      this.mobile_number=ownerDetailsForm.mobile_number;   
      this.email_address=ownerDetailsForm.email_address;  
     // stepper.next();
      this.startTimer();
      this.addselfRegisteredOrganization();
    }else{
      this.form2InvalidFlag=true;	
      this.submit_btn_show_hide="block";  
    }
  }

  enteredEmail(emailId)
  {
    var email_id_chk=/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    if (email_id_chk.test(emailId) == true) 
    {      
      this.registrationService.CheckEmailIsAlreadyExist(emailId,this.auth_key,this.session_id,this.device_id).subscribe(
        response => {
          if (response != '') {
            this.emailExistDetails = response;    
                    
            if(this.emailExistDetails['message']=='Exist')
            {
              this.emailExistFlag=true;
              this.success_msg="Entered email ID is already Register User";
              this.snackBarDisplay();  
              this.ownerDetailsForm.get('email_address').setValue('');
            }
            else if(this.emailExistDetails['message']=='NotExist')
            {
              this.emailExistFlag=false;              
            }
                     
          }
          else {
            console.log('something is wrong with CheckEmailIsAlreadyExist Service');
            this.emailExistDetails = [];
          }
        },
        error => console.log("CheckEmailIsAlreadyExist Service IS NOT WORKING!")
      );
    }
   
  }
  addselfRegisteredOrganization()
  {
    
    this.registrationService.addSelfRegisteredOrganization(this.auth_key,this.session_id,this.device_id,this.academy_name,this.org_image,this.address_line1,this.address_line2,this.country_id,this.state_id,this.city_id,this.first_name,this.last_name,this.mobile_number,this.email_address,this.org_thumbnail_image).subscribe(
      response => {
        if (response != '') {
          this.organization_id = response;
          this.success_msg="Verification link is sent to your email ID";
          this.snackBarDisplay();
          this.submit_btn_show_hide="none";
        }
        else {
          console.log('something is wrong with GetCities Service');
          this.organization_id = [];
        }
      },
      error => console.log("GetCities Service IS NOT WORKING!")
    );
  }

  submitPasswordForm()
  {
    if(this.passwordForm.valid)
    {
      this.form3InvalidFlag=false;    
      this.password= this.passwordForm.controls.password.value;

      this.registrationService.generateSession(this.auth_key,this.session_id,this.device_id).subscribe(
      response => {
        if (response != '') {      
                
        
      this.registrationService.addSelfRegisteredOrganizationAfterVerification(this.auth_key,this.session_id,this.device_id,this.guest_org_id,this.password).subscribe(
      response => {
        if (response != '') {
          this.organization_id = response['organization_id'];
          this.customer_id=response['customer_id'];
          this.email_address=response['email_address'];
          this.success_msg="New password is set to your login";
          this.snackBarDisplay();
          this.loginUser();
        }
        else {
          console.log('something is wrong with GetCities Service');
          this.organization_id = [];
        }
      },
      error => console.log("GetCities Service IS NOT WORKING!")
      );

      }
        else {
          console.log('something is wrong with setSession Service');
         // this.auth_key = [];
        }
      },
      error => console.log("setSession Service IS NOT WORKING!")
      );
    }
    else
    {
      this.form3InvalidFlag=true;	
    }
  }

  loginUser() 
  {  
    localStorage.setItem('userMail',this.email_address); 
    this.login_flag='e';  
    this.registrationService.checkCredentialsV2(this.email_address,this.password,this.login_flag).subscribe(
    response => 
    {
      this.checkcredential_data=response;

      if(this.checkcredential_data['forgot password flag']=="yes" )
      {        
        this.router.navigate(['resetPassword']);
        // console.log("Please reset your password");
      }
      else if(this.checkcredential_data.IsRegistered=="yes" && this.checkcredential_data['Password State']=="Matched" && this.checkcredential_data['User State']=="Valid")
      {
        //console.log(response);
        //console.log(response['Customer Id'][0].customer_id);
        //this.router.navigate(['dashboard']);
        this.registrationService.checkAuthorization(this.checkcredential_data['Customer Id'][0].customer_id).subscribe(
        success => 
        {          
          if(success) 
          {               
            
            this.full_name=this.checkcredential_data['Customer Id'][0].first_name+" "+this.checkcredential_data['Customer Id'][0].last_name;
            this.user_profile_img=this.checkcredential_data['Customer Id'][0].academy_original_image_folder_path;
            localStorage.setItem('Profile_img',this.user_profile_img);            
            localStorage.setItem('UserName',this.full_name);
            localStorage.setItem('currentUser',response['Customer Id'][0].customer_id);
            //this.googleAnalyticsEventsService.emitEvent("AcadWare Analytics", "User navigation", "Customer ID", response['Customer Id'][0].customer_id);
            this.registrationService.getOrganizationList(response['Customer Id'][0].customer_id).subscribe(
            response1 => 
            {
              
              if( response1!="This email ID / Mobile is not attached to any academy. Please contact the academy for information.")
              {
                      if(response1.academy_list.length!=0)
                    {                     
                      if(response1.academy_list.length>1)
                      {
                        localStorage.setItem('organization_list',JSON.stringify(response1.academy_list));
                        this.router.navigate(['organization_selector']);
                      }
                      else
                      {
                        // console.log("ORGanization ID"+response.academy_list[0].organization_id);
                        sessionStorage.setItem('OrganizationID',response1.academy_list[0].organization_id);
                        localStorage.setItem('is_Admin','1');
                        this.router.navigate(['dashboard'],{ queryParams: { page: 1 }  });
                      }        
                    }
                    else
                    {
                      this.router.navigate(['dashboard']);
                    }
              }
          else{
                this.errorMessage=response;
              }
              
            },
            error=>console.log("Organization List service is not working!")
          ); 
        }
        else
        {          
          this.errorMessage='Your registered id does not have the necessary rights to access the site';          
          this.router.navigate(['']);
        }             
      },
        error => console.log("Authorization is not working!")
      );
    }
    else
    {      
      
      if(response.IsRegistered=="yes" && response['Password State']=="Notmatched" && response['User State']=="Valid")
      {
        this.errorMessage='Password is Incorrect';
      }
      else if(response.IsRegistered=="no" && response['User State']=="Invalid")
      this.errorMessage='Email Id is Incorrect';     
    
      this.router.navigate(['']);
    }   
  },
  error => {console.log("login is not working!");}
  );
}

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedIndex = tabChangeEvent.index;
    if (this.selectedIndex == '1') {
      this.startTimer();
    }
  }
  public nextStep() {
    this.selectedIndex += 1;
    if (this.selectedIndex == '1') {
      this.startTimer();
    }
    else {

    }
  }
  startTimer() {
    this.timeLeft = 90;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {

      }
    }, 1000)
  }
  openImageCroper() {
    this.dialog.open(this.profileImageCroperAdd,
      {
        height: '550px',
        width: '450px',
        disableClose: true,
      });
  }
  resetImage() {
    this.data = { image: "assets/images/museum.png" };
  }
  cropedProfileImage()
  {
    var org_image:any;
    var thumbnail_image:any;
    org_image = this.data.image; 
    thumbnail_image = this.data.image;

    this.org_image=org_image.split(',');
    this.org_image=this.org_image[1];

    this.org_thumbnail_image=thumbnail_image.split(',');
    this.org_thumbnail_image=this.org_thumbnail_image[1];
    
  }

   snackBarDisplay()
  {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    this.snackBar.open(this.success_msg,this.action ? this.actionButtonLabel : undefined,config);
  } 

  @ViewChild('profileImageCroperAdd') profileImageCroperAdd: TemplateRef<any>;
  @ViewChild('stepper')stepper;
}
