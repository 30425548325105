import { Injectable } from '@angular/core';
import {AppComponent} from '../app.component';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable()
export class IndividualReportServices {   
   
  publish_report_list:any;  
  assesment_data:any;
  center_data:any;
  batch_data:any;
  player_performance_statistics:any;

  httpOptions;   

  constructor(private http_client: HttpClient) 
  {   
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
    };
  }

   

    getReportPublishList(organization_id)
    {
      
        const  params = new  HttpParams().set('organization_id', organization_id);          
        const url=AppComponent.API_URL+'report/GetReportPublishList';
        return this.http_client.post(url,params,this.httpOptions)
        .pipe(
          map((data:any) =>{
            this.publish_report_list =data;
             if(this.publish_report_list['status'])
            {          
                return this.publish_report_list['published_reports'];
            }
            else
            {
                return '';
            }
          }),
        )
    }

   getPlayerReport(academy_id,performance_set_criteria_setting_id,performance_set_assessment_id,
    player_id,center_id) 
    {
      
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded',     
          // 'auth_key': '' + localStorage.getItem("auth_key"),
          // 'device_id': '' + localStorage.getItem("device_id"),
          // 'session_id': '' + localStorage.getItem("session_id"),
          'Authorization':''+ localStorage.getItem("access_token")
          })
      };
               
        let params = new HttpParams();
        params = params.append('academy_id', academy_id); 
        // params = params.append('report_publish_id', performance_set_assessment_id);
        if(performance_set_criteria_setting_id!='null'){          
          var id=performance_set_criteria_setting_id.split('_');    
          var per_set_cri_setting_id=[];
          id.forEach(element => {
            per_set_cri_setting_id.push(element);
          });
          per_set_cri_setting_id.forEach((element,i) => {
            params =params.append('performance_set_criteria_setting_id['+i+']', element);
          });  
        }
       
         
        params =params.append('performance_set_assessment_id', performance_set_assessment_id);
     
    
        if(player_id!=undefined || player_id!=null)
        {
            params = params.append('filter[player_id]', player_id);
            params = params.append('filter[academy_center_id][0]', center_id);
        }
    
        

        // const url=AppComponent.API_URL+'report/GetPlayerReport';
        const url=AppComponent.API_URL+'report/GetPlayerAssessmentReport';
        return this.http_client.post(url,params,this.httpOptions)
        .pipe(
          map((data:any) =>{
            if(data['status']==200)
            {
              return data;
            }
            else
            {
              return '';
            }
          }),
        ) 
  
    }

    getPlayer627Report(academy_id,report_publish_id,player_id,center_id) 
      {
        
        this.httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/x-www-form-urlencoded',     
            // 'auth_key': '' + localStorage.getItem("auth_key"),
            // 'device_id': '' + localStorage.getItem("device_id"),
            // 'session_id': '' + localStorage.getItem("session_id"),
            'Authorization':''+ localStorage.getItem("access_token")
            })
        };
           
          let params = new HttpParams();
          params = params.append('academy_id', academy_id); 
          params = params.append('report_publish_id', report_publish_id);
      
          if(player_id!=undefined || player_id!=null)
          {
              params = params.append('filter[player_id]', player_id);
              params = params.append('filter[academy_center_id][0]', center_id);
          }     
          
  
          const url=AppComponent.API_URL+'report/GetPlayerReport';
          return this.http_client.post(url,params,this.httpOptions)
          .pipe(
            map((data:any) =>{
           if(data['status'])
            {
            return data['report_card'];
            }
            else
            {
            return '';
            }
            }),
          ) 
    
      }

    getCenterDetails(customer_id, organization_id, is_admin)
    {
        const  params = new  HttpParams().set('customer_id', customer_id).set('organization_id', organization_id).set('is_admin', is_admin);        
        const local_center_url = AppComponent.API_URL + 'center/GetCenterListWithGroups ';
        return this.http_client.post(local_center_url,params,this.httpOptions)
        .pipe(
          map((data:any) =>{
            this.center_data =data['Academy Center Ids'];
            if(this.center_data.length!=0)
            {         
              return this.center_data;
            }
            else
              {
                return '';
              }
          }),
        );

    }

    GetBatchsByAcademy(organization_id)
    {
        const  params = new  HttpParams().set('organization_id', organization_id);
        const local_center_url = AppComponent.API_URL + 'batch/GetBatchsByAcademy';
        return this.http_client.post(local_center_url, params,this.httpOptions)
        .pipe(
          map((data:any) =>{
            this.batch_data =data['batch'];
            if(this.batch_data.length!=0)
            {         
              return this.batch_data;
            }
            else
              {
                return '';
              }
          }),
        );
    }

      getPlayerPerformanceStatistics(organization_id, player_id, performance_set_assessment_id) {
      let params = new HttpParams();  
      params = params.append('organization_id', organization_id);
      params = params.append('player_id', player_id);
      for (var i = 0; i < performance_set_assessment_id.length; i++)
      {
        params = params.append('performance_set_assessment_id[' + i + ']', performance_set_assessment_id[i]);
        }

      const getPlayerPerformanceStatistics_url = AppComponent.API_URL + 'dashboard/GetPlayerPerformanceStatistics';
      return this.http_client.post(getPlayerPerformanceStatistics_url, params,this.httpOptions)
      .pipe(
                map((data:any) =>{
                    this.player_performance_statistics =data['player_performance_statistics'];
                    if(this.player_performance_statistics.length!=0)
                    {         
                      return this.player_performance_statistics;
                    }
                    else
                      {
                        return '';
                      }
                }),
            )    
      }
}