import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {ResetpasswordService} from '../resetpassword_services/resetpassword.service';
import { UUID } from 'angular2-uuid';
import {LoginService} from '../login_services/login.service';
import {OrganizationDetailsService} from '../../layouts/admin/organization-details.service';
import {MatSnackBar,MatSnackBarConfig,MatSnackBarHorizontalPosition,MatSnackBarVerticalPosition,} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {

  public resetPassword_form: FormGroup;
  passwordAlert:string='Password should be alphanumeric and minimum 8 characters';
  email_address:string='';
  password:string='';
  confirm_password:string='';
  auth_key:string='';
  session_id:string='';
  device_id:string='';
  success_msg:string='';
  spinner_div:any='none';
  hide:any;
  hide1:any;
  errorMessage:any;
  userMail:any;
  login_flag:any;
  checkcredential_data:any;
  full_name:any;
  user_profile_img:any;
  public loading = false;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  setAutoHide: boolean = true;
  autoHide: number = 3000;
  actionButtonLabel: string = 'Close';
  action: boolean = true;
  sos_login_flag: boolean;
  set_password_token: any;
  organization_list: any=[];
  datastream_flag: boolean;
  sportz_village_flag: boolean;
  yalla_goal_flag: boolean;
  form_invalid_flag: boolean=false;
  sports_pad_flag: boolean;
  
  constructor(private fb: FormBuilder, private router: Router,private ResetpasswordService:ResetpasswordService
  ,private login_service:LoginService, private OrganizationService:OrganizationDetailsService,public snackBar:MatSnackBar,
  private translate:TranslateService) { 
    this.sos_login_flag=sessionStorage.getItem('sos_login_flag')=='Y'?true:false;
    this.datastream_flag=sessionStorage.getItem('datastream_flag')=='Y'?true:false;
    this.sportz_village_flag=sessionStorage.getItem('sportz_village_flag')=='Y'?true:false;
    this.yalla_goal_flag = sessionStorage.getItem('yalla_goal_flag')=='Y'?true:false;
    this.sports_pad_flag = sessionStorage.getItem('sports_pad_flag')=='Y'?true:false;
    this.userMail=localStorage.getItem('userMail');   
    this.checkEmailorMobile(this.userMail);
    this.set_password_token=sessionStorage.getItem('set_password_token');
    const browserLang = sessionStorage.getItem("locale")!=null?sessionStorage.getItem("locale"):'en';
      translate.use(browserLang.match(/en|hi/) ? browserLang : 'en');

  }

  ngOnInit() { 
    this.resetPassword_form = this.fb.group ( {
    Email: [this.userMail],
    Password: ['', [Validators.compose([Validators.required, Validators.pattern(/^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*_\-]).{8,}$/)])]],
    Confirm_Password: ['',[Validators.required]]
  } );
}
checkEmailorMobile(userID)
{
  var email_id_chk=/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  //var phone_no_chk=/^(?:\d{10})$/;
  var phone_no_chk = /^(?:\d{8}|\d{10})$/;
  if (email_id_chk.test(userID) == true) 
  {   
    return this.login_flag='e';
  }
  if (phone_no_chk.test(userID) == true) 
  {  
    return this.login_flag='m';
  }      
}

resetPassword(post)
{
  if(this.resetPassword_form.valid){
    this.form_invalid_flag=false;
    this.email_address=post.Email;
    this.password = post.Password;
    this.confirm_password=post.Confirm_Password;
    // console.log(this.email_address+""+this.password+""+this.confirm_password);
    if(this.confirm_password!=this.password)
    {
      this.resetPassword_form.reset();
      this.success_msg="Password Mismatched";
      this.snackBarDisplay();
      this.resetPassword_form.get('Email').setValue(this.email_address);
      return false;
    }
    else
    {      
      this.auth_key=localStorage.getItem("auth_key");
      this.session_id=localStorage.getItem("session_id");
      this.device_id=localStorage.getItem("device_id"); 
     
          this.ResetpasswordService.resetPassword(this.auth_key,this.session_id,this.device_id,
            this.email_address,this.password,this.login_flag,this.set_password_token).subscribe(
            success => 
            {
              if(success['status']==200) 
              {                              
                this.success_msg=success['message'];
                this.addPost();
              }
              else
              {
                //document.write("Failure");
                this.resetPassword_form.reset();
                this.router.navigate(['resetPassword']);
              }
                
             
            },
          error => console.log("Reset Password is not working!")
        );
    }
  }else{
    this.form_invalid_flag=true;
  }
    
}


generateSessionID():string
{
  let uuid = UUID.UUID();
  return uuid;
}



  addPost() 
  {
    this.spinner_div="block";   
    this.loading = true;        
    
    this.login_service.checkCredentialsV2(this.email_address,this.confirm_password,this.login_flag).subscribe(
    response => 
    {
      this.checkcredential_data=response;
     
      if(this.checkcredential_data['data']['is_registered']=="yes"  && this.checkcredential_data['data']['user_state']=="Valid")
      { 

        this.login_service.checkCredentialsV3(this.email_address, this.confirm_password, this.login_flag,'').subscribe(
        msg => {
          if(msg['status']==200){
            localStorage.setItem('access_token', msg['data']['access_token']);
            localStorage.setItem('refresh_token', msg['data']['refresh_token']);

            this.login_service.checkAuthorization(this.checkcredential_data['data']['customers'][0].customer_id).subscribe(
              success => {
                
                if (success) {                   
                  localStorage.setItem('Profile_img', this.checkcredential_data['data']['customers'][0].academy_original_image_folder_path);
                  localStorage.setItem('UserName', this.checkcredential_data['data']['customers'][0].first_name + " " + this.checkcredential_data['data']['customers'][0].last_name);
                  localStorage.setItem('currentUser', this.checkcredential_data['data']['customers'][0].customer_id);              

                  this.login_service.getOrganizationList(this.checkcredential_data['data']['customers'][0].customer_id).subscribe(
                    response1 => {            
                      
                        if (response1['organization_list'].length != 0) {
                          this.spinner_div = "none";
                          if (response1['is_admin']) {
                            localStorage.setItem('is_Admin', '1');
                          }
                          else {
                            localStorage.setItem('is_Admin', '0');
                          }
                          response1['organization_list'].forEach(element => {
                            var role_id=0;
                            element['role_types'].forEach(role => {
                              if(role=="A" || role=="H")
                              {
                                role_id=1;
                              }                             
                              
                            });
                            if(role_id==1){
                              this.organization_list.push(element);
                            }
                          });
                          if(this.organization_list.length>1){
                            localStorage.setItem('organization_list', JSON.stringify(this.organization_list));
                            this.router.navigate(['organization_selector']);
                          }
                          if(this.organization_list.length==1){
                            localStorage.setItem('organization_list', JSON.stringify(this.organization_list));
                            localStorage.setItem('Organization_list_1',JSON.stringify(this.organization_list));
                            sessionStorage.setItem('OrganizationID', this.organization_list[0].organization_id);
                            this.login_service.getChildOrganizations(this.checkcredential_data['data']['customers'][0].customer_id, this.organization_list[0].organization_id).subscribe(
                              response => {

                                if (response['status'] == 200 && response['data']['organization_details']!=false) {
                                  
                                  if (response['data']['organization_details']['child_academies'].length != 0) {
                                    this.router.navigate(['organization_selector', this.organization_list[0].organization_id]);
                                  }
                                  else { 
                                    this.login_academy(this.organization_list);
                                  }
                                }
                                else {
                                  this.login_academy(this.organization_list);
                                }
                              });
                          }
           
                        }
                    });
                }
                else {
                  this.errorMessage = 'Your registered id does not have the necessary rights to access the site';
                  this.router.navigate(['']);
                }
              },
              error => console.log("Authorization is not working!")
            );
          }
          if(msg['status']==401){
            this.errorMessage = msg['message'];
            this.router.navigate(['']);
          }
    
      this.spinner_div = "none";
    });
    }
    else
    { 
    
      if(response.is_registered=="no" && response['user_state']=="Invalid")
      this.errorMessage='Email Id is incorrect';
      //this.login_form.reset();
      this.loading = false;
      this.router.navigate(['']);
    }   
  },
  error => {console.log("login is not working!");this.loading = false;}
  );
}

 snackBarDisplay()
  {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    this.snackBar.open(this.success_msg,this.action ? this.actionButtonLabel : undefined,config);
  } 


  login_academy(response1){
    var admin=0;
    var headoffice=0;
    var admin_role=0;
    var content_creator=0;
    
    response1[0]['role_types'].forEach(element => {
      if(element=='H')
      {
        headoffice=1;
      }
      if(element=='A')
      {
        admin=1;
      }
    });
    if(admin==1)
    {
      localStorage.setItem('Flag', 'A');
    }
    if(headoffice==1 && admin==0)
    {
      localStorage.setItem('Flag', 'H');
    }

    response1[0]['role_names'].forEach(element => {
      if(element=='Admin')
      {
        admin_role=1;
      }
      if(element=='Content Creator')
      {
        content_creator=1;
      }
    });
    sessionStorage.setItem('parent_academy_flag', 'N'); 

    if(admin_role==1 || headoffice==1)
    {
      localStorage.setItem('Content_creator_flag', 'N');
      sessionStorage.setItem('sos_login_flag', this.sos_login_flag==true?'Y':'N');
      sessionStorage.setItem('datastream_flag', this.datastream_flag==true?'Y':'N');
      sessionStorage.setItem('sportz_village_flag', this.sportz_village_flag==true?'Y':'N');
      this.router.navigate(['dashboard'], { queryParams: { page: 1 } });
     
    }
    if(content_creator==1 && admin_role==0)
    {
      localStorage.setItem('Content_creator_flag', 'Y');
      sessionStorage.setItem('sos_login_flag', this.sos_login_flag==true?'Y':'N');
      sessionStorage.setItem('datastream_flag', this.datastream_flag==true?'Y':'N');
      sessionStorage.setItem('sportz_village_flag', this.sportz_village_flag==true?'Y':'N');
      this.router.navigate(['/coaching-drill']);
    }
    
  }


}
