import { Injectable } from "@angular/core";
import { AppComponent } from "../../app.component";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map, catchError, tap } from "rxjs/operators";

@Injectable()
export class TermsConditionServices {
  disabled:any;
  status:any;
  httpOptions;
  constructor(private http_client: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
    };
  }
  getTandCFlag(organization_id) {
    const params = new HttpParams().set("organization_id", organization_id);
    const local_playerlist_url =AppComponent.API_URL +"org_terms_and_conditions/GetOrgCurrentTermsAndConditionsDetails";
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }
  updateTandCFlag(organization_id, term_and_condition_flag) {
    const params = new HttpParams()
      .set("organization_id", organization_id)
      .set("term_and_condition_flag", term_and_condition_flag);

    const local_playerlist_url =AppComponent.API_URL +"org_terms_and_conditions/UpdateOrgTermsAndConditionsFlagDetails";
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  getCurrentTandC(organization_id) {
    const params = new HttpParams().set("organization_id", organization_id);

    const local_playerlist_url =AppComponent.API_URL +"org_terms_and_conditions/GetOrgCurrentTermsAndConditionsDetails";
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  addTandC(organization_id,term_and_condition_content,present_to_all_new_registrants_flag,present_to_all_existing_members_for_re_acceptance_flag,login_customer_id) {
    const params = new HttpParams().set("organization_id", organization_id)
                                   .set("term_and_condition_content",term_and_condition_content)
                                   .set("present_to_all_new_registrants_flag",present_to_all_new_registrants_flag)
                                   .set("present_to_all_existing_members_for_re_acceptance_flag",present_to_all_existing_members_for_re_acceptance_flag)
                                   .set("login_customer_id",login_customer_id);
    const local_playerlist_url = AppComponent.API_URL +  "org_terms_and_conditions/AddOrgTermsAndConditionsDetails";
        return this.http_client.post(local_playerlist_url, params, this.httpOptions)
                                .pipe(
                                     map((data: any) => {
                                       return data;
                                     })
                                   );
  }

  updateTandC(organization_id,term_and_condition_id,term_and_condition_content,present_to_all_new_registrants_flag,present_to_all_existing_members_for_re_acceptance_flag,login_customer_id){
    const params = new HttpParams().set("organization_id", organization_id)
    .set("term_and_condition_id", term_and_condition_id)
    .set("term_and_condition_content",term_and_condition_content)
    .set("present_to_all_new_registrants_flag",present_to_all_new_registrants_flag)
    .set("present_to_all_existing_members_for_re_acceptance_flag",present_to_all_existing_members_for_re_acceptance_flag)
    .set("login_customer_id",login_customer_id);

    const local_playerlist_url = AppComponent.API_URL +  "org_terms_and_conditions/UpdateOrgTermsAndConditionsDetails";
        return this.http_client.post(local_playerlist_url, params, this.httpOptions)
                                .pipe(
                                     map((data: any) => {
                                       return data;
                                     })
                                   );
  }

  publishTandC(organization_id,term_and_condition_id,change_log,login_customer_id,
    term_and_condition_content,present_to_all_new_registrants_flag,present_to_all_existing_members_for_re_acceptance_flag,){
    const params = new HttpParams().set("organization_id", organization_id)
    .set("term_and_condition_id", term_and_condition_id)
    .set("change_log",change_log)
    .set("term_and_condition_content",term_and_condition_content)
    .set("present_to_all_new_registrants_flag",present_to_all_new_registrants_flag)
    .set("present_to_all_existing_members_for_re_acceptance_flag",present_to_all_existing_members_for_re_acceptance_flag)
    
    .set("login_customer_id",login_customer_id);

    const local_playerlist_url =
      AppComponent.API_URL +
      "org_terms_and_conditions/PublishOrgTermsAndConditionsDetails";
    return this.http_client
      .post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  getOrgTandCHistory(organization_id){
    const params = new HttpParams().set("organization_id", organization_id);

    const local_playerlist_url =
      AppComponent.API_URL +
      "org_terms_and_conditions/GetOrgTermsAndConditionsHistory";
    return this.http_client
      .post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

}
