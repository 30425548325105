<ng-container *ngIf="!sos_login_flag">
    <div class="background-image"  *ngIf="!datastream_flag && !sportz_village_flag && !al_shabab_flag
     && !yalla_goal_flag && !sports_pad_flag">
        <img class="img-fluid" src="assets/images/bg-1.jpg" alt="Acadware" />        
    </div>  
    <div class="background-image" *ngIf="datastream_flag" style="background: black;padding-top: 4.5%;">
        <img class="img-fluid" src="assets/images/datastream.png" alt="Acadware" style="background: black;"/>  
    </div>  
    <div class="background-image sportz-village" *ngIf="sportz_village_flag" style="background: white;">
        <img class="img-fluid1" src="assets/images/SVLogin.png" />  
    </div> 
    <div class="background-image sportz-village" *ngIf="al_shabab_flag" style="background: white;">
        <img class="img-fluid1" src="assets/images/Al_shabab.png" />  
    </div>  

    <div class="background-image" *ngIf="yalla_goal_flag" style="background: white;">
        <img class="img-fluid1" src="assets/images/yalla_goal.png" />  
    </div> 

    <div class="background-image" *ngIf="sports_pad_flag" style="background: white;">
        <img class="img-fluid1" src="assets/images/sportspadbag.png" />  
    </div>


    <div class="login_page_wrapper forgot-div">
        <p class="" style="text-align:center; margin-bottom:20px; margin-top: 70px; float:left; width:100%;" 
        *ngIf="!datastream_flag && !sportz_village_flag && !al_shabab_flag && !yalla_goal_flag && !sports_pad_flag">
            <span class="acad-img"><img class="img-fluid" src="assets/images/acadware_logo_vme_white.png" alt="Acadware" style="width:215px;" /></span>    
        </p>
        <p class="" style="text-align:center; margin-top:6px; margin-bottom:210px; float:left; width:100%;"
         *ngIf="datastream_flag">           
            <span class="acad-img">
                <img  src="assets/images/datastreamLogo.png" style="width:215px;" />
            </span>
        </p>

        <p class="" style="text-align:center; margin-top:20px; margin-bottom:155px; float:left; width:100%;"
         *ngIf="sportz_village_flag">           
            <span class="acad-img">
                <img  src="assets/images/SV.png" style="width:215px;" />
            </span>
        </p>
        <p class="" style="text-align:center; margin-top:20px; margin-bottom:155px; float:left; width:100%;margin-bottom: 95px;" 
        *ngIf="al_shabab_flag">           
            <span class="acad-img">
                <img  src="assets/images/shabab-logo.png" style="width:215px;" />
            </span>
        </p>

        <p class="" style="text-align:center; margin-top:20px; margin-bottom:20px; float:left; width:100%;"
         *ngIf="yalla_goal_flag">           
            <span class="acad-img">
                <img  src="assets/images/yalla_goal_logo.png" style="width:215px;" />
            </span>
        </p> 
        <p class="" style="text-align:center; margin-top:20px; margin-bottom:20px; float:left; width:100%;"
         *ngIf="sports_pad_flag">           
            <span class="acad-img">
                <img  src="assets/images/sportspadIcon.png" style="width:130px;" />
            </span>
        </p>
    
    <div class="md-card" id="forgot_password_reset" style="clear:both;border-radius: 7px;">
       
        <div class="md-card-content large-padding"> 
            <h2 style="margin:0px 0px 20px 0px;" 
            *ngIf="!datastream_flag && !sportz_village_flag && !al_shabab_flag && !yalla_goal_flag && !sports_pad_flag ">{{'forgotPassword.ForgotPassword' | translate}}</h2> 
            <h2 style="margin:0px 0px 20px 0px;color:rgb(112,77,159)"  *ngIf="datastream_flag">{{'forgotPassword.ForgotPassword' | translate}}</h2> 
            <h2 style="margin:0px 0px 20px 0px;color:#026EB0"  *ngIf="sportz_village_flag">{{'forgotPassword.ForgotPassword' | translate}}</h2>     
            <h2 style="margin:0px 0px 20px 0px;color:#026EB0"  *ngIf="al_shabab_flag">{{'forgotPassword.ForgotPassword' | translate}}</h2>  
            <h2 style="margin:0px 0px 20px 0px;color:#568B3F"  *ngIf="yalla_goal_flag">{{'forgotPassword.ForgotPassword' | translate}}</h2>    
            <h2 style="margin:0px 0px 20px 0px;color:#EF7021"  *ngIf="sports_pad_flag">{{'forgotPassword.ForgotPassword' | translate}}</h2>  
            <form class="md-float-material" [formGroup]="forgot_form" (ngSubmit)="onSubmit(forgot_form.value);">
                 <div class="col-md-12">
                    <!-- <div class="uk-alert uk-alert-success" data-uk-alert="" *ngIf="!forgot_form.controls['Email'].valid && forgot_form.controls['Email'].touched">{{ emailAlert }}</div> -->
                    <div *ngIf="alertmsg" style="margin-bottom: 16px;font-size: 13px;color: #3498db;">{{ alertmsg }}</div>
                    <mat-error *ngIf="invalidmsg" style="margin-bottom: 16px;" >{{ 'EnteredEmailMobileNotRegistered' | translate }}</mat-error>
                </div>
                <div class="example-container">                
                    <mat-form-field appearance="outline">    
                        <mat-label>{{'EmailAddress' | translate}} / {{'MOBILE' | translate}}</mat-label>                          
                        <input matInput required formControlName="Email" (keyup)="clearMsg();checkEmailorMobile($event.target.value)" (change)="checkEmailorMobile($event.target.value)" >
                        <mat-error *ngIf="!forgot_form.controls['Email'].valid && !forgot_form.controls['Email'].untouched || formInvalidFlag" style="margin-top: 20px">{{'validEmailMobileErrorMsg' | translate}}</mat-error>                         
                    </mat-form-field>
    
                    <div class="button-row" style="text-align:center; margin-top:30px;">
                        <button mat-raised-button  *ngIf="!datastream_flag && !sportz_village_flag && !al_shabab_flag && !yalla_goal_flag && !sports_pad_flag" style="background-color: #13b8d5;color: aliceblue" type="submit">{{'Submit' | translate}}</button> 
                        <button mat-raised-button *ngIf="datastream_flag" style="background-color: rgb(112,77,159);color: aliceblue" type="submit">{{'Submit' | translate}}</button> 
                        <button mat-raised-button *ngIf="sportz_village_flag" style="background-color: #026EB0;color: aliceblue" type="submit">{{'Submit' | translate}}</button> 
                        <button mat-raised-button *ngIf="al_shabab_flag" style="background-color: #974846;color: aliceblue" type="submit">{{'Submit' | translate}}</button> 
                        <button mat-raised-button *ngIf="yalla_goal_flag" style="background-color: #568B3F;color: aliceblue" type="submit">{{'Submit' | translate}}</button> 
                        <button mat-raised-button *ngIf="sports_pad_flag" style="background-color: #EF7021;color: aliceblue" type="submit">{{'Submit' | translate}}</button> 
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="uk-margin-top uk-text-center" style="clear:both; position:relative; z-index:10;">
        <p class="text-center" style="color:#fff;">
            <a [routerLink]="['/login']" style="color:#fff;" *ngIf="!datastream_flag && !sportz_village_flag
            && !al_shabab_flag && !yalla_goal_flag && !sports_pad_flag">{{'backTo' | translate}} 
                <span style="color:#13b8d5">{{'Login' | translate}}</span>
            </a>
            <a [routerLink]="['/dataStream-login']" style="color:#fff;" *ngIf="datastream_flag">{{'backTo' | translate}}  
                <span style="color:rgb(112,77,159)">{{'Login' | translate}}</span>
            </a>
            <a [routerLink]="['/sportz-village-login']" style="color:#fff;" *ngIf="sportz_village_flag">{{'backTo' | translate}}  
                <span style="color:#13b8d5">{{'Login' | translate}}</span>
            </a>
            <a [routerLink]="['/al-Shabab-login']" style="color:#fff;" *ngIf="al_shabab_flag">{{'backTo' | translate}}  
                <span style="color:#fff">{{'Login' | translate}}</span>
            </a>
            <a [routerLink]="['/yalla-goal-login']" style="color:#fff;" *ngIf="yalla_goal_flag">{{'backTo' | translate}}  
                <span style="color:#568B3F">{{'Login' | translate}}</span>
            </a>
            <a [routerLink]="['/sports-pad-login']" style="color:#fff;" *ngIf="sports_pad_flag">{{'backTo' | translate}}  
                <span style="color:#EF7021">{{'Login' | translate}}</span>
            </a>
        </p>
    </div>
    </div>
</ng-container>
<ng-container *ngIf="sos_login_flag">
    <div class="background-image">
        <img class="img-fluid" src="assets/images/sos-background.png" />        
    </div>    
    <div class="login_page_wrapper forgot-div">
        <p class="" style="text-align:center; margin-bottom:20px; margin-top: 70px; float:left; width:100%;">
            <span class="acad-img"><img class="img-fluid" src="assets/images/sos-logo.png"  style="width:265px;" /></span>    
        </p>
    
    <div class="md-card" id="forgot_password_reset" style="clear:both;border-radius: 0px;background-color: rgb(65,67,66);">
       
        <div class="md-card-content large-padding">
            <h2 style="margin:0px 0px 20px 0px;color: white;" >{{'forgotPassword.forgotPassword' | translate}}</h2>       
            <form class="md-float-material" [formGroup]="forgot_form" (ngSubmit)="onSubmit(forgot_form.value);">
                 <div class="col-md-12">
                    <!-- <div class="uk-alert uk-alert-success" data-uk-alert="" *ngIf="!forgot_form.controls['Email'].valid && forgot_form.controls['Email'].touched">{{ emailAlert }}</div> -->
                    <div *ngIf="alertmsg" style="margin-bottom: 16px;font-size: 13px;color: #3498db;">{{ alertmsg }}</div>
                    <mat-error *ngIf="invalidmsg" style="margin-bottom: 16px;" >{{ invalidmsg }}</mat-error>
                </div>
                <div class="example-container">                
                    <mat-form-field appearance="outline" style="background-color: rgb(51,51,51);">    
                        <mat-label style="color: white;">Email Address / Mobile</mat-label>                          
                        <input matInput style="color: white;" required formControlName="Email" (keyup)="clearMsg();checkEmailorMobile($event.target.value)" (change)="checkEmailorMobile($event.target.value)" >
                        <mat-error *ngIf="!forgot_form.controls['Email'].valid && !forgot_form.controls['Email'].untouched || formInvalidFlag" style="margin-top: 20px;">{{'validEmailMobileErrorMsg' | translate}}</mat-error>                         
                    </mat-form-field>
    
                    <div class="button-row" style="text-align:center; margin-top:35px;">
                        <button mat-raised-button style="background-color: rgb(255,240,43);border-radius: 30px;width: 100%;height: 45px;" type="submit">{{'Submit' | translate}}</button> 
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="uk-margin-top uk-text-center" style="clear:both; position:relative; z-index:10;">
        <p class="text-center" style="color:#fff;"><a [routerLink]="['/sos-login']" style="color:#fff;">{{'backTo' | translate}}  <span style="color:rgb(255,240,43">{{'Login' | translate}}</span></a></p>
    </div>
    </div>
</ng-container>
