import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable()
export class RegistrationService {
    getCountriesList:any=[];
    getStateList:any=[];
    getCitiesList:any=[];
    auth_key:any=[];
    session:any=[];    
    addOrgRespo:any=[];
    emailVerificationStatus=[];
    organization_details: any;
    organization_data: any;
    userData: any;
    sessionData: any;
    customerData: any;
    role_type: string;
    email_exist_detail: any;
    httpOptions;   

  constructor(private http_client: HttpClient) 
  {   
    
  } 

    CountriesList(auth_key,session_id,device_id)
    { 
      this.httpOptions = {
      headers: new HttpHeaders({
      'Content-Type':  'application/x-www-form-urlencoded',     
      // 'auth_key': auth_key,
      // 'device_id': device_id,
      // 'session_id': session_id,
      })
    };

        
        const GetCountriesForSelfRegistration = AppComponent.API_URL + 'selfRegistration/GetCountriesForSelfRegistration';
        return this.http_client.post(GetCountriesForSelfRegistration,'',this.httpOptions)
        .pipe(
            map((data:any) =>{
                return this.getCountriesList = data['countries_list']; 
            }),
        ) 
        
    }
    GetStates(country_id,auth_key,session_id,device_id)
    { 
      this.httpOptions = {
        headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': auth_key,
        // 'device_id': device_id,
        // 'session_id': session_id,
        })
      };

      const  params = new  HttpParams().set('country_id', country_id);      
      const GetStatesForSelfRegistration = AppComponent.API_URL + 'selfRegistration/GetStatesForSelfRegistration';
      return this.http_client.post(GetStatesForSelfRegistration,params,this.httpOptions)
      .pipe(
            map((data:any) =>{
                 return this.getStateList = data['states_list'];  
            }),
        )
    }
    GetCities(country_id,state_id,auth_key,session_id,device_id)
    { 
        const  params = new  HttpParams().set('country_id', country_id).set('state_id', state_id); 

        this.httpOptions = {
          headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded',     
          // 'auth_key': auth_key,
          // 'device_id': device_id,
          // 'session_id': session_id,
          })
        };
        const GetCitiesForSelfRegistration = AppComponent.API_URL + 'selfRegistration/GetCitiesForSelfRegistration';
        return this.http_client.post(GetCitiesForSelfRegistration,params,this.httpOptions)
        .pipe(
            map((data:any) =>{
                this.getCitiesList = data['cities_list'];                        
            return this.getCitiesList;
            }),
        )  
        
    }
    generateGuestAuthKey(guest_name)
    { 
        const  params = new  HttpParams().set('guest_name', guest_name);   
       
        const url = AppComponent.API_URL + 'selfRegistration/GenerateGuestAuthKey';
        return this.http_client.post(url,params,this.httpOptions)
        .pipe(
            map((data:any) =>{
                return this.auth_key = data['auth_key'];  
            }),
        )        
    } 
    generateSession(auth_key,session_id,device_id)
    {         
         this.httpOptions = {
          headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded',     
          'auth_key': auth_key,
          'device_id': device_id,
          'session_id': session_id,
          })
        };
       
        const url = AppComponent.API_URL + 'selfRegistration/Session';
        return this.http_client.post(url,'',this.httpOptions)
        .pipe(
            map((data:any) =>{
                return this.session = data['id']; 
            }),
        ) 
        
    } 

    addSelfRegisteredOrganization(auth_key,session_id,device_id, organization_name,org_image,address_line1,address_line2,country_id,state_id,city_id,first_name,last_name,mobile_number,email_address,org_thumbnail_image)
    {         
        this.httpOptions = {
          headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded',     
          // 'auth_key': auth_key,
          // 'device_id': device_id,
          // 'session_id': session_id,
          })
        };

        let params = new HttpParams();   
        params = params.append('organization_name', organization_name );
        params = params.append('org_image', org_image );
        params = params.append('address_line1', address_line1 );
        params = params.append('address_line2', address_line2 );
        params = params.append('country_id', country_id );
        params = params.append('state_id', state_id );
        params = params.append('city_id', city_id );
        params = params.append('first_name', first_name );
        params = params.append('last_name', last_name );
        params = params.append('mobile_number', mobile_number );
        params = params.append('email_address', email_address );
        params = params.append('org_thumbnail_image', org_thumbnail_image );

       const url = AppComponent.API_URL + 'selfRegistration/AddSelfRegisteredOrganization';
        return this.http_client.post(url,params,this.httpOptions)
        .pipe(
            map((data:any) =>{
                return this.addOrgRespo = data['organization_id'];
                
            }),
        ) 
        
    }

     emailVerificationForSelfRegistration(guest_org_id)
    {         
             
        let params = new HttpParams();   
        params = params.append('guest_org_id', guest_org_id);
        const url = AppComponent.API_URL + 'selfRegistration/EmailVerificationForSelfRegistration';
        return this.http_client.post(url,params,this.httpOptions)
        .pipe(
            map((data:any) =>{
                return this.emailVerificationStatus = data['email_verification_details']; 
            }),
        )  
        
    }

     addSelfRegisteredOrganizationAfterVerification(auth_key,session_id,device_id,guest_org_id,password)
    {         
        
        this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded',     
          // 'auth_key': auth_key,
          // 'device_id': device_id,
          // 'session_id': session_id,
          })
        };

        let params = new HttpParams();   
        params = params.append('guest_org_id', guest_org_id);
        params = params.append('password', password);
        const url = AppComponent.API_URL + 'selfRegistration/AddSelfRegisteredOrganizationAfterVerification';
        return this.http_client.post(url,params,this.httpOptions)
        .pipe(
            map((data:any) =>{
                return this.organization_details = data['organization_details'];
                
            }),
        ) 
        
    } 


     checkCredentialsV2(email, password, loginflag)
  { 
    let params = new HttpParams(); 
    if (loginflag == 'e') 
    {
      const  params = new  HttpParams().set('email_address', email)
      .set('password', password)
      .set('login_flag', loginflag);
      
    }
    else if (loginflag == 'm') 
    {
      const  params = new  HttpParams().set('mobile_number', email)
                                        .set('password', password)
                                        .set('login_flag', loginflag);
      var body = `mobile_number=${email}&password=${password}&login_flag=${loginflag}`;
    }   
    const local_authetication_url = AppComponent.API_URL + 'authentication/AuthenticateUser';
    return this.http_client.post(local_authetication_url, params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.userData = data;       
                if (this.userData.length != 0) {
                  return this.userData;
                }
                else {
                  return '';
                }
            }),
        )
  }

   setSession(auth_key, session_id, device_id)
  {    
    if (auth_key != '' && session_id != '' && device_id != '') 
    {     
      localStorage.setItem("auth_key", auth_key);
      localStorage.setItem("session_id", session_id);
      localStorage.setItem("device_id", device_id);

      const local_session_url = AppComponent.API_URL + 'authentication/Session';
      return this.http_client.post(local_session_url, '')
      .pipe(
            map((data:any) =>{
                this.sessionData = data;
                if (this.sessionData.id) 
                {
                  return true;
                }
                else 
                {
                  return false;
                }
            }),
        )  
        }
  }

  checkAuthorization(customer_id)
  {
    const  params = new  HttpParams().set('customer_id', customer_id);
    localStorage.setItem('customer_id', '' + customer_id);     
    const local_authorization_url = AppComponent.API_URL + 'authorization/Authorization';
    return this.http_client.post(local_authorization_url, params)
    .pipe(
            map((data:any) =>{
                this.customerData = data;    
        localStorage.setItem('UserRole', JSON.stringify(this.customerData));
        for (var i = 0; i < this.customerData['User Role'].length; i++) {
          if (this.customerData['User Role'][i].role_type == "H") 
          {
            this.role_type = "H";
            break;
          }
          else if (this.customerData['User Role'][i].role_type == "A") 
          {
            this.role_type = "A";
            break;
          }
          else 
          {
            continue;
          }
        }
        if (this.role_type == "H") 
        {
          localStorage.setItem('Flag', '' + this.role_type);
          return true;
        }

        else if (this.role_type == "A") 
        {
          localStorage.setItem('Flag', '' + this.role_type);
          return true;
        }
        else
        {
          return false;
        }
            }),
        )
  }


    getOrganizationList(CustomerId)
  {    
    let params = new HttpParams();  
    params = params.append('customer_id', CustomerId );
    params = params.append('app_type', '000' );

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        })
    };

    const local_authetication_url=AppComponent.API_URL+'organization/GetOrganizationsList';
    return this.http_client.post(local_authetication_url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.organization_data =data['Academy List'];
                if(this.organization_data.length!=0)
                {         
                  return this.organization_data;
                }
                else
                  {
                    return '';
                  }
            }),
        );
  }  

  CheckEmailIsAlreadyExist(email_address,auth_key,session_id,device_id)
  { 
    let params = new HttpParams();  
    params = params.append('email_address', email_address );  

    this.httpOptions = {
      headers: new HttpHeaders({
      'Content-Type':  'application/x-www-form-urlencoded',     
      // 'auth_key': auth_key,
      // 'device_id': device_id,
      // 'session_id': session_id,
      })
    };
    
    const url = AppComponent.API_URL + 'selfRegistration/CheckEmailIsAlreadyExist';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.email_exist_detail =data['email_exist'];                       
                  return this.email_exist_detail;               
            }),
        )
    
  }
}
