import { ReportEngineServices } from './../assessment/report-engine/report-engine.service';
import { forEach } from '@angular/router/src/utils/collection';
import { DateAdapter, MAT_DATE_FORMATS, MatDatepickerInputEvent, MatDialog, MatDialogRef, MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ExpenseService } from './expense.service';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { APP_DATE_FORMATS, AppDateAdapter } from '../date-formate';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-expense',
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.css'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class ExpenseComponent implements OnInit {
  organization_id: any;
  request_list: any;

  parent_academy_flag: boolean;
  request_id: any;
  request_details: any[];
  status: any='';
  rejection_reason: string;
  reason_div_flag: boolean = true;
  button_flag: boolean = true;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  setAutoHide: boolean = true;
  autoHide: number = 3000;
  actionButtonLabel: string = 'Close';
  action: boolean = true;
  success_msg: string;

  staff_id: any;
  spinner_div: any;
  staff_customer_id: any;
  customer_id: any;
  staff_data: any = [];
  show_data: boolean;
  request_status: any;
  image_path: '';
  today = new Date();
  from_date: any = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 30);
  to_date = this.today;
  date_format: string;
  org_est_date: Date;

  imagePath: any;
  button_background_color: string;
  button_text_color: string;
  header_background: string;
  header_text_color: string;
  main_text_color: string;
  rejection_form: any;
  maxToDate: Date;
  currency: any;
  pending_expenses: any = '0';
  pending_amount: any;
  role_type: any;
  sanitizedUrl: any;
  dialogRef: MatDialogRef<any>;
  approved_expense: any='0';
  approved_amount: any;
  rejected_expense: any='0';
  rejected_amount: any;
  expense_details: any;

  constructor(private expense_service: ExpenseService, public dialog: MatDialog, public snackBar: MatSnackBar, private fb: FormBuilder, private sanitizer: DomSanitizer) {
    this.organization_id = sessionStorage.getItem('OrganizationID');
    this.org_est_date = new Date(sessionStorage.getItem('org_est_date'));
    this.date_format = sessionStorage.getItem('date_format');
    this.currency = sessionStorage.getItem('currency');
    this.button_background_color = sessionStorage.getItem('button_background_color');
    this.button_text_color = sessionStorage.getItem('button_text_color');
    this.header_background = sessionStorage.getItem('header_background');
    this.header_text_color = sessionStorage.getItem('header_text_color');
    this.main_text_color = sessionStorage.getItem('main_text_color');
  }

  ngOnInit() {
    this.getExpenseRequestList();
    this.getStaffList();
  }

  @ViewChild('expenseDetails') expenseDetails: TemplateRef<any>;
  @ViewChild('receipt') receipt: TemplateRef<any>;
  @ViewChild('rejectionReason') rejectionReason: TemplateRef<any>;

  getExpenseRequestList() {
    this.spinner_div = "block";
    {
      this.expense_service.getExpenseRequestList(this.organization_id, this.status, this.staff_id, this.from_date, this.to_date, this.date_format).subscribe(
        response => {
          if (response != '') {
            this.request_list = response;
            this.spinner_div = "none";
            this.getPendingExpenseDetails();
          }
          else {
            this.request_list = 'Request list not found';
            this.spinner_div = "none";
          }
        });
    }
  }

  getExpenseRequestDetails(req_id) {
    this.request_id = req_id;
    this.expense_service.getExpenseRequestDetails(this.organization_id, this.request_id).subscribe(
      response => {
        if (response != '') {
          this.request_details = response;
          this.request_details.forEach(item => {
            item.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(item.image_path);
          });
        }
        else {
          this.request_details = [];
        }
      });
    this.dialogRef = this.dialog.open(this.expenseDetails, {
      width: '410px',
      disableClose: true,
    });
  }

  isImage(path: string): boolean {
    return path && path.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  isPDF(path: string): boolean {
    return path && path.toLowerCase().endsWith('.pdf');
  }

  updateExpenseRequestStatus(status: any) {
    this.expense_service.updateExpenseRequestStatus(this.request_id, status, this.rejection_reason).subscribe(
      response => {
        if (response == true) {
          this.success_msg = "Expense status has been Updated";
          this.snackBarDisplay();
          this.getExpenseRequestList();
        }
        else {
          this.getExpenseRequestList();
        }
      }
    )
  }

  getPendingExpenseDetails() {
    this.expense_service.getPendingExpenseDetails(this.status, this.organization_id, this.staff_id,this.from_date,this.to_date,this.date_format).subscribe(
      response => {
        if (response != '') {
          this.expense_details=response;
          this.expense_details['data']['Submitted'].forEach(element => {     
            this.pending_expenses = element['total_pending_expenses'];         
            this.pending_amount = element['total_pending_amount'];
          });
         this.expense_details['data']['Approved'].forEach(element => {
            this.approved_expense=element['total_approved_expenses'];
            this.approved_amount=element['total_approved_amount'];
          });
          this.expense_details['data']['Rejected'].forEach(element => {
            this.rejected_expense=element['total_rejected_expenses'];
            this.rejected_amount=element['total_rejected_amount'];
          });
        }
      }
    )
  }

  getStaffList() {
    this.spinner_div = "block";
    this.expense_service.getStaffDetails(this.organization_id, this.role_type).subscribe(
      response => {
        this.spinner_div = "none";
        if (response != '') {
          this.staff_data = response;
        }
        else {
          this.staff_data = [];
        }
      });
  }

  selectedStatus(value) {
    if (value == 'Rejected') {
      this.onEnterRejectionReason(this.rejection_reason);
    }
    else {
      this.updateExpenseRequestStatus(value);
    }
  }

  onEnterRejectionReason(rejection_reason: string) {
    this.rejection_reason = rejection_reason
    this.dialog.open(this.rejectionReason, {
      width: '410px',
      disableClose: true,
    });
  }

  enlargeImage(imagePath: string) {
    this.imagePath = imagePath;
    this.dialog.open(this.receipt, {
      width: '410px',
      disableClose: true,
    });
  }

  snackBarDisplay() {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    this.snackBar.open(this.success_msg, this.action ? this.actionButtonLabel : undefined, config);
  }

  export_to_csv() {
    var headers = ['Requested By', 'Expense Date', 'Merchant Name', 'Amount(' + this.currency + ')', 'Description '];
    var data = [];

    this.request_list.forEach(element => {

      data.push({
        requested_by: element.requested_by,
        expense_date: element.expense_date,
        merchant_name: element.merchant_name,
        amount: element.amount,
        description: element.description
      })
    });
    new Angular5Csv(data, 'Expense Report Data', { headers: headers });
  }

  viewAttachment(imagePath: any) {
    window.open(imagePath, '_blank');
  }

  closePopup() {
    this.dialogRef.close();

  }

}
