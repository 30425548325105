import { Injectable } from '@angular/core';
import {AppComponent} from '../../app.component';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable()
export class OrganizationDetailsService {
organization_data:any;
center_data:any;
center_id:string;
organization_id:string;
organization_details_data:any;
organization_preferences:any;
report_acl:any;
notification_list:any;
notification_acl_details:any;
notification_acl_status:any;
httpOptions;  
  unread_message_count: any;
  updateCustomerNotification: string;

constructor(private http_client: HttpClient) { 

 
}

  getOrganizationList(CustomerId)
  {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
      };
    const  params = new  HttpParams().set('customer_id', CustomerId);    
   
    const local_authetication_url=AppComponent.API_URL+'organization/GetOrganizationsList';
    return this.http_client.post(local_authetication_url,params,this.httpOptions)

    .pipe(
          map((data:any) =>{
            this.organization_data =data['Academy List'];
            if(this.organization_data.length!=0)
            {         
              return this.organization_data;
            }
            else
              {
                return '';
              }
          }),
        )
  }
  getOrganizationDetails(Organization_Id)
  {  
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
      };
    const  params = new  HttpParams().set('organization_id', Organization_Id);  
   
    const local_authetication_url=AppComponent.API_URL+'organization/GetOrganizationDetails';
    return this.http_client.post(local_authetication_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.organization_details_data =data['data']['academy_details'];
            if(this.organization_details_data.length!=0)
            {         
              return this.organization_details_data;
            }
            else
              {
                return '';
              }
          }),
        )
  }

  getOrganizationPreferences(Organization_Id)
  {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
      };
    const  params = new  HttpParams().set('organization_id', Organization_Id);    
   
    const local_authetication_url=AppComponent.API_URL+'/preferences/Getpreferences';
    return this.http_client.post(local_authetication_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.organization_preferences =data['Preferences details'];
            if(this.organization_preferences.length!=0)
            {         
              return this.organization_preferences;
            }
            else
              {
                return '';
              }
          }),
        ) 
  }
  GetAccessControleOfReport(Organization_Id)
  {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
      };
    const  params = new  HttpParams().set('organization_id', Organization_Id);    
    
    const local_authetication_url=AppComponent.API_URL+'/entitlement/GetAccessControleOfReport';
    return this.http_client.post(local_authetication_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.report_acl =data['report_acl'];
            if(this.report_acl.length!=0)
            {         
              return this.report_acl;
            }
            else
              {
                return '';
              }
          }),
        )      

  }
  getCustomerNotificationList(customer_id,organization_id)
  {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
      };
    const  params = new  HttpParams().set('organization_id', organization_id).set('customer_id', customer_id);    
  
    const local_getNotification_url=AppComponent.API_URL+'/messaging/GetCustomerNotificationList';
    return this.http_client.post(local_getNotification_url,params,this.httpOptions)
    
    .pipe(
          map((data:any) =>{
            this.notification_list =data['customer_notification_List'];
            if(this.notification_list.length!=0)
            {         
              return this.notification_list;
            }
            else
              {
                return '';
              }
          }),
        )
  }

  getAccessControleOfNotification(organization_id)
  {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
      };
    const  params = new  HttpParams().set('organization_id', organization_id);    
   
    const local_getNotificationcontrol_url=AppComponent.API_URL+'/entitlement/GetNotificationAccessControlDetails';
    return this.http_client.post(local_getNotificationcontrol_url,params,this.httpOptions)

    .pipe(
            map((data:any) =>{
                this.notification_acl_details =data['notification_acl_details'];
                if(this.notification_acl_details.length!=0)
                {         
                  return this.notification_acl_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }

  setAccessControleOfNotification(organization_id,birthday_notification,outstanding_payments_notification,
    payment_received_notification,invite_to_download_app_notification,news_notification,
    cheque_status_change_notification,customer_id,birthday_notification_academy_cc,birthday_notification_parent_cc,
    outstanding_payments_notification_academy_cc,outstanding_payments_notification_parent_cc,pre_payment_due_day,
    post_payment_due_day,outstanding_payments_notification_before,outstanding_payments_notification_after,
    payment_received_notification_academy_cc,payment_received_notification_parent_cc,invite_to_download_app_academy_cc,
    invite_to_download_app_parent_cc,news_notification_academy_cc,news_notification_parent_cc,cheque_status_change_academy_cc,
    cheque_status_change_parent_cc,birthday_template_content,cheque_status_template_content,news_template_content,
    pre_payment_outstanding_template_content,post_payment_outstanding_template_content,payment_received_template_content,
    thank_you_template_content,payment_received_attachment,notification_setup_id,payment_received_notification_attachment,
    calendar_task_notification,calendar_task_daily_content,calendar_task_weekly_content,
    calendar_task_notification_weekely_academy_cc,calendar_task_notification_weekely_parent_cc,
    calendar_task_notification_daily_academy_cc,calendar_task_notification_daily_parent_cc,  
    
    attendance_notification,deregistration_notification,
    staff_rating_notification,batch_capacity_notification,payment_due_extension_notification,
    attendance_notification_academy_cc,attendance_notification_parent_cc,calendar_task_notification_daily
    ,calendar_task_notification_weekely,deregistration_notification_academy_cc,
    deregistration_notification_parent_cc,staff_rating_notification_academy,staff_rating_notification_parent,
    attendance_content,deregistration_content,batch_capacity_content,
    payment_due_date_extension_content,staff_rating_academy_content,staff_rating_parent_content,
    subscription_expiry_notification_academy_cc,subscription_expiry_notification_parent_cc,
    subscription_expiry_notification_before_days,subscription_expiry_notification_after_days,
    subscription_expiry_notification_before,subscription_expiry_notification_after,
    subscription_expiry_before_days_content,subscription_expiry_after_days_content,subscription_expiry_notification,
    invite_to_download_app_notification_for_std_parent,invite_to_download_app_notification_for_staff,
    invite_to_download_app_notification_for_std_parent_academy_cc,invite_to_download_app_notification_for_std_parent_parent_cc,
    invite_to_download_app_notification_for_staff_academy_cc,invite_to_download_std_parent_template_content,invite_to_download_staff_template_content,
    self_registration_notification,self_registration_email_notification_to_admin,self_registration_email_notification_to_player,
    self_registration_mobile_notification_to_admin,
    self_registration_email_admin_template_content,
    self_registration_mobile_admin_template_content,
    self_registration_email_player_template_content
    )
  {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
      };
    
    let  params = new  HttpParams().set('organization_id', organization_id)
                                      .set('birthday_notification', birthday_notification)
                                      .set('outstanding_payments_notification', outstanding_payments_notification)
                                      .set('payment_received_notification', payment_received_notification)
                                      .set('invite_to_download_app_notification', invite_to_download_app_notification)
                                      .set('news_notification', news_notification)
                                      .set('cheque_status_change_notification', cheque_status_change_notification)
                                      .set('customer_id', customer_id)
                                      .set('birthday_notification_academy_cc', birthday_notification_academy_cc) 
                                      .set('birthday_notification_parent_cc', birthday_notification_parent_cc)
                                      .set('outstanding_payments_notification_academy_cc', outstanding_payments_notification_academy_cc) 
                                      .set('outstanding_payments_notification_parent_cc', outstanding_payments_notification_parent_cc) 
                                      .set('outstanding_payments_notification_before_days', pre_payment_due_day) 
                                      .set('outstanding_payments_notification_after_days', post_payment_due_day) 
                                      .set('outstanding_payments_notification_before', outstanding_payments_notification_before) 
                                      .set('outstanding_payments_notification_after', outstanding_payments_notification_after)
                                      .set('payment_received_notification_academy_cc', payment_received_notification_academy_cc) 
                                      .set('payment_received_notification_parent_cc', payment_received_notification_parent_cc) 
                                      .set('invite_to_download_app_academy_cc', invite_to_download_app_academy_cc) 
                                      .set('invite_to_download_app_parent_cc', invite_to_download_app_parent_cc) 
                                      .set('news_notification_academy_cc', news_notification_academy_cc)
                                      .set('news_notification_parent_cc', news_notification_parent_cc) 
                                      .set('cheque_status_change_academy_cc', cheque_status_change_academy_cc)
                                      .set('cheque_status_change_parent_cc', cheque_status_change_parent_cc)
                                      .set('payment_received_notification_attachment', payment_received_notification_attachment)                                                              
                                      .set('notification_setup_id', notification_setup_id)
                                      .set('calendar_task_notification',calendar_task_notification)
                                      .set('calendar_task_notification_weekely_academy_cc', calendar_task_notification_weekely_academy_cc)
                                      .set('calendar_task_notification_weekely_parent_cc', calendar_task_notification_weekely_parent_cc)
                                      .set('calendar_task_notification_daily_academy_cc', calendar_task_notification_daily_academy_cc)
                                      .set('calendar_task_notification_daily_parent_cc', calendar_task_notification_daily_parent_cc)
                                      
                                      
                                      .set('attendance_notification', attendance_notification) 
                                      .set('deregistration_notification', deregistration_notification) 
                                      .set('staff_rating_notification', staff_rating_notification)
                                      .set('batch_capacity_notification', batch_capacity_notification) 
                                      .set('payment_due_extension_notification', payment_due_extension_notification)
                                      .set('attendance_notification_academy_cc', attendance_notification_academy_cc)
                                      .set('attendance_notification_parent_cc', attendance_notification_parent_cc)                                                              
                                      .set('calendar_task_notification_daily', calendar_task_notification_daily)
                                      .set('calendar_task_notification_weekely',calendar_task_notification_weekely)
                                      .set('deregistration_notification_academy_cc', deregistration_notification_academy_cc)
                                      .set('deregistration_notification_parent_cc', deregistration_notification_parent_cc)
                                      .set('staff_rating_notification_academy', staff_rating_notification_academy)
                                      .set('staff_rating_notification_parent', staff_rating_notification_parent)
                                      .set('subscription_expiry_notification_academy_cc', subscription_expiry_notification_academy_cc)
                                      .set('subscription_expiry_notification_parent_cc',subscription_expiry_notification_parent_cc)
                                      .set('subscription_expiry_notification_before_days', subscription_expiry_notification_before_days)
                                      .set('subscription_expiry_notification_after_days', subscription_expiry_notification_after_days)
                                      .set('subscription_expiry_notification_before', subscription_expiry_notification_before)
                                      .set('subscription_expiry_notification_after', subscription_expiry_notification_after)
                                      .set('subscription_expiry_notification', subscription_expiry_notification)
                                      
                                      .set('invite_to_download_app_notification_for_std_parent', (invite_to_download_app_notification_for_std_parent==true?'1':'0'))
                                      .set('invite_to_download_app_notification_for_staff', (invite_to_download_app_notification_for_staff==true?'1':'0'))
                                      .set('invite_to_download_app_notification_for_std_parent_academy_cc', (invite_to_download_app_notification_for_std_parent_academy_cc==true?'1':'0'))
                                      .set('invite_to_download_app_notification_for_std_parent_parent_cc', (invite_to_download_app_notification_for_std_parent_parent_cc==true?'1':'0'))
                                      .set('invite_to_download_app_notification_for_staff_academy_cc', (invite_to_download_app_notification_for_staff_academy_cc==true?'1':'0'))
                                      
                                      .set('self_registration_notification', self_registration_notification)
                                      .set('self_registration_email_notification_to_admin', self_registration_email_notification_to_admin)
                                      .set('self_registration_email_notification_to_player', self_registration_email_notification_to_player)
                                      .set('self_registration_mobile_notification_to_admin', self_registration_mobile_notification_to_admin);
                                   
                                      var email_template_array=[];
                                      email_template_array.push({id:'birthday',template:birthday_template_content},
                                                                {id:'cheque_status',template:cheque_status_template_content},
                                                                {id:'news',template:news_template_content},
                                                                {id:'payment_outstanding_before_days',template:pre_payment_outstanding_template_content},
                                                                {id:'payment_outstanding_after_days',template:post_payment_outstanding_template_content},
                                                                {id:'payment_received',template:payment_received_template_content},
                                                                {id:'thank_you',template:thank_you_template_content},
                                                                {id:'payment_received_attachment',template:payment_received_attachment},
                                                                {id:'calendar_task_daily',template:calendar_task_daily_content},
                                                                {id:'calendar_task_weekly',template:calendar_task_weekly_content},                                                                
                                                                
                                                                {id:'attendance',template:attendance_content},
                                                                {id:'deregistration',template:deregistration_content},
                                                                {id:'batch_capacity',template:batch_capacity_content},
                                                                {id:'payment_due_extension',template:payment_due_date_extension_content},
                                                                {id:'staff_rating_academy',template:staff_rating_academy_content},
                                                                {id:'staff_rating_parent',template:staff_rating_parent_content},
                                                                {id:'subscription_expiry_before_days',template:subscription_expiry_before_days_content},
                                                                {id:'subscription_expiry_after_days',template:subscription_expiry_after_days_content},
                                                                {id:'invite_to_download_app_notification_for_std_parent',template:invite_to_download_std_parent_template_content},
                                                                {id:'invite_to_download_app_notification_for_staff',template:invite_to_download_staff_template_content},
                                                                
                                                                {id:'self_registration_email_notification_to_admin',template:self_registration_email_admin_template_content},
                                                                {id:'self_registration_email_notification_to_player',template:self_registration_email_player_template_content},
                                                                {id:'self_registration_mobile_notification_to_admin',template:self_registration_mobile_admin_template_content} );

                                                               
                                                                
    
                                      email_template_array.forEach((element,i) => {
                                        if(element.id=='payment_received_attachment')
                                        {
                                          if(payment_received_notification_attachment=='1')
                                          {
                                            params = params.append('email_template_name['+i+']', element.id);
                                            params = params.append('template_content['+i+']', element.template); 
                                          }
                                        }
                                        else{
                                          params = params.append('email_template_name['+i+']', element.id);
                                          params = params.append('template_content['+i+']', element.template); 
                                        }
                                        
                                        
                                      });
                                      

    const local_getNotificationcontrol_url=AppComponent.API_URL+'/entitlement/SetNotificationAccessControlDetails';
    return this.http_client.post(local_getNotificationcontrol_url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.notification_acl_status =data['notification_acl_status'];
                if(this.notification_acl_status.length!=0)
                {         
                  return this.notification_acl_status;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }

  getChildOrganizations(login_customer_id,academy_parent_id)
  {    
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
      };
    const  params = new  HttpParams().set('login_user_id', login_customer_id)
                                    .set('academy_parent_id', academy_parent_id);    
    const local_playerlist_url = AppComponent.API_URL + 'parent_child_academy/GetChildOrganizations';  
    return this.http_client.post(local_playerlist_url, params,this.httpOptions)
    .pipe(
            map((data:any) =>{
             return data
            }),
        )
  }

  getOrganizationModulesStatus(login_user_id,role,organization_id){
    const params = new HttpParams().set("login_user_id", login_user_id)
                                    .set("role", role)
                                    .set("organization_id", organization_id);
    const url =AppComponent.API_URL +"entitlement_granularization/GetOrganizationModulesStatus";
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  getOrganizationListToTransferPlayer(organization_id)
  {    
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',
        'Authorization':''+ localStorage.getItem("access_token")
        })
      };
    const  params = new  HttpParams().set('organization_id', organization_id);    
    const local_playerlist_url = AppComponent.API_URL + 'parent_child_academy/GetOrganizationListToTransferPlayer';  
    return this.http_client.post(local_playerlist_url, params,this.httpOptions)
    .pipe(
            map((data:any) =>{
             return data
            }),
        )
  }
  getCustomerUnreadMsgCount(customer_id,organization_id){
    let params=new HttpParams();
    params=params.append('customer_id',customer_id);
    params=params.append('organization_id',organization_id);

    const url=AppComponent.API_URL+'messaging/GetCustomerUnreadMsgCount';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(
      map((data:any)=>{
        this.unread_message_count=data['unread_message_count'];
       if(this.unread_message_count!=''){
        return this.unread_message_count;
       }
      })
    )

}


}
