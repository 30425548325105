import { Injectable } from '@angular/core';
import {LoginService} from '../login_services/login.service';
import {AppComponent} from '../../app.component';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Md5 } from 'ts-md5';
import { ApiUrlConstant } from '../../../app/apiUrl.constant';

@Injectable()
export class ResetpasswordService 
{
  reset_password_data:any;
  httpOptions;  

  constructor(private http_client: HttpClient,private login_service:LoginService) { }

  resetPassword(auth_key,session_id,device_id,email,password,login_flag,set_password_token)
  {
    
    let params = new HttpParams(); 
    const md5 = new Md5();    
    let md5_password:any;
    md5_password=md5.appendStr(password).end();
    
    if (login_flag == 'e') 
      {
        
        params =params.append('email_address', email);     
      }
      else if (login_flag == 'm') 
      {
        params =params.append('mobile_number', email);
      }
      params =params.append('password', md5_password.toString()); 
      params =params.append('login_flag', login_flag);
      params =params.append('set_password_token', set_password_token);
    
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded',     
          // 'auth_key': '' + localStorage.getItem("auth_key"),
          // 'device_id': '' + localStorage.getItem("device_id"),
          // 'session_id': '' + localStorage.getItem("session_id"),
          'Authorization':''+ localStorage.getItem("access_token")
          })
      };
    const local_authetication_url=AppComponent.API_URL+ApiUrlConstant.SetPassword;
    return this.http_client.post(local_authetication_url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{              
                
                  return data;
               
            }),
        )    
  }
}
