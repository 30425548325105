import { Injectable } from '@angular/core';
import {HttpRequest,HttpHandler,HttpEvent,HttpInterceptor, HttpResponse, HttpErrorResponse, HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Router } from '@angular/router';
import { delayWhen, map, retry, retryWhen, tap } from 'rxjs/operators';
import { AppComponent } from './app.component';
import {MatDialog} from '@angular/material';

@Injectable()
export class TokenInterceptor implements HttpInterceptor { 
  httpOptions: { headers: any; }; 
  
  constructor(private router: Router,private http_client: HttpClient,public dialog: MatDialog) {
 
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    
    request = request.clone({
      // setHeaders: {
      //   'auth_key':  localStorage.getItem("auth_key'),
      //   'device_id':  localStorage.getItem("device_id'),
      //   'session_id': localStorage.getItem("session_id'),
      //   'Content-Type':'application/x-www-form-urlencoded'
      // }
    });
  
    return next.handle(request).pipe()
    .map((event: HttpEvent<any>) => {
      
      if (event instanceof HttpResponse && ~~(event.status / 100) > 3) {
        // console.info('HttpResponse::event =', event, ';');
      }         
      if(event instanceof HttpResponse){  
        return event;       
      }else {
        return event;
      }
      
    })
   
    .catch((err: any, caught) => {
      if (err instanceof HttpErrorResponse) {
        
        if (err.status === 401) {
          
          if(err['error']['data'].code=='ACCESS_TOKEN_EXPIRED'){   
             var req1=request;              
            this.refreshToken();      
              req1 = req1.clone({
                setHeaders: {
                  'Authorization':''+ localStorage.getItem("access_token")
                }
              });
              return next.handle(req1);
          }
          if(err['error']['data'].code=='REFRESH_TOKEN_EXPIRED'){      
            this.dialog.closeAll();      
            this.router.navigate(['']);
          }
          if(err['error']['data'].code=='INVALID_ACCESS_TOKEN'){      
            this.dialog.closeAll();      
            this.router.navigate(['']);
          }
        }
        return Observable.throw(err);
      }
    });    
    
  }
  refreshToken(){
    this.refreshTokenApi().subscribe(
      response=>
      {     
        if(response['status']==200)
        {               
          localStorage.setItem('access_token', response['data']['access_token']);
          localStorage.setItem('refresh_token', response['data']['refresh_token']);
          
        }              
        else
        {
              
        }
      });
  }
  refreshTokenApi(){ 
    
    this.httpOptions = {
      headers: new HttpHeaders({
      'Content-Type':  'application/x-www-form-urlencoded',     
      // 'auth_key': '' + localStorage.getItem("auth_key"),
      // 'device_id': '' + localStorage.getItem("device_id"),
      // 'session_id': '' + localStorage.getItem("session_id"),
      'Authorization':''+ localStorage.getItem("access_token")
      })
    };

    const  params = new  HttpParams().set('refresh_token', localStorage.getItem("refresh_token"))
                                     .set('customer_id', localStorage.getItem("customer_id"))
                                     .set('organization_id', sessionStorage.getItem("OrganizationID"))
                                     .set('role_type', localStorage.getItem("Flag"));
    const local_playerlist_url = AppComponent.API_URL + 'authentication/GetAccessToken';  
    return this.http_client.post(local_playerlist_url, params,this.httpOptions)
    .pipe(
    map((data:any) =>{               
      return data;     
    }));
  }
}