import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  sos_login_flag: string;
  datastream_flag: string;
  mahd_flag: string;
  sportz_village_flag: string;
  al_hilal_flag: string;
  al_shabab_flag: string;
  yalla_goal_flag: string;
  sports_pad_flag: boolean;


  constructor(private router: Router) {
    this.sos_login_flag = sessionStorage.getItem('sos_login_flag');
    this.datastream_flag = sessionStorage.getItem('datastream_flag');
    this.mahd_flag = sessionStorage.getItem('mahd_flag');
    this.sportz_village_flag = sessionStorage.getItem('sportz_village_flag');
    this.al_hilal_flag = sessionStorage.getItem('al_hilal_flag');
    this.al_shabab_flag = sessionStorage.getItem('al_shabab_flag');
    this.yalla_goal_flag = sessionStorage.getItem('yalla_goal_flag');
    this.sports_pad_flag = sessionStorage.getItem('sports_pad_flag')=='Y'?true:false;
   }


  ngOnInit() {
    sessionStorage.clear();
    localStorage.clear();
    //this._cookieService.removeAll();  
      
    if(this.sos_login_flag=='Y'){
      this.router.navigate(['sos-login']);
    }
    if(this.datastream_flag=='Y'){
      this.router.navigate(['dataStream-login']);
    }
    if(this.mahd_flag=='Y'){
      this.router.navigate(['mahd-login']);
    }
    if(this.sportz_village_flag=='Y'){
      this.router.navigate(['sportz-village-login']);
    }
    if(this.al_hilal_flag=='Y'){
      this.router.navigate(['al-hilal-login']);
    }
    if(this.al_shabab_flag=='Y'){
      this.router.navigate(['al-Shabab-login']);
    }
    if(this.yalla_goal_flag=='Y'){
      this.router.navigate(['yalla-goal-login']);
    }
    if(this.yalla_goal_flag=='Y'){
      this.router.navigate(['yalla-goal-login']);
    }
    if(this.sports_pad_flag){
      this.router.navigate(['sports-pad-login']);
    }
    
    else{
      this.router.navigate(['']);
    }
    
  }

}
