<div class="button-row add-button-common" *ngIf="allow_admin_to_add_child_academy">
    <button mat-fab id="addRow" title="Add Child Academy" (click)="addAcademyDialog()"
        style="background-color: #13b8d5;color: white;"><mat-icon>add</mat-icon></button>
</div>

<div class="login_page_wrapper org-div">
    <div class="background-image"
        *ngIf="!child_flag && !mahd_flag && !sportz_village_flag && !yalla_goal_flag && !sports_pad_flag">
        <img class="img-fluid" src="assets/images/bg-1.jpg" alt="Acadware" />
    </div>
    <div class="background-image sportz-village"
        *ngIf="!child_flag && sportz_village_flag && !yalla_goal_flag && !sports_pad_flag" style="background: white;">
        <img class="img-fluid1" src="assets/images/SVLogin.png" alt="Acadware" />
    </div>
    <div class="background-image"
        *ngIf="!child_flag && mahd_flag && !sportz_village_flag && !yalla_goal_flag && !sports_pad_flag"
        style="background: white;">
        <img class="img-fluid1" src="assets/images/MahdLogin.png" alt="Acadware" />
    </div>
    <div class="background-image"
        *ngIf="!child_flag && !mahd_flag && !sportz_village_flag && yalla_goal_flag && !sports_pad_flag"
        style="background: white;">
        <img class="img-fluid1" src="assets/images/yalla_goal.png" alt="Acadware" />
    </div>
    <div class="background-image"
        *ngIf="!child_flag && !mahd_flag && !sportz_village_flag && !yalla_goal_flag && sports_pad_flag"
        style="background: white;">
        <img class="img-fluid1" src="assets/images/sportspadbag.png" alt="Acadware" />
    </div>

    <ng-container *ngIf="!child_flag && !mahd_flag && !sportz_village_flag && !yalla_goal_flag && !sports_pad_flag">
        <div class="adjustment-div">
            <p class="" style="text-align:center; margin-top:70px; margin-bottom:20px; float:left; width:100%;">
                <span class="acad-img"><img src="assets/images/acadware_logo_vme_white.png" alt="Acadware"
                        style="width:215px;" /></span>
            </p>
            <div class="md-card" id="org-select-page" style="clear:both;border-radius: 7px;">
                <div class="md-card-content large-padding" id="org-select-card">
                    <div class="login_heading">
                        <h2 style="margin:0px 0px 25px 0px;">{{'selectAcademy' | translate}}</h2>
                    </div>
                    <form class="md-float-material" [formGroup]="organization_selector_form"
                        (ngSubmit)="selectorganization(organization_selector_form.value,'form','Different')">
                        <div class="example-container">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'selectAnAcademyName' | translate}}</mat-label>
                                <mat-select placeholder="Choose one" formControlName="Organization_list">
                                    <!-- <mat-option value="" [attr.selected]="true" disabled>--Please select an Academy Name--</mat-option> -->
                                    <!-- <mat-select-trigger>{{organization_selector_form.controls['Organization_list'].value}}</mat-select-trigger> -->
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel="{{'searchAcademy' |translate}}"
                                            noEntriesFoundLabel="{{'noMatchFound' | translate}}"
                                            (keyup)="filterAcademy($event.target.value)">
                                            <mat-icon (click)="codeSelection()" ngxMatSelectSearchClear>close</mat-icon>
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let list of organization_list1" [value]="list.organization_id">
                                        {{list.organization_name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="button-row" style="text-align:center; margin-top:10px;">
                                <button mat-raised-button color="primary" [disabled]="!organization_selector_form.valid"
                                    style="background-color: #13b8d5;">{{'AttendanceTrackerComp.Go' | translate |
                                    uppercase}} </button>
                            </div>
                        </div>
                    </form>

                </div>

            </div>

            <div class="uk-margin-top uk-text-center">
                <p class="text-inverse text-center" style="position:relative;">
                    {{ 'backTo' | translate }}
                    <a [routerLink]="['/login']" style="color: #13b8d5;">{{ 'Login' | translate }}</a>
                    <br><br>
                    <ng-template [ngIf]="showDeleteRequestLink">
                        VIEW <a [routerLink]="['/deletion-request-listing']" style="color: #f0ff24;">DELETE REQUEST</a>
                    </ng-template>
                </p>
                
                <!-- <p class="text-right" style="color:#fff; text-align:center;margin-top: 10px;position:relative;">{{'LanguageOffered' | translate}}:
                            <a (click)="changeLocale('en')"  style="color:#f0ff24;">English </a>|
                            <a (click)="changeLocale('hi')" style="color:#f0ff24;">हिन्दी</a>
                        </p>  -->

            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!child_flag && sportz_village_flag && !mahd_flag && !yalla_goal_flag && !sports_pad_flag">
        <div class="adjustment-div">

            <p class="" style="text-align:center; margin-top:20px; margin-bottom:155px; float:left; width:100%;">

                <span class="acad-img">
                    <img src="assets/images/SV.png" alt="Acadware" style="width:215px;" />
                </span>
            </p>

            <div class="md-card" id="org-select-page" style="clear:both;border-radius: 7px;">
                <div class="md-card-content large-padding" id="org-select-card">
                    <div class="login_heading">
                        <h2 style="margin:0px 0px 25px 0px;color: #026EB0;"> Select Academy</h2>
                    </div>
                    <form class="md-float-material" [formGroup]="organization_selector_form"
                        (ngSubmit)="selectorganization(organization_selector_form.value,'form','Different')">
                        <div class="example-container">
                            <mat-form-field appearance="outline">
                                <mat-label>Select An Academy Name</mat-label>
                                <mat-select placeholder="Choose one" formControlName="Organization_list">
                                    <!-- <mat-option value="" [attr.selected]="true" disabled>--Please select an Academy Name--</mat-option> -->
                                    <!-- <mat-select-trigger>{{organization_selector_form.controls['Organization_list'].value}}</mat-select-trigger> -->
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel="Search an Academy Name"
                                            noEntriesFoundLabel="no match found"
                                            (keyup)="filterAcademy($event.target.value)">
                                            <mat-icon (click)="codeSelection()" ngxMatSelectSearchClear>close</mat-icon>
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let list of organization_list1" [value]="list.organization_id">
                                        {{list.organization_name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="button-row" style="text-align:center; margin-top:10px;">
                                <button mat-raised-button color="primary" [disabled]="!organization_selector_form.valid"
                                    style="background-color: #026EB0;color: white;">{{'AttendanceTrackerComp.Go' |
                                    translate | uppercase}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="uk-margin-top uk-text-center">
                <p class="text-inverse text-center" style="position:relative;">{{'backTo' | translate}}<a
                        [routerLink]="['/sportz-village-login']" style="color: #026EB0;">{{'Login' | translate}}</a></p>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!child_flag && mahd_flag && !sportz_village_flag && !yalla_goal_flag && !sports_pad_flag">
        <div class="adjustment-div">
            <p class="" style="text-align:center; margin-top:70px; margin-bottom:20px; float:left; width:100%;">
                <span class="acad-img"><img class="img-fluid" src="assets/images/MahdAcademy.png" alt="Acadware"
                        style="width:215px;" /></span>
            </p>
            <div class="md-card" id="org-select-page" style="clear:both;border-radius: 7px;">
                <div class="md-card-content large-padding" id="org-select-card">
                    <div class="login_heading">
                        <h2 style="margin:0px 0px 25px 0px;"> Select Academy</h2>
                    </div>
                    <form class="md-float-material" [formGroup]="organization_selector_form"
                        (ngSubmit)="selectorganization(organization_selector_form.value,'form','Different')">
                        <div class="example-container">
                            <mat-form-field appearance="outline">
                                <mat-label>Select An Academy Name</mat-label>
                                <mat-select placeholder="Choose one" formControlName="Organization_list">
                                    <!-- <mat-option value="" [attr.selected]="true" disabled>--Please select an Academy Name--</mat-option> -->
                                    <!-- <mat-select-trigger>{{organization_selector_form.controls['Organization_list'].value}}</mat-select-trigger> -->
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel="Search an Academy Name"
                                            noEntriesFoundLabel="no match found"
                                            (keyup)="filterAcademy($event.target.value)">
                                            <mat-icon (click)="codeSelection()" ngxMatSelectSearchClear>close</mat-icon>
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let list of organization_list1" [value]="list.organization_id">
                                        {{list.organization_name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="button-row" style="text-align:center; margin-top:10px;">
                                <button mat-raised-button color="primary" [disabled]="!organization_selector_form.valid"
                                    style="background-color: #13b8d5;">{{'AttendanceTrackerComp.Go' | translate |
                                    uppercase}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="uk-margin-top uk-text-center">
                <p class="text-inverse text-center" style="position:relative;">{{'backTo' | translate}}<a
                        [routerLink]="['/login']" style="color: #13b8d5;">{{'Login' | translate}}.</a></p>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!child_flag && !mahd_flag && !sportz_village_flag && yalla_goal_flag && !sports_pad_flag">
        <div class="adjustment-div">
            <p class="" style="text-align:center; margin-top:20px; margin-bottom:20px; float:left; width:100%;">
                <span class="acad-img"><img class="img-fluid" src="assets/images/yalla_goal_logo.png"
                        style="width:215px;" /></span>
            </p>
            <div class="md-card" id="org-select-page" style="clear:both;border-radius: 7px;">
                <div class="md-card-content large-padding" id="org-select-card">
                    <div class="login_heading">
                        <h2 style="margin:0px 0px 25px 0px;color: #568B3F;"> Select Academy</h2>
                    </div>
                    <form class="md-float-material" [formGroup]="organization_selector_form"
                        (ngSubmit)="selectorganization(organization_selector_form.value,'form','Different')">
                        <div class="example-container">
                            <mat-form-field appearance="outline">
                                <mat-label>Select An Academy Name</mat-label>
                                <mat-select placeholder="Choose one" formControlName="Organization_list">
                                    <!-- <mat-option value="" [attr.selected]="true" disabled>--Please select an Academy Name--</mat-option> -->
                                    <!-- <mat-select-trigger>{{organization_selector_form.controls['Organization_list'].value}}</mat-select-trigger> -->
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel="Search an Academy Name"
                                            noEntriesFoundLabel="no match found"
                                            (keyup)="filterAcademy($event.target.value)">
                                            <mat-icon (click)="codeSelection()" ngxMatSelectSearchClear>close</mat-icon>
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let list of organization_list1" [value]="list.organization_id">
                                        {{list.organization_name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="button-row" style="text-align:center; margin-top:10px;">
                                <button mat-raised-button color="primary" [disabled]="!organization_selector_form.valid"
                                    style="background-color: #568B3F;color:white">{{'AttendanceTrackerComp.Go' |
                                    translate | uppercase}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="uk-margin-top uk-text-center">
                <p class="text-inverse text-center" style="position:relative;">{{'backTo' | translate}}<a
                        [routerLink]="['/yalla-goal-login']" style="color: #568B3F;">{{'Login' | translate}}.</a></p>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!child_flag && !mahd_flag && !sportz_village_flag && !yalla_goal_flag && sports_pad_flag">
        <div class="adjustment-div">
            <p class="" style="text-align:center; margin-top:5px; margin-bottom:20px; float:left; width:100%;">
                <span class="acad-img"><img src="assets/images/sportspadIcon.png" style="width:130px;" /></span>
            </p>
            <div class="md-card" id="org-select-page" style="clear:both;border-radius: 7px;">
                <div class="md-card-content large-padding" id="org-select-card">
                    <div class="login_heading">
                        <h2 style="margin:0px 0px 25px 0px;color: #EF7021;"> Select Academy</h2>
                    </div>
                    <form class="md-float-material" [formGroup]="organization_selector_form"
                        (ngSubmit)="selectorganization(organization_selector_form.value,'form','Different')">
                        <div class="example-container">
                            <mat-form-field appearance="outline">
                                <mat-label>Select An Academy Name</mat-label>
                                <mat-select placeholder="Choose one" formControlName="Organization_list">
                                    <!-- <mat-option value="" [attr.selected]="true" disabled>--Please select an Academy Name--</mat-option> -->
                                    <!-- <mat-select-trigger>{{organization_selector_form.controls['Organization_list'].value}}</mat-select-trigger> -->
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel="Search an Academy Name"
                                            noEntriesFoundLabel="no match found"
                                            (keyup)="filterAcademy($event.target.value)">
                                            <mat-icon (click)="codeSelection()" ngxMatSelectSearchClear>close</mat-icon>
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let list of organization_list1" [value]="list.organization_id">
                                        {{list.organization_name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="button-row" style="text-align:center; margin-top:10px;">
                                <button mat-raised-button color="primary" [disabled]="!organization_selector_form.valid"
                                    style="background-color: #EF7021;color:white">{{'AttendanceTrackerComp.Go' |
                                    translate | uppercase}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="uk-margin-top uk-text-center">
                <p class="text-inverse text-center" style="position:relative;">{{'backTo' | translate}}<a
                        [routerLink]="['/sports-pad-login']" style="color: #EF7021;">{{'Login' | translate}}.</a></p>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="child_flag">


        <div class="adjustment-div-child">
            <p class="" style="text-align:center; margin-top:25px; margin-bottom:20px; float:left; width:100%;">
                <!-- <span class="acad-img"><img class="img-fluid" src="assets/images/acadware_logo_vme.png" alt="Acadware" style="width:200px;" onerror="this.src='assets/images/acadware_logo_vme.png'" /></span>            -->
            </p>
            <!-- <div class="md-card" id="org-select-page" style="clear:both;border-radius: 7px;">
                <div class="md-card-content large-padding" id="org-select-card">  -->

            <div class="login_heading">
                <h2 style="margin:0px 0px 15px 0px;font-weight: 900 !important;">
                    <!-- <i  class="fa fa-1x fa-arrow-left" (click)="back()" style="cursor: pointer;float: left;"></i>  -->
                    {{'primaryOrganization' | translate}}
                </h2>
            </div>
            <div class="uk-grid custom-tabs">
                <div class="uk-width-medium-1-3" style="width: 20%;padding: 0px !important;">

                </div>
                <div class="uk-width-medium-1-3 ripple" *ngIf="parent_academy"
                    (click)="primaryOrg(parent_academy.academy_parent_id)"
                    style="width: 60%;text-align: center;padding: 5px 0px 0px 0px !important;cursor: pointer;">
                    <div class="uk-grid">
                        <!-- <div class="uk-width-medium-1-1">
                            <h6 style="float:right;cursor: pointer;" >
                                <i class="fa fa-circle text-danger Blink"></i>&nbsp;{{'LiveAttendance' | translate}}&nbsp;
                            </h6>
                        </div> -->
                        <div class="uk-width-medium-1-1">
                            <img [src]="parent_academy.logo_path"
                                style="border: 0px solid #ddd;display: inline-block;width: 85px;"
                                onerror="this.src='assets/images/acadware_logo_vme.png'">
                            <h4 style="cursor: pointer;font-size: 19px !important;margin-top: 6px;margin-bottom: 5px;">
                                {{parent_academy.organization_name}} </h4>
                            <span style="margin-bottom:5px">{{parent_academy.location}}</span>
                        </div>

                    </div>


                </div>
                <div class="uk-width-medium-1-3" style="width: 20%;padding: 0px !important;">

                </div>
            </div>
            <div class="login_heading">
                <h2 style="margin: 25px 0px -12px 0px;font-weight: 900 !important;">
                    {{'AffiliatedOrganization' | translate}}</h2>
                <div class="uk-grid custom-tabs">
                    <div class="uk-width-medium-1-3" style="width: 20%;padding: 0px !important;">

                    </div>
                    <div class="example-header_a search-field uk-width-medium-1-3"
                        style="width: 60%;padding: 0px !important;">
                        <mat-form-field>
                            <mat-icon matPreffix style="float: left; font-size: 23px;">search</mat-icon>
                            <input matInput (keyup)="applyFilter($event.target.value)"
                                placeholder="{{'Search' | translate}}"
                                style="float: left; width: auto; padding: 0px 10px;" [(ngModel)]="value" />
                            <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear"
                                style="float: right; font-size: 23px;" (click)="value = ''; applyFilter('')">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                        <h6 *ngIf="NoRecord_flag" style="color: red;">{{'noMatchFound' | translate}}</h6>
                    </div>
                    <div class="uk-width-medium-1-3" style="width: 20%;padding: 0px !important;">

                    </div>
                </div>
            </div>

            <div class="uk-grid custom-tabs child_academy" style="padding: 30px;">
                <div class="uk-width-medium-1-4 ripple" *ngFor="let element of child_academies" (click)="element.is_admin=='yes'?selectorganization(element.academy_child_id,'',
                    parent_academy.academy_parent_id==element.academy_child_id?'Equal':'Different'):noAdmin()"
                    style="margin-top: 20px; padding: 5px 0px 0px 0px !important;border-radius: 5px;cursor: pointer;">
                    <img [src]="element.logo_path" style="border: 1px solid #ddd;display: inline-block;width: 70px;">
                    <h4 style="cursor: pointer;font-size: 14px !important;margin-top: 6px;margin-bottom: 5px;">
                        {{element.organization_name}}</h4>
                    <span>{{element.location}}</span>
                </div>
            </div>
            <!-- </div>       
            </div> -->
            <div class="uk-margin-top uk-text-center"
                *ngIf="child_flag && !mahd_flag && !sportz_village_flag && !yalla_goal_flag">
                <p class="text-center" style="position:relative;">{{'backTo' | translate}}<a [routerLink]="['/login']"
                        style="color: #13b8d5;">{{'Login' | translate}}.</a></p>
            </div>
            <div class="uk-margin-top uk-text-center"
                *ngIf="child_flag && !mahd_flag && !sportz_village_flag && yalla_goal_flag">
                <p class="text-center" style="position:relative;">{{'backTo' | translate}}<a
                        [routerLink]="['/yalla-goal-login']" style="color: #568B3F;">{{'Login' |translate}}.</a></p>
            </div>
        </div>

    </ng-container>
</div>

<ng-template #parentLandingSelector>
    <h4 style="font-size: 18px !important;">{{'primaryAcademyLine' | translate}}
        <button mat-icon-button mat-dialog-close style="float: right;">
            <mat-icon aria-hidden="false">close</mat-icon>
        </button>
    </h4>
    <h4 style="text-align: center;margin-bottom: 0px;font-size: 18px !important;">{{'clickOnBelowProceed' | translate}}
    </h4>
    <mat-dialog-actions style="margin-top: 0px !important;">
        <div class="uk-grid">
            <div class="uk-width-1-2">
                <button mat-raised-button color="primary" mat-dialog-close
                    style="background-color:#13b8d5;width: 100%;font-size: 16px !important;"
                    (click)="parentAcademyLogin()">{{'primaryOrganization' | translate}} <br><small>({{'Primary' |
                        translate}})</small></button>
            </div>
            <div class="uk-width-1-2" style="padding-left: 15px !important;">
                <button mat-raised-button color="primary" mat-dialog-close
                    style="background-color:#13b8d5;width: 100%;font-size: 16px !important;"
                    (click)="parentAcademyAdminLogin()">{{'admin' | translate}} <br><small>({{'Affiliate' |
                        translate}})</small></button>
            </div>
        </div>

    </mat-dialog-actions>
</ng-template>



<ng-template #addAcademy>
    <h4 mat-dialog-title>{{'AddAcademy' | translate}}
        <button mat-icon-button [mat-dialog-close]="" (click)="closeDialog()" style="float: right;">
            <mat-icon aria-hidden="false">close</mat-icon>
        </button>
    </h4>
    <form [formGroup]="add_academy_form">
        <mat-dialog-content>
            <div class="uk-grid">
                <div class="uk-width-medium-5-10">
                    <div class="uk-grid">
                        <div class="uk-width-medium-1-1 custom-margin">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'AcademyName' | translate}}</mat-label>
                                <input matInput placeholder="{{'AcademyName' | translate}}"
                                    formControlName="organization_name" required>
                                <mat-error
                                    *ngIf="!add_academy_form.controls['organization_name'].valid && add_academy_form.controls['organization_name'].touched || form_invalid_flag">Please
                                    enter academy name</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="uk-width-medium-1-1 custom-margin">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'Type' | translate}}</mat-label>
                                <mat-select formControlName="sport_type" required>
                                    <mat-option *ngFor="let data of sport_type_list" value="{{ data.sport_name }}">
                                        {{ data.sport_name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="!add_academy_form.controls['sport_type'].valid && add_academy_form.controls['sport_type'].touched || form_invalid_flag">Please
                                    select a sport</mat-error>
                            </mat-form-field>

                        </div>

                        <div class="uk-width-medium-1-1 custom-margin">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'AppicationAccess' | translate}}</mat-label>
                                <mat-select formControlName="organization_app_access"
                                    [disabled]="organization_app_access_field">
                                    <mat-option value="G">Generic</mat-option>
                                    <mat-option value="R">Restricted</mat-option>
                                    <mat-option value="N">No-Access</mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="!add_academy_form.controls['organization_app_access'].valid && add_academy_form.controls['organization_app_access'].touched || form_invalid_flag">Please
                                    specify the application access</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="uk-width-medium-1-1 custom-margin">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'EstablishedDate' | translate}}</mat-label>
                                <input matInput [matDatepicker]="picker" readonly [max]="today"
                                    formControlName="organization_establish_date" />
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error
                                    *ngIf="!add_academy_form.controls['organization_establish_date'].valid && add_academy_form.controls['organization_establish_date'].touched || form_invalid_flag">
                                    Please select a date</mat-error>
                            </mat-form-field>
                        </div>


                        <div class="uk-width-medium-1-1 custom-margin">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'Country' | translate}}</mat-label>
                                <mat-select formControlName="country_id">
                                    <mat-option *ngFor="let data of countries_list" [value]="data.country_id"
                                        (click)="selectedcountry(data.country_id)">
                                        {{ data.country_name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="!add_academy_form.controls['country_id'].valid && add_academy_form.controls['country_id'].touched || form_invalid_flag">
                                    Please select a country</mat-error>

                            </mat-form-field>
                        </div>


                        <div class="uk-width-medium-1-1 custom-margin">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'State' | translate}}</mat-label>
                                <mat-select formControlName="state_id">
                                    <mat-option *ngFor="let data of getSates_data" value="{{ data.state_id }}"
                                        (click)="selectedstate(data.state_id)">
                                        {{ data.state_name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="!add_academy_form.controls['state_id'].valid && add_academy_form.controls['state_id'].touched || form_invalid_flag">
                                    Please select a state</mat-error>

                            </mat-form-field>
                        </div>


                        <div class="uk-width-medium-1-1 custom-margin">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'City' | translate}}</mat-label>
                                <mat-select formControlName="city_id">
                                    <mat-option *ngFor="let data of getCities_data" value="{{ data.city_id }}"
                                        (click)="selectedcity(data.city_id)">
                                        {{ data.city_name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="!add_academy_form.controls['city_id'].valid && add_academy_form.controls['city_id'].touched || form_invalid_flag">
                                    Please select a city</mat-error>
                            </mat-form-field>
                        </div>






                    </div>
                </div>

                <div class="uk-width-medium-5-10">
                    <div class="uk-grid">
                        <!-- <div class="uk-width-medium-1-1">
                        <mat-label class="academy-label">Academy logo <small><i>(optional)</i></small></mat-label>
                        <div class="picture" style="position: relative;">
                            <img class="picture-src" [src]="data.image" (click)="openImageCroper()"
                                onerror="this.src='assets/images/museum.png'">
                        </div>
                        <h5 class="choose" style="position: relative;">(Click image to upload your
                            logo)</h5>
                    </div> -->

                        <div class="uk-width-medium-1-1 custom-margin">

                            <mat-form-field appearance="outline" style="width: 30%;">
                                <mat-label>{{'Code' | translate}}</mat-label>
                                <mat-select formControlName="country_code" (click)="codeSelection()"
                                    (selectionChange)="countryCodeSelected('a')">
                                    <mat-select-trigger>{{add_academy_form.controls['country_code'].value}}
                                    </mat-select-trigger>
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel="Search Country"
                                            noEntriesFoundLabel="no match found"
                                            (keyup)="filterCountryCode($event.target.value)">
                                            <mat-icon (click)="codeSelection()" ngxMatSelectSearchClear>close</mat-icon>
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let data of countries_code_list" [value]="data.country_code">
                                        {{data.country_name}}<span style="float: right;">{{data.country_code}}</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" style="width: 70%;">
                                <mat-label>{{'ContactNumber' | translate}}</mat-label>
                                <input matInput placeholder="{{'ContactNumber' | translate}}"
                                    formControlName="organization_contact_number" required>
                                <mat-error
                                    *ngIf="!add_academy_form.controls['organization_contact_number'].valid && add_academy_form.controls['organization_contact_number'].touched || form_invalid_flag">Please
                                    enter contact number</mat-error>
                            </mat-form-field>
                        </div>


                        <div class="uk-width-medium-1-1 custom-margin">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'EmailAddress' | translate}}</mat-label>
                                <input matInput placeholder="{{'EmailAddress' | translate}}"
                                    formControlName="organization_email" required>
                                <mat-error
                                    *ngIf="!add_academy_form.controls['organization_email'].valid && add_academy_form.controls['organization_email'].touched || form_invalid_flag">Please
                                    enter valid school email</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="uk-width-medium-1-1 custom-margin">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'Zipcode' | translate}}</mat-label>
                                <input matInput placeholder="{{'Zipcode' | translate}}" formControlName="zipcode"
                                    required>
                                <mat-error
                                    *ngIf="!add_academy_form.controls['zipcode'].valid && add_academy_form.controls['zipcode'].touched || form_invalid_flag">Please
                                    enter zipcode</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="uk-width-medium-1-1 custom-margin">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'Address1' |translate}}</mat-label>
                                <input matInput placeholder="{{'Address1' |translate}}" formControlName="address_line1"
                                    required>
                                <mat-error
                                    *ngIf="!add_academy_form.controls['address_line1'].valid && add_academy_form.controls['address_line1'].touched || form_invalid_flag">Please
                                    enter address 1</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="uk-width-medium-1-1 custom-margin">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'Address2' |translate}}</mat-label>
                                <input matInput placeholder="{{'Address2' |translate}}" formControlName="address_line2">
                                <!-- <mat-error *ngIf="!add_academy_form.controls['address_line2'].valid && add_academy_form.controls['address_line2'].touched" style="margin-top: 5px;">Please Enter Address 2</mat-error> -->
                            </mat-form-field>
                        </div>

                        <div class="uk-width-medium-1-1 custom-margin">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'FacebookLink' | translate}}</mat-label>
                                <input matInput placeholder="{{'Facebook Link' | translate}}"
                                    formControlName="organization_facebook_link">
                                <!-- <mat-error *ngIf="!add_academy_form.controls['organization_facebook_link'].valid && add_academy_form.controls['organization_facebook_link'].touched" style="margin-top: 5px;">Please Enter Facebook Link</mat-error> -->
                            </mat-form-field>
                        </div>

                        <div class="uk-width-medium-1-1 custom-margin">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'WebsiteLink' | translate}}</mat-label>
                                <input matInput placeholder="{{'WebsiteLink' | translate}}"
                                    formControlName="organization_website_link">
                                <!-- <mat-error *ngIf="!add_academy_form.controls['organization_website_link'].valid && add_academy_form.controls['organization_website_link'].touched" style="margin-top: 5px;">Please Enter Website Link</mat-error> -->
                            </mat-form-field>
                        </div>
                        <!-- <mat-checkbox  (change)="showTime()" style="color: white;">Biometric Attendance</mat-checkbox> -->
                    </div>
                </div>
            </div>

        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-raised-button style="background-color: #13b8d5;color: white;" (click)="addChildAcademy()">
                Submit
            </button>

            <button mat-raised-button style="background-color: #13b8d5;color: white;" mat-dialog-close
                (click)="closeDialog()">
                Cancel
            </button>
        </mat-dialog-actions>
    </form>
</ng-template>