import { Injectable } from '@angular/core';
import { AppComponent } from '../app.component';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

const organization_details_url = AppComponent.API_URL + 'organization/GetOrganizationDetails';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn:'root'
})
export class CommonApiService {   
  academy_details: any;  
  addOrganizationForm_Data: any; 
  update_organisation: any; 
  state_list:any; 
  cities_list:any; 
  Preferences_details:any;
  setPreferences_details:any;   
  httpOptions;  
  update_student: any;
  localeEvent = new Subject<string>();

  constructor(private http_client: HttpClient,private translate:TranslateService) 
  {   
    
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
    };
  }

  getOrganizationDetails(organization_id)
  {    
    let params = new HttpParams();    
    params =params.append('organization_id', organization_id); 
    return this.http_client.post(organization_details_url, params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.academy_details =data['data']['academy_details'];
                if(this.academy_details.length!=0)
                {         
                  return this.academy_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }
  addOrganizationFormData()
  {
    const local_formData_url = AppComponent.API_URL + 'mobile/AddOrganizationFormData';
    return this.http_client.post(local_formData_url,[], this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.addOrganizationForm_Data =data['add_organization_form_data'];
                if(this.addOrganizationForm_Data.length!=0)
                {         
                  return this.addOrganizationForm_Data;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }
 GetStates(country_id)
  {
    let params = new HttpParams();    
    params =params.append('country_id', country_id); 
    const local_getState_url = AppComponent.API_URL + 'general/GetStates';
    return this.http_client.post(local_getState_url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.state_list =data['states_list'];
                if(this.state_list.length!=0)
                {         
                  return this.state_list;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }
  GetCities(country_id,state_id)
  {
    let params = new HttpParams();    
    params =params.append('country_id', country_id); 
    params =params.append('state_id', state_id); 
    const local_getCities_url = AppComponent.API_URL + 'general/GetCities';
    return this.http_client.post(local_getCities_url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.cities_list =data['cities_list'];
                if(this.cities_list.length!=0)
                {         
                  return this.cities_list;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }
   
  updateOrganization(customer_id,organization_name,organization_app_access,sport_type,organization_establish_date,
    organization_contact_number,organization_kit_provided_flag,organization_biometric_taken_flag,country_id,
    state_id,city_id,district,zipcode,address_line1,address_line2,organization_email,organization_website_link,
    organization_facebook_link,organization_id,contact_id,organization_online_payment_flag,LTAD_flag,
    cheque_tracking_flag,capture_parents_info_flag) 
  {
    let params = new HttpParams();    
    params =params.append('customer_id', customer_id); 
    params =params.append('organization_name', organization_name); 
    params =params.append('organization_app_access',organization_app_access); 
    params =params.append('sport_type', sport_type); 
    params =params.append('organization_establish_date', organization_establish_date); 
    params =params.append('organization_contact_number', organization_contact_number); 
    params =params.append('organization_kit_provided_flag', organization_kit_provided_flag); 
    params =params.append('organization_biometric_taken_flag', organization_biometric_taken_flag); 
    params =params.append('country_id', country_id); 
    params =params.append('state_id', state_id); 
    params =params.append('city_id', city_id); 
    params =params.append('district', district); 
    params =params.append('zipcode', zipcode); 
    params =params.append('address_line1', address_line1); 
    params =params.append('address_line2', address_line2); 
    params =params.append('organization_email', organization_email); 
    params =params.append('organization_website_link', organization_website_link); 
    params =params.append('organization_facebook_link', organization_facebook_link); 
    params =params.append('organization_id', organization_id); 
    params =params.append('contact_id', contact_id); 
    params =params.append('organization_online_payment_flag', organization_online_payment_flag); 
    params =params.append('LTAD_flag', LTAD_flag); 
    params =params.append('cheque_tracking_flag', cheque_tracking_flag); 
    params =params.append('capture_parents_info_flag', capture_parents_info_flag); 
    const local_updateOrganization_url = AppComponent.API_URL + 'organization/UpdateOrganization';
    return this.http_client.post(local_updateOrganization_url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.update_organisation =data['organization_details'];
                if(this.update_organisation.length!=0)
                {         
                  return this.update_organisation;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }
   
  getpreferences(organization_id)
  {
    let params = new HttpParams();    
    params =params.append('organization_id', organization_id);     
    const local_getPreference_url = AppComponent.API_URL + 'preferences/Getpreferences';
    return this.http_client.post(local_getPreference_url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.Preferences_details =data['Preferences details'];
                if(this.Preferences_details.length!=0)
                {         
                  return this.Preferences_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }
  Setpreferences(organization_id,organization_name,cmbHeight,cmbWeight,cmbCurrency,cmbFontFamily,cmbDateFormats,cmbRegBackgroundColor,
    cmbRegTextColor,cmbRegFormHintTextColor,cmbMainPageBackgroundColor,cmbMainTextColor,cmbPgHeaderBgColor,cmbPgHeaderTextColor,
    cmbCardHeaderBackgroundcolor,cmbCardHeaderTextcolor,cmbButtonBackgroundcolor,cmbButtonTextcolor,cmbSelectedTabunderlinecolor,
    cmbSideNavHeaderBackgroundColor,cmbSideNavHeaderFontColor,cmbSideNavHeaderMenuColor,cmbSideNavBackgroundColor,org_image,
    org_thumbnail_image,academy_preferences_id)
  {
    let params = new HttpParams();    
    params =params.append('organization_id', organization_id);    
    params =params.append('organization_name', organization_name);  
    params =params.append('cmbHeight', cmbHeight);   
    params =params.append('cmbWeight', cmbWeight);  
    params =params.append('cmbCurrency', cmbCurrency);  
    params =params.append('cmbFontFamily', cmbFontFamily); 
    params =params.append('cmbDateFormats', cmbDateFormats);
     
    params =params.append('cmbRegBackgroundColor', cmbRegBackgroundColor);  
    params =params.append('cmbRegTextColor', cmbRegTextColor);  
    params =params.append('cmbRegFormHintTextColor', cmbRegFormHintTextColor);  
    params =params.append('cmbMainPageBackgroundColor', cmbMainPageBackgroundColor);  
    params =params.append('cmbMainTextColor', cmbMainTextColor);  
    params =params.append('cmbPgHeaderBgColor', cmbPgHeaderBgColor);  
    params =params.append('cmbPgHeaderTextColor', cmbPgHeaderTextColor);  
    params =params.append('cmbCardHeaderBackgroundcolor', cmbCardHeaderBackgroundcolor);  
    params =params.append('cmbCardHeaderTextcolor', cmbCardHeaderTextcolor);  
    params =params.append('cmbButtonBackgroundcolor', cmbButtonBackgroundcolor);  
    params =params.append('cmbButtonTextcolor', cmbButtonTextcolor);
    params =params.append('cmbSelectedTabunderlinecolor', cmbSelectedTabunderlinecolor);  
    params =params.append('cmbSideNavHeaderBackgroundColor', cmbSideNavHeaderBackgroundColor);  
    params =params.append('cmbSideNavHeaderFontColor', cmbSideNavHeaderFontColor);
    params =params.append('cmbSideNavHeaderMenuColor', cmbSideNavHeaderMenuColor);   
    params =params.append('cmbSideNavBackgroundColor', cmbSideNavBackgroundColor);  
    
    if(org_image==undefined || org_thumbnail_image==undefined)
    {
      params =params.append('org_image', '');  
      params =params.append('org_thumbnail_image', ''); 
    }
    else
    {
      params =params.append('org_image', org_image);  
      params =params.append('org_thumbnail_image', org_thumbnail_image); 
    }
    
    params =params.append('academy_preferences_id', academy_preferences_id);

    const local_setPreference_url = AppComponent.API_URL + 'preferences/Setpreferences';
    return this.http_client.post(local_setPreference_url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.setPreferences_details =data['Response as inserted/updated  academy_preferences_id'];
                if(this.setPreferences_details!="")
                {         
                  return this.setPreferences_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  } 

  getOrganizationModulesStatus(login_user_id,role,organization_id){
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
    };
    const params = new HttpParams().set("login_user_id", login_user_id)
                                    .set("role", role)
                                    .set("organization_id", organization_id);
    const url =AppComponent.API_URL +"entitlement_granularization/GetOrganizationModulesStatus";
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  changeLocale(locale:string){
    sessionStorage.setItem('locale',locale);
    this.translate.use(locale);
    this.localeEvent.next(locale);
    
  }
 
}