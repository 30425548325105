<div id="page_content_inner" class="registration-page">
    <div class="uk-grid" data-uk-grid-margin>
        <div class="uk-width-medium-8-10 glass-effect" style="margin:auto;">
            <div style="position:relative; text-align:center;"> <img class="img-fluid" src="assets/images/acadware_logo_vme_white.png"
                alt="Acadware" style="width:215px;" />
            </div>
            <!-- <h2 class="example-h2 wizard-title" style="position: relative;color: #ffffff;">Registration</h2> -->
            <mat-horizontal-stepper [linear]="isLinear" #stepper="matHorizontalStepper">
                <mat-step [stepControl]="firstFormGroup">
                    <form [formGroup]="firstFormGroup">
                        <ng-template matStepLabel>ABOUT</ng-template>
                        <div class="demo-tab-content">
                            <div class="uk-grid">
                                <div class="uk-width-medium-1-1">
                                    <h4 class="info-text" style="position: relative;color: #ffffff;">Good Decision!
                                        Let's start with some basic info</h4>
                                </div>
                                <div class="uk-width-medium-5-10">
                                    <div class="uk-grid">
                                        <div class="uk-width-medium-1-1" style="margin-top:50px;">
                                            <mat-form-field>
                                                <input matInput class="academy-name-input" placeholder="Academy Name"
                                                    autocomplete="off" formControlName="academy_name">
                                                <mat-error *ngIf="!firstFormGroup.controls['academy_name'].valid && !firstFormGroup.controls['academy_name'].untouched || formInvalidFlag">Enter
                                                    Academy Name</mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>

                                <div class="uk-width-medium-5-10">
                                    <div class="uk-grid">
                                        <div class="uk-width-medium-1-1">
                                            <mat-label class="academy-label">Academy logo <small><i>(optional)</i></small></mat-label>
                                            <div class="picture" style="position: relative;">
                                                <img class="picture-src" [src]="data.image" (click)="openImageCroper()"
                                                    onerror="this.src='assets/images/museum.png'">
                                            </div>
                                            <h5 class="choose" style="position: relative;">(Click image to upload your
                                                logo)</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="uk-grid">
                            <div class="uk-width-medium-10-10" style="text-align:right;">
                                <button mat-raised-button color="primary" style="margin:0px 0px;" (click)="getAcademyName(firstFormGroup.value,stepper)">Next</button>
                            </div>
                        </div>
                    </form>
            </mat-step>

            <mat-step>
                <form [formGroup]="addressFormGroup">
                    <ng-template matStepLabel>ADDRESS</ng-template>
                    <div class="demo-tab-content">
                        <div class="uk-grid">
                            <div class="uk-width-medium-1-1" *ngIf="academy_name">
                                <h4 class="info-text" style="position: relative;color: #ffffff;">Where is your
                                    {{academy_name}} Located?</h4>
                            </div>

                            <div class="uk-width-medium-5-10">
                                <div class="uk-grid">
                                    <div class="uk-width-medium-1-1">
                                        <mat-form-field>
                                            <input matInput placeholder="Address Line 1" autocomplete="off" class="normal-input"
                                                formControlName="address_line1">
                                            <mat-error *ngIf="!addressFormGroup.controls['address_line1'].valid && !addressFormGroup.controls['address_line1'].untouched || form1InvalidFlag">Enter
                                                Address 1</mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="uk-width-medium-1-1">
                                        <mat-form-field>
                                            <input matInput placeholder="Address Line 2" autocomplete="off" class="normal-input"
                                                formControlName="address_line2">
                                            <mat-error *ngIf="!addressFormGroup.controls['address_line2'].valid && !addressFormGroup.controls['address_line2'].untouched || form1InvalidFlag">Enter
                                                Address 2</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>



                            <div class="uk-width-medium-5-10">
                                <div class="uk-grid">
                                    <div class="uk-width-medium-1-1">
                                        <mat-form-field>
                                            <mat-select placeholder="Select Country" formControlName="country_id">
                                                <mat-option *ngFor="let data of countriesList" [value]="data.country_id"
                                                    (click)="selectedcountry(data.country_id)">
                                                    {{data.country_name}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="!addressFormGroup.controls['country_id'].valid && !addressFormGroup.controls['country_id'].untouched || form1InvalidFlag">Select
                                                Country</mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="uk-width-medium-1-1">
                                        <mat-form-field>
                                            <mat-select placeholder="Select State" formControlName="state_id">
                                                <mat-option *ngFor="let data of statesList" [value]="data.state_id"
                                                    (click)="selectedstate(data.state_id)">
                                                    {{data.state_name}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="!addressFormGroup.controls['state_id'].valid && !addressFormGroup.controls['state_id'].untouched || form1InvalidFlag">Select
                                                State</mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="uk-width-medium-1-1">
                                        <mat-form-field>
                                            <mat-select placeholder="Select City" formControlName="city_id">
                                                <mat-option *ngFor="let data of citiesList" [value]="data.city_id"
                                                    (click)="selectedcity(data.city_id)">
                                                    {{data.city_name}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="!addressFormGroup.controls['city_id'].valid && !addressFormGroup.controls['city_id'].untouched || form1InvalidFlag">Select
                                                City</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="uk-grid">
                        <div class="uk-width-medium-10-10" style="margin:0px 0px;">
                            <button mat-raised-button matStepperPrevious color="primary" style="float:left;">Back</button>
                            <button mat-raised-button color="primary" (click)="submitAcademyDetails(addressFormGroup.value,stepper)"
                                style="float:right;">Next</button>
                        </div>
                    </div>
                </form>
            </mat-step>

            <mat-step>
                <form [formGroup]="ownerDetailsForm">
                    <ng-template matStepLabel>Create login</ng-template>
                    <div class="demo-tab-content">
                        <div class="uk-grid">
                            <div class="uk-width-medium-1-1">
                                <h4 class="info-text" style="position: relative;color: #ffffff;">Thank you! Now
                                    lets create login for you</h4>
                            </div>

                            <div class="uk-width-medium-5-10">
                                <div class="uk-grid">
                                    <div class="uk-width-medium-1-1">
                                        <table style="width:100%">
                                            <tr>
                                                <td style="padding-right:15px;">
                                                    <mat-form-field>
                                                        <input matInput placeholder="First name" class="normal-input" formControlName="first_name">
                                                        <mat-error *ngIf="!ownerDetailsForm.controls['first_name'].valid && !ownerDetailsForm.controls['first_name'].untouched || form2InvalidFlag">Enter Firstname </mat-error>
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field>
                                                        <input matInput placeholder="Last Name" class="normal-input" formControlName="last_name">
                                                        <mat-error *ngIf="!ownerDetailsForm.controls['last_name'].valid && !ownerDetailsForm.controls['last_name'].untouched || form2InvalidFlag">Enter Lastname </mat-error>
                                                    </mat-form-field>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="uk-width-medium-1-1">
                                        <mat-form-field>
                                            <input matInput placeholder="Mobile Number" #mobile autocomplete="off" class="normal-input" formControlName="mobile_number" maxlength="10" style="padding: 3px 0px;">
                                            <mat-icon matSuffix style="padding: 10px; color:#fff;">smartphone</mat-icon>
                                            
                                            <mat-hint align="end" style="color: rgba(255, 254, 254, 0.54);">{{mobile.value.length}} / 10</mat-hint>
                                            <mat-error *ngIf="!ownerDetailsForm.controls['mobile_number'].valid && !ownerDetailsForm.controls['mobile_number'].untouched || form2InvalidFlag">Enter Valid Mobile Number </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="uk-width-medium-1-1">
                                        <mat-form-field>
                                            <input matInput placeholder="Email ID" autocomplete="off" class="normal-input" formControlName="email_address" style="padding: 3px 0px;" (keyup)="enteredEmail($event.target.value)">
                                            <mat-icon matSuffix style="padding: 10px; color:#fff;">email</mat-icon>
                                            <mat-error *ngIf="!ownerDetailsForm.controls['email_address'].valid && !ownerDetailsForm.controls['email_address'].untouched || form2InvalidFlag">Enter Valid Email Address </mat-error>                                            
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <div class="uk-width-medium-5-10">
                                <div class="uk-grid">
                                    <div class="uk-width-medium-1-1">
                                        <p class="info-text-message">
                                            On submission of this form you will be sent a verification email to
                                            your email ID
                                        </p>
                                        <p class="info-text-message">
                                            Please click on the verification link to proceed with your
                                            registration.<br />
                                            <!-- <button mat-raised-button color="accent" style="margin:20px 0px;">Resend
                                                <small>({{timeLeft}} Seconds)</small></button> -->
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="uk-grid">
                        <div class="uk-width-medium-10-10" style="margin:0px 0px;">
                            <button mat-raised-button matStepperPrevious color="primary" style="float:left;">Back</button>
                            <div [style.display]="submit_btn_show_hide"><button mat-raised-button color="primary"  (click)="submitOwnerDetails(ownerDetailsForm.value,stepper)" style="float:right;">Submit</button></div>
                        </div>
                    </div>
                </form>
            </mat-step>

            <mat-step [stepControl]="passwordForm">
                <form [formGroup]="passwordForm">
                    <ng-template matStepLabel>VERIFICATION</ng-template>
                    <div class="demo-tab-content">
                        <div class="uk-grid" > 
                            <div class="uk-width-medium-1-1" [style.display]="pendingDiv">
                                <h4 class="info-text" style="position: relative;color: #ffffff; margin-bottom:9px;">Congratulations!
                                    Your account is successfully verified.</h4>
                                <h5 class="sub-text" style="position: relative;color: #ffffff; margin-bottom:9px;">Time
                                    to set your own new password</h5>
                            </div>
                            <div class="uk-width-medium-5-10" style="margin:20px auto 50px auto;" [style.display]="pendingDiv">
                                <mat-form-field>
                                    <input matInput placeholder="Password" formControlName="password" autocomplete="off" class="normal-input" [type]="hide ? 'text':'password'">
                                    <mat-icon matSuffix (click)="hide = !hide" style="padding: 10px; color:#fff;">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                                    <mat-error *ngIf="!passwordForm.controls['password'].valid && !passwordForm.controls['password'].untouched || form3InvalidFlag">Enter Valid Passward <small>( Password should be atleast 8 alphanumeric character )</small> </mat-error>
                                </mat-form-field>
                                <mat-form-field *ngIf="passwordForm.controls['password'].valid">
                                    <input matInput placeholder="Confirm Password" formControlName="confirmPassword" autocomplete="off" class="normal-input"  [type]="hide1 ? 'text' : 'password'">
                                    <mat-icon matSuffix (click)="hide1 = !hide1" style="padding: 10px; color:#fff;">{{hide1 ? 'visibility' : 'visibility_off'}}</mat-icon>
                                    <mat-error *ngIf="passwordForm.controls['confirmPassword'].pristine || passwordForm.controls['confirmPassword'].errors?.MatchPassword || form3InvalidFlag">Password does not match</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="uk-grid" [style.display]="approvedDiv">
                            <div class="uk-width-medium-1-1">
                                <h4 class="info-text" style="position: relative;color: #ffffff; margin-bottom:9px;">
                                    Your account is already verified.</h4>                                
                            </div>
                        </div>
                    </div>
                    <div class="uk-grid">
                        <div class="uk-width-medium-10-10" style="margin:10px 0px">
                            <div [style.display]="SubmitBtnDiv">
                             <button mat-raised-button color="primary" (click)="submitPasswordForm()" style="float:right;">Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>Set Roles</ng-template>
                <div class="demo-tab-content">
                    <div class="uk-grid">
                        <div class="uk-width-medium-1-1">
                            <h4 class="info-text" style="position: relative;color: #ffffff; margin-bottom:9px;">Time
                                to get organized by setting up roles in your academy</h4>
                            <h5 class="sub-text" style="position: relative;color: #ffffff; margin-bottom:35px;">Acadware
                                helps you to setup roles at the academy or center level</h5>
                        </div>
                        <div class="uk-width-medium-5-10 academy-level-role" style="border-right: 1px solid rgba(255,255,255,0.4);">
                            <h5>Academy level roles</h5>
                            <p>Academy level roles, eg. Admin, Operational Manager are administration oriented
                                roles with access to data and dashboards across all centers.</p>
                        </div>
                        <div class="uk-width-medium-5-10 center-level-role">
                            <h5>Center level roles</h5>
                            <p>Center level roles, eg. Coach, Trainer, are daily operations oriented roles that
                                helps the staff on the ground to run the academy more efficiently and effectivly</p>
                        </div>
                    </div>
                </div>
                <div class="uk-grid">
                    <div class="uk-width-medium-10-10">
                        <button mat-raised-button color="primary" matStepperPrevious style="float:left;">Back</button>
                        <button mat-raised-button color="primary" (click)="nextStep()" style="float:right;">Next</button>
                    </div>
                </div>
                    <!-- <div>
                    <button mat-button matStepperPrevious>Back</button>
                    <button mat-button (click)="stepper.reset()">Reset</button>
                  </div> -->
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>Create Roles</ng-template>
                <div class="demo-tab-content">
                    <div class="uk-grid">
                        <div class="uk-width-medium-1-1">
                            <h4 class="info-text" style="position: relative;color: #ffffff; margin-bottom:9px;">Select
                                the Roles you need or create new ones</h4>
                        </div>
                        <div class="uk-width-medium-1-3 academy-level-role" style="border-right: 1px solid rgba(255,255,255,0.4);">
                            <h5>Academy level roles</h5>
                            <ul class="checkbox-roles">
                                <li>
                                    <mat-checkbox>Administrator</mat-checkbox>
                                </li>
                                <li>
                                    <mat-checkbox>Operations Manager</mat-checkbox>
                                </li>
                            </ul>
                        </div>

                        <div class="uk-width-medium-1-3 center-level-role" style="border-right: 1px solid rgba(255,255,255,0.4);">
                            <h5>Center level roles</h5>
                            <ul class="checkbox-roles">
                                <li>
                                    <mat-checkbox>Junior Coach</mat-checkbox>
                                </li>
                                <li>
                                    <mat-checkbox>Senior Coach</mat-checkbox>
                                </li>
                                <li>
                                    <mat-checkbox>Assistant Coach</mat-checkbox>
                                </li>
                                <li>
                                    <mat-checkbox>Fitness Trainer</mat-checkbox>
                                </li>
                            </ul>
                        </div>

                        <div class="uk-width-medium-1-3 create-new-role">
                            <h5>Create new roles</h5>
                            <mat-form-field>
                                <input matInput placeholder="Role Name" class="normal-input">
                            </mat-form-field>

                            <mat-form-field>
                                <mat-select placeholder="Role Level">
                                    <mat-option>fgfg</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div class="button-row" style="text-align:right">
                                <button mat-raised-button>Add Role</button>
                            </div>
                        </div>
                        <div class="uk-width-medium-1-1" style="margin:30px 0px;">
                            <mat-chip-list>
                                <mat-chip>One fish</mat-chip>
                                <mat-chip>Two fish</mat-chip>
                                <mat-chip color="primary" selected="true">Primary fish</mat-chip>
                                <mat-chip color="accent" selected="true">Accent fish</mat-chip>
                            </mat-chip-list>
                        </div>
                    </div>
                </div>
                <div class="uk-grid">
                    <div class="uk-width-medium-10-10" style="margin:40px 0px;">
                        <button mat-raised-button color="primary" matStepperPrevious style="float:left;">Back</button>
                        <button mat-raised-button color="primary" (click)="nextStep()" style="float:right;">Center
                            Setup</button>
                    </div>
                </div>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>Great job</ng-template>
                <div class="demo-tab-content">
                    <div class="uk-grid">
                        <div class="uk-width-medium-1-1">
                            <h4 class="info-text" style="position: relative;color: #ffffff;">You're doing great!</h4>
                        </div>
                    </div>
                    <div class="uk-grid">
                        <div class="uk-width-medium-5-10 academy-level-role" style="border-right: 1px solid rgba(255,255,255,0.4);">
                            <p style="margin-bottom: 25px;">So far we have onboarded your academy, setup login and
                                created roles for your staff!</p>
                            <p>Let us now proceed to setup your center</p>
                        </div>
                        <div class="uk-width-medium-5-10 center-level-role">
                            <h5 style="margin-top:0px;">how many centers do you operate?</h5>
                            <div class="center-count" style="margin-top:50px;">
                                <mat-radio-group>
                                    <mat-radio-button value="1">Single</mat-radio-button>
                                    <mat-radio-button value="2">Multiple</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div style="margin-top:20px;">
                                <mat-form-field>
                                    <input matInput placeholder="Number of Centers" autocomplete="off" class="normal-input">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="uk-grid" style="margin-top:40px;">
                    <div class="uk-width-medium-10-10" style="">
                        <button mat-raised-button color="primary" matStepperPrevious style="float:left">Back</button>
                        <button mat-raised-button color="primary" (click)="nextStep()" style="float:right">Next</button>
                    </div>
                </div>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>Center Detail</ng-template>
                <div class="demo-tab-content">
                    <div class="uk-grid">
                        <div class="uk-width-medium-1-1">
                            <h4 class="info-text" style="position: relative;color: #ffffff; margin-bottom:9px;">Please
                                provide center details</h4>
                            <h5 class="sub-text" style="position: relative;color: #ffffff;">Center 1/5</h5>
                        </div>
                    </div>

                    <div class="uk-grid">
                        <div class="uk-width-medium-5-10 academy-level-role">
                            <mat-form-field>
                                <input matInput placeholder="Center Name" autocomplete="off" class="normal-input">
                            </mat-form-field>

                            <mat-form-field>
                                <input matInput placeholder="Address Line 1" autocomplete="off" class="normal-input">
                            </mat-form-field>

                            <mat-form-field>
                                <input matInput placeholder="Address Line 2" autocomplete="off" class="normal-input">
                            </mat-form-field>
                        </div>

                        <div class="uk-width-medium-5-10 center-level-role">
                            <mat-form-field>
                                <mat-select placeholder="Country">
                                    <mat-option>fgfg</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field>
                                <mat-select placeholder="State">
                                    <mat-option>fgfg</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field>
                                <mat-select placeholder="City">
                                    <mat-option>fgfg</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="uk-grid" style="margin-top:40px;">
                    <div class="uk-width-medium-10-10">
                        <button mat-raised-button color="primary" matStepperPrevious style="float:left;">Back</button>
                        <button mat-raised-button color="primary" (click)="nextStep()" style="float:right;">Next</button>
                    </div>
                </div>
            </mat-step>
        </mat-horizontal-stepper>

        <div class="back-to-login">
            <p style="text-align:center;">
                <a href="javascript:void(0);" [routerLink]="['/login']"> <i class="material-icons">keyboard_backspace</i>
                    Login</a>
            </p>
        </div>
    </div>
</div>
</div>

<ng-template #profileImageCroperAdd>
    <h4 mat-dialog-title>Profile Image
        <button mat-icon-button [mat-dialog-close]=" " (click)="resetImage();" style="float:right;">
            <i class="material-icons">close</i>
        </button>
    </h4>
    <div>
        <img-cropper [image]="data" src="assets/images/pi.png" [settings]="cropperSettings"></img-cropper><br>
        <!-- <img [src]="data.image" [width]="cropperSettings.croppedWidth" [height]="cropperSettings.croppedHeight"> -->
        <button mat-raised-button [style.background-color]="button_background_color" [style.color]="button_text_color"
            [mat-dialog-close]=" " (click)="cropedProfileImage()">Ok</button>
    </div>
</ng-template>