import { Injectable } from '@angular/core';
import { AppComponent } from '../app.component';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { receiveMessageOnPort } from 'worker_threads';

@Injectable()
export class FeesReportServices {

  payment_report_details: any;
  center_data: any;
  get_batch_details: any;
  staff_data: any;

  httpOptions;
  player_payment_details: any;
  date_format: string;
  temp_date: string;

  constructor(private http_client: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };
  }

  getPaymentReportDetails(academy_id, tab, academy_center_id, academy_group_id,
    payment_due, payment_due_start_date, payment_due_end_date, payment_mode,
    payment_date, from_date, to_date,
    received_by,
    allow_partial_payment,status,date_format) {

    let params = new HttpParams();
    params = params.append('academy_id', academy_id);
    params = params.append('tab', tab);
    if (academy_center_id != undefined && academy_center_id.length != 0) {
      academy_center_id.forEach((element, index) => {
        params = params.append('filter[academy_center_id][' + index + ']', element);
      });
    }
    if (academy_group_id != undefined && academy_group_id.length != 0) {
      academy_group_id.forEach((element, index) => {
        params = params.append('filter[academy_group_id][' + index + ']', element);
      });
    }
    if (tab == 'overdue') {
      if (payment_due != null && payment_due.length != 0) {
        params = params.append('filter[payment_due]', payment_due);
      }

      if (payment_due_start_date != null) {
        params = params.append('filter[payment_due_start_date]',this.dateFormater(payment_due_start_date,date_format) );
      }
      if (payment_due_end_date != null) {
        params = params.append('filter[payment_due_end_date]',this.dateFormater(payment_due_end_date,date_format) );
      }
      if(status!=undefined){
        status.forEach((element,i) => {
          params=params.append('filter[status]['+i+']',element);
        });
      }
    }
    if (tab == 'received') {
      if (payment_mode != undefined && payment_mode.length != 0) {
        payment_mode.forEach((element, index) => {
          params = params.append('filter[payment_mode][' + index + ']', element);
        });
      }
      if (from_date != null) {

        params = params.append('filter[from_date]',this.dateFormater(from_date ,date_format));
      }
      if (to_date != null) {
        params = params.append('filter[to_date]', this.dateFormater(to_date ,date_format));
      }

      if (received_by != undefined && received_by.length != 0) {
        received_by.forEach((element, index) => {
          params = params.append('filter[received_by][' + index + ']', element);
        });
      }
      if(status!=undefined){
        status.forEach((element,i) => {
          params=params.append('filter[status]['+i+']',element);
        });
      }
    }

    const url = allow_partial_payment ? AppComponent.API_URL + 'payment/GetPartialPaymentReportDetails' :
      AppComponent.API_URL + 'payment/GetPaymentReportDetails';
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.payment_report_details = data;
          if (this.payment_report_details['status']) {
            return this.payment_report_details;
          }
          else {
            return '';
          }
        }),
      )
  }

  getParentOrgPaymentReportDetails(parent_academy_id, tab, academy_id, from_date, to_date,
    payment_due, payment_due_start_date, payment_due_end_date, allow_partial_payment,
    date_format, received_by, payment_mode, academy_center_id, academy_group_id,status) {

    let params = new HttpParams();

    params = params.append('parent_academy_id', parent_academy_id);
    params = params.append('tab', tab);
    if (academy_id != undefined && academy_id.length != 0) {
      academy_id.forEach((element, index) => {
        params = params.append('filter[academy_id][' + index + ']', element);
      });
    }
    if (academy_center_id != undefined && academy_center_id.length != 0) {
      academy_center_id.forEach((element, index) => {
        params = params.append('filter[academy_center_id][' + index + ']', element);
      });
    }
    if (academy_group_id != undefined && academy_group_id.length != 0) {
      academy_group_id.forEach((element, index) => {
        params = params.append('filter[academy_group_id][' + index + ']', element);
      });
    }
    if (tab == 'received') {
      if (from_date != null && from_date != undefined) {
        params = params.append('filter[from_date]', this.dateFormater(from_date, date_format));
      }

      if (to_date != null && to_date != undefined) {
        params = params.append('filter[to_date]', this.dateFormater(to_date, date_format));
      }

      if (payment_mode != undefined && payment_mode.length != 0) {
        payment_mode.forEach((element, index) => {
          params = params.append('filter[payment_mode][' + index + ']', element);
        });
      }
      if (received_by != undefined && received_by.length != 0) {
        received_by.forEach((element, index) => {
          params = params.append('filter[received_by][' + index + ']', element);
        });
      }
      if (status != undefined) {
        status.forEach((element, i) => {
          params = params.append('filter[status][' + i + ']', element);        
        });
      }
    }

    if (tab == 'overdue') {
      if (payment_due != null && payment_due.length != 0) {
        params = params.append('filter[payment_due]', payment_due);
      }
      if (payment_due_start_date != null && payment_due_start_date != undefined) {
        params = params.append('filter[payment_due_start_date]', this.dateFormater(payment_due_start_date, date_format));
      }
      if (payment_due_end_date != null && payment_due_end_date != undefined) {
        params = params.append('filter[payment_due_end_date]', this.dateFormater(payment_due_end_date, date_format))
      }
      if (status != undefined) {
        status.forEach((element, i) => {
          params = params.append('filter[status][' + i + ']', element);        
        });
      }

    }

    const url = allow_partial_payment ? AppComponent.API_URL + 'payment/GetParentOrgPartialPaymentReportDetails' :
      AppComponent.API_URL + 'payment/GetParentOrganizationPaymentReportDetails';

    return this.http_client.post(url, params, this.httpOptions)
      .pipe(map((data: any) => {
        this.player_payment_details = data;
        if (this.player_payment_details['status']) {
          return this.player_payment_details;
        }
        else {
          return '';
        }
      }))
  }

  getCenterDetails(customer_id, organization_id, is_admin) {
    const params = new HttpParams().set('customer_id', customer_id)
      .set('organization_id', organization_id)
      .set('is_admin', is_admin);

    const local_center_url = AppComponent.API_URL + 'center/GetCenterListWithGroups ';
    return this.http_client.post(local_center_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.center_data = data['Academy Center Ids'];
          if (this.center_data.length != 0) {
            return this.center_data;
          }
          else {
            return '';
          }
        }),
      )
  }

  getOrganizationCenterBatches(organization_id,child_academies) {
    let params = new HttpParams();
    params = params.append('parent_organization_id', organization_id);

    child_academies.forEach((element, i) => {
      params = params.append('filters[organization_id]' + '[' + i + ']', element['academy_child_id']);
    });


    const state_list_url = AppComponent.API_URL + 'batch/GetOrganizationCenterBatches';
    return this.http_client.post(state_list_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['status'] == 200) {
            return data;
          }
          else {
            return '';
          }
        }),
      )
  }
  GetBatchDetail(organization_id) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);

    const state_list_url = AppComponent.API_URL + 'batch/GetBatchsByAcademy';
    return this.http_client.post(state_list_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.get_batch_details = data['batch'];
          if (this.get_batch_details.length != 0) {
            return this.get_batch_details;
          }
          else {
            return '';
          }
        }),
      )
  }



  getStaffList(organization_id) {
    const params = new HttpParams().set('organization_id', organization_id);

    const local_staff_url = AppComponent.API_URL + 'user/GetCenterUserList ';
    return this.http_client.post(local_staff_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.staff_data = data['User List'];
          if (this.staff_data.length != 0 && data != false) {
            return this.staff_data;
          }
          else {
            return '';
          }
        }),
      )
  }

  dateFormater(date, date_format) {
    this.date_format = sessionStorage.getItem('date_format');
    var day: any;
    var month: any;
    day = date.getDate();
    month = (date.getMonth() + 1);
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    if (this.date_format == "Y-m-d") {
      return this.temp_date = date.getFullYear() + "-" + month + "-" + day;
    }
    if (this.date_format == "d-m-Y") {
      return this.temp_date = day + "-" + month + "-" + date.getFullYear();
    }
    if (this.date_format == "Y/m/d") {
      return this.temp_date = date.getFullYear() + "/" + month + "/" + day;
    }

    if (this.date_format == "d/m/Y") {
      return this.temp_date = day + "/" + month + "/" + date.getFullYear();
    }

    if (this.date_format == "m/d/Y") {
      return this.temp_date = day + "/" + month + "/" + date.getFullYear();
    }


  }


}