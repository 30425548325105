import { Injectable } from '@angular/core';
import {AppComponent} from '../../app.component';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { ApiUrlConstant } from '../../../app/apiUrl.constant';

@Injectable()

export class ForgotPasswordService 
{
  passwordData:any;
  httpOptions;  

  constructor(private http_client: HttpClient) { }
  
  checkPassword_state(email:string,auth_key:string,session_id:string,device_id:string)
  {   
    const  params = new  HttpParams().set('email_address', email);   

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
    };  
    const local_url=AppComponent.API_URL+ApiUrlConstant.ForgotPassword;    
    return this.http_client.post(local_url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.passwordData = data;          
                if(this.passwordData['Forgot Password  State']=="Success")
                {
                  return true;
                }
                else
                {
                  return false;
                }
            }),
        )   
  }
  checkPassword_stateV2(email:string,auth_key:string,session_id:string,device_id:string,login_flag:string)
  { 

    let params = new HttpParams(); 
    if (login_flag == 'e') 
    {
      params =params.append('email_address', email); 
      params =params.append('login_flag', login_flag);      
    }
    else if (login_flag == 'm') 
    {
      params =params.append('mobile_number', email); 
      params =params.append('login_flag', login_flag);      
    }
    
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
    }; 
    const local_url=AppComponent.API_URL+ApiUrlConstant.ForgotPassword;   
    return this.http_client.post(local_url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
              return data;   
            
            }),
        ) 
  }
}
