import { Injectable } from "@angular/core";
import { AppComponent } from "../../app.component";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map, catchError, tap } from "rxjs/operators";

@Injectable()
export class StaffService {
  staff_data: any = "";
  role_type_data: any;
  add_staff_data: any;
  allowDelete_details: any;
  delete_user_details: any;
  Search_staff_details: any;
  qualification_Details: any;
  staffDetail: any;
  httpOptions;
  date_format: any;
  temp_date: any;

  constructor(private http_client: HttpClient) {
    this.date_format = sessionStorage.getItem("date_format");
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
    };
  }
  getStaffDetails(organization_id) {
    const params = new HttpParams().set("organization_id", organization_id);

    const local_staff_url = AppComponent.API_URL + "user/GetCenterUserList ";
    return this.http_client
      .post(local_staff_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.staff_data = data["User List"];
          if (this.staff_data.length != 0 && data != false) {
            return this.staff_data;
          } else {
            return "";
          }
        })
      );
  }

  getRoleType(organization_id) {
    const params = new HttpParams().set("organization_id", organization_id);
    const local_role_type_url = AppComponent.API_URL + "role/GetRole";
    return this.http_client
      .post(local_role_type_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.role_type_data = data["Role State"];
          if (this.role_type_data.length != 0 && data != false) {
            return this.role_type_data;
          } else {
            return "";
          }
        })
      );
  }

  AddStaff(organization_id,academy_center_id,customer_id,role_id,email_address,first_name,last_name,country_code,
    contact_no,customer_image,customer_thumbnail_image,emailExistFlag,existingstaff,staff_joining_date,
    qualification_id,staff_profile,qualification) {
    let params = new HttpParams();
    params = params.append("organization_id", organization_id);
    if (academy_center_id != null) {
      for (var i = 0; i < academy_center_id.length; i++) {
        params = params.append("academy_center_id[" + i + "]",academy_center_id[i]);
      }
    }
    params = params.append("role_id", role_id);
    if(email_address!=undefined){
      params = params.append("email_address", email_address);
    }
   
    params = params.append("first_name", first_name);
    params = params.append("last_name", last_name);
    // if (contact_no != undefined) {
      // if(contact_no!=0){
        params = params.append("contact_no", contact_no!=undefined?contact_no:'');
      // }
      
      if(country_code!=''  && country_code!=null && country_code!=undefined)
      {
        params =params.append('country_code', country_code); 
      }
      else{
        params =params.append('country_code', ''); 
      }
    // }
    
    if (customer_image != undefined) {
      params = params.append("customer_image", customer_image);
      params = params.append("customer_thumbnail_image",customer_thumbnail_image);
    } else {
      params = params.append("customer_image", "");
      params = params.append("customer_thumbnail_image", "");
    }

    if (emailExistFlag == "Y") {
      params = params.append("emailExistFlag", emailExistFlag);
    }
    if (emailExistFlag == "Y" && existingstaff == "Y") {
      params = params.append("customer_id", customer_id);
    }
    if (existingstaff == "Y") {
      params = params.append("staffFlag", existingstaff);
    }

    if (qualification_id != null) {
      params = params.append("qualification_id", qualification_id);
    } else {
      params = params.append("qualification_id", "");
    }
    if (qualification != null || qualification != undefined) {
      params = params.append("qualification", qualification);
    }
    if (staff_profile != "" || staff_profile != undefined) {
      params = params.append("staff_profile", staff_profile);
    }
    if (staff_joining_date != "") {
      params = params.append("staff_joining_date",this.dateFormater(staff_joining_date));
    }

    const local_add_staff_url = AppComponent.API_URL + "user/AddUser";
    return this.http_client
      .post(local_add_staff_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.add_staff_data = data;
          if (this.add_staff_data.length != 0 && data != false) {
            return this.add_staff_data;
          } else {
            return "";
          }
        })
      );
  }
  IsAllowDeleteUser() {
    const isallow_delete_staff_url =
      AppComponent.API_URL + "user/IsAllowDeleteUser";
    return this.http_client
      .post(isallow_delete_staff_url, [], this.httpOptions)
      .pipe(
        map((data: any) => {
          this.allowDelete_details = data["user_details"];
          if (this.allowDelete_details.length != 0 && data != false) {
            return this.allowDelete_details;
          } else {
            return "";
          }
        })
      );
  }

  DeleteUser(customer_id,academy_center_id,academy_id,role_id,login_customer_id) {
    let params = new HttpParams();
    params = params.append("customer_id", customer_id);
    if(academy_center_id!='')
    {
      params = params.append("academy_center_id", academy_center_id);
    }   
    params = params.append("academy_id", academy_id);
    params = params.append("role_id", role_id);
    params = params.append("login_customer_id", login_customer_id);

    const Local_delete_staff_url = AppComponent.API_URL + "user/DeregisterStaffMember";
    return this.http_client
      .post(Local_delete_staff_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.delete_user_details = data;
          if (this.delete_user_details['status']==true) {
            return this.delete_user_details;
          } else {
            return "";
          }
        })
      );
  }
  searchStaff(searchString, search_flag) {
    let params = new HttpParams();
    params = params.append("searchString", searchString);
    params = params.append("search_flag", search_flag);
    const Local_search_staff_url = AppComponent.API_URL + "user/SearchUser";
    return this.http_client
      .post(Local_search_staff_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.Search_staff_details = data["User Data"];
          if (this.Search_staff_details.length != 0 && data != false) {
            return this.Search_staff_details;
          } else {
            return "";
          }
        })
      );
  }

  getQualificationDetails(organization_id) {
    let params = new HttpParams();
    params = params.append("organization_id", organization_id);
    const Local_search_staff_url =
      AppComponent.API_URL + "user/GetQualificationDetails";
    return this.http_client
      .post(Local_search_staff_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.qualification_Details = data;
          if (this.qualification_Details.length != 0) {
            return this.qualification_Details;
          } else {
            return "";
          }
        })
      );
  }

  dateFormater(date) {
    if (this.date_format == "Y-m-d") {
      return (this.temp_date =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate());
    }
    if (this.date_format == "d-m-Y") {
      return (this.temp_date =
        date.getDate() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getFullYear());
    }
    if (this.date_format == "Y/m/d") {
      return (this.temp_date =
        date.getFullYear() +
        "/" +
        (date.getMonth() + 1) +
        "/" +
        date.getDate());
    }

    if (this.date_format == "d/m/Y") {
      return (this.temp_date =
        date.getDate() +
        "/" +
        (date.getMonth() + 1) +
        "/" +
        date.getFullYear());
    }

    if (this.date_format == "m/d/Y") {
      return (this.temp_date =
        date.getDate() +
        "/" +
        (date.getMonth() + 1) +
        "/" +
        date.getFullYear());
    }
  }

  getStafMemberDetails(organization_id, customer_id) {
    let params = new HttpParams();

    params = params.append("organization_id", organization_id);
    params = params.append("customer_id", customer_id);
    // params = params.append('academy_center_id',academy_center_id);

    const url = AppComponent.API_URL + "user/GetCenterUserList";

    return this.http_client.post(url, params, this.httpOptions).pipe(
      map((data: any) => {
        this.staffDetail = data["User List"];

        if (this.staffDetail.length != 0) {
          return this.staffDetail;
        } else {
          return "";
        }
      })
    );
  }
  deleteStaffMember(customer_id, academy_id, login_customer_id) {
    let params = new HttpParams();
    params = params.append("customer_id", customer_id);
    params = params.append("academy_id", academy_id);
    params = params.append("login_customer_id", login_customer_id);

    const Local_delete_staff_url =
      AppComponent.API_URL + "user/DeleteStaffMember";
    return this.http_client
      .post(Local_delete_staff_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data["status"] == true) {
            return data;
          } else {
            return "";
          }
        })
      );
  }

  editStaffMember(organization_id,customer_id,email_address,first_name,last_name,country_code,
    contact_no,staff_joining_date,customer_image,customer_thumbnail_image,qualification_id,
    qualification, staff_profile,action_flag) {

    let params = new HttpParams();
    params = params.append("organization_id", organization_id);
    if(email_address!=undefined){
      params = params.append("email_address", email_address);
    }   
    params = params.append("first_name", first_name);
    params = params.append("last_name", last_name);

    params = params.append("contact_no", contact_no!=undefined?contact_no:'');
    if(country_code!=''  && country_code!=null && country_code!=undefined)
    {
      params =params.append('country_code', country_code); 
    }else{
      params =params.append('country_code', ''); 
    }
    
    if (staff_joining_date != "") 
    {
      params = params.append(
        "staff_joining_date",
        this.dateFormater(staff_joining_date)
      );
    }
    if (customer_image != undefined) 
    {
      params = params.append("customer_image", customer_image);
      params = params.append(
        "customer_thumbnail_image",
        customer_thumbnail_image
      );
    }
     else 
     {
      params = params.append("customer_image", "");
      params = params.append("customer_thumbnail_image", "");
    }

    params = params.append("customer_id", customer_id);

    if (qualification_id != null) {
      params = params.append("qualification_id", qualification_id);
    } else {
      params = params.append("qualification_id", "");
    }
    if (qualification != null || qualification != undefined) {
      params = params.append("qualification", qualification);
    }
    if (staff_profile != "" || staff_profile != undefined) {
      params = params.append("staff_profile", staff_profile);
    }

    params = params.append("action_flag", action_flag);

    const url = AppComponent.API_URL + "mobile/UpdateStaffProfile";
    return this.http_client.post(url, params, this.httpOptions).pipe(
      map((data: any) => {
        if(data['Update User State'] == 'success'){
          return data['Update User State'];
        }
      })
    );
  }
  assignRole(customer_id, academy_center_id, academy_id, role_id,role_type) {
    let params = new HttpParams();
    params = params.append("customer_id", customer_id);
    if (academy_center_id != null) {
      params = params.append("academy_center_id", academy_center_id);
    }
    params = params.append("academy_id", academy_id);
    params = params.append("role_id", role_id);
    params = params.append("role_type",role_type);

    const url = AppComponent.API_URL + "user/AssignRole";

    return this.http_client.post(url, params, this.httpOptions).pipe(
      map((data: any) => {
        if (data["status"] == true) {
          return data;
        } else {
          return "";
        }
      })
    );
  }
  getCountriesCode(){       
    const local_playerlist_url = AppComponent.API_URL + 'general/GetCountries';  
    return this.http_client.post(local_playerlist_url, '',this.httpOptions)  
      .pipe(map((data:any) =>{                  
        if(data['countries_list'])
        {   
              
          return data;
        }
        else
          {
            return '';
          }
      }),
    )
  }
}
