import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { map, catchError, tap } from 'rxjs/operators';
@Injectable()
export class SalesOrderReportService { 
    httpOptions:any;
    sales_order_data: any;
  date_format: string;
  temp_date: string;
      
    constructor(private http_client:HttpClient)
    {       
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded',     
          // 'auth_key': '' + localStorage.getItem("auth_key"),
          // 'device_id': '' + localStorage.getItem("device_id"),
          // 'session_id': '' + localStorage.getItem("session_id"),
          'Authorization':''+ localStorage.getItem("access_token")
          })
      };
    } 
    getSalesOrder(organization_id,from_date,to_date,center_id) 
    {            

        let params = new HttpParams();    
        params = params.append('organization_id',organization_id);
        params = params.append('from_date',this.dateFormater(from_date));
        params = params.append('to_date',this.dateFormater(to_date));
        center_id.forEach((element,i) => {
            params = params.append('filters[center]['+i+']',element);  
        });

        const url = AppComponent.API_URL + 'payment/GetSalesOrderDetails';
        return this.http_client.post(url,params,this.httpOptions)
        .pipe(
          map((data:any) =>{
            this.sales_order_data =data['accept_player_payment'];
            if(this.sales_order_data!=false)
            {         
              return this.sales_order_data;
            }
            else
              {
                return '';
              }
          }),
        )        
    }
    dateFormater(date)
{ 
  this.date_format = sessionStorage.getItem('date_format');      
  var day:any;
  var month:any;
  day=date.getDate();
  month=(date.getMonth() + 1);
  if(day<10)
  {
    day="0"+day;
  }
  if(month<10)
  {
    month="0"+month;
  }
  if (this.date_format=="Y-m-d") 
  {      
    return this.temp_date = date.getFullYear()+"-"+month+"-"+day;
  }
  if (this.date_format=="d-m-Y") 
  {      
    return this.temp_date = day+ "-"+month+"-"+date.getFullYear();
  }
  if (this.date_format=="Y/m/d") 
  {      
    return this.temp_date = date.getFullYear()+"/"+ month+"/"+day;
  }

  if (this.date_format=="d/m/Y") 
  {      
    return this.temp_date = day+"/"+month+"/"+date.getFullYear();
  }

  if (this.date_format=="m/d/Y") 
  {      
    return this.temp_date =day+"/"+month+"/"+date.getFullYear();
  }   
     
    
}

}
