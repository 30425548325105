import { Injectable } from '@angular/core';
import {AppComponent} from '../app.component';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class DeregisterStudentServices {   
  deregister_player_list:any;
  deregister_player_detail:any;   
  httpOptions;  
  player_id:string ='';

  private _deregisterUpdated: Subject<any> = new Subject();
  deregisterUpdated: Observable<any> = this._deregisterUpdated.asObservable();

  constructor(private http_client: HttpClient) 
  {   
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
    };
  }
   
  getDeregisteredPlayersDetails(organization_id)
  {
    const  params = new  HttpParams().set('organization_id', organization_id);
  
    const url = AppComponent.API_URL + 'player/GetDeregisteredPlayersDetails';
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
            map((data:any) =>{
                this.deregister_player_list =data;
                if(this.deregister_player_list['status'])
                {         
                  return this.deregister_player_list;
                }
                else
                  {
                    return '';
                  }
            }),
            tap(()=>{
              this._deregisterUpdated.next();
            }),
        )  
  }

   getPlayerDeregistrationReportAtParentLevel(organization_id,child_academy)
  {
    let params = new HttpParams();    
    params =params.append('organization_id', organization_id);
    child_academy.forEach((element,i) => {
      params =params.append('filters[organization_id]['+i+']', element);
    });
  
    const url = AppComponent.API_URL + 'report/GetPlayerDeregistrationReport';
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
            map((data:any) =>{
                if(data['deregistration_report_details'].length!=0)
                {         
                  return data;
                }
                else
                  {
                    return '';
                  }
            }),
            tap(()=>{
              this._deregisterUpdated.next();
            }),
        )  
  }
  deleteOrganizationPlayer(organization_id,player_id,academy_center_id)
  {
    const  params = new  HttpParams().set('organization_id', organization_id)
                                     .set('player_id', player_id)
                                     .set('academy_center_id', academy_center_id);

     
    const url = AppComponent.API_URL + 'player/DeleteOrganizationPlayer';
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
            map((data:any) =>{
                if(data['status']==200)
                {         
                  return data;
                }
                else
                  {
                    return '';
                  }
            })
        );  
  }
  deleteOrganizationBulkPlayer(organization_id,player_id,academy_center_id)
  {


    let params = new HttpParams();    
    params =params.append('organization_id', organization_id);
    player_id.forEach((element,i) => {
      params =params.append('player_id['+i+']', element);
    });
    academy_center_id.forEach((element,i) => {
      params =params.append('academy_center_id['+i+']', element);
    });

     
    const url = AppComponent.API_URL + 'player/DeleteOrganizationBulkPlayer';
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
            map((data:any) =>{
                if(data['status']==200)
                {         
                  return data;
                }
                else
                  {
                    return '';
                  }
            })
        );  
  }

}