import { Component, OnInit } from '@angular/core';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { TrendReportServices } from './trend-reports.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-trend-reports',
  templateUrl: './trend-reports.component.html',
  styleUrls: ['./trend-reports.component.css']
})
export class TrendReportsComponent implements OnInit {
  sub:any;
  player_id:any;
  Organization_id:any;
  academy_center_id:any;
  performance_set_assessment_id=[];
  // auth_key:any;
  // session_id:any;
  // device_id:any;

  success_msg: string = '';
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  setAutoHide: boolean = true;
  autoHide: number = 3000;
  actionButtonLabel: string = 'Close';
  action: boolean = true;

  graph_data: any = [];
  player_performance_statistics: any;
  error_msg: string;
  graph_draw: boolean = false;
  data_graph_units:any=[];
  final_graph_value = [];
  datasetValue = [];
  final_table_lables = [];
  final_table_scores = [];  
  final_graph_lables= [];
  graph_div_name = [];
  performance_parameter_name:any;
  table_show: boolean = true;
  data_table_lables: any = [];
  data_table_scores: any = [];
  flag: boolean;
  flag_for_graphs: boolean;
  multiple_score_flag = false;
  graph_numeric_value = [];
  ids:string;
  button_background_color: string = '#FF7254';
  button_text_color: string='#dddddd';
  header_background: string='#055C8D';
  header_text_color: string='#dddddd';
  preference_center_name: any;

  constructor(private TR_services:TrendReportServices,public snackBar:MatSnackBar,private route:ActivatedRoute) { }

  ngOnInit() 
  {
     this.sub = this.route.queryParams.subscribe(params => {  
             
        this.player_id = params['player_id'];
        this.Organization_id = params['org_id'];
        this.ids = params['performance_set_assessment_id']; 
        // this.auth_key = params['auth_key'];
        // this.device_id = params['device_id'];
        // this.session_id = params['session_id'];         
      });      
      // localStorage.setItem("auth_key", this.auth_key);
      // localStorage.setItem("session_id", this.session_id);
      // localStorage.setItem("device_id", this.device_id); 
      // this.button_background_color = sessionStorage.getItem('button_background_color');
      // this.button_text_color = sessionStorage.getItem('button_text_color');
      // this.header_background = sessionStorage.getItem('header_background');
      // this.header_text_color = sessionStorage.getItem('header_text_color');     
      //this.button_background_color = sessionStorage.getItem('button_background_color');
     var id=this.ids.split('_');     
      id.forEach(element => {
        this.performance_set_assessment_id.push(element);
      });
    this.loadTooltip();
  this.getPlayerPerformanceStatistics();
  }
  loadTooltip()
  {
    //for showing tooltips on line chart
    Chart.pluginService.register({
      beforeRender: function (chart) {
        if (chart.config.options.showAllTooltips) {
          // create an array of tooltips
          // we can't use the chart tooltip because there is only one tooltip per chart
          chart.pluginTooltips = [];
          chart.config.data.datasets.forEach(function (dataset, i) {
            chart.getDatasetMeta(i).data.forEach(function (sector, j) {
              chart.pluginTooltips.push(new Chart.Tooltip({
                _chart: chart.chart,
                _chartInstance: chart,
                _data: chart.data,
                _options: chart.options.tooltips,
                _active: [sector]
              }, chart));
            });
          });
          // turn off normal tooltips
          chart.options.tooltips.enabled = false;
        }
      },
      afterDraw: function (chart, easing) {
        if (chart.config.options.showAllTooltips) {
          // we don't want the permanent tooltips to animate, so don't do anything till the animation runs atleast once
          if (!chart.allTooltipsOnce) {
            if (easing !== 1)
              return;
            chart.allTooltipsOnce = true;
          }

          // turn on tooltips
          chart.options.tooltips.enabled = true;
          Chart.helpers.each(chart.pluginTooltips, function (tooltip) {
            tooltip.initialize();
            tooltip.update();
            // we don't actually need this since we are not animating tooltips
            tooltip.pivot();
            tooltip.transition(easing).draw();
          });
          chart.options.tooltips.enabled = false;
        }
      }
    });
  }

  getPlayerPerformanceStatistics() 
  {
    this.graph_draw = false;
    if(this.performance_set_assessment_id.length>1)
    {
    this.TR_services.getPlayerPerformanceStatistics(this.Organization_id, this.player_id, this.performance_set_assessment_id).subscribe(
      response => {
        if (response != '') {
          this.final_graph_value = [];
          this.datasetValue=[];
          this.final_table_lables = [];
          this.final_table_scores = [];
          this.final_graph_lables = [];
          this.graph_div_name = [];
          this.data_graph_units=[];
          this.preference_center_name=response['preference_center_name']

          this.player_performance_statistics = response['player_performance_statistics'][0];
    
          this.graph_data = response['player_performance_statistics'][0]['graph_data'];
          this.error_msg = "";        
          this.table_show = false;
          this.performance_parameter_name = [];
          for (var i = 0; i < this.graph_data.length; i++) 
          {
            var even_index_value = [];
            var odd_index_value = [];
            for (var j = 0; j < this.graph_data[i]['score_type'].length; j++) 
            {
              if (this.graph_data[i]['score_type'][j] == "Range" || this.graph_data[i]['score_type'][j] == "Text") 
              {
                this.data_table_lables.push(this.graph_data[i]['data_labels'][j]+' - '+this.graph_data[i]['assessment_start_end_date'][j]);
                this.data_table_scores.push(this.graph_data[i]['student_score'][j]);
                this.table_show = true;
                this.flag = true;
                this.flag_for_graphs = false;
              }
              else 
              {
                this.flag = false;
              }
              if (this.graph_data[i]['score_type'][j] == "Numeric" ||this.graph_data[i]['score_type'][j] == "Rating") 
              {
                
                if (this.graph_data[i]['student_score'][j].indexOf(',') > -1) 
                {
                  
                  var abc = this.graph_data[i]['student_score'][j].split(',');                 
                  for (var a = 0; a < abc.length; a++) 
                  {
                    if (a % 2 == 0) 
                    {
                      even_index_value.push(abc[a]);
                      this.flag_for_graphs = true;
                      this.multiple_score_flag = true;
                    }
                    else
                    {
                      odd_index_value.push(abc[a]);                     
                      this.multiple_score_flag = true;
                    }
                  }
                }
                else 
                {                  
                  this.graph_numeric_value.push(this.graph_data[i]['student_score'][j]);                  
                  this.flag_for_graphs = true;
                  this.multiple_score_flag = false;                
                }
                this.data_table_lables.push(this.graph_data[i]['data_labels'][j]+' - '+this.graph_data[i]['assessment_start_end_date'][j]);               
              }              
            }            
            if (this.multiple_score_flag === true) 
            { 
              this.graph_numeric_value.push(even_index_value);
              this.graph_numeric_value.push(odd_index_value);    
            }

            if (this.flag=== true) {
              this.performance_parameter_name.push(this.graph_data[i]['performance_parameter_name']);
            }
            else {
              this.performance_parameter_name.push('');
            }
            if (this.flag_for_graphs=== true) {
              this.graph_div_name.push(this.graph_data[i]['performance_parameter_name']);
            }
            else {
              //this.graph_div_name.push('');
            }
            this.final_graph_lables.push(this.data_table_lables);
            this.final_table_lables.push(this.data_table_lables);
            this.final_table_scores.push(this.data_table_scores);
            if(this.graph_numeric_value.length!=0)
            {
            this.final_graph_value.push(this.graph_numeric_value);
            this.data_graph_units.push(this.graph_data[i]['score_unit']);
            }
            this.data_table_lables = [];
            this.data_table_scores = [];
            this.graph_numeric_value = [];
          }      
          this.drawGraphs(); 
        }
        else {       
          this.success_msg = "Student score yet to update in the System";
          this.snackBarDisplay();
          this.graph_data = [];
        }
      },
      error => this.error_msg = error
    );
  }
  else
  {
    // this.success_msg="Select two or more assessments to generate student progress reports";
    // this.snackBarDisplay();
    this.final_graph_value = [];
    this.datasetValue=[];
    this.final_table_lables = [];
    this.final_table_scores = [];
    this.final_graph_lables = [];
    this.graph_div_name = [];
    this.performance_parameter_name=[];
  }
  }

   drawGraphs() {
      
    if (this.final_graph_value.length != 0) 
    {
      
      this.graph_draw = true;
      setTimeout(() => {
        for (var i = 0; i < this.final_graph_value.length; i++) {
          (function (i) 
		{
            if(this.final_graph_value[i].length==1 || this.final_graph_value[i].length!=1)
            { 
            this.chart = new Chart('canvas' + i, {
              type: this.graph_data[i]['chart_type'],
              data: {
                labels: this.final_graph_lables[i],               
                datasets: [{
                lineTension: 0,
                label: '',              
                data: this.final_graph_value[i],
                backgroundColor: [
                    "rgb(" + this.graph_data[i]['background_color']["r"]+ "," + this.graph_data[i]["background_color"]["g"]+ "," + this.graph_data[i]["background_color"]["b"] + ")"
                  ],
                borderColor: [
                  "rgb(" + this.graph_data[i]['background_color']["r"]+ "," + this.graph_data[i]["background_color"]["g"]+ "," + this.graph_data[i]["background_color"]["b"] + ")"
                ],
                borderWidth: 1,
                fill:false,
                // pointHoverRadius: 1,
                pointHoverBackgroundColor: "rgb(" + this.graph_data[i]['background_color']["r"]+ "," + this.graph_data[i]["background_color"]["g"]+ "," + this.graph_data[i]["background_color"]["b"] + ")",
                pointBackgroundColor: "rgb(" + this.graph_data[i]['background_color']["r"]+ "," + this.graph_data[i]["background_color"]["g"]+ "," + this.graph_data[i]["background_color"]["b"] + ")"

                }]
              },
              options: {
                responsive: true,
   // maintainAspectRatio: false,
    animation: {
        duration: 0 
    },
                scales: {
                  yAxes: [{
                    ticks: {
                      display: true,
                      padding: 5,
                      fontSize: 10,
                      stepSize: 1
                      //beginAtZero:true
                    },
                    gridLines: {
                      display: false,
                      drawBorder: true,
                    },
                    scaleLabel: {
                      display: true,
                      labelString: this.data_graph_units[i][0]
                    }
                  }],
                  xAxes: [{
                    gridLines: {
                      display: false,
                      drawBorder: true,

                    },
                    ticks: {
                      padding: 1,
                      fontSize: 9
                    }
                  
                  }],

                },
                legend: {
                  display: false
                },
                tooltips: {
                  backgroundColor: this.button_background_color,
                  callbacks: {
                    title: function () { },
                    pointBackgroundColor: function () { }, 
                  },
                  yAlign: "bottom",
                  displayColors: false
                },
                showAllTooltips: true,
                layout: {
                  padding: {
                    top: 40
                  }
                },
              }
            });
          }       
          
           }).call(this, i); 
          
        }
        
      }, 1000);
     }
  }

  snackBarDisplay() 
  {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    this.snackBar.open(this.success_msg, this.action ? this.actionButtonLabel : undefined, config);
  }
}
