import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {ForgotPasswordService} from '../forgotPassword_services/forgotPassword.service';
import {LoginService} from '../login_services/login.service';
import { UUID } from 'angular2-uuid';
import {MatSnackBar,MatSnackBarConfig,MatSnackBarHorizontalPosition,MatSnackBarVerticalPosition} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css'],
})
export class ForgotComponent implements OnInit {

  password_data:any;
  public forgot_form: FormGroup;
  email:string = '';
  emailAlert: string = 'Please enter a Valid Email Address';
  device_id:any;
  alertmsg:string='';
  session_id:any;  
  formInvalidFlag:boolean=false;
  invalidmsg:any;
  login_flag: string;
  sos_login_flag: boolean;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  setAutoHide: boolean = true;
  autoHide: number = 3000;
  actionButtonLabel: string = 'Close';
  action: boolean = true;
  datastream_flag: boolean;
  sportz_village_flag: boolean;
  al_shabab_flag: boolean;
  yalla_goal_flag: boolean;
  sports_pad_flag:boolean;
  constructor(private fb: FormBuilder, private router: Router,private forgot_pwd_service:ForgotPasswordService,
    private login_service:LoginService,public snackBar:MatSnackBar,private translate:TranslateService)  
  {
    this.sos_login_flag=sessionStorage.getItem('sos_login_flag')=='Y'?true:false;
    this.datastream_flag=sessionStorage.getItem('datastream_flag')=='Y'?true:false;    
    this.sportz_village_flag=sessionStorage.getItem('sportz_village_flag')=='Y'?true:false;
    this.al_shabab_flag=sessionStorage.getItem('al_shabab_flag')=='Y'?true:false;
    this.yalla_goal_flag = sessionStorage.getItem('yalla_goal_flag')=='Y'?true:false;
    this.sports_pad_flag = sessionStorage.getItem('sports_pad_flag')=='Y'?true:false;
    const browserLang = sessionStorage.getItem("locale")!=null?sessionStorage.getItem("locale"):'en';
    translate.use(browserLang.match(/en|hi/) ? browserLang : 'en');

  }
  ngOnInit() 
  {   
    this.forgot_form = this.fb.group({
      Email:  [null , Validators.compose ( [ Validators.required,Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}|(\d{10})$/)])],
    });
  }

  onSubmit(post) {
      if(this.forgot_form.valid)
      {
        this.formInvalidFlag=false;     
        this.email=post.Email;
        this.login_service.checkCredentialsV2(this.email,'',this.login_flag).subscribe(
          response => 
          {

            if(response['status']==200 && response['data']['user_state']=='Valid')
            {
              
              this.session_id=this.generateSessionID();
              this.device_id=this.session_id;
                 
                      // this.forgot_pwd_service.checkPassword_state(this.email,response['auth_key'],this.session_id,this.device_id).subscribe(
                      this.forgot_pwd_service.checkPassword_stateV2(this.email,response['auth_key'],this.session_id,this.device_id,this.login_flag).subscribe(
                        success => 
                        
                        {                                                  
                          if(success['status']==200) 
                          {  
                            this.alertmsg=success['message']; 
                            this.snackBarDisplay();
                            if(this.sos_login_flag){
                              this.router.navigate(['sos-login'],{ queryParams: {id: this.email,flag:this.login_flag}}); 
                            }
                            if(this.sports_pad_flag){
                              this.router.navigate(['sports-pad-login'],{ queryParams: {id: this.email,flag:this.login_flag}}); 
                            }else{
                              this.router.navigate(['login'],{ queryParams: {id: this.email,flag:this.login_flag}}); 
                            }
                            
                          }
                          else
                          {                           
                            this.invalidmsg="Entered email address / Mobile is not registered";                           
                          }
                            
                         
                        },
                      error => console.log("Forgot Password is not working!")
                    );                      
            
              
            }
            else
            {
              //console.log("UNDONE");
              this.invalidmsg="Entered email address / Mobile is not registered";   
             
            }
              
         },
         error => console.log("login is not working!")
        );   
      }
      else{
        this.formInvalidFlag=true;
      }  
  }

  generateSessionID():string
  {
    let uuid = UUID.UUID();
    return uuid;
  }

  checkEmailorMobile(userID)
  {
    
    this.login_flag='';    
    var email_id_chk=/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    var phone_no_chk=/^(?:\d{10})$/;
    if (email_id_chk.test(userID) == true) 
    {   
      return this.login_flag='e';
    }
    if (phone_no_chk.test(userID) == true) 
    {  
      return this.login_flag='m';
    }      
  }
  clearMsg()
  {
    this.alertmsg='';
    this.invalidmsg='';
  }

  snackBarDisplay() 
  {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    this.snackBar.open(this.alertmsg,this.action ? this.actionButtonLabel : undefined,config);
  } 

  
}
