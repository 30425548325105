import { MatDialog, MatPaginator, MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatTableDataSource } from '@angular/material';
import { DeleteRequestListService } from './../delete-request-list.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-delete-req-listing',
  templateUrl: './delete-req-listing.component.html',
  styleUrls: ['./delete-req-listing.component.css']
})
export class DeleteReqListingComponent implements OnInit {
  customer_id: any;
  request_listing: any = [];
  displayedColumns: string[] = ['Name', 'Registered Email Id', 'Registered Mobile', 'Academy', 'Requested On', 'reason_for_deletion', 'Status'];

  request_listing_datasource = new MatTableDataSource(this.request_listing);
  request_id: any;
  student_data: any;
  change_request_status_form: FormGroup;
  header_background: string;
  button_text_color: string;
  header_text_color: string;
  button_background_color: string;
  main_text_color: string;
  status: any;
  rejection_reason: any;
  update_status: any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  setAutoHide: boolean = true;
  autoHide: number = 3000;
  actionButtonLabel: string = 'Close';
  action: boolean = true;
  success_msg: string;
  constructor(private service: DeleteRequestListService, public dialog: MatDialog, private fb: FormBuilder, public snackBar: MatSnackBar) {
    this.customer_id = localStorage.getItem('customer_id');
    this.button_background_color = sessionStorage.getItem('button_background_color');
    this.button_text_color = sessionStorage.getItem('button_text_color');
    this.header_background = sessionStorage.getItem('header_background');
    this.header_text_color = sessionStorage.getItem('header_text_color');
    this.main_text_color = sessionStorage.getItem('main_text_color');
  }

  ngOnInit() {
    this.getDeleteRequestList();
    this.change_request_status_form = this.fb.group({
      status: [null, Validators.compose([Validators.required])],
      rejection_reason: new FormControl,
    });
  }

  getDeleteRequestList() {
    this.service.getDeleteRequestList(this.customer_id).subscribe(
      response => {
        if (response != '') {
          this.request_listing = response;
          this.request_listing_datasource.data = this.request_listing;
        }
      }
    )
  }
  @ViewChild('changeStatusPopUp') changeStatusPopUp: TemplateRef<any>;
  changeStatus(data: any) {
    this.request_id = data.request_id;
    this.student_data = data;
    this.dialog.open(this.changeStatusPopUp, {

      width: '440px',
      disableClose: true,
    });
  }
  onStatusOptionChanged(status: any) {
    if (status == 'Approved') {
      this.change_request_status_form.get('rejection_reason').clearValidators();
      this.change_request_status_form.get('rejection_reason').updateValueAndValidity();
    } else {
      this.change_request_status_form.get('rejection_reason').setValidators([Validators.compose([Validators.required])]);
      this.change_request_status_form.get('rejection_reason').updateValueAndValidity();
    }
  }

  updateDeleteRequestStatus() {
    this.service.updateDeleteRequestStatus(this.change_request_status_form.value, this.request_id, this.rejection_reason).subscribe(
      response => {
        if (this.change_request_status_form.valid) {
          this.update_status = response;
          this.getDeleteRequestList();
          if (this.change_request_status_form.value.status == 'Rejected') {
            this.success_msg = "Status Updated Successfully";
            this.snackBarDisplay();
            this.change_request_status_form.get('status').reset();
            this.dialog.closeAll();

          }
          if (this.change_request_status_form.value.status == 'Approved') {
            this.change_request_status_form.get('status').reset();
            this.success_msg = "Status Updated Successfully";
            this.snackBarDisplay();
            this.dialog.closeAll();
          }
        }
      }
    )
  }
 
  snackBarDisplay() {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    this.snackBar.open(this.success_msg, this.action ? this.actionButtonLabel : undefined, config);
  }

  @ViewChild('paginator') paginator: MatPaginator;

  ngAfterViewInit() {
    this.request_listing_datasource.paginator = this.paginator;
  }
}
