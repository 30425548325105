import { AppComponent } from './../../app.component';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeleteRequestListService {

  httpOptions: any;
  request_status: any;
  update_status: any;

  constructor(private http_client: HttpClient) {

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };
  }
  
  getDeleteRequestList(customer_id){
    let params=new HttpParams();
    params=params.append('customer_id',customer_id);
    const url=AppComponent.API_URL+'request/GetDeleteRequestList';
    
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(
      map((data: any) => {
        this.request_status = data['data']['request_list'];
        if (this.request_status != 0) {
          return this.request_status;
        }
        else {
          return '';
        }
      }),
    )
  }

  updateDeleteRequestStatus(form_data,request_id,rejection_reason){
    let params=new HttpParams();
    params=params.append('status',form_data.status);
    params=params.append('request_id',request_id);
    if(form_data.status=='Rejected'){
      params=params.append('rejection_reason',form_data.rejection_reason);
    }
    const url=AppComponent.API_URL+'request/UpdateDeleteRequestStatus';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(map((data)=>{
         this.update_status=data['data']['update_request_data'];
         if(this.update_status!=0){
          return this.update_status;
         }
         else{
          return '';
         }
    }))
  }

}
