<div class="background-image">
  <img class="img-fluid" src="assets/images/bg-1.jpg" alt="Acadware" />
</div>

<p class="" style="text-align:center;  margin-bottom:20px; float:left; width:100%;">
  <span class="acad-img"><img src="assets/images/acadware_logo_vme_white.png" alt="Acadware"
      style="width:215px;margin-top:50px;" /></span>
</p>

<div class="table-overlay">
  <div class="card-container">
    <div class="uk-grid" style="margin-top: 90px;">
      <div class="uk-width-medium-1-1 uk-row-first">
        <div class="uk-grid uk-grid-collapse" style="margin-left: -3px;">
          <div class="md-card spiral-binding" style="margin-left:4px; height:100%;width: 100%;">
            <div class="md-card" style="height:100%;width: 100%;">
              <div class="md-card-content">
                <div class="demo-tab-content">
                  <mat-table [dataSource]="request_listing_datasource" class="custom-table">
                    <ng-container matColumnDef="Name">
                      <mat-header-cell *matHeaderCellDef>Name
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">{{element.name}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Registered Email Id">
                      <mat-header-cell *matHeaderCellDef>Registered Email 
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">{{element.email_address}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Registered Mobile">
                      <mat-header-cell *matHeaderCellDef> Registered Mobile
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">{{element.mobile_number}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Academy">
                      <mat-header-cell *matHeaderCellDef> Academy
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">{{element.organization_name}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Requested On">
                      <mat-header-cell *matHeaderCellDef>Requested On</mat-header-cell>
                      <mat-cell *matCellDef="let element">{{element.created_date}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="reason_for_deletion">
                      <mat-header-cell *matHeaderCellDef>Reason For Deletion</mat-header-cell>
                      <mat-cell *matCellDef="let element">{{element.reason_for_deletion}}</mat-cell>
                    </ng-container>

                    <!-- <ng-container matColumnDef="Status">
                      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                      <mat-cell *matCellDef="let element">{{element.status}}
                        <button mat-flat-button color="primary" *ngIf="element.status=='Submitted'"
                        (click)="changeStatus(element)">
                        Update Status
                        </button>     
                      </mat-cell> 
                    </ng-container> -->

                    <ng-container matColumnDef="Status">
                      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                      <mat-cell *matCellDef="let element" style="display: flex; align-items: center;">
                        {{element.status}}
                        <button mat-icon-button color="primary" matTooltip="Update Status" aria-label="Update Status" *ngIf="element.status=='Submitted'" (click)="changeStatus(element)">
                          <mat-icon>edit</mat-icon>
                        </button>
                      </mat-cell>
                    </ng-container>
                    
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  </mat-table>
                  <mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5]" [showFirstLastButtons]="true">
                  </mat-paginator>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p style="display: flex; justify-content: center; margin-top: 20px;color:white;">Back  to &nbsp;
    <a [routerLink]="['/login']" style="color: #13b8d5;">Login</a>
  </p>
  
  <ng-template #changeStatusPopUp>
    <h4 mat-dialog-title>View Delete Request - {{student_data.name}} 
      <button mat-icon-button style="float:right;" (click)="change_request_status_form.reset()" mat-dialog-close><mat-icon>close</mat-icon></button>
    </h4>  
    <form [formGroup]="change_request_status_form">
      <mat-dialog-content> 
       
        <p style="margin:0px 0px 2px 0px;"><b>Registered Email :</b> {{student_data.email_address}} </p><br>
        <p style="margin:0px 0px 2px 0px;"><b>Registered Mobile:</b> {{student_data.mobile_number}} </p><br>
        <p style="margin:0px 0px 2px 0px;"><b>Academy :</b>  {{student_data.organization_name}} </p><br>
        <p style="margin:0px 0px 2px 0px;"><b>Requested On :</b>  {{student_data.created_date}} </p><br>
        <p style="margin:0px 0px 2px 0px;"><b>Reason For Deletion :</b>  {{student_data.reason_for_deletion}} </p><br>
        <mat-form-field appearance="outline">
          <mat-label [style.color]="header_background">Status</mat-label>
          <mat-select formControlName="status" (selectionChange)="onStatusOptionChanged($event.value)">
            <mat-option  value="Approved">Approved</mat-option>
            <mat-option  value="Rejected">Rejected</mat-option>
          </mat-select>
          <mat-error *ngIf="!change_request_status_form.controls['status'].valid && !change_request_status_form.controls['status'].untouched || form_invalid_flag">Please select status</mat-error>
        </mat-form-field> 
        <mat-form-field appearance="outline" *ngIf="change_request_status_form.value.status=='Rejected'">
          <mat-label [style.color]="header_background">Rejection Reason</mat-label>
          <input matInput formControlName="rejection_reason"/>
          <mat-error *ngIf="!change_request_status_form.controls['rejection_reason'].valid && !change_request_status_form.controls['rejection_reason'].untouched || form_invalid_flag">
            Please enter rejection reason
          </mat-error>
        </mat-form-field>    
      </mat-dialog-content>
      <mat-dialog-actions>
        <button mat-raised-button color="primary" (click)="updateDeleteRequestStatus();" 
        [style.background-color]="change_request_status_form.controls['status'].value ? '#3f51b5' : '#9e9e9e'" 
        [style.color]="change_request_status_form.controls['status'].value ? '#ffffff' : '#000000'" 
        [disabled]="!change_request_status_form.controls['rejection_reason'].valid || !change_request_status_form.controls['status'].valid">{{'Submit' | translate}}</button>
        <button mat-raised-button color="primary" (click)="change_request_status_form.reset()" mat-dialog-close>{{'Cancel' | translate}}</button>       
      </mat-dialog-actions> 
    </form>       
  </ng-template>



</div>