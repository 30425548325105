<div class="background-image">
  <img class="img-fluid" src="assets/images/bg-1.jpg" />
</div>

<p class="" style="text-align:center; margin-top:70px; margin-bottom:20px; float:left; width:100%;">
  <span class="acad-img"><img src="assets/images/acadware_logo_vme_white.png" alt="Acadware"
      style="width:215px;" /></span>
</p>

<div class="card-container">
  <div class="uk-grid" style="margin-top: 0px;">
    <div class="uk-width-medium-1-1 uk-row-first">
      <div class="uk-grid uk-grid-collapse">
        <div class="md-card spiral-binding" style="margin-left:4px; height:100%;width: 100%;">
          <div class="md-card" style="height:100%;width: 100%;">
            <div class="md-card-content" style="display: flex; align-items: center;">

              <div style="margin-right: 20px;height:500px;">
                <h4>Data Deletion Request Process</h4>
                <img src="assets/images/data-flow1.png" alt=""
                  style="margin-right: 350px;height:480px;margin-top: -5px;">
              </div>

              <form [formGroup]="data_deletion_form" style="width: 100%;">

                <div style="margin-bottom: 20px;">

                  <h4>Data Deletion Request</h4>
                  <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>{{ 'Name' | translate }}</mat-label>
                    <input matInput formControlName="name" placeholder="Enter your name" required>
                    <mat-error *ngIf="data_deletion_form.get('name').hasError('required')">Name is required</mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Registered Email</mat-label>
                    <input matInput formControlName="email_address" placeholder="Enter your email" required>
                    <mat-error *ngIf="data_deletion_form.get('email_address').hasError('required')">Email is
                      required</mat-error>
                    <mat-error *ngIf="data_deletion_form.get('email_address').hasError('email')">Invalid email
                      address</mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Registered Mobile</mat-label>
                    <input matInput formControlName="mobile_number" placeholder="Enter your registered mobile number"
                      required>
                    <mat-error *ngIf="data_deletion_form.get('mobile_number').hasError('required')">Mobile number is
                      required</mat-error>
                    <mat-error *ngIf="data_deletion_form.get('mobile_number').hasError('mobile')">Invalid mobile
                      number</mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Academy Name</mat-label>
                    <input matInput formControlName="organization_name" placeholder="Enter your Academy Name" required>
                    <mat-error *ngIf="data_deletion_form.get('organization_name').hasError('required')">Academy Name is
                      required</mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Reason For Deletion</mat-label>
                    <input matInput formControlName="reason_for_deletion"
                      placeholder="Enter your reason for deletion here" required>
                    <mat-error *ngIf="data_deletion_form.get('reason_for_deletion').hasError('required')">Reason is
                      required</mat-error>
                  </mat-form-field>
                </div>

                <button mat-raised-button color="primary" (click)="submitForm()"
                  style="background-color: #13b8d5;color:aliceblue;margin-left:130px;"
                  [disabled]="data_deletion_form.invalid">Submit</button>

                <p>
                  <a [routerLink]="['/login']" style="margin-left:130px;">Back to Login</a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>