<div class="login_page_wrapper login-div">
    <div class="background-image">
        <img class="img-fluid" src="assets/images/bg-1.jpg" alt="Acadware" />        
    </div> 
</div>
<ng-template #term_conditon>
  <h4 mat-dialog-title>TERMS OF USE
      <button mat-icon-button [mat-dialog-close]=" " style="float:right;" (click)="cancel()">
          <mat-icon aria-hidden="false">close</mat-icon>
      </button>
  </h4> 
  <mat-dialog-content>
      <p *ngIf="term_conditon_data" [innerHTML]="term_conditon_data.content"></p>  
  </mat-dialog-content>    
  <mat-dialog-actions>  
      <button mat-raised-button color="primary" [mat-dialog-close]=" " style="background-color:#13b8d5;" (click)="cancel()" >Cancel</button>
  </mat-dialog-actions>
</ng-template>
