import { Injectable } from '@angular/core';
import { AppComponent } from '../app.component';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { ApiUrlConstant } from '../apiUrl.constant';
import { Md5 } from 'ts-md5';

@Injectable()
export class AcademyProfileService {
  role_type: string;
  customerData: any;
 
  academy_details: any;  
  addOrganizationForm_Data: any; 
  update_organisation: any; 
  state_list:any; 
  cities_list:any; 
  Preferences_details:any;
  setPreferences_details:any;
   
  httpOptions;  
  organization_data: any;

  constructor(private http_client: HttpClient) 
  {   
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
    };
    //console.log(this.httpOptions)
  }

  getOrganizationDetails(organization_id)
  {    
    let params = new HttpParams();    
    params =params.append('organization_id', organization_id);  
    const local_playerlist_url = AppComponent.API_URL + 'organization/GetOrganizationDetails';
    return this.http_client.post(local_playerlist_url, params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.academy_details =data['data']['academy_details'];
                if(this.academy_details.length!=0)
                {         
                  return this.academy_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }
  addOrganizationFormData()
  {
    const local_formData_url = AppComponent.API_URL + 'mobile/AddOrganizationFormData';
    return this.http_client.post(local_formData_url,[], this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.addOrganizationForm_Data =data['add_organization_form_data'];
                if(this.addOrganizationForm_Data.length!=0)
                {         
                  return this.addOrganizationForm_Data;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }
 GetStates(country_id)
  {
    let params = new HttpParams();    
    params =params.append('country_id', country_id); 
    const local_getState_url = AppComponent.API_URL + 'general/GetStates';
    return this.http_client.post(local_getState_url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.state_list =data['states_list'];
                if(this.state_list.length!=0)
                {         
                  return this.state_list;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }
  GetCities(country_id,state_id)
  {
    let params = new HttpParams();    
    params =params.append('country_id', country_id); 
    params =params.append('state_id', state_id); 
    const local_getCities_url = AppComponent.API_URL + 'general/GetCities';
    return this.http_client.post(local_getCities_url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.cities_list =data['cities_list'];
                if(this.cities_list.length!=0)
                {         
                  return this.cities_list;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }
   
  updateOrganization(customer_id,organization_name,organization_app_access,sport_type,organization_establish_date,
    country_code,organization_contact_number,organization_kit_provided_flag,organization_biometric_taken_flag,country_id,
    state_id,city_id,district,zipcode,address_line1,address_line2,organization_email,organization_website_link,
    organization_facebook_link,organization_id,contact_id,organization_online_payment_flag,LTAD_flag,
    cheque_tracking_flag,capture_parents_info_flag,play_store_url,app_store_url) 
  {
    let params = new HttpParams();    
    params =params.append('customer_id', customer_id); 
    params =params.append('organization_name', organization_name); 
    params =params.append('organization_app_access',organization_app_access); 
    params =params.append('sport_type', sport_type); 
    params =params.append('organization_establish_date', organization_establish_date); 
    params =params.append('country_code', country_code); 
    params =params.append('organization_contact_number', organization_contact_number); 
    params =params.append('organization_kit_provided_flag', organization_kit_provided_flag); 
    params =params.append('organization_biometric_taken_flag', organization_biometric_taken_flag); 
    params =params.append('country_id', country_id); 
    params =params.append('state_id', state_id); 
    params =params.append('city_id', city_id); 
    params =params.append('district', district); 
    params =params.append('zipcode', zipcode); 
    params =params.append('address_line1', address_line1); 
    params =params.append('address_line2', address_line2); 
    params =params.append('organization_email', organization_email); 
    params =params.append('organization_website_link', organization_website_link); 
    params =params.append('organization_facebook_link', organization_facebook_link); 
    params =params.append('organization_id', organization_id); 
    params =params.append('contact_id', contact_id); 
    params =params.append('organization_online_payment_flag', organization_online_payment_flag); 
    params =params.append('LTAD_flag', LTAD_flag); 
    params =params.append('cheque_tracking_flag', cheque_tracking_flag); 
    params =params.append('capture_parents_info_flag', capture_parents_info_flag); 

    // params =params.append('play_store_url', play_store_url); 
    // params =params.append('app_store_url', app_store_url); 
    const local_updateOrganization_url = AppComponent.API_URL + 'organization/UpdateOrganization';
    return this.http_client.post(local_updateOrganization_url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.update_organisation =data['organization_details'];
                if(this.update_organisation.length!=0)
                {         
                  return this.update_organisation;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }
   
  getpreferences(organization_id)
  {
    let params = new HttpParams();    
    params =params.append('organization_id', organization_id);     
    const local_getPreference_url = AppComponent.API_URL + 'preferences/Getpreferences';
    return this.http_client.post(local_getPreference_url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.Preferences_details =data['Preferences details'];
                if(this.Preferences_details.length!=0)
                {         
                  return this.Preferences_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }
  Setpreferences(organization_id,organization_name,cmbHeight,cmbWeight,cmbCurrency,cmbFontFamily,cmbDateFormats,cmbRegBackgroundColor,
    cmbRegTextColor,cmbRegFormHintTextColor,cmbMainPageBackgroundColor,cmbMainTextColor,cmbPgHeaderBgColor,cmbPgHeaderTextColor,
    cmbCardHeaderBackgroundcolor,cmbCardHeaderTextcolor,cmbButtonBackgroundcolor,cmbButtonTextcolor,cmbSelectedTabunderlinecolor,
    cmbSideNavHeaderBackgroundColor,cmbSideNavHeaderFontColor,cmbSideNavHeaderMenuColor,cmbSideNavBackgroundColor,org_image,
    org_thumbnail_image,academy_preferences_id,center_name)
  {
    let params = new HttpParams();    
    params =params.append('organization_id', organization_id);    
    params =params.append('organization_name', organization_name);  
    params =params.append('cmbHeight', cmbHeight);   
    params =params.append('cmbWeight', cmbWeight);  
    params =params.append('cmbCurrency', cmbCurrency);  
    params =params.append('cmbFontFamily', cmbFontFamily); 
    params =params.append('cmbDateFormats', cmbDateFormats);
    params =params.append('center_name', center_name);
     
    params =params.append('cmbRegBackgroundColor', cmbRegBackgroundColor);  
    params =params.append('cmbRegTextColor', cmbRegTextColor);  
    params =params.append('cmbRegFormHintTextColor', cmbRegFormHintTextColor);  
    params =params.append('cmbMainPageBackgroundColor', cmbMainPageBackgroundColor);  
    params =params.append('cmbMainTextColor', cmbMainTextColor);  
    params =params.append('cmbPgHeaderBgColor', cmbPgHeaderBgColor);  
    params =params.append('cmbPgHeaderTextColor', cmbPgHeaderTextColor);  
    params =params.append('cmbCardHeaderBackgroundcolor', cmbCardHeaderBackgroundcolor);  
    params =params.append('cmbCardHeaderTextcolor', cmbCardHeaderTextcolor);  
    params =params.append('cmbButtonBackgroundcolor', cmbButtonBackgroundcolor);  
    params =params.append('cmbButtonTextcolor', cmbButtonTextcolor);
    params =params.append('cmbSelectedTabunderlinecolor', cmbSelectedTabunderlinecolor);  
    params =params.append('cmbSideNavHeaderBackgroundColor', cmbSideNavHeaderBackgroundColor);  
    params =params.append('cmbSideNavHeaderFontColor', cmbSideNavHeaderFontColor);
    params =params.append('cmbSideNavHeaderMenuColor', cmbSideNavHeaderMenuColor);   
    params =params.append('cmbSideNavBackgroundColor', cmbSideNavBackgroundColor);  
    
    if(org_image==undefined || org_thumbnail_image==undefined)
    {
      params =params.append('org_image', '');  
      params =params.append('org_thumbnail_image', ''); 
    }
    else
    {
      params =params.append('org_image', org_image);  
      params =params.append('org_thumbnail_image', org_thumbnail_image); 
    }
    
    params =params.append('academy_preferences_id', academy_preferences_id);

    const local_setPreference_url = AppComponent.API_URL + 'preferences/Setpreferences';
    return this.http_client.post(local_setPreference_url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.setPreferences_details =data['Response as inserted/updated  academy_preferences_id'];
                if(this.setPreferences_details!="")
                {         
                  return this.setPreferences_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }
  
  getCountriesCode(){       
    const local_playerlist_url = AppComponent.API_URL + 'general/GetCountries';  
    return this.http_client.post(local_playerlist_url, '',this.httpOptions)  
      .pipe(map((data:any) =>{                  
        if(data['countries_list'])
        {   
              
          return data;
        }
        else
          {
            return '';
          }
      }),
    )
  }
  deleteAcademy(organization_id){
    let params=new HttpParams();
    params=params.append('organization_id',organization_id);
   
    const delete_academy=AppComponent.API_URL+ 'delete_organization/DeleteMaster';
    return this.http_client.post(delete_academy,params,this.httpOptions)
    .pipe(map((data:any)=>{
       return data;
    }))
   }

  deleteAllRequest(organization_id){
    let params=new HttpParams();
    params=params.append('organization_id',organization_id);
   
    const url=AppComponent.API_URL+ 'delete_organization/DeleteAllRequest';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(map((data:any)=>{
       return data;
    }))
   }

   deleteRankingAndTaggingData(organization_id){
    let params=new HttpParams();
    params=params.append('organization_id',organization_id);
   
    const url=AppComponent.API_URL+ 'delete_organization/DeleteRankingAndTaggingData';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(map((data:any)=>{
       return data;
    }))
   }

   deleteCompetitionData(organization_id){
    let params=new HttpParams();
    params=params.append('organization_id',organization_id);
   
    const url=AppComponent.API_URL+ 'delete_organization/DeleteCompetitionData';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(map((data:any)=>{
       return data;
    }))
   }
   
   deletePost(organization_id){
    let params=new HttpParams();
    params=params.append('organization_id',organization_id);
   
    const url=AppComponent.API_URL+ 'delete_organization/DeletePost';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(map((data:any)=>{
       return data;
    }))
   }
   
   deleteCalendarRelatedActivities(organization_id){
    let params=new HttpParams();
    params=params.append('organization_id',organization_id);
   
    const url=AppComponent.API_URL+ 'delete_organization/DeleteCalendarRelatedActivities';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(map((data:any)=>{
       return data;
    }))
   }

   deletePerformanceMaster(organization_id){
    let params=new HttpParams();
    params=params.append('organization_id',organization_id);
   
    const url=AppComponent.API_URL+ 'delete_organization/DeletePerformanceMaster';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(map((data:any)=>{
       return data;
    }))
   }
   
   deleteCoachingDrill(organization_id){
    let params=new HttpParams();
    params=params.append('organization_id',organization_id);
   
    const url=AppComponent.API_URL+ 'delete_organization/DeleteCoachingDrill';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(map((data:any)=>{
       return data;
    }))
   }
      
   deleteWorkoutMaster(organization_id){
    let params=new HttpParams();
    params=params.append('organization_id',organization_id);
   
    const url=AppComponent.API_URL+ 'delete_organization/DeleteWorkoutMaster';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(map((data:any)=>{
       return data;
    }))
   }
   deleteVideoConfMaster(organization_id){
    let params=new HttpParams();
    params=params.append('organization_id',organization_id);
   
    const url=AppComponent.API_URL+ 'delete_organization/DeleteVideoConfMaster';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(map((data:any)=>{
       return data;
    }))
   }

   deleteLessonPlanMaster(organization_id){
    let params=new HttpParams();
    params=params.append('organization_id',organization_id);
   
    const url=AppComponent.API_URL+ 'delete_organization/DeleteLessonPlanMaster';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(map((data:any)=>{
       return data;
    }))
   }
   
   deleteInjuryLog(organization_id){
    let params=new HttpParams();
    params=params.append('organization_id',organization_id);
   
    const url=AppComponent.API_URL+ 'delete_organization/DeleteInjuryLog';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(map((data:any)=>{
       return data;
    }))
   }
      
   deleteAttendanceAndSession(organization_id){
    let params=new HttpParams();
    params=params.append('organization_id',organization_id);
   
    const url=AppComponent.API_URL+ 'delete_organization/DeleteAttendanceAndSession';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(map((data:any)=>{
       return data;
    }))
   }

   deletePaymentsAndInvoicesAndFeePlans(organization_id){
    let params=new HttpParams();
    params=params.append('organization_id',organization_id);
   
    const url=AppComponent.API_URL+ 'delete_organization/DeletePaymentsAndInvoicesAndFeePlans';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(map((data:any)=>{
       return data;
    }))
   }
   deletePlayerData(organization_id){
    let params=new HttpParams();
    params=params.append('organization_id',organization_id);
   
    const url=AppComponent.API_URL+ 'delete_organization/DeletePlayerData';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(map((data:any)=>{
       return data;
    }))
   }

   deleteStaff(organization_id){
    let params=new HttpParams();
    params=params.append('organization_id',organization_id);
   
    const url=AppComponent.API_URL+ 'delete_organization/DeleteStaff';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(map((data:any)=>{
       return data;
    }))
   }
   deleteDashboard(organization_id){
    let params=new HttpParams();
    params=params.append('organization_id',organization_id);
   
    const url=AppComponent.API_URL+ 'delete_organization/DeleteDashboard';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(map((data:any)=>{
       return data;
    }))
   }

   deleteParentChildMappingRelation(organization_id){
    let params=new HttpParams();
    params=params.append('organization_id',organization_id);
   
    const url=AppComponent.API_URL+ 'delete_organization/DeleteParentChildMappingRelation';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(map((data:any)=>{
       return data;
    }))
   }
   
   deleteAcademyData(organization_id){
    let params=new HttpParams();
    params=params.append('organization_id',organization_id);
   
    const url=AppComponent.API_URL+ 'delete_organization/DeleteAcademyData';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(map((data:any)=>{
       return data;
    }))
   }


  getOrganizationList(customer_id)
  {    
    let params = new HttpParams();  
    params = params.append('customer_id', customer_id );
  
    const local_authetication_url=AppComponent.API_URL+'organization/GetOrganizationsList';
    return this.http_client.post(local_authetication_url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.organization_data =data['data']['organization_list'];
                return this.organization_data;
                
            }),
        );
  } 
}