import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from "@angular/material";
export class AppDateAdapter extends NativeDateAdapter {
  
    date_format=sessionStorage.getItem('date_format');  
    format(date: Date, displayFormat: Object): string {
      
      if (displayFormat == "input") {        
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        ;

        if(this.date_format=="Y-m-d")
        {
          return year + '-' + this._to2digit(month)+'-' + this._to2digit(day);
        }
        else if(this.date_format=="d-m-Y")
        {
          return this._to2digit(day) + '-' + this._to2digit(month) + '-' + year; 
        }
        else if(this.date_format=="Y/m/d")
        {
          return year + '/' + this._to2digit(month)+'/' + this._to2digit(day);
        }
        else if(this.date_format=="d/m/Y")
        {
          return this._to2digit(day) + '/' + this._to2digit(month) + '/' + year;  
        }
        else if(this.date_format=="m/d/Y")
        {
          return this._to2digit(month) + '/' + this._to2digit(day) + '/' + year; 
        }
        else
        {
          return this._to2digit(day) + '/' + this._to2digit(month) + '/' + year; 
        } 
      } else {
        return date.toDateString();
      }
    }
    private _to2digit(n: number) {
      
      return ('00' + n).slice(-2);
    }
  }
    export const APP_DATE_FORMATS =
    {
      
      parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
      },
      display: {
        dateInput: 'input',
        monthYearLabel: { month: 'short', year: 'numeric', day: 'numeric' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
      }
    }