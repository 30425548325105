import { Injectable } from '@angular/core';
import {AppComponent} from '../../app.component';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable()
export class NewsService 
{  
  GetAcademyNewsNotification_data:any;
  add_news_data:any;
  specific_news_data:any;
  edit_news_data:any;
  delete_specific_news_data:any;

  httpOptions;  

  constructor(private http_client: HttpClient) 
  {   
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
    };
  }

   GetAcademyNewsNotification(organization_id, flag)
  {  
    const params = new HttpParams().set('organization_id', organization_id).set('flag', flag);     
    
    const local_GetAcademyNewsNotification_url=AppComponent.API_URL+'news/GetAcademyNewsNotification';
    return this.http_client.post(local_GetAcademyNewsNotification_url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.GetAcademyNewsNotification_data =data['news_template_details'];
                if(this.GetAcademyNewsNotification_data.length!=0)
                {         
                  return this.GetAcademyNewsNotification_data;
                }
                else
                  {
                    return '';
                  }
            }),
        ) 
  }

  AddAcademyNewsNotification(customer_id, organization_id, subject, content, publishing_status,date_published,image,video_url,selectedOption)
  {          
    let params = new HttpParams();    
    params = params.append('organization_id',organization_id);
    params = params.append('customer_id',customer_id);
    params = params.append('subject',subject);
    params = params.append('content',content);
    params = params.append('publishing_status',publishing_status);
    params = params.append('date_published',date_published);
    if(selectedOption=='1')
    {
      params = params.append('image',image);
    }
    if(selectedOption=='2'){
      params = params.append('video_url',video_url);
    }
    
    
    const local_AddAcademyNewsNotification_url=AppComponent.API_URL+'news/AddAcademyNewsNotification';
    return this.http_client.post(local_AddAcademyNewsNotification_url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.add_news_data =data;
                if(this.add_news_data['status']==true)
                {         
                  return this.add_news_data;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }

  GetSpecificNewsNotificationDetails(academy_news_id)
  {
    let params = new HttpParams();    
    params = params.append('academy_news_id',academy_news_id);
    
    const local_specificNewsNotification_url=AppComponent.API_URL+'news/GetAcademyNewsNotificationDetails';
    return this.http_client.post(local_specificNewsNotification_url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.specific_news_data =data['news_template_details'];
                if(this.specific_news_data.length!=0)
                {         
                  return this.specific_news_data;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }

  UpdateAcademyNewsNotification(academy_news_id, customer_id, organization_id, subject,
     content, publishing_status,date_published, image,image_update_flag,video_url,selectedOption)
  {
    let params = new HttpParams(); 
    params = params.append('academy_news_id',academy_news_id);   
    params = params.append('organization_id',organization_id);
    params = params.append('customer_id',customer_id);
    params = params.append('subject',subject);
    params = params.append('content',content);
    params = params.append('publishing_status',publishing_status);    
    params = params.append('date_published',date_published);    
    if(image_update_flag==true)
    {
     params = params.append('image',image);
    } 
    if(selectedOption=='2')   
    {
      params = params.append('video_url',video_url);
    }
    const local_EditAcademyNewsNotification_url=AppComponent.API_URL+'news/UpdateAcademyNewsNotification';
    return this.http_client.post(local_EditAcademyNewsNotification_url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.edit_news_data =data['updated_news_notification'];
                if(this.edit_news_data.length!=0)
                {         
                  return this.edit_news_data;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }

  DeleteAcademyNewsNotification(academy_news_id)
  {
    let params = new HttpParams();    
    params = params.append('academy_news_id',academy_news_id);
    
    const local_DeleteNewsNotification_url=AppComponent.API_URL+'news/DeleteAcademyNewsNotification';
    return this.http_client.post(local_DeleteNewsNotification_url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                this.delete_specific_news_data =data['deleted_news_notification_details'];
                if(this.delete_specific_news_data.length!=0)
                {         
                  return this.delete_specific_news_data;
                }
                else
                  {
                    return '';
                  }
            }),
        )     
  } 
  sendEmailPostNotification(organization_id,login_customer_id,content,subject,publishing_status,
    date_published,selectedOption,image,video_url,recipient,notification_mode,academy_center_id,player_id,
    academy_news_id,customer_id,academy_group_id)
  {
    let params = new HttpParams();    
    params = params.append('organization_id',organization_id);
    params = params.append('login_customer_id',login_customer_id);
    params = params.append('subject',subject);
    params = params.append('content',content);
    params = params.append('publishing_status',publishing_status);
    params = params.append('date_published',date_published);    
    params = params.append('academy_news_id',academy_news_id);
    if(selectedOption=='1')
    {
      params = params.append('image',image);
    }
    if(selectedOption=='2'){
      params = params.append('video_url',video_url);
    }
    notification_mode.forEach((element,i) => {
      params = params.append('notification_mode['+i+']',element);
    });
    if(recipient[0]!='Student/Staff')
    {
      params = params.append('recipient',recipient);
    }
    if(recipient[0]=='Center')
    {
      academy_center_id.forEach((element,i) => {
        params = params.append('academy_center_id['+i+']',element);
      });
    }
    if(recipient[0]=='Batch')
    {
      academy_group_id.forEach((element,i) => {
        params = params.append('academy_group_id['+i+']',element);
      });
    }
    if(recipient[0]=='Student/Staff')
    {
      player_id.forEach((element,i) => {
        params = params.append('player_id['+i+']',element);
      });
      customer_id.forEach((element,i) => {
        params = params.append('customer_id['+i+']',element);
      });
    }
    
    const url=AppComponent.API_URL+'news/SendEmailNewsNotification';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                // this.add_news_data =data['news_notification_added'];
                if(data.length!=0)
                {         
                  return data;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }

  addAcademyNewsAttendees(organization_id,login_customer_id,
    recipient,academy_center_id,player_id,
    academy_news_id,customer_id,academy_group_id)
  {
    let params = new HttpParams();    
    params = params.append('organization_id',organization_id);
    params = params.append('login_customer_id',login_customer_id);  
    params = params.append('academy_news_id',academy_news_id);  
    params = params.append('assignment_category',recipient[0]);
    if(recipient[0]=='Academy')
    {
      params = params.append('attendee_id[0]',organization_id);
    }
    if(recipient[0]=='Center')
    {
      academy_center_id.forEach((element,i) => {
        params = params.append('attendee_id['+i+']',element);
      });
    }
    if(recipient[0]=='Batch')
    {
      academy_group_id.forEach((element,i) => {
        params = params.append('attendee_id['+i+']',element);
      });
    }
    if(recipient[0]=='Student/Staff')
    {
      var id=0;
      player_id.forEach((element,i) => {
        params = params.append('attendee_id['+i+']',element.player_id);
        params = params.append('academy_center_id['+i+']',element.academy_center_id);
        params = params.append('role['+id+']','Student');
        id ++;
      });
      customer_id.forEach((element,i) => {
        params = params.append('attendee_id['+id+']',element.customer_id);
        params = params.append('academy_center_id['+id+']',element.academy_center_id);
        params = params.append('role['+id+']','Staff');
        id ++;
      });
    }
    
    const url=AppComponent.API_URL+'news/AddAcademyNewsAttendees';
    return this.http_client.post(url,params,this.httpOptions)
    .pipe(
            map((data:any) =>{
                // this.add_news_data =data['news_notification_added'];
                if(data['status']==200)
                {         
                  return data;
                }
                else
                  {
                    return '';
                  }
            }),
        )
  }
  socialChannelStatus(organization_id){
    const params = new HttpParams().set("organization_id", organization_id)
                                  //  .set("status", '1');
    const url =AppComponent.API_URL +"socialChannel/ChangeSocialChannelStatus";
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  getSocialChannelUserData(organization_id){
    const params = new HttpParams().set("organization_id", organization_id);
                                  //  .set("status", '1');
    const url =AppComponent.API_URL +"socialChannel/GetSocialChannelUserData";
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

}
