import { DeleteReqListingComponent } from './authentication/delete-req-listing/delete-req-listing/delete-req-listing.component';
import { DataDeletionReqComponent } from './authentication/data_deletion/data-deletion-req/data-deletion-req.component';
import {RouterModule, Routes} from '@angular/router';

import {AdminLayoutComponent} from './layouts/admin/admin-layout.component';
import { LogoutComponent } from './authentication/logout/logout.component';
import { ForgotComponent } from './authentication/forgot/forgot.component';
import {ResetpasswordComponent} from './authentication/resetpassword/resetpassword.component';
import { OrganizationSelectorComponent } from './authentication/login/login_form/organization_selector/organization-selector.component';
import {RegistrationComponent} from './registration/registration.component';
import { IndividualReportComponent } from './individual-report/individual-report.component';
import { TrendReportsComponent } from './individual-report/trend-reports/trend-reports.component';
import { TermOfUseComponent } from './term-of-use/term-of-use.component'; 
import {AuthGuard} from './auth.guard';
import { NgModule } from '@angular/core';
export const appRoutes: Routes = [

  // { 
  //   path: '',
  //   redirectTo: '/register',
  //   pathMatch: 'full'
  // },
  
  { 
    path: '',
    redirectTo: '/login', 
    pathMatch: 'full'
  },

  {
       path: 'login',
       loadChildren: './authentication/login/login.module#LoginModule' ,
  },
  {
    path: 'sos-login',
    loadChildren: './authentication/login/login.module#LoginModule',
  },
  {
    path: 'dataStream-login',
    loadChildren: './authentication/login/login.module#LoginModule',
  },

  {
    path: 'mahd-login',
    loadChildren: './authentication/login/login.module#LoginModule',
  },

  {
    path: 'sportz-village-login',
    loadChildren: './authentication/login/login.module#LoginModule',
  },
  {
    path: 'al-hilal-login',
    loadChildren: './authentication/login/login.module#LoginModule',
  },

  {
    path: 'al-Shabab-login',
    loadChildren: './authentication/login/login.module#LoginModule',
  },

  {
    path: 'yalla-goal-login',
    loadChildren: './authentication/login/login.module#LoginModule',
  },
  {
    path: 'sports-pad-login',
    loadChildren: './authentication/login/login.module#LoginModule',
  },

  {
      path: '',
      component: AdminLayoutComponent,
      children: [
        {
          path: 'student',
          loadChildren: './student-list/student-list.module#StudentListModule',
          canActivate: [AuthGuard],
          runGuardsAndResolvers: 'always',
        }, 
        
        {
          path: 'academyDetails',
          loadChildren: './academy-profile/academy-profile.module#AcademyProfileModule',
          canActivate: [AuthGuard],         
        }, 
              
        {
          path: 'center',
          loadChildren: './center/center.module#CenterModule',
          data: {
            breadcrumb: 'Center'
          },
          canActivate: [AuthGuard]
        },
          {
            path: 'role',
            loadChildren: './role/role.module#RoleModule',
            data: {
              breadcrumb: 'Role'
            },
          canActivate: [AuthGuard]
        },
        {
          path: 'staff',
          loadChildren: './staff/staff.module#StaffModule',
          data: {
            breadcrumb: 'Staff'
          },
          canActivate: [AuthGuard]
      },
      {
        path: 'FeePlans',
        loadChildren: './fee-plans/fee-plans.module#FeePlansModule',
        data: {
          breadcrumb: 'FeePlans'
        },
        canActivate: [AuthGuard]
      },
       
      {
        path: 'Assessment',
        loadChildren: './assessment/assessment.module#AssessmentModule',
        data: {
          breadcrumb: 'Assesment'
        },
        canActivate: [AuthGuard]
      },
      {
        path:'Reports',
        loadChildren: './in-out-report/in-out-report.module#InOutReportModule',        
        canActivate: [AuthGuard]
      },
      
      {
        path:'dashboard',
        loadChildren: './chart_dashboard/chart.module#ChartModule',
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always'
      },
      {
        path:'posts',
        loadChildren: './news/news.module#NewsModule',
        data: {
          breadcrumb: 'News'
        },
        canActivate: [AuthGuard]
      },      

      {
        path:'messaging',
        loadChildren: './messaging/messaging.module#MessagingModule',
        data: {
          breadcrumb: 'Messaging'
        },
        canActivate: [AuthGuard]
      },

      {
        path:'academySettings',
        loadChildren: './academy-settings/academy-settings.module#AcademySettingsModule',
        data: {
          breadcrumb: 'academySetting'
        },
        canActivate: [AuthGuard]
      },

      {
        path:'cheque-tracker',
        loadChildren: './cheque-tracker/cheque-tracker.module#ChequeTrackerModule',
        data: {
          breadcrumb: 'cheque-tracker'
        },
        canActivate: [AuthGuard]
      },
       {
        path:'request',
        loadChildren: './request/request.module#RequestModule',
        data: {
          breadcrumb: 'request'
        },
        canActivate: [AuthGuard]
      },
      {
        path:'expense',
        loadChildren: './expense/expense.module#ExpenseModule',
        data: {
          breadcrumb: 'expense'
        },
        canActivate: [AuthGuard]
      },
      //  {
      //   path:'tournaments',
      //   loadChildren: './tournaments/tournaments.module#TournamentsModule',
      //   data: {
      //     breadcrumb: 'tournaments'
      //   },
      //   canActivate: [AuthGuard]
      // },
      
      // {
      //   path:'asignCoachList',
      //   loadChildren: './asign-coach-tournament-list/asign-coach-tournament-list.module#AsignCoachTournamentListModule',
      //   data: {
      //     breadcrumb: 'tournaments'
      //   },
      //   canActivate: [AuthGuard]
      // },
       {
        path: 'DeregisterList',
        loadChildren: './deregister-student/deregister-student.module#DeregisterStudentModule',
        data: {
          breadcrumb: 'list'
        },
        canActivate: [AuthGuard]
      },
      
      {
        path: 'FeesReports',
        loadChildren: './fees-reports/fees-reports.module#FeesReportsModule',
        data: {
          breadcrumb: 'list'
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'LTADReports',
        loadChildren: './ltad-reports/ltad-reports.module#LTADReportsModule',
        data: {
          breadcrumb: 'list'
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'attendance-tracker',
        loadChildren: './attendance-tracker/attendance-tracker.module#AttendanceTrackerModule',
        data: {
          breadcrumb: 'list'
        },
        canActivate: [AuthGuard]
      },  
      {
        path: 'report',
        loadChildren: './report-page-dashboard/report-page-dashboard.module#ReportPageDashboardModule',
        data: {
          breadcrumb: 'list'
        },
        canActivate: [AuthGuard]
      }, 
       {
        path: 'payment-tracker',
        loadChildren: './payment-tracker/payment-tracker.module#PaymentTrackerModule',
        data: {
          breadcrumb: 'list'
        },
        canActivate: [AuthGuard]
      }, 
      {
        path: 'competition-tracker',
        loadChildren: './competition-tracker-report/competition-tracker-report.module#CompetitionTrackerReportModule',
        data: {
          breadcrumb: 'list'
        },
        canActivate: [AuthGuard]
      }, 
      {
        path: 'lesson-plans',
        loadChildren: './lesson-plans/lesson-plans.module#LessonPlansModule',
        data: {
          breadcrumb: 'list'
        },
        canActivate: [AuthGuard]
      },

      {
        path: 'coaching-drill',
        loadChildren: './coaching-drill/coaching-drill.module#CoachingDrillModule',
        data: {
          breadcrumb: 'list'
        },
        canActivate: [AuthGuard]
      }, 
      {
        path: 'session-tracker',
        loadChildren: './session-tracker/session-tracker.module#SessionTrackerModule',
        data: {
          breadcrumb: 'list'
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'session-report',
        loadChildren: './session-tracker/session-tracker.module#SessionTrackerModule',
        data: {
          breadcrumb: 'list'
        },
        canActivate: [AuthGuard]
      },
      ],
      
    },  
     
   
   {
      path: '**',
      redirectTo: 'error/404'
},
{
  path: 'logout',
  component: LogoutComponent,
    data: {
      breadcrumb: 'Logout'
    }
},
{
  path: 'forgot',
  component: ForgotComponent,
  data: {
    breadcrumb: 'Forgot'
  }
},
{
  path: 'resetPassword',
  component: ResetpasswordComponent,
  data: {
    breadcrumb: 'ResetPassword'
  }
},
{
  path: 'organization_selector',
  component: OrganizationSelectorComponent,
  canActivate: [AuthGuard],
  data: {
    breadcrumb: 'Select Organization'
  }
},

{
  path: 'organization_selector/:organization_id',
  component: OrganizationSelectorComponent,
  canActivate: [AuthGuard]
},

{
  path: 'register',
  component: RegistrationComponent,
  data: {
          breadcrumb: 'Registration'
        }
},
{
  path: 'individual-report',
  component: IndividualReportComponent,
  data: {
          breadcrumb: 'individual-report'
        }
},
{
  path: 'trend-report',
  component: TrendReportsComponent,
  data: {
          breadcrumb: 'trendReportsComponent'
        }
},
{
  path: 'terms-of-use',
  component: TermOfUseComponent,
  data: {
          breadcrumb: 'termOfUseComponent'
        }
},
{
  path:'data-deletion-req',
  component:DataDeletionReqComponent,
  data:{
    breadcrumb: 'DataDeletionReqComponent'
  }
},
{
  path:'deletion-request-listing',
  component:DeleteReqListingComponent,
  data:{
    breadcrumb :'DeleteReqListingComponent'
  }
}

];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes,{onSameUrlNavigation: 'reload',scrollPositionRestoration:'enabled'})
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
