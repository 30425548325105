import { AppComponent } from './../../../app.component';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataDeletionReqService {
  httpOptions: any;

  constructor(private http_client: HttpClient) {

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };
  }

  addDeleteRequest(name, email_address, mobile_number, organization_name, reason_for_deletion) {
    let params = new HttpParams();
    params = params.append('name', name);
    params = params.append('email_address', email_address);
    params = params.append('mobile_number', mobile_number);
    params = params.append('organization_name', organization_name);
    params = params.append('reason_for_deletion', reason_for_deletion);

    const url = AppComponent.API_URL + 'request/AddDeleteRequest';
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data;
        }),
      )

  }

}
