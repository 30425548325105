import { Injectable } from '@angular/core';
import {AppComponent} from '../../app.component';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable()
export class StudentRankingService {
  assesment_data:any=''; 
  student_performance_ranking:any=''; 
  player_list:any=''; 
  headers = new Headers();
  parameterList:any;
  tag_list:any;
  add_tag_list:any;
  delete_tag_details:any;
  student_tag_list:any;

 httpOptions;   
  player_assesment_details: any;

  constructor(private http_client: HttpClient) 
  {   
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
    };
  }

// getAssesmentDetails(organization_id) 
// {
//   const  params = new  HttpParams().set('organization_id', organization_id); 
//   const local_assesment_url=AppComponent.API_URL+'performance/GetPerformanceAssessment';
//   return this.http_client.post(local_assesment_url,params,this.httpOptions)
//   .pipe(
//             map((data:any) =>{
//                 this.assesment_data =data['performance_assessment_details'];
//                 if(this.assesment_data.length!=0)
//                 {         
//                   return this.assesment_data;
//                 }
//                 else
//                   {
//                     return '';
//                   }
//             }),
//         )    
// }
getAssesmentDetails(organization_id) 
{
  const  params = new  HttpParams().set('organization_id', organization_id); 
  const local_assesment_url=AppComponent.API_URL+'performance/GetPerformanceAssessmentForDropdown';
  return this.http_client.post(local_assesment_url,params,this.httpOptions)
  .pipe(
            map((data:any) =>{
                this.assesment_data =data['performance_assessment_details'];
                if(this.assesment_data.length!=0)
                {         
                  return this.assesment_data;
                }
                else
                  {
                    return '';
                  }
            }),
        )    
}


 getPlayersPerformanceRanking(performance_set_assessment_id) 
{
  const  params = new  HttpParams().set('performance_set_assessment_id', performance_set_assessment_id); 
  const local_assesment_url=AppComponent.API_URL+'player/GetPlayerPerformanceRanking';
  return this.http_client.post(local_assesment_url,params,this.httpOptions)
  .pipe(
            map((data:any) =>{
                this.student_performance_ranking =data['performance_assessment_details'];
                if(this.student_performance_ranking.length!=0)
                {         
                  return this.student_performance_ranking;
                }
                else
                  {
                    return '';
                  }
            }),
        )
      
}

getPlayerList(flag,organization_id,performance_set_assessment_id) 
{
   const  params = new  HttpParams().set('flag', flag)
                                    .set('organization_id', organization_id)
                                    .set('filters[performance_ranking][performance_set_assessment_id]', performance_set_assessment_id);  

  const local_getplayer_url=AppComponent.API_URL+'player/GetPlayerList';
  return this.http_client.post(local_getplayer_url,params,this.httpOptions)
  .pipe(
            map((data:any) =>{
                this.player_list =data['center_player_data'];
                if(this.player_list.length!=0)
                {         
                  return this.player_list;
                }
                else
                  {
                    return '';
                  }
            }),
        )      
}  

getPerformanceParameterList(performance_set_id,performance_set_assessment_id,organization_id) 
{
  let params = new HttpParams();    
  // params =params.append('performance_set_id', performance_set_id);
  params =params.append('performance_set_assessment_id', performance_set_assessment_id);
  params =params.append('organization_id', organization_id);
  params =params.append('flag', "ranking");
  
  const local_assesment_url=AppComponent.API_URL+'performance/GetPerformanceParameterList';
  return this.http_client.post(local_assesment_url,params,this.httpOptions)
  .pipe(
            map((data:any) =>{
                this.parameterList =data['performance_parameter_details'];
                if(this.parameterList.length!=0)
                {         
                  return this.parameterList;
                }
                else
                  {
                    return '';
                  }
            }),
        )     
}
getPlayerListWithFilter(role_flag, organization_id, filter_value, filter_type,
  age_flag,performance_set_assessment_id,filter_tags_id) 
{  
  let params = new HttpParams();    
    params =params.append('organization_id', organization_id);
    params =params.append('flag', role_flag);
    params =params.append('filters[performance_ranking][performance_set_assessment_id]', performance_set_assessment_id);

    for (var i = 0; i < filter_type.length; i++) 
    {
      if (filter_type[i] == "Gender") 
      {
        if(filter_value[i]['Gender']!=undefined)
        {
          params =params.append('filters[gender]', filter_value[i]['Gender']);
        }
        
      }
      else if (filter_type[i] == "Age") 
      {
        if(filter_value[i]['Age'][0]!=null && filter_value[i]['Age'][1]!=null)
        {               
        params =params.append('filters[age][after]', filter_value[i]['Age'][0]);
        params =params.append('filters[age][before]', filter_value[i]['Age'][1]);
        }
        else if(age_flag=="Y" && filter_value[i]['Age'][0]!=undefined)
        {
          params =params.append('filters[age][after]', filter_value[i]['Age'][0]);
        }
        else if(age_flag=="N" && filter_value[i]['Age'][0]!=undefined)
        {
          params =params.append('filters[age][before]', filter_value[i]['Age'][0]);
        }
      } 
      else if(filter_type[i] == "Score") 
      {
        for (var j = 0; j < filter_value[i]['Score'].length; j++) 
        {
          params =params.append('filters[performance_ranking][score_id]'+'['+j+']', filter_value[i]['Score'][j]);          
        }
      }
      else if(filter_type[i] == "performanceParameterId")
      {
        for (var j = 0; j < filter_value[i]['performanceParameterId'].length; j++) 
        {   
          params =params.append('filters[performance_ranking][performance_parameter_id]'+'['+j+']', filter_value[i]['performanceParameterId'][j]); 
        }
      }
      else if(filter_type[i] == "Toppers") 
      {        
        if(filter_value[i]['Toppers']!=undefined)
        {
          params =params.append('filters[toppers]', filter_value[i]['Toppers']); 
        }
          
        
      }
      else if(filter_type[i] == "Group") 
      {   
        for (var j = 0; j < filter_value[i]['Group'].length; j++) 
        {   
          params =params.append('filters[group]'+'['+j+']', filter_value[i]['Group'][j]); 
        }    
         
        
      }
    }
    if(filter_tags_id)
    {
      filter_tags_id.forEach((element,j) => {
        params =params.append('filters[tag]'+'['+j+']', element); 
      });
    }
  const local_getplayer_url=AppComponent.API_URL+'player/GetPlayerList';
  return this.http_client.post(local_getplayer_url,params,this.httpOptions)
  .pipe(
            map((data:any) =>{
                this.player_list =data['center_player_data'];
                if(this.player_list.length!=0)
                {         
                  return this.player_list;
                }
                else
                  {
                    return '';
                  }
            }),
        )       
}

getTagList(academy_id,tag_name)
{
  let params = new HttpParams();    
  params =params.append('academy_id', academy_id);
  if(tag_name!='')
  {
    params =params.append('tag_name', tag_name);
  }  
  const local_getTagList_url=AppComponent.API_URL+'tag/GetTagListByTagName';
  return this.http_client.post(local_getTagList_url,params,this.httpOptions)
  .pipe(
            map((data:any) =>{
                this.tag_list =data['tag'];
                if(this.tag_list.length!=0)
                {         
                  return this.tag_list;
                }
                else
                  {
                    return '';
                  }
            }),
        )  
}
getTagsDetailsByPlayer(player_ids,academy_id)
{
  let params = new HttpParams();    
  params =params.append('academy_id', academy_id);
  player_ids.forEach((item, index) => {
    params =params.append('player_ids['+index+']',item);
  });   
  const local_getTagdetails_url=AppComponent.API_URL+'tag/GetTagsDetailsByPlayer';
  return this.http_client.post(local_getTagdetails_url,params,this.httpOptions)
  .pipe(
            map((data:any) =>{
                this.student_tag_list =data['player'];
                if(this.student_tag_list.length!="" )
                {         
                  return this.student_tag_list;
                }
                else
                  {
                    return '';
                  }
            }),
        )
}
addTagsDetails(customer_id,tag_list,academy_id,player_id,batch_ids,performance_set_assessment_id)
{
  let params = new HttpParams();    
  params =params.append('customer_id', customer_id);
  params =params.append('academy_id', academy_id);
  params =params.append('performance_set_assessment_id', performance_set_assessment_id);
  
  tag_list.forEach((element,i) => {    
    params =params.append('tag['+i+'][tag_id]',element['tag_id']);
    params =params.append('tag['+i+'][tag_name]',element['tag_name']);
  });
  
  player_id.forEach((item, index) => {
    params =params.append('player['+index+'][player_id]',item);
     
  }); 
  batch_ids.forEach((item, index) => {
    params =params.append('player['+index+'][academy_group_id]',item);   
  });
  const local_addTagList_url=AppComponent.API_URL+'tag/AddPlayersTagsDetails';
  return this.http_client.post(local_addTagList_url,params,this.httpOptions)
  .pipe(
            map((data:any) =>{
                this.add_tag_list =data['player_tag'];
                if(this.add_tag_list!='' )
                {         
                  return this.add_tag_list;
                }
                else
                  {
                    return '';  
                  }
            }),
        )
}
deletePlayerAllTag(customer_id,player_id,academy_id)
{
  let params = new HttpParams();
  params =params.append('customer_id', customer_id);
  params =params.append('academy_id',academy_id);  
  player_id.forEach((item, index) => {
    params =params.append('player['+index+'][player_id]',item);
  }); 
  
  const local_deleteTagList_url=AppComponent.API_URL+'tag/DeletePlayerAllTag';
  return this.http_client.post(local_deleteTagList_url,params,this.httpOptions)
  .pipe(
            map((data:any) =>{
                this.delete_tag_details =data['delete_player_tag'];
                if(this.delete_tag_details.length!=0)
                {         
                  return this.delete_tag_details;
                }
                else
                  {
                    return '';
                  }
            }),
        )
}
deletePlayerTag(customer_id,academy_id,player_id,tag_id)
{
  let params = new HttpParams();    
  params = params.append('customer_id', customer_id);
  params = params.append('academy_id', academy_id);
  params = params.append('player_id', player_id);
  params = params.append('tag_id', tag_id);

    
  const url=AppComponent.API_URL+'tag/DeletePlayerTag';
  return this.http_client.post(url,params,this.httpOptions)
   .pipe(
            map((data:any) =>{
                
                if(data['delete_player_tag'])
                {         
                  return data['delete_player_tag'];
                }
                else
                  {
                    return 'false';
                  }
            }),
        )
}

getPlayerAssesmentDetails(performance_set_assessment_id,organization_id)
{
  const  params = new  HttpParams().set('performance_set_assessment_id', performance_set_assessment_id)
                                  .set('organization_id', organization_id);
  
  const local_player_assesment_url=AppComponent.API_URL+'player/GetPlayerAssessment';
  return this.http_client.post(local_player_assesment_url,params,this.httpOptions)
   .pipe(
          map((data:any) =>{
              this.player_assesment_details =data['performance_assessment_details'];
              if(this.player_assesment_details.length!=0)
              {         
                return this.player_assesment_details;
              }
              else
                {
                  return '';
                }
          }),
      )
}

}