import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { map } from 'rxjs/operators';
import { debug } from 'console';


@Injectable({
  providedIn: 'root'
})
export class ExpenseService {

  httpOptions: any;
  request_list: any;
  request_details: any;
  update_request_status: any;
  staff_data: any = "";
  request_status: any;
  date_format: any;
  temp_date: string;


  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };
  }

  getExpenseRequestList(organization_id, status, staff_id, from_date, to_date, date_format) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    if (status != undefined) {
      params = params.append('filters[status]', status);
    }
    if (staff_id != undefined) {
      params = params.append('filters[staff_id]', staff_id);
    }
    if (from_date != undefined && from_date != null) {
      params = params.append('filters[from_date]', this.dateFormater(from_date, date_format));

    }
    if (to_date != undefined && to_date != null) {
      params = params.append('filters[to_date]', this.dateFormater(to_date, date_format));
    }

    const get_expense_request_list = AppComponent.API_URL + 'request/GetExpenseRequestList';
    return this.http.post(get_expense_request_list, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['message'] == 'Request List') {
            this.request_list = data['data']['request_list'];
            if (this.request_list.length != 0) {
              return this.request_list;
            }
            else {
              return '';
            }
          } else {
            return '';
          }
        }),
      )
  }

  getExpenseRequestDetails(organization_id, request_id) {

    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('request_id', request_id);

    const get_expense_reqest_details = AppComponent.API_URL + 'request/GetExpenseRequestDetails';
    return this.http.post(get_expense_reqest_details, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.request_details = data['data']['request_data'];
          if (this.request_details.length != 0) {
            return this.request_details;
          }
          else {
            return '';
          }
        }
        )
      )
  }

  updateExpenseRequestStatus(request_id, status, rejection_reason) {
  
    let params = new HttpParams();
    params = params.append('request_id', request_id);
    if (status == 'Approved') {
      params = params.append('status', status);
    }
    else {
      params = params.append('status', status);
      params = params.append('rejection_reason', rejection_reason);
    }
    console.log("params =>", params);
    const update_expense_request_status = AppComponent.API_URL + 'request/UpdateExpenseRequestStatus';
    return this.http.post(update_expense_request_status, params, this.httpOptions)
      .pipe(map((data: any) => {
        this.update_request_status = data['data']['update_request_data_status'];
        if (this.update_request_status != 0) {
          return this.update_request_status;
        }
        else {
          return '';
        }
      }
      ))
  }

  getPendingExpenseDetails(status, organization_id, staff_id,from_date,to_date,date_format) {

    let params = new HttpParams();
    params = params.append('status', status);
    params = params.append('organization_id', organization_id);
    
    if (staff_id != undefined) {
      params = params.append('filters[staff_id]', staff_id);
    }
  
    if (from_date != undefined && from_date != null) {
      params = params.append('filters[from_date]', this.dateFormater(from_date, date_format));

    }
    if (to_date != undefined && to_date != null) {
      params = params.append('filters[to_date]', this.dateFormater(to_date, date_format));
    }
    const url = AppComponent.API_URL + 'request/GetPendingExpenseDetails';
    return this.http.post(url, params, this.httpOptions)
      .pipe(map((data: any) => {
        if (data['status'] == 200) {
          return data;
        }
        else {
          return '';
        }
      }))
  }


  getStaffDetails(organization_id, role_type) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    role_type='C';
    params = params.append('filters[role_type]', role_type);
    const local_staff_url = AppComponent.API_URL + "user/GetCenterUserList ";
    return this.http
      .post(local_staff_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.staff_data = data["User List"];
          if (this.staff_data.length != 0 && data != false) {
            return this.staff_data;
          } else {
            return "";
          }
        })
      );
  }


  dateFormater(date, date_format) {
    this.date_format = sessionStorage.getItem('date_format');
    var day: any;
    var month: any;
    day = date.getDate();
    month = (date.getMonth() + 1);
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    if (this.date_format == "Y-m-d") {
      return this.temp_date = date.getFullYear() + "-" + month + "-" + day;
    }
    if (this.date_format == "d-m-Y") {
      return this.temp_date = day + "-" + month + "-" + date.getFullYear();
    }
    if (this.date_format == "Y/m/d") {
      return this.temp_date = date.getFullYear() + "/" + month + "/" + day;
    }

    if (this.date_format == "d/m/Y") {
      return this.temp_date = day + "/" + month + "/" + date.getFullYear();
    }

    if (this.date_format == "m/d/Y") {
      return this.temp_date = day + "/" + month + "/" + date.getFullYear();
    }

  }

}

