import { Injectable } from '@angular/core';
import {AppComponent} from '../../app.component';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { ApiUrlConstant } from '../../../app/apiUrl.constant';

@Injectable()
export class RoleService {
  role_data:any='';
  set_role_data:any='';  
  delete_role_details:any='';
  is_Allow_DeleteRole:any='';
  specific_role_data:any='';
  modified_role_details:any='';
  exist_role_details:any;
  accessControlQues_list:any;
  access_control_details:any;
  specificRole_access_control_details:any;
  update_access_control_details:any;

  httpOptions;   

  constructor(private http_client: HttpClient) 
  {   
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',
        'Authorization':''+ localStorage.getItem("access_token")
        })
    };
  }
  getRoleDetails(organization_id)
  {   
    const  params = new  HttpParams().set('organization_id', organization_id);
    const local_role_url=AppComponent.API_URL+ApiUrlConstant.GetRole;    
    return this.http_client.post(local_role_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.role_data =data['Role State'];
            if(this.role_data.length!=0)
            {         
              return this.role_data;
            }
            else
              {
                return '';
              }
          }),
        )  
  }
  getSpecificRoleDetails(organization_id,role_id)
  {
    const  params = new  HttpParams().set('organization_id', organization_id).set('role_id', role_id);  
    const local_specific_role_url=AppComponent.API_URL+ApiUrlConstant.GetRole;
    return this.http_client.post(local_specific_role_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.specific_role_data =data['Role State'];
            if(this.specific_role_data.length!=0)
            {         
              return this.specific_role_data;
            }
            else
              {
                return '';
              }
          }),
        )    
  }
  //View role list
  SetRole(role_name, role_type, organization_id)
  {
    const  params = new  HttpParams().set('organization_id', organization_id).set('role_name', role_name).set('role_type', role_type);
     
    const local_set_role_url=AppComponent.API_URL+ApiUrlConstant.SetRole;
    return this.http_client.post(local_set_role_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.set_role_data =data['Role State'];
            if(this.set_role_data.length!=0)
            {         
              return this.set_role_data;
            }
            else
              {
                return '';
              }
          }),
        )     
  }
  IsAllowDeleteRole(role_id)
  {
    const  params = new  HttpParams().set('role_id', role_id);  
        
    const local_delete_role_url=AppComponent.API_URL+ApiUrlConstant.IsAllowDeleteRole;
    return this.http_client.post(local_delete_role_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.is_Allow_DeleteRole =data['role_details'];
            if(this.is_Allow_DeleteRole.length!=0)
            {         
              return this.is_Allow_DeleteRole;
            }
            else
              {
                return '';
              }
          }),
        )      
  }

  DeleteRole(role_id,deleted_by_customer_id)
  {
    const  params = new  HttpParams().set('role_id', role_id).set('deleted_by_customer_id', deleted_by_customer_id);  
         
    const local_delete_role_url=AppComponent.API_URL+ApiUrlConstant.DeleteRole;
    return this.http_client.post(local_delete_role_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.delete_role_details =data['delete_role_details'];
            if(this.delete_role_details.length!=0)
            {         
              return this.delete_role_details;
            }
            else
              {
                return '';
              }
          }),
        )   
  }

  modifyRole(role_id, role_name, role_type, organization_id)
  {  
    const  params = new  HttpParams().set('role_id', role_id)
                                    .set('role_name', role_name)
                                    .set('role_type', role_type)
                                    .set('organization_id', organization_id);  
       
    const local_modify_role_url=AppComponent.API_URL+ApiUrlConstant.SetRole;
    return this.http_client.post(local_modify_role_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.modified_role_details =data['Role State'];
            if(this.modified_role_details.length!=0)
            {         
              return this.modified_role_details;
            }
            else
              {
                return '';
              }
          }),
        )    
  }
  CheckRoleDetails(organization_id,role_type,role_name)
  { 
    const  params = new  HttpParams().set('organization_id', organization_id)
                                    .set('role_name', role_name)
                                    .set('role_type', role_type);     
     
    const local_checkrole_url=AppComponent.API_URL+ApiUrlConstant.CheckRoleDetails;
    return this.http_client.post(local_checkrole_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.exist_role_details =data['Role State'];
            if(this.exist_role_details.length!=0)
            {         
              return this.exist_role_details;
            }
            else
              {
                return '';
              }
          }),
        )      
  }
  accessControlQuestionsByRoleType(role_type)
  { 
    const  params = new  HttpParams().set('role_type', role_type); 

    const local_accessCntrlQuesList_url=AppComponent.API_URL+ApiUrlConstant.AccessControlQuestionsByRoleType;
    return this.http_client.post(local_accessCntrlQuesList_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.accessControlQues_list =data['question_list_details'];
            if(this.accessControlQues_list.length!=0)
            {         
              return this.accessControlQues_list;
            }
            else
              {
                return '';
              }
          }),
        )      
  } 
  getOrganizationModulesQuestionsList(role_type,organization_id){ 
    const  params = new  HttpParams().set('filter[role]', role_type)
                                     .set('organization_id', organization_id);  

    const local_accessCntrlQuesList_url=AppComponent.API_URL+ApiUrlConstant.GetOrganizationModulesQuestionsList;
    return this.http_client.post(local_accessCntrlQuesList_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.accessControlQues_list =data['modules'];
            if(this.accessControlQues_list.length!=0)
            {         
              return this.accessControlQues_list;
            }
            else
            {
              return '';
            }
          }),
        )      
  } 

  addAccessControlDetails(roleId,accessControlQues_list)
  {    
    let params = new HttpParams();  

    accessControlQues_list.forEach((element,i) => {
      params = params.append('access_control_details['+i+'][role_id]', roleId);
      params =params.append('access_control_details['+i+'][module_id]', element.module_id); 
      params =params.append('access_control_details['+i+'][question_id]', element.question_id); 
      if(element.answer==true)
      {
        params =params.append('access_control_details['+i+'][answer]', "yes"); 
      }
      else
      {
        params =params.append('access_control_details['+i+'][answer]', "no"); 
      }
    });
    // const local_addAccessControl_url=AppComponent.API_URL+'entitlement/AddAccessControlDetails';
    const local_addAccessControl_url=AppComponent.API_URL+ApiUrlConstant.AddAccessControlDetails;
    return this.http_client.post(local_addAccessControl_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.access_control_details =data['access_control_details'];
            if(this.access_control_details.length!=0)
            {         
              return this.access_control_details;
            }
            else
              {
                return '';
              }
          }),
        )        
  } 

  //combine service
  addRoleEntitlementDetails(role_name,role_type,organization_id,module_ids,question_ids,answers)
  {   
    let params = new HttpParams(); 
    params =params.append('role_name', role_name);        
    params =params.append('role_type', role_type); 
    params = params.append('organization_id', organization_id); 

    for(var i=0;i<module_ids.length;i++)
    {
      params =params.append('access_control_details['+i+'][module_id]', module_ids[i]); 
    }
    for(var i=0;i<question_ids.length;i++)
    {
      params =params.append('access_control_details['+i+'][question_id]', question_ids[i]); 
    }
    for(var i=0;i<answers.length;i++)
    {
      if(answers[i]==true)
      {
        params =params.append('access_control_details['+i+'][answer]', "yes"); 
      }
      else
      {
        params =params.append('access_control_details['+i+'][answer]', "no"); 
      }
    }

    const local_addAccessControl_url=AppComponent.API_URL+ApiUrlConstant.AddRoleEntitlementDetails;
    return this.http_client.post(local_addAccessControl_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.access_control_details =data['role_entitlement_details'];
            if(this.access_control_details.length!=0)
            {         
              return this.access_control_details;
            }
            else
              {
                return '';
              }
          }),
        )
  } 
  
  getAccessControlDetailsByRoleID(role_id)  
  {
    const params = new HttpParams().set('role_id', role_id);     
    //const local_getAccessControlDetailsByRoleID_url=AppComponent.API_URL+'entitlement/GetAccessControlDetailsByRoleID';
    // const local_getAccessControlDetailsByRoleID_url=AppComponent.API_URL+'entitlement_granularization/GetAccessControlDetailsByRoleID';
    const local_getAccessControlDetailsByRoleID_url=AppComponent.API_URL+ApiUrlConstant.GetRoleEntitlementDetails;
    return this.http_client.post(local_getAccessControlDetailsByRoleID_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.specificRole_access_control_details =data['role_entitlement_details']['access_control_details'];
            if(this.specificRole_access_control_details.length!=0)
            {         
              return this.specificRole_access_control_details;
            }
            else
              {
                return '';
              }
          }),
        )
  } 
  
  updateRoleEntitlementDetails(roleId,accessControlQues_list)
  {
    
    let params = new HttpParams(); 

  

    accessControlQues_list.forEach((element,i) => {
      params = params.append('access_control_details['+i+'][role_id]', roleId);
      params =params.append('access_control_details['+i+'][rights_id]',element.rights_id); 
      params =params.append('access_control_details['+i+'][module_id]', element.module_id); 
      params =params.append('access_control_details['+i+'][question_id]', element.question_id); 
      if(element.answer==true)
      {
        params =params.append('access_control_details['+i+'][answer]', "yes"); 
      }
      else
      {
        params =params.append('access_control_details['+i+'][answer]', "no"); 
      }
    });

    // const local_updateAccessControl_url=AppComponent.API_URL+'entitlement/UpdateAccessControlDetails';
    const local_updateAccessControl_url=AppComponent.API_URL+ApiUrlConstant.UpdateAccessControlDetails;
    return this.http_client.post(local_updateAccessControl_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.update_access_control_details =data['access_control_details'];
            if(this.update_access_control_details.length!=0)
            {         
              return this.update_access_control_details;
            }
            else
              {
                return '';
              }
          }),
        )  

  }  

}