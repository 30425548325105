import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';
import { DataDeletionReqService } from './data-deletion-req.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-data-deletion-req',
  templateUrl: './data-deletion-req.component.html',
  styleUrls: ['./data-deletion-req.component.css'],

})
export class DataDeletionReqComponent implements OnInit {
  data_deletion_form: any;
  name: any;
  email_address: any;
  mobile_number: any;
  organization_name: any;
  reason_for_deletion: any;
  data_deletion: any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  setAutoHide: boolean = true;
  autoHide: number = 3000;
  actionButtonLabel: string = 'Close';
  action: boolean = true;
  success_msg: string;
  

  constructor(private fb: FormBuilder, private deletion_service: DataDeletionReqService, public snackBar: MatSnackBar,
    private router: Router) { }

  ngOnInit() {
    this.data_deletion_form = this.fb.group({
      name: [null, (Validators.required)],
      email_address: [null, Validators.compose([Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}|(\d{10})$/)])],
      mobile_number: [null, [Validators.required, Validators.pattern(/^(\+\d{1,3}[- ]?)?\d{10}$/)]],
      organization_name: [null, (Validators.required)],
      reason_for_deletion: [null, (Validators.required)]
    })
  }

  submitForm() {

    if (this.data_deletion_form.valid) {
      const fromData = this.data_deletion_form.value;
      this.deletion_service.addDeleteRequest(fromData.name, fromData.email_address, fromData.mobile_number, fromData.organization_name,
        fromData.reason_for_deletion).subscribe(
          response => {
            if (response != '') {
              this.data_deletion = response;
              this.success_msg = "Delete Request has been submitted";
              this.snackBarDisplay();
              this.router.navigate(['login']);

            }
            else {
              this.success_msg = "Something went wrong";
              this.snackBarDisplay();
            }
          }
        )
    }

  }

  snackBarDisplay() {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    this.snackBar.open(this.success_msg, this.action ? this.actionButtonLabel : undefined, config);
  }

}


