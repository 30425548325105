import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { LoginService } from '../authentication/login_services/login.service';

@Component({
  selector: 'app-term-of-use',
  templateUrl: './term-of-use.component.html',
  styleUrls: ['./term-of-use.component.css']
})
export class TermOfUseComponent implements OnInit {
  term_conditon_data: any;

  constructor(public dialog: MatDialog,private login_service: LoginService,private router: Router) { }

  ngOnInit() {
    this.openTermsCondition();
  }
  @ViewChild('term_conditon') term_conditon: TemplateRef<any>;
  openTermsCondition()
  {
    this.dialog.open(this.term_conditon,
      {      
        width:'550px',
        disableClose:true,
      });    
      this.login_service.getUserTermCondition().subscribe(
        response => 
        {
          if(response!='')
          {
          this.term_conditon_data=response;
          }
          else
          {
            console.log('error');
          }
        },
        error => console.log("getUserTermCondition IS NOT WORKING!")
    );
  }
  cancel(){
    this.router.navigate(['login']);
  }

}
