<div class="login_page_wrapper login-div">  
  <div class="background-image" *ngIf="!sos_login_flag && !datastream_flag && !sportz_village_flag && !yalla_goal_flag && !sports_pad_flag">
    <img class="img-fluid" src="assets/images/bg-1.jpg" alt="Acadware" />  
  </div>   
  <div class="background-image" *ngIf="sos_login_flag">
      <img class="img-fluid" src="assets/images/sos-background.png" alt="Acadware" />  
  </div>  
  <div class="background-image" *ngIf="datastream_flag" style="background: black;padding-top: 4.5%;">
    <img class="img-fluid" src="assets/images/datastream.png" alt="Acadware" style="background: black;"/>  
  </div> 
  <div class="background-image sportz-village" *ngIf="sportz_village_flag" style="background: white;">
    <img class="img-fluid1" src="assets/images/SVLogin.png" />  
  </div>     

  <div class="background-image" *ngIf="yalla_goal_flag" style="background: white;">
    <img class="img-fluid1" src="assets/images/yalla_goal.png" />  
  </div> 
  <div class="background-image" *ngIf="sports_pad_flag" style="background: white;">
    <img class="img-fluid1" src="assets/images/sportspadbag.png" />  
  </div> 
     

  <div class="adjustment-div"  *ngIf="!sos_login_flag">
    <p class=""  *ngIf="!datastream_flag && !sportz_village_flag && !yalla_goal_flag && !sports_pad_flag" style="text-align:center; margin-top:70px; margin-bottom:20px; float:left; width:100%;">
      <span class="acad-img" >
        <img class="img-fluid" src="assets/images/acadware_logo_vme_white.png" alt="Acadware" style="width:215px;" />
      </span>    
    </p>
    <p class="" *ngIf="datastream_flag" style="text-align:center; margin-top:6px; margin-bottom:210px; float:left; width:100%;">     
      <span class="acad-img" >
        <img class="img-fluid" src="assets/images/datastreamLogo.png" alt="Acadware" style="width:215px;" />
      </span>
    </p>
    <p class="" *ngIf="sportz_village_flag" style="text-align:center; margin-top:20px; margin-bottom:155px; float:left; width:100%;">           
      <span class="acad-img">
          <img  src="assets/images/SV.png" alt="Acadware" style="width:215px;" />
      </span>
    </p>

    <p class="" style="text-align:center; margin-top:20px; margin-bottom:20px; float:left; width:100%;"
         *ngIf="yalla_goal_flag">           
            <span class="acad-img">
                <img  src="assets/images/yalla_goal_logo.png" style="width:215px;" />
            </span>
        </p>

    <p class="" style="text-align:center; margin-top:20px; margin-bottom:20px; float:left; width:100%;"
      *ngIf="sports_pad_flag">           
        <span class="acad-img">
            <img  src="assets/images/sportspadIcon.png" style="width:130px;" />
        </span>
    </p>

    <div class="md-card" id="login_card" style="clear:both;border-radius: 7px;">            
      <div class="md-card-toolbar-actions">
          <div [style.display]="spinner_div" style="float:left; width:100%;"><mat-progress-bar mode="indeterminate"></mat-progress-bar></div>
      </div>

        <div class="md-card-content large-padding" id="login_form">
               
          <div class="login_heading">
            <h2 style="margin:0px 0px 20px 0px;" *ngIf="!datastream_flag && !sportz_village_flag && !yalla_goal_flag && !sports_pad_flag">{{'forgotPassword.ResetPassword' |translate}}</h2>
            <h2 style="margin:0px 0px 20px 0px;color:rgb(112,77,159)" *ngIf="datastream_flag">{{'forgotPassword.ResetPassword' |translate}}</h2>
            <h2 style="margin:0px 0px 20px 0px;color:#026EB0" *ngIf="sportz_village_flag">{{'forgotPassword.ResetPassword' |translate}}</h2>
            <h2 style="margin:0px 0px 20px 0px;color:#568B3F"  *ngIf="yalla_goal_flag">{{'forgotPassword.ResetPassword' |translate}}</h2> 
            <h2 style="margin:0px 0px 20px 0px;color:#EF7021"  *ngIf="sports_pad_flag">{{'forgotPassword.ResetPassword' |translate}}</h2> 
          </div>

          <form class="md-float-material" [formGroup]="resetPassword_form" >
                
            <div class="example-container-reset" >
              <div>
                  <mat-error *ngIf="success_msg">{{ success_msg }}</mat-error>
                  <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
              </div>

              <mat-form-field appearance="outline"> 
                <mat-label>{{'EmailAddress' | translate}} / {{'MOBILE' | translate}}</mat-label>                       
                  <input matInput placeholder="Email Address" formControlName="Email" readonly>                 
              </mat-form-field>

              <mat-form-field appearance="outline" style="margin-top: 15px;">  
                <mat-label>{{'forgotPassword.NewPassword' | translate}}</mat-label>                         
                  <input matInput placeholder="{{'forgotPassword.NewPassword' | translate}}" formControlName="Password" [type]="hide ? 'text':'password' "  autocomplete="off">
                  <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility':'visibility_off'}}</mat-icon>
                  <mat-error style="margin-top: 18px;" *ngIf="!resetPassword_form.controls['Password'].valid && resetPassword_form.controls['Password'].touched  || form_invalid_flag">{{ passwordAlert }}</mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" style="margin-top: 15px;">    
                <mat-label>{{'forgotPassword.ConfirmPassword' | translate}}</mat-label>                       
                  <input matInput placeholder="{{'forgotPassword.ConfirmPassword' | translate}}" formControlName="Confirm_Password" [type]="hide1 ? 'text':'password'"  autocomplete="off">
                  <mat-icon matSuffix (click)="hide1 = !hide1">{{hide1 ? 'visibility':'visibility_off' }}</mat-icon>
                  <mat-error style="margin-top: 18px;" *ngIf="!resetPassword_form.controls['Confirm_Password'].valid && resetPassword_form.controls['Confirm_Password'].touched || form_invalid_flag">{{ passwordAlert }}</mat-error>
              </mat-form-field>
                    
                <div class="button-row" style="text-align:center; margin-top:20px;">
                  <button mat-raised-button id="submit" *ngIf="!datastream_flag && !sportz_village_flag && !yalla_goal_flag && !sports_pad_flag" (click)="resetPassword(resetPassword_form.value)" type="submit" style="background-color: #13b8d5;">{{'Login' | translate}}</button>
                  <button mat-raised-button id="submit" *ngIf="datastream_flag" (click)="resetPassword(resetPassword_form.value)" type="submit" style="background-color:rgb(112,77,159);color:white;">{{'Login' | translate}}</button>
                  <button mat-raised-button id="submit" *ngIf="sportz_village_flag" (click)="resetPassword(resetPassword_form.value)" type="submit" style="background-color:#026EB0;color:white;">{{'Login' | translate}}</button>
                  <button mat-raised-button id="submit" *ngIf="yalla_goal_flag" (click)="resetPassword(resetPassword_form.value)" type="submit" style="background-color:#568B3F;color:white;">{{'Login' | translate}}</button>
                  <button mat-raised-button id="submit" *ngIf="sports_pad_flag" (click)="resetPassword(resetPassword_form.value)" type="submit" style="background-color:#EF7021;color:white;">{{'Login' | translate}}</button>
                </div>
            </div>
          </form>
      </div>
    </div>  
  </div>


  <div class="adjustment-div"  *ngIf="sos_login_flag">
    <p class="" style="text-align:center; margin-top:70px; margin-bottom:20px; float:left; width:100%;">
      <span class="acad-img">
        <img class="img-fluid" src="assets/images/sos-logo.png" alt="Acadware" style="width:265px;" />
      </span>
    </p>

    <div class="md-card" id="login_card" style="clear:both;border-radius: 0px;background-color: rgba(65,67,66);">            
      <div class="md-card-toolbar-actions">
          <div [style.display]="spinner_div" style="float:left; width:100%;"><mat-progress-bar mode="indeterminate"></mat-progress-bar></div>
      </div>

        <div class="md-card-content large-padding" id="login_form">
               
          <div class="login_heading">
              <h2 style="margin:0px 0px 20px 0px;color: white;">{{'forgotPassword.ResetPassword' |translate}}</h2>
          </div>

          <form class="md-float-material" [formGroup]="resetPassword_form" >
                
            <div class="example-container-reset" >
              <div>
                  <mat-error *ngIf="success_msg">{{ success_msg }}</mat-error>
                  <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
              </div>

              <mat-form-field  style="background-color: rgb(51,51,51);" appearance="outline">        
                <mat-label style="color: white;">{{'EmailAddress' | translate}} / {{'MOBILE' | translate}}</mat-label>                  
                <input matInput style="color: white;" placeholder="Email Address" formControlName="Email" readonly>                 
              </mat-form-field>

              <mat-form-field style="background-color: rgb(51,51,51);margin-top: 15px;margin-bottom: 15px;" appearance="outline">  
                <mat-label style="color: white;">{{'forgotPassword.NewPassword' | translate}}</mat-label>                        
                  <input matInput style="color: white;" formControlName="Password" [type]="hide ? 'text':'password' "  autocomplete="off">
                  <mat-icon matSuffix (click)="hide = !hide" style="color: white;">{{hide ? 'visibility':'visibility_off'}}</mat-icon>
                  <mat-error *ngIf="!resetPassword_form.controls['Password'].valid && resetPassword_form.controls['Password'].touched || form_invalid_flag" style="margin-top: 18px;">{{ passwordAlert }}</mat-error>
              </mat-form-field>

              <mat-form-field style="background-color: rgb(51,51,51);margin-top: 15px;margin-bottom: 15px;" appearance="outline">  
                <mat-label style="color: white;">{{'forgotPassword.ConfirmPassword' | translate}}</mat-label>                       
                  <input matInput style="color: white;" formControlName="Confirm_Password" [type]="hide1 ? 'text':'password'"  autocomplete="off">
                  <mat-icon matSuffix (click)="hide1 = !hide1" style="color: white;">{{hide1 ? 'visibility':'visibility_off' }}</mat-icon>
                  <mat-error *ngIf="!resetPassword_form.controls['Confirm_Password'].valid && resetPassword_form.controls['Confirm_Password'].touched || form_invalid_flag" style="margin-top: 18px;">{{ passwordAlert }}</mat-error>
              </mat-form-field>
                   
                <div class="button-row" style="text-align:center; margin-top:10px;" >
                  <button mat-raised-button (click)="resetPassword(resetPassword_form.value)" type="submit" style="background-color: rgb(255,240,43);border-radius: 30px;width: 100%;height: 45px;">{{'Login' | translate}}</button>
                </div>
                
            </div>
          </form>
      </div>
    </div>  
  </div>
</div>